import React, { PureComponent } from 'react';

import { Text, View, Avatar, Pressable, Icon, TextArea, AnimatedDots } from '../../components/base';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";
import { withNavigation } from 'react-navigation';
import { ifIphoneX } from 'react-native-iphone-x-helper'
import { sendPrivateMessage } from "../../state/contact/actions";
import { connect } from "react-redux";
import Validator from '../../utils/validator';
import { LinearGradient } from "expo-linear-gradient";
import { Toast } from "../../components";

class Footer extends PureComponent {

    state = {
        isLoading: false,
        message: ''
    };

    render() {

        const {
            theme,
            styles,
            style,
            me,
            intl,
            navigation,
            isTransportOnline,
            ...restProps
        } = this.props;

        return (
            <View style={styles.wrapper}>
                {isTransportOnline ? (
                    <View style={styles.container}>
                        <Avatar style={styles.avatar} avatarUrl={me.avatar_normal} />
                        <TextArea
                            style={styles.textArea}
                            onChangeText={(message) => this.setState({ message })}
                            value={this.state.message}
                            placeholder={intl.formatMessage({ id: 'conversation.input_placeholder' })}
                            placeholderTextColor={theme.COLORS.SECONDARY_TEXT}
                        />
                        <Pressable style={[
                            styles.submitButton,
                            this.state.isLoading ? { opacity: 0.6 } : null
                        ]} disabled={this.state.isLoading} onPress={(this.state.message !== ''
                            ? this.onPressSubmit
                            : this.onPressSendPhoto
                        )}>
                            <Icon name={(
                                this.state.message !== ''
                                    ? 'send'
                                    : 'image'
                            )} color={(
                                this.state.message !== ''
                                    ? theme.COLORS.TEXT
                                    : theme.COLORS.SECONDARY_TEXT
                            )} size={17} />
                        </Pressable>
                    </View>
                ) : (
                    <View style={styles.offlineContainer}>
                        <LinearGradient
                            {...theme.COLORS.GRADIENTS.DANGER}
                            style={styles.reconnectingGradient}>
                            <Text style={styles.reconnectingText}>
                                {intl.formatMessage({ id: 'common.reconnecting' })}<AnimatedDots style={styles.reconnectingText} />
                            </Text>
                        </LinearGradient>
                    </View>
                )}
            </View>
        );
    }

    onPressSubmit = async () => {
        const { intl } = this.props;
        await this.setState({
            isLoading: true,
            message: this.state.message.replace(/^\s+|\s+$/g, '')
        });
        let validator = new Validator([
            ['message', Validator.rules.NOT_EMPTY],
            ['message', Validator.rules.LENGTH, { min: 1, max: 1000 }]
        ], {
            message: this.state.message
        });
        if(await validator.validate()) {
            let { success, errors } = await this.props.sendPrivateMessage(
                this.props.contact.id,
                this.state.message
            );
            if(success) {
                await this.setState({ message: '' });
            } else if(errors) {
                validator.setErrors(errors);
            }
        }
        const errorsMessages = validator.getIntlErrorsMessages(intl);
        if(errorsMessages['message']) {
            Toast.showSimpleWarning(errorsMessages['message']);
        }
        await this.setState({ isLoading: false });
    };

    onPressSendPhoto = async () => {
        await this.props.onPressUploadPicture();
    };
}

const mapStateToProps = ({ user, common }) => ({
    me: user.me,
    isTransportOnline: common.isTransportOnline
});

const mapDispatchToProps = dispatch => ({
    sendPrivateMessage: (contactID, message) => dispatch(sendPrivateMessage(contactID, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withNavigation(injectIntl(withStyles(Footer, theme => ({
        wrapper: {
            width: '100%',
            justifySelf: 'flex-end',
            backgroundColor: theme.COLORS.BACKGROUND
        },
        container: {
            paddingTop: 10,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingLeft: 36 + (1.5 * theme.SIZES.CONTENT_HORIZONTAL_PADDING),
            paddingRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            ...ifIphoneX({
                paddingBottom: 20
            }, {
                paddingBottom: 10
            })
        },
        offlineContainer: {
            paddingTop: 10,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingLeft: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            paddingRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            ...ifIphoneX({
                paddingBottom: 20
            }, {
                paddingBottom: 10
            })
        },
        submitButton: {
            position: 'absolute',
            height: 36,
            width: 36,
            alignItems: 'center',
            justifyContent: 'center',
            right: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            ...ifIphoneX({
                bottom: 20
            }, {
                bottom: 10
            })
        },
        textArea: {
            backgroundColor: theme.COLORS.BACKGROUND,
            borderRadius: 15,

            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            marginTop: 0,
            overflow: 'hidden',

            paddingLeft: 10,
            paddingRight: 30,

            paddingTop: theme.SIZES.PLATFORM_OS === 'android' ? 4 : 8.5,
            paddingBottom: theme.SIZES.PLATFORM_OS === 'android' ? 4 : 8.5,

            maxHeight: 140,
            color: theme.COLORS.TEXT,
            fontSize: 14,
            textAlign: 'left'
        },
        avatar: {
            position: 'absolute',
            width: 36,
            height: 36,
            borderRadius: 15,
            top: 10,
            left: theme.SIZES.CONTENT_HORIZONTAL_PADDING
        },
        reconnectingGradient: {
            height: 36,
            width: '100%',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center'
        },
        reconnectingText: {
            color: theme.COLORS.WHITE,
            fontWeight: '900'
        }
    }))))
);
