/**
 * @param permissions
 * @param action
 * @param targetCityID
 * @param targetUniversityID
 * @returns {boolean}
 */
export const checkPermissions = (permissions, action, targetCityID, targetUniversityID) => {
    for (let i in permissions) {
        if(targetUniversityID) {
            if(permissions[i].permission === 'admin' && ((!permissions[i].university_id && !permissions[i].city_id) || permissions[i].university_id == targetUniversityID)) {
                return true;
            } else if(permissions[i].permission === 'moderator' && ((!permissions[i].university_id && !permissions[i].city_id) || permissions[i].university_id == targetUniversityID)) {
                return true;
            }
        } else if (targetCityID) {
            if(permissions[i].permission === 'admin' && ((!permissions[i].university_id && !permissions[i].city_id) || permissions[i].city_id == targetCityID)) {
                return true;
            } else if(permissions[i].permission === 'moderator' && ((!permissions[i].university_id && !permissions[i].city_id) || permissions[i].city_id == targetCityID)) {
                return true;
            }
        }
    }
    return false;
};
