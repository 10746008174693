import React, { PureComponent } from 'react';

import { Animated } from "react-native";
import { Text, View, Pressable, Icon } from '../../components/base';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";
import { withNavigation } from 'react-navigation';
import OptionsModal from "./OptionsModal";

class HeaderCollapsed extends PureComponent {

    state = {
        isOptionsModalOpened: false
    };

    render() {

        const {
            theme,
            styles,
            style,
            scrollY,
            channel,
            intl,
            navigation,
            inverted,
            ...restProps
        } = this.props;

        const headerHeight = !inverted ? scrollY.interpolate({
            inputRange: [-theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT, 0, theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT-theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT],
            outputRange: [theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT / 3, 0, -(theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT-theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT) / 2],
            extrapolate: 'clamp'
        }) : theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT;

        const headerTitleOpacity = !inverted ? scrollY.interpolate({
            inputRange: [theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT * 1.5, theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT-theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT],
            outputRange: [0, 1],
            extrapolate: 'clamp'
        }) : 1;

        return (
            <View style={styles.wrapper} pointerEvents={'box-none'}>
                <Animated.View style={[ styles.panel, {
                    opacity: headerTitleOpacity,
                } ]}>
                    {!!channel.thumbnail_original && (
                        <Animated.Image blurRadius={3} style={styles.coverImage} source={{ uri: channel.thumbnail_original }} />
                    )}
                    <View style={!!channel.thumbnail_large ? styles.contentWithZoom : styles.content} >
                        <Pressable onPress={this.onPress}>
                            <Text numberOfLines={1} style={styles.headingText}>
                                {channel.subject}
                            </Text>
                        </Pressable>
                    </View>
                    <View style={styles.iconsRow} pointerEvents={'box-none'}>
                        <View style={{ flex: 1, alignItems: 'flex-start' }} pointerEvents={'box-none'}>
                            <Pressable onPress={this.onPressBack} style={styles.backButton}>
                                <Icon name={'chevron-down'} color={theme.COLORS.TEXT} size={25} />
                            </Pressable>
                        </View>
                        <View style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row' }} pointerEvents={'box-none'}>
                            {!!channel.thumbnail_large && (
                                <Pressable onPress={this.props.scaleTopicImage} style={{ marginRight: 10 }}>
                                    <Icon name={'zoom-in'} color={theme.COLORS.TEXT} size={20} />
                                </Pressable>
                            )}
                            <Pressable onPress={() => this.setState({ isOptionsModalOpened: true })}>
                                <Icon name={'more-horizontal'} color={theme.COLORS.TEXT} size={25} />
                            </Pressable>
                        </View>
                    </View>
                </Animated.View>
                {!inverted && (
                    <Animated.View style={[styles.expandedIconsRow, {
                        transform: [{ translateY: headerHeight }]
                    }]}>
                        <View style={{ flex: 1, alignItems: 'flex-start' }}>
                            <Pressable onPress={this.onPressBack} style={styles.backButton}>
                                <Icon name={'chevron-down'} color={theme.COLORS.TEXT} size={25} />
                            </Pressable>
                        </View>
                        <View style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row' }}>
                            {!!channel.thumbnail_large && (
                                <Pressable onPress={this.props.scaleTopicImage} style={{ marginRight: 10 }}>
                                    <Icon name={'zoom-in'} color={theme.COLORS.TEXT} size={20} />
                                </Pressable>
                            )}
                            <Pressable onPress={() => this.setState({ isOptionsModalOpened: true })}>
                                <Icon name={'more-horizontal'} color={theme.COLORS.TEXT} size={25} />
                            </Pressable>
                        </View>
                    </Animated.View>
                )}
                <OptionsModal
                    visible={this.state.isOptionsModalOpened}
                    channel={channel}
                    showReport={this.props.showReport}
                    onClose={() => this.setState({ isOptionsModalOpened: false })}
                />
            </View>
        );
    }

    onPressBack = () => {
        const { navigation } = this.props;
        navigation.goBack(null);
    };

    onPress = () => {
        this.props.onPress();
    };
}

export default withNavigation(injectIntl(withStyles(HeaderCollapsed, theme => ({
    wrapper: {
        position: 'absolute',
        width: '100%',
        height: theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT,
        overflow: 'hidden',
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 25,
        top: 0
    },
    panel: {
        width: '100%',
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 25,
        justifyContent: 'center',
        overflow: 'hidden',
        height: theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        position: 'absolute',
        top: 0
    },
    coverImage: {
        position: 'absolute',
        opacity: .5,
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT
    },
    content: {
        paddingTop: 0,
        paddingHorizontal: 60
    },
    contentWithZoom: {
        paddingTop: 0,
        paddingHorizontal: 80
    },
    headingText: {
        color: theme.COLORS.TEXT,
        fontSize: 18,
        fontFamily: theme.FONTS.BALOO,
        textAlign: 'center'
    },
    iconsRow: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: theme.SIZES.HEADER_HORIZONTAL_PADDING,
        paddingTop: 0,
        position: 'absolute',
        height: theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT,
        alignItems: 'center'
    },
    expandedIconsRow: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: theme.SIZES.HEADER_HORIZONTAL_PADDING,
        position: 'absolute',
        top: 0,
        height: theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT,
        alignItems: 'center'
    },
    backButton: {
        marginLeft: -4
    }
}))));
