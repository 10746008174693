import {
    GET_USER,
    GET_ME,
    UPDATE_PROFILE_IMAGE,
    GET_USER_KARMA_HISTORY,
    LOAD_MORE_USER_KARMA_HISTORY,
    LOAD_MORE_CHANNEL_MESSAGES,
    LOAD_MORE_CHANNEL_MEMBERS_LIST,
    GET_USER_CHANNEL_MESSAGES,
    GET_USER_CHANNELS,
    LOAD_MORE_USER_CHANNELS,
    GET_NOTIFICATIONS,
    READ_NOTIFICATIONS,
    LOAD_MORE_NOTIFICATIONS,
    UPDATE_USER_PROFILE,
    CLEAR_USER_REDUCER,
    BLOCK_USER,
    UNBLOCK_USER,
    GET_USER_CONTACTS,
    UPDATE_USER_MUTE,
    LOAD_MORE_USER_CONTACTS
} from '../action-types';

let initialState = {
    users: {},
    karma_history: {},
    user_contacts: {},
    channel_messages: {},
    user_channels: {},
    notifications: [],
    me: null,
    ignoreList: []
};

export default (state = { ...initialState }, action) => {
    const {type, payload} = action;
    switch (type) {
        case GET_USER:
            let {userID, data} = payload,
                user = {};
            user[userID] = data;
            return {
                ...state,
                users: {
                    ...state.users,
                    ...user
                },
            };
        case GET_ME:
            return {
                ...state,
                me: payload.data,
                ignoreList: payload.data.ignoreList || []
            };
        case UPDATE_USER_MUTE:
            return {
                ...state,
                me: {
                    ...state.me,
                    mute: payload.data
                }
            };
        case UPDATE_PROFILE_IMAGE:
            let { profileImage } = payload;
            return {
                ...state,
                me: {
                    ...state.me,
                    ...profileImage
                }
            };
        case BLOCK_USER:
            if(state.users[payload.userID]) {
                state.users[payload.userID].blocked = true;
            }
            state.ignoreList.push(payload.userID);
            return {
                ...state,
                ignoreList: [
                    ...state.ignoreList
                ],
                users: {
                    ...state.users
                },
            };
        case UNBLOCK_USER:
            if(state.users[payload.userID]) {
                state.users[payload.userID].blocked = false;
            }
            const _index = state.ignoreList.indexOf(payload.userID);
            if(_index > -1) {
                state.ignoreList.splice(_index, 1);
            }
            return {
                ...state,
                ignoreList: [
                    ...state.ignoreList
                ],
                users: {
                    ...state.users,
                },
            };
        case UPDATE_USER_PROFILE:
            return {
                ...state,
                me: {
                    ...state.me,
                    ...payload.data
                }
            };
        case GET_USER_KARMA_HISTORY:
            let karma_history = {};
            karma_history[payload.userID] = payload.data;
            return {
                ...state,
                karma_history: {
                    ...state.karma_history,
                    ...karma_history
                },
            };
        case GET_USER_CONTACTS:
            let user_contacts = {};
            user_contacts[payload.userID] = payload.data;
            return {
                ...state,
                user_contacts: {
                    ...state.user_contacts,
                    ...user_contacts
                },
            };
        case LOAD_MORE_CHANNEL_MEMBERS_LIST:
            if(state.karma_history[payload.userID]) {
                state.karma_history[payload.userID] = state.karma_history[payload.userID].concat(payload.data);
            }
            return {
                ...state,
                karma_history: {
                    ...state.karma_history
                },
            };
        case GET_USER_CHANNEL_MESSAGES:
            let channel_messages = {};
            channel_messages[payload.userID] = payload.data;
            return {
                ...state,
                channel_messages: {
                    ...state.channel_messages,
                    ...channel_messages
                },
            };
        case LOAD_MORE_CHANNEL_MESSAGES:
            if(state.channel_messages[payload.userID]) {
                state.channel_messages[payload.userID] = state.channel_messages[payload.userID].concat(payload.data);
            }
            return {
                ...state,
                channel_messages: {
                    ...state.channel_messages
                },
            };
        case GET_USER_CHANNELS:
            let user_channels = {};
            user_channels[payload.userID] = payload.data;
            return {
                ...state,
                user_channels: {
                    ...state.user_channels,
                    ...user_channels
                },
            };
        case LOAD_MORE_USER_CHANNELS:
            if(state.user_channels[payload.userID]) {
                state.user_channels[payload.userID] = state.user_channels[payload.userID].concat(payload.data);
            }
            return {
                ...state,
                user_channels: {
                    ...state.user_channels
                },
            };
        case LOAD_MORE_USER_KARMA_HISTORY:
            if(state.karma_history[payload.userID]) {
                state.karma_history[payload.userID] = state.karma_history[payload.userID].concat(payload.data);
            }
            return {
                ...state,
                karma_history: {
                    ...state.karma_history
                },
            };
        case LOAD_MORE_USER_CONTACTS:
            if(state.user_contacts[payload.userID]) {
                state.user_contacts[payload.userID] = state.user_contacts[payload.userID].concat(payload.data);
            }
            return {
                ...state,
                user_contacts: {
                    ...state.user_contacts
                },
            };
        case GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: payload.data
            };
        case LOAD_MORE_NOTIFICATIONS:
            if(state.notifications) {
                state.notifications = state.notifications.concat(payload.data);
            }
            return {
                ...state
            };
        case READ_NOTIFICATIONS:
            state.me.hasNotifications = false;
            state.me.notifications_count = 0;
            return {
                ...state,
                me: {
                    ...state.me
                }
            };
        case CLEAR_USER_REDUCER:
            return { ...initialState };
        default:
            return state;
    }
};
