import React from 'react';

import { injectIntl } from "react-intl";
import { withStyles } from "../../theme";
import { KView, View, Screen, Text, Icon, Pressable, SView } from '../../components/base';
import { TabView, TextInput, TextArea, Button, Checkbox, Select, Toast } from "../../components";
import { getMe, updateProfile, updatePassword } from '../../state/user/actions';
import { clearStore, logout } from "../../state/auth/actions";
import { connect } from "react-redux";
import screenNames from "../../navigation/screenNames";
import styles from "./styles";
import Constants from "expo-constants";
import Validator from '../../utils/validator';
import { wait } from "../../utils/async";
import {
    changeLocale,
    changeTheme,
    getCities,
    getCountries,
    getLocation,
    getUniversities,
    sendUniversitySubmission,
    updateLocation
} from "../../state/common/actions";
import { getListOfLocales } from "../../utils/localizationHelper";
import { list as listOfThemes } from "../../theme";
import Events from "../../utils/events";

class SettingsScreen extends Screen {

    screenName = screenNames.SETTINGS;

    state = {
        isLoading: false,
        isReloading: false,
        isSubmissionLoading: false,
        isSubmissionSubmitted: false,
        initialTab: SettingsScreen.Tabs.PROFILE,
        locationForm: {
            countryID: null,
            cityID: null,
            universityID: null
        },
        submissionFormData: {
            title: ''
        },
        submissionErrors: {},
        countries: [],
        cities: [],
        universities: [],
        form: {
            nickname: this.props.me.nickname,
            full_name: this.props.me.full_name,
            gender: this.props.me.gender,
            bio: this.props.me.bio,
            restricted_mode: this.props.me.restricted_mode,
            website: this.props.me.website
        },
        locale: this.props.locale,
        themeName: this.props.themeName,
        passwordForm: {
            old_password: '',
            new_password: ''
        },
        errors: {}
    };

    static Tabs = {
        PROFILE: 'profile',
        APPLICATION: 'app',
        LOCATION: 'location',
        PRIVACY: 'privacy'
    };

    content() {

        const { form, errors, themeName, locale, isLoading, isReloading, initialTab } = this.state;
        const { theme, styles, me, navigation, intl } = this.props;
        const {
            isSubmissionLoading,
            locationForm,
            submissionFormData,
            submissionErrors,
            countries,
            cities,
            universities,
            isSubmissionSubmitted
        } = this.state;

        if(isReloading) {
            return null;
        }

        return (
            <KView style={styles.screenWrapper}>
                <View style={styles.headerWrapper}>
                    <Text style={[
                        styles.headingText,
                        styles.headingTextOffset
                    ]}>
                        {intl.formatMessage({ id: 'settings.heading' })}
                    </Text>
                    <Text style={styles.subHeadingText}>
                        @{me.nickname}
                    </Text>
                    <Pressable onPress={() => navigation.goBack()} style={styles.headerBackButton}>
                        <Icon name={'arrow-left'} color={theme.COLORS.TEXT} size={23} />
                    </Pressable>
                </View>
                <TabView
                    scrollTabBarEnabled
                    selectedKey={initialTab}
                    tabs={[ {
                        key: SettingsScreen.Tabs.PROFILE,
                        icon: 'user',
                        title: intl.formatMessage({ id: 'settings.profile' }),
                    }, {
                        key: SettingsScreen.Tabs.LOCATION,
                        icon: 'map-pin',
                        title: intl.formatMessage({ id: 'edit_location.heading' }),
                    }, {
                        key: SettingsScreen.Tabs.APPLICATION,
                        icon: 'smartphone',
                        title: intl.formatMessage({ id: 'settings.application' }),
                    }, {
                        key: SettingsScreen.Tabs.PRIVACY,
                        icon: 'lock',
                        title: intl.formatMessage({ id: 'settings.privacy' }),
                    } ]}
                    onChange={(key) => {
                        if(key === SettingsScreen.Tabs.LOCATION) {
                            return this.onNavigateLocation()
                        }
                    }}
                    routes={{
                        [SettingsScreen.Tabs.PROFILE]: (
                            <SView
                                style={styles.formWrapper}
                                contentContainerStyle={styles.formContentWrapper}
                            >
                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'settings.name' })}:
                                    </Text>
                                    <TextInput
                                        style={styles.formInput}
                                        error={errors.full_name}
                                        autoCorrect={false}
                                        placeholder={intl.formatMessage({ id: 'settings.name_placeholder' })}
                                        variation={'default'}
                                        value={form.full_name}
                                        onChangeText={(full_name) => this.setState({ form: { ...form, full_name } })}
                                    />
                                </View>

                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'settings.nickname' })}:
                                    </Text>
                                    <TextInput
                                        style={styles.formInput}
                                        icon={'at-sign'}
                                        autoCapitalize={'none'}
                                        autoCorrect={false}
                                        placeholder={intl.formatMessage({ id: 'settings.nickname_placeholder' })}
                                        variation={'default'}
                                        value={form.nickname}
                                        error={errors.nickname}
                                        onChangeText={(nickname) => this.setState({ form: { ...form, nickname } })}
                                    />
                                </View>

                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'settings.gender' })}:
                                    </Text>
                                    <Select
                                        style={styles.formInput}
                                        variation={'default'}
                                        value={form.gender}
                                        error={errors.gender}
                                        onChange={(gender) => this.setState({ form: { ...form, gender } })}
                                        placeholder={intl.formatMessage({ id: 'settings.gender_placeholder' })}
                                        showHeadingValue
                                        options={[
                                            { key: null, heading: '👽', text: intl.formatMessage({ id: 'settings.not_selected' }) },
                                            { key: 1, heading: '👨', text: intl.formatMessage({ id: 'settings.male' }) },
                                            { key: 2, heading: '👩',  text: intl.formatMessage({ id: 'settings.female' }) },
                                        ]}
                                    />
                                </View>

                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'settings.website' })}:
                                    </Text>
                                    <TextInput
                                        placeholder={intl.formatMessage({ id: 'settings.website_placeholder' })}
                                        icon={'link'}
                                        autoCapitalize={'none'}
                                        autoCorrect={false}
                                        style={styles.formInput}
                                        variation={'default'}
                                        value={form.website}
                                        error={errors.website}
                                        onChangeText={(website) => this.setState({ form: { ...form, website } })}
                                    />
                                </View>

                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'settings.bio' })}:
                                    </Text>
                                    <TextArea
                                        placeholder={intl.formatMessage({ id: 'settings.bio_placeholder' })}
                                        value={form.bio}
                                        error={errors.bio}
                                        onChangeText={(bio) => this.setState({ form: { ...form, bio } })}
                                        style={styles.formInputArea}
                                        variation={'default'}
                                    />
                                </View>

                                <Button
                                    onPress={this.onPressSubmitProfile}
                                    style={styles.submitButton}
                                    variation={'primary'}
                                    size={'medium'}
                                    loading={isLoading}
                                >
                                    {intl.formatMessage({ id: 'common.save' })}
                                </Button>

                                <Button
                                    onPress={this.onPressLogout}
                                    style={styles.logoutButton}
                                    variation={'transparent'}
                                    size={'medium'}
                                >
                                    <Text style={styles.logoutButtonText}>
                                        {intl.formatMessage({ id: 'settings.logout' })}
                                    </Text>
                                </Button>
                            </SView>
                        ),
                        [SettingsScreen.Tabs.LOCATION]: (
                            <SView
                                style={styles.formWrapper}
                                contentContainerStyle={styles.formContentWrapper}
                            >
                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'edit_location.country' })}:
                                    </Text>
                                    <Select
                                        style={styles.formInput}
                                        variation={'default'}
                                        value={locationForm.countryID}
                                        error={errors.countryID}
                                        onChange={this.onSelectCountry}
                                        placeholder={intl.formatMessage({ id: 'edit_location.country_placeholder' })}
                                        showHeadingValue
                                        options={countries}
                                    />
                                </View>

                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'edit_location.city' })}:
                                    </Text>
                                    <Select
                                        style={styles.formInput}
                                        variation={'default'}
                                        value={locationForm.cityID}
                                        error={errors.cityID}
                                        onChange={this.onSelectCity}
                                        placeholder={intl.formatMessage({ id: 'edit_location.city_placeholder' })}
                                        options={cities}
                                    />
                                </View>

                                {!(locationForm.cityID && universities.length === 0) && (
                                    <View style={styles.formField}>
                                        <Text style={styles.formLabelText}>
                                            {intl.formatMessage({ id: 'edit_location.education_institution' })}:
                                        </Text>
                                        <Select
                                            style={styles.formInput}
                                            variation={'default'}
                                            disabled={!locationForm.cityID}
                                            error={errors.universityID}
                                            value={locationForm.universityID}
                                            onChange={this.onSelectUniversity}
                                            placeholder={intl.formatMessage({ id: 'edit_location.education_institution_placeholder' })}
                                            options={universities}
                                        />
                                    </View>
                                )}

                                {locationForm.cityID && universities.length === 0 && !isSubmissionSubmitted && (
                                    <View style={[
                                        styles.formField, { backgroundColor: theme.COLORS.BACKGROUND_ITEM, borderRadius: 17.5, padding: 10 }
                                    ]}>
                                        <Text style={styles.formLabelText}>
                                            {intl.formatMessage({ id: 'edit_location.no_education_institution' }, {
                                                cityName: this.selectedCity()
                                            })}
                                        </Text>
                                        <TextInput
                                            placeholder={intl.formatMessage({ id: 'edit_location.name_placeholder' })}
                                            style={[
                                                styles.formInput, { marginTop: 5 }
                                            ]}
                                            error={submissionErrors.title}
                                            value={submissionFormData.title}
                                            onChangeText={(title) => this.setState({ submissionFormData: { ...this.state.submissionFormData, title } })}
                                            textInputStyle={{ backgroundColor: theme.COLORS.BACKGROUND }}
                                            variation={'default'}
                                        />
                                        <Button
                                            style={{ marginTop: 10 }}
                                            variation={'secondary'}
                                            size={'small'}
                                            onPress={this.onPressSubmitUniversity}
                                            loading={isSubmissionLoading}
                                        >
                                            {intl.formatMessage({ id: 'common.submit' })}
                                        </Button>
                                    </View>
                                )}

                                <Button
                                    style={styles.submitButton}
                                    variation={'primary'}
                                    size={'medium'}
                                    onPress={this.onPressChange}
                                    loading={isLoading}
                                >
                                    {intl.formatMessage({ id: 'common.change' })}
                                </Button>

                            </SView>
                        ),
                        [SettingsScreen.Tabs.APPLICATION]: (
                            <SView
                                style={styles.formWrapper}
                                contentContainerStyle={styles.formContentWrapper}
                            >
                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'settings.language' })}:
                                    </Text>
                                    <Select
                                        showHeadingValue
                                        style={styles.formInput}
                                        variation={'default'}
                                        value={locale}
                                        onChange={this.handleChangeLocale}
                                        options={getListOfLocales().map(item => ({
                                            key: item.key,
                                            heading: item.icon,
                                            text: item.name
                                        }))}
                                    />
                                </View>

                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'settings.appearance' })}:
                                    </Text>
                                    <Select
                                        showHeadingValue
                                        style={styles.formInput}
                                        variation={'default'}
                                        value={themeName}
                                        onChange={this.handleChangeTheme}
                                        options={Object.keys(listOfThemes).map(themeName => ({
                                            key: themeName,
                                            heading: listOfThemes[themeName].icon,
                                            text: listOfThemes[themeName].title
                                        }))}
                                    />
                                </View>

                                <View style={styles.formField}>
                                    <Checkbox
                                        style={styles.formInputSwitch}
                                        labelStyle={styles.formLabelText}
                                        variation={'switch'}
                                        value={form.restricted_mode}
                                        onChange={(value) => this.setState({ form: { ...form, restricted_mode: value ? 1 : 0 } })}
                                        label={intl.formatMessage({ id: 'settings.restricted_mode_label' }) + ':'}
                                    />
                                </View>

                                <Button
                                    onPress={this.onPressSubmitProfile}
                                    style={styles.submitButton}
                                    variation={'primary'}
                                    size={'medium'}
                                    loading={isLoading}
                                >
                                    {intl.formatMessage({ id: 'common.save' })}
                                </Button>

                                <Text style={styles.versionText}>v{Constants.manifest.version}</Text>
                            </SView>
                        ),
                        [SettingsScreen.Tabs.PRIVACY]: (
                            <SView style={styles.formWrapper}>
                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'settings.old_password' })}:
                                    </Text>
                                    <TextInput
                                        style={styles.formInput}
                                        variation={'default'}
                                        icon={'lock'}
                                        secureTextEntry={true}
                                        error={errors.old_password}
                                        onChangeText={(old_password) => this.setState({ passwordForm: { ...this.state.passwordForm, old_password } })}
                                    />
                                </View>
                                <View style={styles.formField}>
                                    <Text style={styles.formLabelText}>
                                        {intl.formatMessage({ id: 'settings.new_password' })}:
                                    </Text>
                                    <TextInput
                                        style={styles.formInput}
                                        autoCapitalize={'none'}
                                        autoCorrect={false}
                                        variation={'default'}
                                        error={errors.new_password}
                                        onChangeText={(new_password) => this.setState({ passwordForm: { ...this.state.passwordForm, new_password } })}
                                    />
                                </View>
                                <Button
                                    onPress={this.onPressChangePassword}
                                    style={styles.submitButton}
                                    size={'medium'}
                                    variation={'primary'}
                                    loading={isLoading}
                                >
                                    {intl.formatMessage({ id: 'settings.change_password' })}
                                </Button>
                            </SView>
                        )
                    }}
                />
            </KView>
        );
    }

    onNavigateLocation = async () => {
        const { result } = await this.props.getCountries();

        await this.setState({
            countries: result.sort(dynamicSort('long_name')).map(country => ({
                key: country.id,
                text: country.long_name,
                heading: country.flag
            })),
        });

        if(this.props.country && this.props.country.id) {
            await this.onSelectCountry(this.props.country.id);
        }

        if(this.props.city && this.props.city.id) {
            await this.onSelectCity(this.props.city.id);
        }

        if(this.props.university && this.props.university.id) {
            await this.onSelectUniversity(this.props.university.id);
        }
    };

    handleChangeLocale = async (locale) => {
        await this.setState({ isReloading: true });
        await wait(700);
        await this.props.changeLocale(locale);
        await this.setState({ locale });
        await this.setState({ isReloading: false });
    };

    handleChangeTheme = async (themeName) => {
        await this.setState({ isReloading: true });
        await wait(700);
        await this.props.changeTheme(themeName);
        await this.setState({ themeName });
        await this.setState({ isReloading: false });
    };

    onPressLogout = async () => {
        const { navigation } = this.props;
        await this.setState({ showLoader: true });
        await this.props.logout(this.props.pushToken);
        await this.setState({ showLoader: false });
        navigation.navigate(screenNames.WELCOME);
        await wait(1000);
        await this.props.clearStore();
    };

    onPressSubmitProfile = async () => {
        const { intl } = this.props;
        await this.setState({ isLoading: true, errors: {} });
        let validator = new Validator([
            ['full_name', Validator.rules.LENGTH, { min: 1, max: 30 }],
            ['nickname', Validator.rules.NOT_EMPTY],
            ['nickname', Validator.rules.LENGTH, { min: 4, max: 21 }],
            ['nickname', Validator.rules.REGEX, Validator.regEx.NICKNAME],
            ['gender', Validator.rules.IN, ['1', '2']],
            ['website', Validator.rules.WEBSITE],
            ['bio', Validator.rules.LENGTH, { min: 1, max: 200 }],
        ], this.state.form);
        if(await validator.validate()) {
            let { success, errors } = await this.props.updateProfile(this.state.form);
            if (success) {
                Toast.showSimpleSuccess(intl.formatMessage({ id: 'settings.profile_saved' }));
            } else if(errors) {
                validator.setErrors(errors);
                await this.setState({
                    errors: validator.getIntlErrorsMessages(this.props.intl)
                });
            }
        } else {
            await this.setState({
                errors: validator.getIntlErrorsMessages(this.props.intl)
            });
        }
        await this.setState({ isLoading: false });
    };

    onPressChangePassword = async () => {
        const { intl } = this.props;
        await this.setState({ isLoading: true, errors: {} });

        let validator = new Validator([
            ['old_password', Validator.rules.NOT_EMPTY],
            ['new_password', Validator.rules.NOT_EMPTY],
            ['old_password', Validator.rules.LENGTH, { min: 6, max: 32 }],
            ['new_password', Validator.rules.LENGTH, { min: 6, max: 32 }]
        ], this.state.passwordForm);

        if(await validator.validate()) {
            const { success, errors, error } = await this.props.updatePassword(this.state.passwordForm);
            if(success) {
                Toast.showSimpleSuccess(intl.formatMessage({ id: 'settings.password_changed' }));
                await this.setState({ passwordForm: {
                        old_password: '',
                        new_password: ''
                    } });
            } else if(!success && errors) {
                validator.setErrors(errors);
                await this.setState({
                    errors: validator.getIntlErrorsMessages(intl)
                });
            } else if (!success && !errors && !error) {
                Toast.showSimpleDanger(intl.formatMessage({ id: 'settings.wrong_password' }));
            }
        } else {
            await this.setState({ errors: validator.getIntlErrorsMessages(intl) });
        }

        await this.setState({ isLoading: false });
    };

    onSelectCountry = async (countryID) => {
        if(!this.props.cities[countryID]) {
            await this.props.getCities(countryID);
        }
        return this.setState({
            locationForm: { ...this.state.locationForm, countryID, cityID: null, universityID: null },
            cities: this.props.cities[countryID].sort(dynamicSort('long_name')).map(city => ({
                key: city.id,
                text: city.long_name
            }))
        });
    };

    onSelectCity = async (cityID) => {
        if(!this.props.universities[cityID]) {
            await this.props.getUniversities(cityID);
        }

        const typeToName = (type) => {
            switch (type) {
                case '0':
                    return this.props.intl.formatMessage({ id: 'edit_location.university' });
                case '1':
                    return this.props.intl.formatMessage({ id: 'edit_location.school' });
                case '2':
                    return this.props.intl.formatMessage({ id: 'edit_location.lyceum' }) + '/' + this.props.intl.formatMessage({ id: 'edit_location.gymnasium' });
                case '3':
                    return this.props.intl.formatMessage({ id: 'edit_location.college' });
                default:
                    return this.props.intl.formatMessage({ id: 'edit_location.educational_institution' });
            }
        };

        return this.setState({
            locationForm: { ...this.state.locationForm, cityID, universityID: null },
            universities: this.props.universities[cityID].sort(dynamicSort('long_name')).map(university => ({
                key: university.id,
                heading: university.short_name,
                text: typeToName(university.type.toString())
            }))
        });
    };

    onSelectUniversity = async (universityID) => {
        return this.setState({
            locationForm: { ...this.state.locationForm, universityID },
        });
    };

    selectedCity() {
        for(let i in this.state.cities) {
            if(this.state.locationForm.cityID === this.state.cities[i].key) {
                return this.state.cities[i].text;
            }
        }
        return '';
    }

    onPressChange = async () => {
        await this.setState({ isLoading: true, errors: {} });

        let form = { ...this.state.locationForm };
        form.cityID = this.props.city.id;
        if(this.props.university) {
            form.universityID = this.props.university.id;
        } else {
            form.universityID = 0;
        }

        let validator = new Validator([
            ['cityID', Validator.rules.NOT_EMPTY],
            ['cityID', Validator.rules.INTEGER, { min: 1 }],
            ['universityID', Validator.rules.INTEGER, { min: 1 }]
        ], form);

        if(await validator.validate()) {
            let { success, errors } = await this.props.updateLocation(this.state.locationForm.cityID, this.state.locationForm.universityID ? this.state.locationForm.universityID : null);
            if(success) {
                await this.props.getLocation();
                await this.props.getMe();
                Toast.showSimpleSuccess(this.props.intl.formatMessage({ id: 'edit_location.location_has_updated' }));
                this.props.navigation.goBack();
                Events.publish(Events.Types.CHANGE_LOCATION, {
                    country: this.props.country,
                    city: this.props.city,
                    university: this.props.university
                });
            } else if(errors) {
                validator.setErrors(errors);
                await this.setState({
                    errors: validator.getIntlErrorsMessages(this.props.intl)
                });
            }
        } else {
            await this.setState({
                errors: validator.getIntlErrorsMessages(this.props.intl)
            });
        }
        await this.setState({
            isLoading: false
        });
    };

    onPressSubmitUniversity = async () => {
        await this.setState({ isSubmissionLoading: true, submissionErrors: {} });

        let formData = { ...this.state.submissionFormData };

        formData.cityID = this.state.locationForm.cityID;

        let validator = new Validator([
            ['title', Validator.rules.NOT_EMPTY],
            ['title', Validator.rules.LENGTH, { min: 2, max: 200 }],
            ['cityID', Validator.rules.INTEGER, { min: 1 }],
        ], formData);

        if(await validator.validate()) {
            let { success, errors } = await this.props.sendUniversitySubmission(formData.cityID, formData.title);
            if(success) {
                Toast.showSimpleSuccess(this.props.intl.formatMessage({ id: 'edit_location.request_sent' }));
                await this.setState({
                    isSubmissionSubmitted: true,
                    submissionFormData: {
                        title: ''
                    }
                });
            } else if(errors) {
                validator.setErrors(errors);
                await this.setState({
                    submissionErrors: validator.getIntlErrorsMessages(this.props.intl)
                });
            }
        } else {
            await this.setState({
                submissionErrors: validator.getIntlErrorsMessages(this.props.intl)
            });
        }
        await this.setState({
            isSubmissionLoading: false
        })
    };

    onNavigate = async () => {
        const { navigation } = this.props;
        if(navigation.getParam('initialTab', null) !== null) {
            await wait(300);
            await this.setState({
                initialTab: navigation.getParam('initialTab')
            });
            return this.onNavigateLocation();
        }
    };
}

const dynamicSort = (property) => {
    let sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
};


const mapStateToProps = ({ auth, user, common }) => ({
    me: user.me,
    pushToken: auth.pushToken,
    themeName: common.themeName,
    locale: common.locale,
    city: common.city,
    country: common.country,
    university: common.university,
    countries: common.countries,
    cities: common.cities,
    universities: common.universities,
});

const mapDispatchToProps = dispatch => ({
    getMe: () => dispatch(getMe()),
    updateProfile: (formData) => dispatch(updateProfile(formData)),
    changeLocale: (locale) => dispatch(changeLocale(locale)),
    changeTheme: (theme) => dispatch(changeTheme(theme)),
    updatePassword: (formData) => dispatch(updatePassword(formData)),
    clearStore: () => dispatch(clearStore()),
    logout: (token) => dispatch(logout(token)),
    getLocation: () => dispatch(getLocation()),
    getCountries: () => dispatch(getCountries()),
    getCities: (countryID) => dispatch(getCities(countryID)),
    getUniversities: (city_id) => dispatch(getUniversities(city_id)),
    updateLocation: (city_id, university_id) => dispatch(updateLocation(city_id, university_id)),
    sendUniversitySubmission: (cityID, title) => dispatch(sendUniversitySubmission(cityID, title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(SettingsScreen, styles))
);

