import {
    GET_REQUESTS,
    LOAD_MORE_REQUESTS,
    SEND_REQUEST,
    DECLINE_REQUEST,
    REMOVE_CONTACT,
    IS_CONTACTS,
    GET_COUNTERS,
    GET_CONTACTS,
    LOAD_MORE_CONTACTS,
    LOAD_MORE_CONTACT_MESSAGES,
    GET_CONTACT_MESSAGES,
    SEND_PRIVATE_MESSAGE,
    RECEIVED_CONTACT_EVENT,
    DELETE_CONTACT_MESSAGE,
    GET_CONTACT,
    OPEN_CONTACT_MESSAGE,
    CLOSE_CONTACT_MESSAGE
} from '../action-types';

import api from '../../networking/api';
import comet from '../../networking/comet';
let OFFSETS = {};

/**
 * **********
 * SEND_REQUEST
 * **********
 */
const sendRequestSuccess = (userID, data) => {
    return {
        type: SEND_REQUEST,
        payload: {
            userID, data
        }
    };
};

export const sendRequest = (userID, message) => {
    return async (dispatch) => {
        let response = await api.call(`POST contact/send-request`, { userID, message }),
            { success, result } = response;
        if(success) {
            dispatch(sendRequestSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * OPEN_CONTACT_MESSAGE
 * **********
 */
export const openContactMessage = (contactID, messageID) => {
    return async (dispatch) => {
        dispatch({
            type: OPEN_CONTACT_MESSAGE,
            payload: {
                contactID, messageID
            }
        });

        return { success: true };
    };
};

/**
 * **********
 * CLOSE_CONTACT_MESSAGE
 * **********
 */
export const closeContactMessage = (contactID, messageID) => {
    return async (dispatch) => {
        dispatch({
            type: CLOSE_CONTACT_MESSAGE,
            payload: {
                contactID, messageID
            }
        });

        return { success: true };
    };
};

/**
 * **********
 * GET_CONTACT
 * **********
 */
const getContactSuccess = (contactID, data) => {
    return {
        type: GET_CONTACT,
        payload: {
            contactID, data
        }
    };
};

export const getContact = (contactID) => {
    return async (dispatch) => {
        let response = await api.call(`GET contact/get/${contactID}`),
            { success, result } = response;
        if(success) {
            dispatch(getContactSuccess(contactID, result));
        }
        return response;
    };
};

/**
 * **********
 * IS_CONTACTS
 * **********
 */
const isContactsSuccess = (userID, data) => {
    return {
        type: IS_CONTACTS,
        payload: {
            userID, data
        }
    };
};

export const isContacts = (userID) => {
    return async (dispatch) => {
        let response = await api.call(`POST contact/is-contacts`, { userID }),
            { success, result } = response;
        if(success) {
            dispatch(isContactsSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * DECLINE_REQUEST
 * **********
 */
const declineRequestSuccess = (userID, data) => {
    return {
        type: DECLINE_REQUEST,
        payload: {
            userID, data
        }
    };
};

export const declineRequest = (userID, requestID) => {
    return async (dispatch) => {
        let response = await api.call(`POST contact/decline-request`, { userID, requestID }),
            { success, result } = response;
        if(success) {
            dispatch(declineRequestSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * REMOVE_CONTACT
 * **********
 */
const removeContactSuccess = (userID, data) => {
    return {
        type: REMOVE_CONTACT,
        payload: {
            userID, data
        }
    };
};

export const removeContact = (userID) => {
    return async (dispatch) => {
        let response = await api.call(`POST contact/delete-contact`, { userID }),
            { success, result } = response;
        if(success) {
            dispatch(removeContactSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * GET_COUNTERS
 * **********
 */
const getCountersSuccess = (data) => {
    return {
        type: GET_COUNTERS,
        payload: {
            data
        }
    };
};

export const getCounters = () => {
    return async (dispatch) => {
        let response = await api.call(`GET contact/get-counters`),
            { success, result } = response;
        if(success) {
            dispatch(getCountersSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * GET_REQUESTS
 * **********
 */
const getRequestsSuccess = (data) => {
    return {
        type: GET_REQUESTS,
        payload: {
            data
        }
    };
};

export const retrieveRequests = () => {
    let limit = 20, offset = 0;
    OFFSETS[GET_REQUESTS] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET contact/get-requests?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            await dispatch(getRequestsSuccess(result));
        }

        return response;
    };
};

/**
 * **********
 * LOAD_MORE_REQUESTS
 * **********
 */
const loadMoreRequestsSuccess = (data) => {
    return {
        type: LOAD_MORE_REQUESTS,
        payload: {
            data
        }
    };
};

export const loadMoreRequests = () => {
    if(OFFSETS[GET_REQUESTS] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreRequestsSuccess([]));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_REQUESTS]) {
        OFFSETS[GET_REQUESTS] = 0;
    }

    let limit = 20, offset = 20 + OFFSETS[GET_REQUESTS];

    return async (dispatch) => {
        let response = await api.call(`GET contact/get-requests?limit=${limit}&offset=${offset}`),
            { result, success } =  response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_REQUESTS] += result.length;
            } else {
                OFFSETS[GET_REQUESTS] = null;
            }
            await dispatch(loadMoreRequestsSuccess(result));
        }

        return response;
    };
};


/**
 * **********
 * GET_CONTACTS
 * **********
 */
const getContactsSuccess = (data) => {
    return {
        type: GET_CONTACTS,
        payload: {
            data
        }
    };
};

export const retrieveContacts = () => {
    let limit = 20, offset = 0;
    OFFSETS[GET_CONTACTS] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET contact/get-all?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            await dispatch(getContactsSuccess(result));
        }

        return response;
    };
};

/**
 * **********
 * SEND_CONTACT_MESSAGE
 * **********
 */
const sendPrivateMessageSuccess = (contactID, message) => {
    return {
        type: SEND_PRIVATE_MESSAGE,
        payload: {
            contactID, message
        }
    };
};

export const sendPrivateMessage = (contactID, message) => {
    return async (dispatch) => {
        let response = await api.call('POST contact/send-message', {
            contact_id: contactID,
            message
        }), { result, success } = response;

        if(success) {
            await dispatch(sendPrivateMessageSuccess(contactID, result));
        }

        return response;
    };
};

export const sendPrivatePhoto = (contactID, imageUri) => {
    return async (dispatch) => {
        let response = await api.uploadImage(`PUT contact/${contactID}/send-message`, 'sendPhoto', imageUri),
            { result, success } = response;

        if(success) {
            await dispatch(sendPrivateMessageSuccess(contactID, result));
        }

        return response;
    };
};

/**
 * **********
 * LOAD_MORE_CONTACTS
 * **********
 */
const loadMoreContactsSuccess = (data) => {
    return {
        type: LOAD_MORE_CONTACTS,
        payload: {
            data
        }
    };
};

export const loadMoreContacts = () => {
    if(OFFSETS[GET_CONTACTS] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreContactsSuccess([]));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_CONTACTS]) {
        OFFSETS[GET_CONTACTS] = 0;
    }

    let limit = 20, offset = 20 + OFFSETS[GET_CONTACTS];

    return async (dispatch) => {
        let response = await api.call(`GET contact/get-all?limit=${limit}&offset=${offset}`),
            { result, success } =  response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_CONTACTS] += result.length;
            } else {
                OFFSETS[GET_CONTACTS] = null;
            }
            await dispatch(loadMoreContactsSuccess(result));
        }

        return response;
    };
};

/**
 * **********
 * GET_CONTACT_MESSAGES
 * **********
 */
const getContactMessagesSuccess = (contactID, data) => {
    return {
        type: GET_CONTACT_MESSAGES,
        payload: {
            contactID, data
        }
    };
};

export const retrieveContactMessages = (contactID) => {
    let limit = 20, offset = 0;
    OFFSETS[GET_CONTACT_MESSAGES] = OFFSETS[GET_CONTACT_MESSAGES] || {};
    OFFSETS[GET_CONTACT_MESSAGES][contactID] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET contact/${contactID}/messages?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            await dispatch(getContactMessagesSuccess(contactID, result));
        }

        return response;
    };
};

/**
 * **********
 * LOAD_MORE_CONTACT_MESSAGES
 * **********
 */
const loadMoreContactMessagesSuccess = (contactID, data) => {
    return {
        type: LOAD_MORE_CONTACT_MESSAGES,
        payload: {
            contactID, data
        }
    };
};

export const loadMoreContactMessages = (contactID) => {
    if(OFFSETS[GET_CONTACT_MESSAGES][contactID] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreContactMessagesSuccess(contactID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_CONTACT_MESSAGES][contactID]) {
        OFFSETS[GET_CONTACT_MESSAGES][contactID] = 0;
    }

    let limit = 20, offset = 20 + OFFSETS[GET_CONTACT_MESSAGES][contactID];

    return async (dispatch) => {
        let response = await api.call(`GET contact/${contactID}/messages?limit=${limit}&offset=${offset}`),
            { result, success } =  response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_CONTACT_MESSAGES][contactID] += result.length;
            } else {
                OFFSETS[GET_CONTACT_MESSAGES][contactID] = null;
            }
            await dispatch(loadMoreContactMessagesSuccess(contactID, result));
        }

        return response;
    };
};

/**
 * **********
 * RECEIVED_CONTACT_EVENT
 * **********
 */
const receivedContactEventSuccess = (contactID, data) => {
    return {
        type: RECEIVED_CONTACT_EVENT,
        payload: {
            contactID, data
        }
    };
};

export const subscribeOnCometContact = (contactID) => {
    return async (dispatch) => {
        const channel = `/contact/${contactID}`;
        if(comet.getSubscription(channel)) {
            return {
                success: true,
                result: comet.getSubscription(channel)
            };
        }
        return {
            success: true,
            result: comet.subscribe(channel, (data) => {
                dispatch(receivedContactEventSuccess(contactID, data));
            })
        };
    };
};

/**
 * **********
 * DELETE_CONTACT_MESSAGE
 * **********
 */
const deleteContactMessageSuccess = (contactID, messageID) => {
    return {
        type: DELETE_CONTACT_MESSAGE,
        payload: {
            contactID, messageID
        }
    };
};

export const deleteContactMessage = (contactID, messageID) => {
    return async (dispatch) => {
        let response = await api.call(`POST contact/${contactID}/message/${messageID}/delete`),
            { success } = response;

        if(success) {
            await dispatch(deleteContactMessageSuccess(contactID, messageID));
        }

        return response;
    };
};
