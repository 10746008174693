import React, { Component } from 'react';

import { Text, View } from '../base';
import { withStyles } from "../../theme";
import { wait } from "../../utils/async";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

class WelcomeFade extends Component {

    async componentDidMount() {
        await wait(3000);
        this.props.onComplete();
    }

    render() {
        const { theme, styles, intl, me } = this.props;
        return (
            <View style={styles.wrapper}>
                <Text style={styles.heroText}>{intl.formatMessage({ id: 'intro.welcome' })}{',\n'}@{me.nickname}!</Text>
            </View>
        );
    }
}

const mapStateToProps = ({ user }) => ({
    me: user.me
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(WelcomeFade, theme => ({
        wrapper: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center'
        },
        heroText: {
            color: theme.COLORS.WHITE,
            fontWeight: '900',
            fontSize: 30,
            lineHeight: 50,
            textAlign: 'center'
        },
    })))
);
