import React from 'react';

import { Text, Pressable, Icon } from '../base';
import { withStyles } from "../../theme";

const getIconName = (value, variation) => {
    if(value && variation === 'checkbox') {
        return 'check-circle';
    } else if(!value && variation === 'checkbox') {
        return 'circle';
    } else if(value && variation === 'switch') {
        return 'toggle-right';
    } else if(!value && variation === 'switch') {
        return 'toggle-left';
    }
};

const getIconSize = (variation) => {
    if(variation === 'checkbox') {
        return 30;
    } else if(variation === 'switch') {
        return 35;
    }
};

export default withStyles(({ theme, styles, style, labelStyle, variation = 'checkbox', value, label, onChange, ...restProps }) => (
    <Pressable style={[
        styles[variation],
        style
    ]} {...restProps} onPress={() => onChange(!value)}>
        <Text numberOfLines={2} style={[
            styles.label, labelStyle
        ]}>{label}</Text>
        <Pressable style={styles.icon} onPress={() => onChange(!value)}>
            <Icon name={getIconName(value, variation)} color={value ? theme.COLORS.PRIMARY : theme.COLORS.SECONDARY_TEXT} size={getIconSize(variation)} />
        </Pressable>
    </Pressable>
), theme => ({
    checkbox: {
        height: 50,
        justifyContent: 'center',
    },
    switch: {
        height: 50,
        justifyContent: 'center',
    },
    label: {
        color: theme.COLORS.TEXT,
        fontWeight: 'bold',
        fontSize: 16,
        marginRight: 50
    },
    icon: {
        position: 'absolute',
        right: 0
    }
}));
