import React from 'react';

import { Animated } from 'react-native';
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withStyles } from "../../theme";
import { Text, KView, View, Icon, Screen, List, Loader } from '../../components/base';
import { openImage } from "../../state/common/actions";
import {
    retrieveChannel,
    retrieveChannelMessages,
    subscribeOnCometChannel,
    loadMoreChannelMessages,
    checkVoteChannelMessage,
    voteChannelMessage,
    openChannelMessage,
    closeChannelMessage,
    subscribeChannel,
    muteChannel,
    reportChannel,
    closeChannel,
    loadMoreChannelMessagesAsc,
    retrieveChannelAscMessages,
    voteChannel, sendPhoto
} from "../../state/channel/actions";
import { blockUser } from "../../state/user/actions";
import MessageItem from './MessageItem';
import SystemMessageItem from './SystemMessageItem';
import screenNames from "../../navigation/screenNames";
import styles from './styles';
import HeaderExpanded from "./HeaderExpanded";
import HeaderCollapsed from "./HeaderCollapsed";
import Footer from "./Footer";
import { connectActionSheet } from "@expo/react-native-action-sheet";
import { IconButton, Ticker } from "../../components";
import MessageOptionsModal from './MessageOptionsModal';
import { openLink } from "../../utils/link";
import { pickFromGallery, takePhoto } from "../../utils/imagePicker";
import { checkPermissions } from "../../utils/permissions";

class TopicScreen extends Screen {

    screenName = screenNames.TOPIC;

    state = {
        topicID: null,
        preloaded: false,
        isLoading: false,
        isRefreshing: false,
        isLoadingMore: false,
        isLoadingVote: false,
        replyMessage: null,
        order: 'asc',
        scrollY: new Animated.Value(0),
        optionsMessage: null,
        isOptionsMessageOpened: false,
        scrollEnabled: true
    };

    content() {

        const { theme, styles, intl } = this.props;

        const { topicID, order, isLoading, isLoadingMore, isLoadingVote, isRefreshing, isOptionsMessageOpened, optionsMessage, scrollEnabled } = this.state,
            { channels, ignoreList, me, messages, messagesAsc, isLoggedIn } = this.props;

        const channel = channels && channels[topicID] ? channels[topicID] : null,
            messagesDescList = messages && messages[topicID] ? messages[topicID] : [],
            messagesAscList = messagesAsc && messagesAsc[topicID] ? messagesAsc[topicID] : [];

        const inverted = order === 'desc';

        const messagesList = inverted
            ? messagesDescList
            : messagesAscList;

        if(!channel) return null;

        return (
            <KView style={styles.screenWrapper}>
                <HeaderExpanded
                    scrollY={this.state.scrollY}
                    inverted={inverted}
                    channel={channel}
                />
                {!isLoading && (
                    <List animated
                          scrollRef={c => (this._scrollView = c)}
                          data={messagesList}
                          refreshing={isRefreshing}
                          onRefresh={inverted ? this.onRefresh : undefined}
                          style={inverted ? styles.messagesListDesc : styles.messagesListAsc}
                          contentContainerStyle={inverted ? styles.messagesWrapperDesc : styles.messagesWrapperAsc}
                          onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: this.state.scrollY }}}], { useNativeDriver: true })}
                          scrollEventThrottle={16}
                          inverted={inverted}
                          scrollEnabled={scrollEnabled}
                          ListHeaderComponent={!inverted && (
                              <View style={styles.topWrapper}>
                                  <View style={styles.topWrapperRoundedBackground} />
                                  <View style={styles.topLineWrapper}>
                                      <Icon name={'message-circle'} style={styles.messageCircleItem} color={theme.COLORS.TEXT} size={16} />
                                      <Ticker textStyle={styles.messagesCountText}>
                                          {channel.messages_count.toString()}
                                      </Ticker>
                                  </View>
                                  <View style={styles.voteWrapper}>
                                      <IconButton onPress={this.onPressVoteDown} disabled={channel.my_vote || isLoadingVote} name={'thumbs-down'} color={channel.my_vote === -1 ? theme.COLORS.DANGER : channel.my_vote ? theme.COLORS.SECONDARY_TEXT : theme.COLORS.TEXT} size={16} />
                                      {channel.created_at < ((Date.now() / 1000) - 4800) ? (
                                          <Ticker textStyle={[styles.tickerText, (channel.rating > 0 ? styles.tickerTextPositive : channel.rating < 0 ? styles.tickerTextNegative : null)]}>
                                              {channel.rating.toString()}
                                          </Ticker>
                                      ) : (
                                          <Text style={styles.tickerText}>
                                              ●
                                          </Text>
                                      )}
                                      <IconButton onPress={this.onPressVoteUp} disabled={channel.my_vote || isLoadingVote} name={'thumbs-up'} color={channel.my_vote === 1 ? theme.COLORS.SUCCESS : channel.my_vote ? theme.COLORS.SECONDARY_TEXT : theme.COLORS.TEXT} size={16} />
                                  </View>
                              </View>
                          )}
                          ListFooterComponent={(
                              <View style={styles.loaderWrapper}>
                                  {isLoadingMore && (
                                      <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={inverted ? styles.loaderDesc : styles.loader} />
                                  )}
                              </View>
                          )}
                          ListEmptyComponent={(
                              <View style={styles.noDataPlaceholderWrapper}>
                                  <Text style={styles.noDataPlaceholderText}>
                                      {(me && me.id === channel.author_id)
                                          ? intl.formatMessage({ id: 'topic.no_messages_author' })
                                          : intl.formatMessage({ id: 'topic.no_messages' })
                                      }
                                  </Text>
                              </View>
                          )}
                          renderItem={({ item }) => (
                              item.is_system ? (
                                  <SystemMessageItem
                                      {...item}
                                      hasSubscription={channel.hasSubscription}
                                      isOpened={!!item.isOpened}
                                      onPress={this.onPressMessage}
                                      onLongPress={this.onLongPressMessage}
                                      style={styles.messageItem}
                                  />
                              ) : (
                                  <MessageItem
                                      {...item}
                                      hasSubscription={channel.hasSubscription}
                                      isSafe={(me && me.restricted_mode) || item.myReport}
                                      isOpened={!!item.isOpened}
                                      isOP={item.author_id === channel.author_id}
                                      authorPermission={(item.author_permission_university_id === channel.university_id || item.author_permission_city_id === channel.city_id) && item.author_permission}
                                      onPress={this.onPressMessage}
                                      onLongPress={this.onLongPressMessage}
                                      onPressLink={this.onPressMessageLink}
                                      onPressImage={this.onPressMessageImage}
                                      onVoteUp={this.onVoteUp}
                                      onVoteDown={this.onVoteDown}
                                      onPressAvatar={this.onPressAvatar}
                                      onNewReply={this.onNewReply}
                                      style={styles.messageItem}
                                  />
                              )
                          )}
                          onEndReached={this.loadMore}
                          keyExtractor={item => item.id.toString()}
                    />
                )}

                {isLoading && (
                    <View style={[inverted ? styles.messagesListDesc : styles.messagesListAsc]} >
                        <View style={[ inverted ? styles.messagesWrapperDesc : styles.messagesWrapperAsc, { flex: 1, minHeight: null, paddingBottom: 5 } ]} >
                            {!inverted && (
                                <View style={styles.topWrapper}>
                                    <View style={styles.topWrapperRoundedBackground} />
                                    <View style={styles.topLineWrapper}>
                                        <Icon name={'message-circle'} style={styles.messageCircleItem} color={theme.COLORS.TEXT} size={16} />
                                        <Ticker textStyle={styles.messagesCountText}>
                                            {channel.messages_count.toString()}
                                        </Ticker>
                                    </View>
                                    <View style={styles.voteWrapper}>
                                        <IconButton onPress={this.onPressVoteDown} disabled={channel.my_vote || isLoadingVote} name={'thumbs-down'} color={channel.my_vote === -1 ? theme.COLORS.DANGER : channel.my_vote ? theme.COLORS.SECONDARY_TEXT : theme.COLORS.TEXT} size={16} />
                                        {channel.created_at < ((Date.now() / 1000) - 4800)  ? (
                                            <Ticker textStyle={[styles.tickerText, (channel.rating > 0 ? styles.tickerTextPositive : channel.rating < 0 ? styles.tickerTextNegative : null)]}>
                                                {channel.rating.toString()}
                                            </Ticker>
                                        ) : (
                                            <Text style={styles.tickerText}>
                                                ●
                                            </Text>
                                        )}
                                        <IconButton onPress={this.onPressVoteUp} disabled={channel.my_vote || isLoadingVote} name={'thumbs-up'} color={channel.my_vote === 1 ? theme.COLORS.SUCCESS : channel.my_vote ? theme.COLORS.SECONDARY_TEXT : theme.COLORS.TEXT} size={16} />
                                    </View>
                                </View>
                            )}
                            <View style={[styles.sceneLoaderWrapper, (!inverted ? { marginTop: -50 } : null)]}>
                                <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                                <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                            </View>
                        </View>
                    </View>
                )}

                <Footer
                    channel={channel}
                    scrollY={this.state.scrollY}
                    inverted={inverted}
                    hasSubscription={channel.hasSubscription}
                    onPressUploadPicture={this.onPressUploadPicture}
                    onCloseReply={this.onCloseReply}
                    onPressScrollToBottom={this.onPressScrollToBottom}
                    inputRef={c => this._textArea = c}
                    onSubscribe={this.onSubscribe}
                    onFocus={this.onTextInputFocused}
                    replyMessage={this.state.replyMessage}
                />
                <HeaderCollapsed
                    scrollY={this.state.scrollY}
                    inverted={inverted}
                    scaleTopicImage={this.scaleTopicImage}
                    onPress={this.onPressScrollToTop}
                    showReport={this.showReport}
                    channel={channel}
                />
                <MessageOptionsModal
                    visible={isOptionsMessageOpened}
                    message={optionsMessage}
                    channel={channel}
                    showReport={this.showReport}
                    onClose={() => this.setState({ isOptionsMessageOpened: false })}
                />
            </KView>
        );
    }

    onNavigate = async () => {
        const { navigation, channels } = this.props;
        await this.setState({
            topicID: navigation.getParam('topicID'),
            order: (channels[navigation.getParam('topicID')] && channels[navigation.getParam('topicID')].hasSubscription) ? 'desc' : 'asc'
        });
        await this.loadData();
    };

    loadData = async () => {
        const { order, topicID, preloaded } = this.state,
            { isLoggedIn } = this.props;
        let requests = [
            [(order === 'asc' ? 'retrieveChannelMessagesAsc' : 'retrieveChannelMessages'), topicID],
        ];
        if(isLoggedIn) {
            requests.push(['subscribeOnCometChannel', topicID]);
        }

        if(!preloaded) {
            requests.unshift(['retrieveChannel', topicID]);
        }
        await this.retrieveData(requests, 'isLoading');
    };

    onRefresh = async (withoutRefresh) => {
        await this.retrieveData([
            [(this.state.order === 'asc' ? 'retrieveChannelMessagesAsc' : 'retrieveChannelMessages'), this.state.topicID]
        ], (withoutRefresh ? null : 'isRefreshing'));
    };

    loadMore = async () => {
        if(this.state.isLoading || this.state.isLoadingMore) return;
        await this.retrieveData([
            (this.state.order === 'asc' ? 'loadMoreChannelMessagesAsc' : 'loadMoreChannelMessages'), this.state.topicID
        ], 'isLoadingMore');
    };

    onPressMessage = async (message) => {
        if(message.isOpened) {
            this.props.closeChannelMessage(this.state.topicID, message.id);
        } else {
            this.props.openChannelMessage(this.state.topicID, message.id);
            if(!message.is_system && typeof message.hasVote === 'undefined') {
                await this.props.checkVoteChannelMessage(this.state.topicID, message.id);
            }
        }
    };

    onLongPressMessage = async (message) => {
        const { channels, me } = this.props;
        const { topicID } = this.state;
        const isAdmin = (me.id === channels[topicID].author_id) || checkPermissions(me.access, 'ban_member', channels[topicID].city_id, channels[topicID].university_id);
        if(message.is_system && !isAdmin) {
            return;
        }
        await this.setState({
            optionsMessage: message,
            isOptionsMessageOpened: true
        });
        this.props.closeChannelMessage(this.state.topicID, message.id);
    };

    onPressUploadPicture = async () => {
        const imageUri = await pickFromGallery({ resizeCoefficient: .5, compressRatio: .8 });
        if(imageUri) {
            await this.setState({ showLoader: true });
            const { success } = await this.props.sendPhoto(
                this.state.topicID,
                this.state.replyMessage && this.state.replyMessage.id,
                imageUri
            );
            await this.setState({ showLoader: false });
        }
    };

    /**
     * @param linkUrl
     * @returns {Promise<void>}
     */
    onPressMessageLink = async (linkUrl) => {
        await openLink(linkUrl, this.props.theme);
    };

    onPressMessageImage = (message) => {
        this.props.openImage(message.extra);
    };

    onVoteUp = (message) => {
        this.props.voteChannelMessage(this.state.topicID, message.id, 'up');
        this.props.closeChannelMessage(this.state.topicID, message.id);
    };

    onVoteDown = (message) => {
        this.props.voteChannelMessage(this.state.topicID, message.id, 'down');
        this.props.closeChannelMessage(this.state.topicID, message.id);
    };

    onPressVoteUp = async () => {
        await this.setState({ isLoadingVote: true });
        await this.props.voteChannel(this.state.topicID, 'up');
        await this.setState({ isLoadingVote: false });
    };

    onPressVoteDown = async () => {
        await this.setState({ isLoadingVote: true });
        await this.props.voteChannel(this.state.topicID, 'down');
        await this.setState({ isLoadingVote: false });
    };

    onPressAvatar = (message) => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.PROFILE, {
            userID: message.author_id
        })
    };

    onNewReply = async (message) => {
        const { topicID } = this.state;
        const { channels, me } = this.props;
        if(channels[topicID].hasSubscription && message.author_id !== me.id) {
            if(this._textArea) {
                this._textArea.focus();
            }
            await this.setState({ replyMessage: message });
            this.props.closeChannelMessage(this.state.topicID, message.id);
        } else {
            this.props.closeChannelMessage(this.state.topicID, message.id);
        }
    };

    onCloseReply = async () => {
        await this.setState({ replyMessage: null });
    };

    onPressScrollToTop = async () => {
        if(this.state.order === 'asc') {
            await this.setState({ scrollEnabled: false });
            await this.setState({ scrollY: new Animated.Value(2) });
            await this.setState({
                scrollY: new Animated.Value(0),
                isLoading: true
            });
            setTimeout(() => this.setState({ scrollEnabled: true, isLoading: false }), 100);
            // await this.setState({ scrollEnabled: false });
            // this._scrollView.current.scrollTo({
            //     y: 0,
            //     animated: false
            // });
            // await this.setState({ scrollY: new Animated.Value(2) });
            // await this.setState({ scrollY: new Animated.Value(0) });
            // setTimeout(() => this.setState({ scrollEnabled: true }), 100);
        } else {
            await this.setState({ scrollEnabled: false });
            await this.setState({ scrollY: new Animated.Value(2) });
            await this.setState({
                scrollY: new Animated.Value(0),
                isLoading: true
            });
            await this.setState({ order: 'asc' });
            await this.onRefresh(true);
            await this.setState({ isLoading: false });
            setTimeout(() => this.setState({ scrollEnabled: true }), 100);
        }
    };

    onPressScrollToBottom = async () => {
        if(this.state.order === 'desc') {
            await this.setState({ scrollEnabled: false });
            await this.setState({ scrollY: new Animated.Value(2) });
            await this.setState({
                scrollY: new Animated.Value(0),
                isLoading: true
            });
            setTimeout(() => this.setState({ scrollEnabled: true, isLoading: false }), 50);
            // await this.setState({ scrollEnabled: false });
            // this._scrollView.scrollTo({
            //     y: 0,
            //     animated: false
            // });
            // this.setState({ scrollY: new Animated.Value(0) });
            // setTimeout(() => this.setState({ scrollEnabled: true }), 50);
        } else {
            await this.setState({ scrollEnabled: false });
            await this.setState({ scrollY: new Animated.Value(2) });
            await this.setState({
                scrollY: new Animated.Value(0),
                isLoading: true
            });
            await this.setState({ order: 'desc' });
            await this.onRefresh(true);
            await this.setState({ isLoading: false });
            setTimeout(() => this.setState({ scrollEnabled: true }), 50);
        }
    };

    scaleTopicImage = () => {
        const { topicID } = this.state;
        const { channels } = this.props;
        if(channels[topicID].thumbnail_large) {
            this.props.openImage(channels[topicID].thumbnail_large);
        }
    };

    onSubscribe = async () => {
        if(this._textArea) {
            this._textArea.focus();
        }
    };

    onTextInputFocused = async () => {
        if(this.state.order === 'asc') {
            await this.onPressScrollToBottom();
        }
    };
}

const mapStateToProps = ({ user, channel, common, auth }) => ({
    isLoggedIn: auth.isLoggedIn,
    channels: channel.channels,
    members: channel.members,
    me: user.me,
    ignoreList: user.ignoreList,
    messages: channel.messages,
    messagesAsc: channel.messagesAsc,
    isTransportOnline: common.isTransportOnline
});

const mapDispatchToProps = dispatch => ({
    openImage: (image) => dispatch(openImage(image)),
    blockUser: (userID) => dispatch(blockUser(userID)),
    closeChannel: (channelID) => dispatch(closeChannel(channelID)),
    retrieveChannel: (channelID) => dispatch(retrieveChannel(channelID)),
    sendPhoto: (channelID, replyID, imageUri) => dispatch(sendPhoto(channelID, replyID, imageUri)),
    voteChannel: (channelID, type) => dispatch(voteChannel(channelID, type)),
    retrieveChannelMessages: (channelID, mode) => dispatch(retrieveChannelMessages(channelID, 0, mode)),
    retrieveChannelMessagesAsc: (channelID, mode) => dispatch(retrieveChannelAscMessages(channelID, 0, mode)),
    loadMoreChannelMessages: (channelID, mode) => dispatch(loadMoreChannelMessages(channelID, 0, mode)),
    loadMoreChannelMessagesAsc: (channelID, mode) => dispatch(loadMoreChannelMessagesAsc(channelID, 0, mode)),
    subscribeOnCometChannel: (channelID) => dispatch(subscribeOnCometChannel(channelID)),
    muteChannel: (channelID, mute) => dispatch(muteChannel(channelID, mute)),
    voteChannelMessage: (channelID, messageID, type) => dispatch(voteChannelMessage(channelID, messageID, type)),
    checkVoteChannelMessage: (channelID, messageID) => dispatch(checkVoteChannelMessage(channelID, messageID)),
    openChannelMessage: (channelID, messageID) => dispatch(openChannelMessage(channelID, messageID)),
    closeChannelMessage: (channelID, messageID) => dispatch(closeChannelMessage(channelID, messageID)),
    subscribeChannel: (channelID) => dispatch(subscribeChannel(channelID)),
    reportChannel: (channelID, reportType) => dispatch(reportChannel(channelID, reportType))
});

export default connect(mapStateToProps, mapDispatchToProps)(
    connectActionSheet(injectIntl(withStyles(TopicScreen, styles)))
);
