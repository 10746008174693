import React, { PureComponent } from 'react';

import { Animated, Easing } from 'react-native';
import { Text, View, Avatar, Pressable, Icon, TextArea, AnimatedDots } from '../../components/base';
import { Toast } from '../../components';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";
import { withNavigation } from 'react-navigation';
import { sendMessage, subscribeChannel } from "../../state/channel/actions";
import { openCommunityRules } from "../../state/common/actions";
import { connect } from "react-redux";
import Validator from '../../utils/validator';
import { LinearGradient } from "expo-linear-gradient";
import MessageItem from "./MessageItem";
import storage from "../../utils/keyValueStorage";
import { wait } from '../../utils/async'

class Footer extends PureComponent {

    state = {
        isLoading: false,
        isLoadingSubscription: false,
        hiddenBottomButton: false,
        message: '',
        offsetY: new Animated.Value(true || this.props.hasSubscription ? 0 : 58)
    };

    componentDidMount = async () => {
        await wait(700);
        Animated.timing(
            this.state.offsetY, {
                easing: Easing.inOut(Easing.quad),
                duration: 150,
                toValue: 0,
                useNativeDriver: true
            }
        ).start();
    };

    render() {

        const {
            theme,
            styles,
            me,
            intl,
            isTransportOnline,
            replyMessage,
            hasSubscription,
            scrollY,
            inverted,
            isLoggedIn
        } = this.props;

        const placeholderText = replyMessage === null
            ? intl.formatMessage({ id: 'topic.reply_to_the_topic' })
            : intl.formatMessage({ id: 'topic.reply_to_nickname' }, {
                nickname: '@' + replyMessage['author_nickname']
            });

        const scrollBottomOpacity = inverted ? scrollY.interpolate({
            inputRange: [0, 150],
            outputRange: [0, 0.8],
            extrapolate: 'clamp'
        }) : scrollY.interpolate({
            inputRange: [0, theme.SIZES.SCREEN_HEIGHT, theme.SIZES.SCREEN_HEIGHT + 100],
            outputRange: [0, 0, 0.8],
            extrapolate: 'clamp'
        });

        const scrollBottomOffset = inverted ? scrollY.interpolate({
            inputRange: [0, 125, 150],
            outputRange: [150, 150, 0],
            extrapolate: 'clamp'
        }) : scrollY.interpolate({
            inputRange: [0, theme.SIZES.SCREEN_HEIGHT, theme.SIZES.SCREEN_HEIGHT + 25],
            outputRange: [150, 150, 0],
            extrapolate: 'clamp'
        });

        return (
            <Animated.View style={[styles.wrapper, {
                transform: [{ translateY: this.state.offsetY }]
            }]}>
                {!this.state.hiddenBottomButton && (
                    <Animated.View style={[styles.scrollDownButtonWrapper, { opacity: scrollBottomOpacity, transform: [{ translateY: scrollBottomOffset }] }]}>
                        <Pressable onPress={this.onPressScrollToBottom} style={styles.scrollDownButton}>
                            <Icon color={theme.COLORS.TEXT} name={'chevron-down'} size={25}/>
                        </Pressable>
                    </Animated.View>
                )}
                {!hasSubscription ? (
                    <View style={styles.noSubscriptionContainer}>
                        <Pressable disabled={this.state.isLoadingSubscription} onPress={this.onPressSubscribe}>
                            <LinearGradient
                                {...theme.COLORS.GRADIENTS.PRIMARY}
                                style={styles.reconnectingGradient}>
                                <Text style={styles.reconnectingText}>
                                    {intl.formatMessage({ id: 'topic.reply_topic' })}
                                </Text>
                            </LinearGradient>
                        </Pressable>
                    </View>
                ) : isTransportOnline ? (
                    <View style={styles.container}>
                        {replyMessage !== null && (
                            <View style={styles.replyMessageWrapper}>
                                <MessageItem reply {...replyMessage} />
                                <Pressable onPress={this.onPressCloseReply} style={styles.closeReplyMessage}>
                                    <Icon  name={'x'} color={theme.COLORS.SECONDARY_TEXT} size={14} />
                                </Pressable>
                            </View>
                        )}
                        <Avatar style={styles.avatar} avatarUrl={me.avatar_normal} />
                        <TextArea
                            style={styles.textArea}
                            inputRef={this.props.inputRef}
                            onChangeText={(message) => this.setState({ message })}
                            onFocus={this.props.onFocus}
                            placeholder={placeholderText}
                            placeholderTextColor={theme.COLORS.SECONDARY_TEXT}
                            value={this.state.message}
                        />
                        <Pressable style={[
                            styles.submitButton,
                            this.state.isLoading ? { opacity: 0.6 } : null
                        ]} onPress={this.state.message !== '' ? this.onPressSubmit : this.props.onPressUploadPicture}>
                            <Icon name={(
                                this.state.message !== ''
                                    ? 'send'
                                    : 'image'
                            )} color={(
                                this.state.message !== ''
                                    ? theme.COLORS.TEXT
                                    : theme.COLORS.SECONDARY_TEXT
                            )} size={17} />
                        </Pressable>
                    </View>
                ) : (
                    <View style={styles.offlineContainer}>
                        <LinearGradient
                            {...theme.COLORS.GRADIENTS.DANGER}
                            style={styles.reconnectingGradient}>
                            <Text style={styles.reconnectingText}>
                                {intl.formatMessage({ id: 'common.reconnecting' })}<AnimatedDots style={styles.reconnectingText} />
                            </Text>
                        </LinearGradient>
                    </View>
                )}
            </Animated.View>
        );
    }

    onPressSubmit = async () => {
        const { intl } = this.props;
        await this.setState({
            isLoading: true,
            message: this.state.message.replace(/^\s+|\s+$/g, '')
        });

        let validator = new Validator([
            ['message', Validator.rules.NOT_EMPTY],
            ['message', Validator.rules.LENGTH, { min: 1, max: 1000 }]
        ], {
            message: this.state.message
        });

        if(await validator.validate()) {
            let { success, errors } = await this.props.sendMessage(
                this.props.channel.id,
                this.props.replyMessage && this.props.replyMessage.id,
                this.state.message
            );
            if(success) {
                await this.setState({ message: '' });
                await this.props.onCloseReply();
            } else if(errors) {
                validator.setErrors(errors);
            }
        }
        const errors = validator.getIntlErrorsMessages(intl);
        if(validator.errorsMessages['message']) {
            Toast.showSimpleWarning(errors['message']);
        }
        await this.setState({ isLoading: false });
    };

    onPressSubscribe = async () => {
        const isAccepted = await storage.get('isRulesAccepted', false);
        if(isAccepted) {
            return this.onSubscribe();
        }
        this.props.openCommunityRules(async () => {
            await storage.set('isRulesAccepted', 1);
            return this.onSubscribe();
        });
    };

    onSubscribe = async () => {
        await this.setState({ isLoadingSubscription: true });
        await this.props.subscribeChannel(this.props.channel.id);
        await this.setState({ isLoadingSubscription: false });
        await this.props.onSubscribe();
    };

    onPressCloseReply = async () => {
        await this.props.onCloseReply();
    };

    onPressScrollToBottom = async () => {
        await this.setState({ hiddenBottomButton: true });
        await this.props.onPressScrollToBottom();
        setTimeout(() => this.setState( { hiddenBottomButton: false }), 100);
    };
}

const mapStateToProps = ({ user, common, auth }) => ({
    me: user.me,
    isLoggedIn: auth.isLoggedIn,
    isTransportOnline: common.isTransportOnline
});

const mapDispatchToProps = dispatch => ({
    sendMessage: (channelID, replyID, message) => dispatch(sendMessage(channelID, replyID, message)),
    subscribeChannel: (channelID) => dispatch(subscribeChannel(channelID)),
    openCommunityRules: (callback) => dispatch(openCommunityRules(callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withNavigation(injectIntl(withStyles(Footer, theme => ({
        wrapper: {
            width: '100%',
            justifySelf: 'flex-end',
            marginTop: -20
        },
        container: {
            paddingTop: 10,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingLeft: 36 + (1.5 * theme.SIZES.CONTENT_HORIZONTAL_PADDING),
            paddingRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            paddingBottom: 10
        },
        offlineContainer: {
            paddingTop: 10,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingLeft: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            paddingRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            paddingBottom: 10
        },
        noSubscriptionContainer: {
            paddingTop: 10,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingLeft: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            paddingRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            paddingBottom: 10
        },
        submitButton: {
            position: 'absolute',
            height: 36,
            width: 36,
            alignItems: 'center',
            justifyContent: 'center',
            right: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            bottom: 10
        },
        textArea: {
            backgroundColor: theme.COLORS.BACKGROUND,
            borderRadius: 15,

            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            marginTop: 0,
            overflow: 'hidden',

            paddingLeft: 10,
            paddingRight: 30,

            paddingTop: theme.SIZES.PLATFORM_OS === 'android' ? 4 : 8.5,
            paddingBottom: theme.SIZES.PLATFORM_OS === 'android' ? 4 : 8.5,

            maxHeight: 140,
            color: theme.COLORS.TEXT,
            fontSize: 14,
            textAlign: 'left'
        },
        replyMessageWrapper: {
            backgroundColor: theme.COLORS.BACKGROUND,
            borderRadius: 15,

            width: theme.SIZES.SCREEN_WIDTH - (2 * theme.SIZES.CONTENT_HORIZONTAL_PADDING),
            marginLeft: -36 - (0.5 * theme.SIZES.CONTENT_HORIZONTAL_PADDING),
            marginRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING,

            paddingTop: 4.5,
            paddingBottom: 8.5,

            marginBottom: 0.5 * theme.SIZES.CONTENT_HORIZONTAL_PADDING
        },
        closeReplyMessage: {
            position: 'absolute',
            top: 0,
            right: 0,
            width: 22,
            height: 22,
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
        },
        avatar: {
            position: 'absolute',
            width: 36,
            height: 36,
            borderRadius: 15,
            bottom: 10,
            left: theme.SIZES.CONTENT_HORIZONTAL_PADDING
        },
        reconnectingGradient: {
            height: 36,
            width: '100%',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center'
        },
        reconnectingText: {
            color: theme.COLORS.WHITE,
            fontWeight: '900'
        },
        scrollDownButtonWrapper: {
            position: 'absolute',
            top: -50 - theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            right: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
            width: 50,
            height: 50,
        },
        scrollDownButton: {
            width: 50,
            height: 50,
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 15
        }
    }))))
);
