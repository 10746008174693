import React, { PureComponent } from 'react';

import { withStyles } from "../../theme";
import { StyleSheet } from 'react-native';
import IconButton from '../IconButton';
import { View } from '../base';
import { connect } from "react-redux";

class NotificationsButton extends PureComponent {
    render () {
        const { theme, styles, style, children, me, isLoggedIn, ...restProps } = this.props;
        const { style: labelStyle } = children[children.length - 1].props;
        return isLoggedIn && (
            <IconButton
                name='bell'
                size={20}
                color={StyleSheet.flatten(labelStyle).color}
                style={[ style, styles.wrapper ]}
                {...restProps}
            >
                {me && me.hasNotifications && (
                    <View style={styles.redDot}  />
                )}
            </IconButton>
        );
    }
}

const mapStateToProps = ({ auth, user }) => ({
    me: user.me,
    isLoggedIn: auth.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(NotificationsButton, theme => ({
        wrapper: {
            padding: 10,
            alignItems: 'center'
        },
        redDot: {
            width: 6,
            height: 6,
            backgroundColor: theme.COLORS.DANGER,
            position: 'absolute',
            bottom: 0,
            borderRadius: 3
        }
    })));
