export const images = {
    welcomeLogo: require('./images/welcome-logo.png'),
    welcomeLogoLight: require('./images/welcome-logo-light.png'),
    welcomeBackground: require('./images/welcome-background.jpeg'),
    welcomeBackgroundLight: require('./images/welcome-background-light.jpeg'),
    noAvatar: require('./images/no-avatar.jpeg'),
    noSefie: require('./images/no-selfie.jpg'),
    splash: require('./images/splash.png'),
    splashLight: require('./images/splash-light.png'),
};

export const fonts = {
    OpenSansBold: require('./fonts/OpenSans-Bold.ttf'),
    OpenSansExtraBold: require('./fonts/OpenSans-ExtraBold.ttf'),
    OpenSansLight: require('./fonts/OpenSans-Light.ttf'),
    OpenSansRegular: require('./fonts/OpenSans-Regular.ttf'),
    OpenSansSemiBold: require('./fonts/OpenSans-SemiBold.ttf'),
    Baloo: require('./fonts/BalooCyrillic.otf'),
};
