import React from 'react';

import { Platform, FlatList, Animated, RefreshControl } from 'react-native';
import { withStyles } from "../../theme";

export default withStyles( ({ children, animated, refreshing, onRefresh, scrollRef, theme, ...restProps }) => (
    (animated && Platform.OS !== 'web')
        ? <Animated.FlatList {...restProps}
                             ref={scrollRef}
                             children={children}
                             refreshControl={
                                 onRefresh
                                     ? <RefreshControl refreshing={refreshing} tintColor={theme.COLORS.SECONDARY_TEXT} onRefresh={onRefresh} />
                                     : undefined
                             }
                             onEndReachedThreshold={.01} />
        : <FlatList {...restProps}
                    ref={scrollRef}
                    children={children}
                    onEndReachedThreshold={.01}
                    refreshControl={
                        onRefresh
                            ? <RefreshControl refreshing={refreshing} tintColor={theme.COLORS.SECONDARY_TEXT} onRefresh={onRefresh} />
                            : undefined
                    }
        />
), () => ({}));
