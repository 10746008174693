import React  from 'react';

import { Loader, View, Screen, Image } from '../../components/base';
import { Toast } from '../../components';
import session from '../../networking/session';
import api from '../../networking/api';
import { processAllSelectors, noReloadErrorCodes } from '../../networking/selector';
import screenNames from '../../navigation/screenNames';
import { withStyles } from "../../theme";
import { Asset } from "expo-asset";
import { Feather } from '@expo/vector-icons';
import { Linking, Notifications } from 'expo';
import { Platform } from 'react-native';
import { images } from "../../../assets";
import * as Font from "expo-font";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Events from '../../utils/events';
import { getMe } from '../../state/user/actions';
import { initializeSession, logout } from '../../state/auth/actions';
import { registerFetchTask } from '../../utils/backgroundUpdate';
import {
    getLocation,
    getDeepLink,
    clearDeepLink,
    registerDevice,
    updateTransportStatus,
    retrieveTheme,
    retrieveLocale
} from '../../state/common/actions';
import { retrieveChannel, updateLastVisit } from '../../state/channel/actions';
import { getContact } from '../../state/contact/actions';
import { wait } from "../../utils/async";
import _ from 'lodash';

class AppLoadingScreen extends Screen {

    screenName = screenNames.APP_LOADING;

    constructor(props) {
        super(props);

        Events.subscribe(Events.Types.SERVER_ERROR, this._onServerError);
        Events.subscribe(Events.Types.TRANSPORT_DOWN, () => {
            this.props.updateTransportStatus(false);
        });
        Events.subscribe(Events.Types.TRANSPORT_DOWN, () => {
            this.props.updateTransportStatus(false);
        });
        Events.subscribe(Events.Types.TRANSPORT_UP, () => {
            this.props.updateTransportStatus(true);
        });
        this.appState = 'active';
        Events.subscribe(Events.Types.CHANGE_APP_STATE, (nextAppState) => {
            this.appState = nextAppState;
        });
        Events.subscribe(Events.Types.RECEIVED_MESSAGE_FROM_SERVER, async ({ channel, message: { data, event } }) => {
            const topicID = channel.split('/')[channel.split('/').length - 1];
            const { currentScreen, currentScreenParams } = this.getCurrentNavigationState();
            if(event === 'new_message') {
                if(currentScreen === screenNames.TOPIC && currentScreenParams && Number(currentScreenParams.topicID) === Number(topicID)) {
                    await wait(100);
                    this.props.updateLastVisit(topicID, data.created_at);
                }
            }
        });

        if(Platform.OS === 'web' && 'serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', async (event) => {
                try {
                    this.appState === 'active' && event.notification.close();
                    await this._handleNotification(event.data);
                } catch(error) {}
            });
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        await this._loadResourcesAsync();
        await this._bootstrapDeepLinks();
        await this._bootstrapWebNavigation();
        await this._bootstrapAsync();
        await this._bootstrapPushNotifications();
        await this._openDeepLink();
        await registerFetchTask();
    }

    _onServerError = async ({ method, action, error }) => {
        const { intl } = this.props;
        if(error.code === api.errorCodes.NO_INTERNET_ERROR_CODE) {
            error.message = intl.formatMessage({ id: 'errors.no_internet' });
        } else if (error.code === api.errorCodes.SERVER_HAS_BROKEN_ERROR_CODE) {
            error.message = intl.formatMessage({ id: 'errors.server_has_broken' });
        } else if(error.code === api.errorCodes.UNAUTHORIZED_ERROR_CODE) {
            await this.props.logout();
            return this.props.navigation.navigate(screenNames.AUTH_STACK);
        } else if(error.code === api.errorCodes.BANNED_ERROR_CODE) {
            await Toast.showSimpleWarning(intl.formatMessage({ id: 'common.you_are_banned' }));
            return this.props.navigation.navigate(screenNames.AUTH_STACK);
        }

        if(_.includes(noReloadErrorCodes, error.code)) {
            await Toast.showSimpleWarning(error.message);
        } else if(method === 'GET' || action === 'common/resolve-deep-link' || action === 'contact/is-contacts') {
            const toast = await Toast.showServerError(error.message, intl.formatMessage({ id: 'common.tap_to_retry' }), async () => {
                toast.setLoading(true);
                await wait(1000);
                const result = await processAllSelectors();
                toast.setLoading(false);
                if(result === 0) {
                    toast.hide();
                }
            });
        } else {
            await Toast.showSimpleDanger(error.message);
        }
    };

    _bootstrapAsync = async () => {
        const { path, queryParams } = this._initialDeepLink;
        if(await session.isLoggedIn()) {
            await this.retrieveData([
                [ 'retrieveLocale' ],
                [ 'retrieveTheme' ],
                [ 'getLocation' ],
                [ 'getDeepLink', path, queryParams ],
                [ 'getMe' ],
                [ 'initializeSession', (await session.get()).userID ],
                [ 'registerDevice', {
                    token: this.props.pushToken
                } ],
            ], (result) => {
                if(!result) return;
                return this.props.navigation.navigate(screenNames.SIGNED_STACK);
            });
        } else {
            await this.retrieveData([
                [ 'retrieveLocale' ],
                [ 'retrieveTheme' ],
                [ 'getLocation' ],
                [ 'getDeepLink', path, queryParams ],
            ], (result) => {
                if(!result) return;
                return this.props.navigation.navigate(screenNames.WELCOME);
            });
        }
    };

    _loadResourcesAsync = async () => {
        return Promise.all([
            Asset.fromModule(images.welcomeBackground).downloadAsync(),
            Asset.fromModule(images.welcomeLogo).downloadAsync(),
            Font.loadAsync(Feather.font)
        ]);
    };

    _bootstrapPushNotifications = async () => {
        Events.subscribe(Events.Types.NAVIGATION_STATE_CHANGE, ({ currentState }) => {
            activeRoute = this.getActiveRoute(currentState);
        });
        Notifications.addListener(this._handleNotification);
    };

    _handleNotification = async (notification) => {
        const { navigation } = this.props;
        const data = {
            ...notification.data
        };
        if(!this.props.isLoggedIn) {
            return;
        }
        let redirect = () => {};
        if(data.type === 'channel') {
            redirect = () => this.navigateToTopic(data.id);
        } else if (data.type === 'user') {
            redirect = () => {
                navigation.navigate(screenNames.PROFILE, {
                    userID: data.id
                });
            };
        } else if (data.type === 'notifications') {
            redirect = () => {
                navigation.navigate(screenNames.NOTIFICATIONS);
            };
        } else if (data.type === 'conversation') {
            redirect = () => this.navigateToConversation(data.id);
        } else if (data.type === 'requests') {
            redirect = () => {
                navigation.navigate(screenNames.CONTACTS_REQUESTS);
            };
        } else if (data.type === 'contacts') {
            redirect = () => {
                navigation.navigate(screenNames.CONTACTS);
            };
        } else {
            return;
        }
        if(notification.origin === 'selected') {
            redirect();
        } else if (notification.origin === 'received') {
            if(data.type === 'channel' && activeRoute) {
                if(activeRoute.routeName === screenNames.TOPIC && Number(activeRoute.params.topicID) === Number(data.id)) {
                    await this._clearAndroidPushNotification(notification);
                    return;
                }
            } else if (data.type === 'conversation' && activeRoute) {
                if(activeRoute.routeName === screenNames.CONVERSATION && Number(activeRoute.params.contactID) === Number(data.id)) {
                    await this._clearAndroidPushNotification(notification);
                    return;
                }
            }
            Toast.showUserMessage(
                data.title,
                data.message,
                data.nickname,
                data.avatarUrl,
                redirect
            );
            await this._clearAndroidPushNotification(notification);
        }
    };

    _clearAndroidPushNotification = async (notification) => {
        if(this.appState === 'active' && Platform.OS === 'android') {
            await Notifications.dismissNotificationAsync(notification.notificationId);
        }
    };

    _bootstrapDeepLinks = async () => {
        if(Platform.OS === 'web') return;
        this._initialDeepLink = await Linking.parseInitialURLAsync();//Linking.parse('https://wace.co/l/ua/kyiv/kpi');
        Linking.addEventListener('url', async (event) => {
            const { path, queryParams } = await Linking.parse(event.url);
            await this.retrieveData([
                'getDeepLink', path, queryParams
            ], (result) => {
                result && this.props.deepLink && this._openDeepLink();
            });
        });
    };

    _bootstrapWebNavigation = async () => {
        if(Platform.OS !== 'web') return;
        let path = '', queryParams = {};
        if(window && window.location) {
            const gup = function ( name, url ) {
                if (!url) url = location.href;
                name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
                let regexS = "[\\?&]"+name+"=([^&#]*)";
                let regex = new RegExp( regexS );
                let results = regex.exec( url );
                return results == null ? null : results[1];
            };
            const resolve = gup('resolve', window.location.toString());
            if(resolve) {
                path = resolve;
            } else {
                path = window.location.pathname.toString().substr(1)
            }
        }
        this._initialDeepLink = {
            path, queryParams
        };
    };

    _openDeepLink = async () => {
        const { navigation, deepLink, isLoggedIn } = this.props;
        if(!deepLink) {
            return;
        }
        if(deepLink.type === 'user') {
            navigation.navigate(screenNames.PROFILE, {
                userID: deepLink.id
            });
            this.props.clearDeepLink();
        } else if(deepLink.type === 'channel') {
            await this.navigateToTopic(deepLink.id);
            this.props.clearDeepLink();
        } else if (deepLink.type === 'location') {
            if(Platform.OS !== 'web') {
                await this.props.getLocation();
                Events.publish(Events.Types.CHANGE_LOCATION);
            } else {
                navigation.navigate(isLoggedIn ? screenNames.FEED : screenNames.START);
            }
            this.props.clearDeepLink();
        }
    };

    content() {
        const { styles, theme } = this.props;
        return (
            <View style={styles.wrapper}>
                <Image style={styles.splash} source={theme.IMAGES.splash} />
                <Loader style={styles.loader} color={theme.COLORS.TEXT} size={'small'} />
            </View>
        );
    }
}

let activeRoute;

const mapStateToProps = ({ auth, common }) => ({
    deepLink: common.deepLink,
    pushToken: auth.pushToken,
    isLoggedIn: auth.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
    getMe: () => dispatch(getMe()),
    retrieveLocale: () => dispatch(retrieveLocale()),
    retrieveTheme: () => dispatch(retrieveTheme()),
    getDeepLink: (path, queryParams) => dispatch(getDeepLink(path, queryParams)),
    clearDeepLink: () => dispatch(clearDeepLink()),
    initializeSession: (userID) => dispatch(initializeSession(userID)),
    getContact: (contactID) => dispatch(getContact(contactID)),
    retrieveChannel: (topicID) => dispatch(retrieveChannel(topicID)),
    registerDevice: (token) => dispatch(registerDevice(token)),
    updateTransportStatus: (status) => dispatch(updateTransportStatus(status)),
    updateLastVisit: (topicID, date) => dispatch(updateLastVisit(topicID, date)),
    logout: () => dispatch(logout()),
    getLocation: () => dispatch(getLocation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(AppLoadingScreen, theme => ({
        wrapper: {
            flex: 1,
            backgroundColor: theme.COLORS.BACKGROUND
        },
        splash: {
            flex: 1,
            resizeMode: 'contain',
            width: undefined,
            height: undefined
        },
        loader: {
            position: 'absolute',
            width: '100%',
            bottom: '15%'
        }
    })))
);
