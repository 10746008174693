const FONTS = {
    OPEN_SANS: {
        'normal': 'OpenSansRegular',
        'bold': 'OpenSansBold',
        '100': 'OpenSansLight',
        '300': 'OpenSansRegular',
        '500': 'OpenSansSemiBold',
        '700': 'OpenSansBold',
        '900': 'OpenSansExtraBold'
    },
    BALOO: 'Baloo',
};

export default FONTS;
