import React, { PureComponent } from 'react';

import { View, Text, Icon, Pressable } from '../../components/base';
import { withStyles } from "../../theme";
import { withNavigation } from 'react-navigation';
import { LinearGradient } from "expo-linear-gradient";
import screenNames from "../../navigation/screenNames";
import { injectIntl } from "react-intl";

class SectionHeader extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            newRequests,
            intl,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                {!!newRequests ? (
                    <Pressable style={styles.newRequestsWrapper} onPress={this.onPress}>
                        <LinearGradient
                            {...theme.COLORS.GRADIENTS.PRIMARY}
                            style={styles.newRequestsGradient}
                        />
                        <Text style={styles.headingText}>
                            {intl.formatMessage({ id: 'contacts.you_have_new_requests' }, { count: newRequests })}
                        </Text>
                        <Icon style={{ position: 'absolute', right: 15 }} name={'user-plus'} size={20} color={theme.COLORS.TEXT} />
                    </Pressable>
                ) : (
                    <LinearGradient
                        {...theme.COLORS.GRADIENTS.PRIMARY}
                        style={styles.gradient}
                    />
                )}
            </View>
        );
    }

    onPress = () => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.CONTACTS_REQUESTS);
    };
}

export default injectIntl(withNavigation(withStyles(SectionHeader, theme => ({
    wrapper: {
        marginTop: 5
    },
    gradient: {
        marginTop: 10,
        borderRadius: 2,
        height: 4,
        marginHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
    },
    newRequestsWrapper: {
        marginTop: 5,
        height: 60,
        marginHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
        borderRadius: 17.5,
        justifyContent: 'center'
    },
    newRequestsGradient: {
        height: 50,
        borderRadius: 17.5,
        position: 'absolute',
        width: '100%'
    },
    headingText: {
        color: theme.COLORS.TEXT,
        fontSize: 15,
        fontWeight: '700',
        marginHorizontal: 15
    }
}))));
