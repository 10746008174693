import React, { Component } from 'react';

import { Modal, Pressable, View, Icon, Text } from "../../components/base";
import { withStyles } from "../../theme";
import { withNavigation } from 'react-navigation';
import { Checkbox, Toast } from "../../components";
import { ifIphoneX } from "react-native-iphone-x-helper";
import {
    updateMute,
} from "../../state/user/actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import screenNames from "../../navigation/screenNames";

class FeedSettingsModal extends Component {

    state = {
        isMuteInPrecess: false
    };

    render() {

        const { isMuteInPrecess } = this.state;
        const { theme, styles, me, intl, visible, country, city, university } = this.props;

        return (
            <Modal
                visible={visible}
                onRequestClose={this.onClose}>
                <View style={styles.bottomContainer}>

                    {!!me && (
                        <Pressable style={styles.listItem} onPress={this.onPressMute} disabled={isMuteInPrecess}>
                            <View style={styles.listItemLeft}>
                                <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.WARNING }]}>
                                    <Icon name={'bell'} color={theme.COLORS.WHITE} size={19} />
                                </View>
                                <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'home.notifications' })}</Text>
                            </View>
                            <View style={styles.listItemRight}>
                                <Checkbox
                                    variation={'switch'}
                                    onChange={this.onPressMute}
                                    value={me.mute.toString() === '0'}
                                    disabled={isMuteInPrecess}
                                />
                            </View>
                        </Pressable>
                    )}

                    <Pressable style={styles.listItem} onPress={this.onPressLocation}>
                        <View style={styles.listItemLeft}>
                            <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.PRIMARY }]}>
                                <Icon name={'map-pin'} color={theme.COLORS.WHITE} size={19} />
                            </View>
                            <Text style={styles.listItemText} numberOfLines={1}>
                                {university
                                    ? `${university.short_name} (${city.short_name})`
                                    : `${city.short_name}, ${country.short_name}`}
                            </Text>
                        </View>
                        <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                            <Icon name={'chevron-right'} color={theme.COLORS.TEXT} size={23} />
                        </View>
                    </Pressable>

                </View>
            </Modal>
        );
    };

    onClose = async () => {
        if(this.props.onClose) {
            return this.props.onClose();
        }
        return null;
    };

    onPressLocation = () => {
        this.onClose();
        const { navigation } = this.props;
        navigation.navigate(screenNames.SETTINGS, {
            initialTab: 'location'
        });
    };
    onPressMute = async () => {
        const { me } = this.props;

        await this.setState({ isMuteInPrecess: true });
        await this.props.updateMute(me.mute.toString() === '1' ? 0 : 1);
        await this.setState({ isMuteInPrecess: false });
    };
}

const mapStateToProps = ({ user, common }) => ({
    me: user.me,
    country: common.country,
    city: common.city,
    university: common.university,
});

const mapDispatchToProps = dispatch => ({
    updateMute: (mute) => dispatch(updateMute(mute)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withNavigation(injectIntl(withStyles(FeedSettingsModal, theme => ({
        wrapper: {
            padding: 10,
            alignItems: 'center'
        },
        modalWrapper: {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.56)',
        },
        fadeWrapper: {
            flex: 1,
        },
        bottomContainer: {
            backgroundColor: theme.COLORS.BACKGROUND,
            paddingTop: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            ...ifIphoneX({
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN + 20,
            }, {
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            })
        },

        listItem: {
            width: '100%',
            height: 50,
            flexDirection: 'row'
        },
        listItemLeft: {
            flex: .7,
            flexDirection: 'row',
            alignItems: 'center'
        },
        listItemRight: {
            flex: .3,
            alignItems: 'flex-end'
        },
        listItemRightIcon: {
            justifyContent: 'center'
        },
        listItemSecondaryText: {
            fontWeight: '700',
            fontSize: 18,
            color: theme.COLORS.SECONDARY_TEXT
        },
        listItemText: {
            fontWeight: '700',
            fontSize: 18,
            color: theme.COLORS.TEXT
        },
        listItemIconWrap: {
            width: 36,
            height: 36,
            borderRadius: 12,
            marginRight: 10,
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            alignItems: 'center',
            justifyContent: 'center'
        }
    }))))
);


