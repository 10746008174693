{/*Red*/}
{/*Orange*/}
{/*Yellow*/}
{/*Olive*/}
{/*Green*/}
{/*Teal*/}
{/*Blue*/}
{/*Violet*/}
{/*Purple*/}
{/*Pink*/}
{/*Brown*/}
{/*Grey*/}
{/*Black*/}

const GRADIENTS_COLORS = {
    BLACK: {
        colors: ['#111111', '#010101'],
        start: [0, 0],
        end: [1, 1]
    },
    SECONDARY: {
        colors: ['#3c3c3c', '#444444'],
        start: [0, 0],
        end: [1, 1]
    },
    WACE_ORIGINAL: {
        colors: ['#7f2efc', '#4641f9'],
        start: [0, 0],
        end: [1, 1]
    },
    PURPLE: {
        colors: ['#DE4946', '#F918B9'],
        start: [0, 0],
        end: [1, 1]
    },
    RED: {
        colors: ['#eb3349', '#f45c43'],
        start: [0, 0],
        end: [1, 1]
    },
    VIOLET: {
        colors: ['#7b4397', '#dc2430'],
        start: [0, 0],
        end: [1, 1]
    },
    PINK: {
        colors: ['#ff512f', '#dd2476'],
        start: [0, 0],
        end: [1, 1]
    },
    ORANGE: {
        colors: ['#ff6337', '#f45c43'],
        start: [0, 0],
        end: [1, 1]
    },
    TEAL: {
        colors: ['#02aab0', '#00cdac'],
        start: [0, 0],
        end: [1, 1]
    },
    GREEN: {
        colors: ['#56ab2f', '#a8e063'],
        start: [0, 0],
        end: [1, 1]
    }
};

export const THEME = {
    STANDARD: '#121212',
    PRIMARY: '#6138f8',
    INFO: '#72b5f8',
    DANGER: '#dc493c',
    WARNING: '#fcab18',
    SUCCESS: '#56ab2f',
};

export const COMPONENTS = {
    BACKGROUND: '#1d1c25',
    BACKGROUND_ITEM: '#313038',
    TEXT: '#FFFFFF',
    PLACEHOLDER_TEXT: 'rgba(255,255,255,.75)',
    SECONDARY_TEXT: '#808080',
    INPUT: '#808080',
    PLACEHOLDER: '#9FA5AA',
    NAVBAR: '#F9F9F9',
    BLOCK: '#808080',
    ICON: '#000000',
};

export const GRADIENTS = {
    STANDARD: GRADIENTS_COLORS.BLACK,
    SECONDARY: GRADIENTS_COLORS.SECONDARY,
    PRIMARY: GRADIENTS_COLORS.WACE_ORIGINAL,
    INFO: GRADIENTS_COLORS.TEAL,
    DANGER: GRADIENTS_COLORS.RED,
    WARNING: GRADIENTS_COLORS.ORANGE,
    SUCCESS: GRADIENTS_COLORS.GREEN,
    HEADER: GRADIENTS_COLORS.SECONDARY,
};

const COLORS = {
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    GREY: '#898989',
    MUTED: '#9FA5AA',
    TRANSPARENT: 'transparent',
    NEUTRAL: 'rgba(255,255,255, 0.65)',
    KEYBOARD: 'dark',
    BAR_STYLE: 'light-content',
    GRADIENTS,
    ...COMPONENTS,
    ...THEME
};

export default COLORS;
