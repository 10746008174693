import React from 'react';

import { createStackNavigator } from 'react-navigation-stack';

import screens from '../screens';
import screenNames from './screenNames';

export default createStackNavigator({
    [screenNames.FEED]: screens.FeedScreen,
    [screenNames.START]: screens.StartScreen,
    [screenNames.SETTINGS]: screens.SettingsScreen,
    [screenNames.WALKTHROUGH]: screens.WalkthroughScreen,
    [screenNames.TOPIC_MEMBERS]: screens.TopicMembersScreen,
    [screenNames.PROFILE]: screens.ProfileScreen,
}, {
    initialRouteName: screenNames.FEED,
    headerMode: 'none',
    defaultNavigationOptions: {
        header: null,
        cardShadowEnabled: false,
        cardOverlayEnabled: false,
        gesturesEnabled: true,
        cardTransparent: true
    },
    navigationOptions: ({ navigation })=>{
        let { routeName } = navigation.state.routes[navigation.state.index];
        let navigationOptions = {
            headerVisible: false
        };
        if(routeName === screenNames.WALKTHROUGH) {
            navigationOptions.tabBarVisible = false;
            navigationOptions.gesturesEnabled = false;
        } else {
            navigationOptions.tabBarVisible = true;
        }
        return navigationOptions;
    }
});
