import React, { Component } from 'react';

import { noReloadErrorCodes, retrieveData } from "../../networking/selector";
import { NavigationActions, SwitchActions } from "react-navigation";
import { withStyles } from "../../theme";
import Loader from '../Loader';
import View from './View';
import { default as MiniLoader } from './Loader';
import Events from '../../utils/events';
import screenNames from "../../navigation/screenNames";
import { Text } from "./index";
import { injectIntl } from "react-intl";

class Screen extends Component {

    onNavigate = () => {};

    getCurrentNavigationState = () => {
        return {
            currentScreen,
            currentScreenParams
        }
    };

    componentDidMount() {
        this._mounted = true;
        this._navigationSubscription = Events.subscribe(Events.Types.NAVIGATION_STATE_CHANGE, ({ currentState, action }) => {
            const activeRoute = this.getActiveRoute(currentState);
            this.currentScreen = activeRoute.routeName;
            currentScreen = activeRoute.routeName;
            currentScreenParams = activeRoute.params;
            (this._mounted
                && (action.type === NavigationActions.NAVIGATE || action.type === SwitchActions.JUMP_TO)
                && this.currentScreen === this.screenName) && (this.onNavigate());
        });
    }

    componentWillUnmount() {
        this._mounted = false;
        this._navigationSubscription.remove();
    }

    /**
     * @param topicID
     * @returns {Promise<void>}
     */
    navigateToTopic = async (topicID) => {
        await this.retrieveData([
            'retrieveChannel', topicID
        ], 'showLoader', (result) => {
            result && this.props.navigation.navigate(screenNames.TOPIC, {
                topicID, preloaded: true
            });
        });
    };

    /**
     * @param contactID
     * @returns {Promise<void>}
     */
    navigateToConversation = async (contactID) => {
        await this.retrieveData([
            'getContact', contactID
        ], 'showLoader', (result) => {
            result && this.props.navigation.navigate(screenNames.CONVERSATION, {
                contactID, preloaded: true
            });
        });
    };

    /**
     * @param buttons
     */
    actionSheet = (buttons) => {
        if(openedActionSheet) {
            setTimeout(this.actionSheet.bind(this, buttons), 1000);
            return;
        } else {
            openedActionSheet = true;
        }
        buttons = buttons.filter(button => button.visible !== false);
        this.props.showActionSheetWithOptions({
            options: buttons.map(button => button.label),
            cancelButtonIndex: buttons.length - 1,
            destructiveButtonIndex: buttons.map(button => button.destructive).indexOf(true)
        }, (index) => {
            if(buttons[index].onPress) {
                buttons[index].onPress();
            }
            setTimeout(() => openedActionSheet = false, 10);
        });
    };

    /**
     * @param onReport
     */
    showReport = (onReport) => {
        const { intl } = this.props;
        this.actionSheet([ {
            label: intl.formatMessage({ id: 'common.spam' }),
            onPress: () => onReport(0)
        }, {
            label: intl.formatMessage({ id: 'common.violence' }),
            onPress: () => onReport(1)
        }, {
            label: intl.formatMessage({ id: 'common.pornography' }),
            onPress: () => onReport(2)
        }, {
            label: intl.formatMessage({ id: 'common.child_abuse' }),
            onPress: () => onReport(3)
        }, {
            label: intl.formatMessage({ id: 'common.copyright' }),
            onPress: () => onReport(4)
        }, {
            label: intl.formatMessage({ id: 'common.other' }),
            onPress: () => onReport(5)
        }, {
            label: intl.formatMessage({ id: 'common.cancel' })
        } ])
    };

    getActiveRoute = (navigationState) => {
        if (!navigationState) {
            return null;
        }
        const route = navigationState.routes[navigationState.index];
        if (route.routes) {
            return this.getActiveRoute(route);
        }
        return route;
    };

    /**
     * @param actions
     * @returns {Promise<{success, errorCode}>}
     */
    async retrieveData (actions) {
        if(typeof actions[0] === 'string') {
            actions = [ actions ];
        }

        let loadingStateKey, beforeFunction, afterFunction;

        if(arguments.length === 2 && typeof arguments[1] === 'string') {
            loadingStateKey = arguments[1];
        } else if(
            arguments.length === 2 && typeof arguments[1] === 'function'
        ) {
            afterFunction = arguments[1];
        } else if(
            arguments.length === 3
            && typeof arguments[1] === 'function'
            && typeof arguments[2] === 'string'
        ) {
            beforeFunction = arguments[1];
            loadingStateKey = arguments[2];
        } else if(
            arguments.length === 3
            && typeof arguments[2] === 'function'
            && typeof arguments[1] === 'string'
        ) {
            afterFunction = arguments[2];
            loadingStateKey = arguments[1];
        } else if(
            arguments.length === 4
            && typeof arguments[2] === 'function'
            && typeof arguments[3] === 'function'
            && typeof arguments[1] === 'string'
        ) {
            afterFunction = arguments[3];
            beforeFunction = arguments[2];
            loadingStateKey = arguments[1];
        }

        let requests = [];
        for(let i in actions) {
            let action = actions[i][0];
            actions[i].shift();
            requests.push(bind(this.props[action], this, actions[i]))
        }

        return await retrieveData(
            requests,
            () => {
                if(loadingStateKey) {
                    this._mounted && this.setState({ [loadingStateKey]: true });
                }
                if(beforeFunction) {
                    beforeFunction();
                }
            },
            ({ success, errorCode }) => {
                if(loadingStateKey && (success || noReloadErrorCodes.indexOf(errorCode) > -1)) {
                    this._mounted && this.setState({ [loadingStateKey]: false });
                }
                if(afterFunction) {
                    afterFunction(success);
                }
            }
        );
    }

    content() {
        return null;
    }

    render() {
        const content = this.content();

        return (
            <Wrapper>
                {content === null ? (
                    <Preloader />
                ) : (
                    content
                )}
                {this.state && this.state.showLoader && <Loader />}
            </Wrapper>
        );
    }
}

let currentScreen = '', currentScreenParams = {}, openedActionSheet = false;

const Wrapper = withStyles(({ styles, children }) => (
    <View style={styles.wrapper}>
        {children}
    </View>
), theme => ({
    wrapper: {
        flex: 1
    }
}));

const Preloader = injectIntl(withStyles(({ styles, intl, theme }) => (
    <View style={styles.wrapper}>
        <MiniLoader style={styles.sceneLoader} color={theme.COLORS.SECONDARY_TEXT} size={'small'} />
        <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
    </View>
), theme => ({
    wrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.COLORS.BACKGROUND
    },
    sceneLoader: {
        marginBottom: 8
    },
    sceneLoaderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        opacity: .7,
        fontSize: 9
    },
})));

const bind = (func, context, args) => {
    return function() {
        return func.apply(context, args);
    };
};

export default Screen;
