import {
    CLOSE_IMAGE,
    GET_LOCATION,
    REGISTER_DEVICE,
    OPEN_IMAGE,
    FORGOT_DEVICE,
    GLOBAL_SEARCH,
    GET_DEEP_LINK,
    LOAD_MORE_GLOBAL_SEARCH,
    CLEAR_DEEP_LINK,
    UPDATE_TRANSPORT_STATUS,
    GET_COUNTRIES,
    GET_CITIES,
    GET_UNIVERSITIES,
    UPDATE_LOCATION,
    LOAD_MORE_UNIVERSITY_USERS,
    GET_UNIVERSITY_USERS,
    UPDATE_CITY_LAST_VISIT,
    UPDATE_UNIVERSITY_LAST_VISIT,
    SEND_UNIVERSITY_SUBMISSION,
    UPDATE_NEW_CHANNELS_LAST_VISIT,
    GET_NEARBY_USERS,
    LOAD_MORE_NEARBY_USERS,
    OPEN_COMMUNITY_RULES,
    CLOSE_COMMUNITY_RULES,
    CHANGE_LOCALE,
    CHANGE_THEME,
    RETRIEVE_LOCALE,
    RETRIEVE_THEME
} from '../action-types';

import { Appearance } from 'react-native-appearance';
import keyValueStorage from '../../utils/keyValueStorage';
import api from '../../networking/api';
import { getMinCurrentLocale, getCurrentLocale } from "../../utils/localizationHelper";

const OFFSETS = {};

/**
 * **********
 * GET_LOCATION
 * **********
 */
const getLocationSuccess = (data, lastVisits) => {
    return {
        type: GET_LOCATION,
        payload: {
            data, lastVisits
        }
    };
};

export const getLocation = () => {
    return async (dispatch) => {
        let response = await api.call(`GET common/location?locale=${getCurrentLocale()}`),
            { success, result } = response;
        if(success) {
            let lastVisits = await keyValueStorage.get('newChannelsLastVisits', {});
            await dispatch(getLocationSuccess(result, lastVisits));
        }
        return response;
    };
};

/**
 * **********
 * SEND_UNIVERSITY_SUBMISSION
 * **********
 */
const sendUniversitySubmissionSuccess = (data) => {
    return {
        type: SEND_UNIVERSITY_SUBMISSION,
        payload: {
            data
        }
    };
};

export const sendUniversitySubmission = (cityID, title) => {
    return async (dispatch) => {
        let response = await api.call(`POST common/university-submission`, { title, city_id: cityID }),
            { success, result } = response;
        if(success) {
            dispatch(sendUniversitySubmissionSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * UPDATE_LOCATION
 * **********
 */
const updateLocationSuccess = (data) => {
    return {
        type: UPDATE_LOCATION,
        payload: {
            data
        }
    };
};

export const updateLocation = (city_id, university_id) => {
    return async (dispatch) => {
        let response = await api.call(`POST common/update-location`, {
                university_id, city_id
            }),
            { success, result } = response;
        if(success) {
            await dispatch(updateLocationSuccess(result));
            await dispatch(getLocation(getCurrentLocale()));
        }
        return response;
    };
};

/**
 * **********
 * GET_COUNTRIES
 * **********
 */
const getCountriesSuccess = (data) => {
    return {
        type: GET_COUNTRIES,
        payload: {
            data
        }
    };
};

export const getCountries = () => {
    return async (dispatch) => {
        //TODO: pagination.
        let response = await api.call(`GET common/location/countries?limit=100&offset=0`),
            { success, result } = response;
        if(success) {
            dispatch(getCountriesSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * GET_CITIES
 * **********
 */
const getCitiesSuccess = (countryID, data) => {
    return {
        type: GET_CITIES,
        payload: {
            countryID, data
        }
    };
};

export const getCities = (countryID) => {
    return async (dispatch) => {
        //TODO: pagination.
        let response = await api.call(`GET common/location/cities/${countryID}?limit=100&offset=0`),
            { success, result } = response;
        if(success) {
            dispatch(getCitiesSuccess(countryID, result));
        }
        return response;
    };
};

/**
 * **********
 * GET_UNIVERSITIES
 * **********
 */
const getUniversitiesSuccess = (cityID, data) => {
    return {
        type: GET_UNIVERSITIES,
        payload: {
            cityID, data
        }
    };
};

export const getUniversities = (cityID) => {
    return async (dispatch) => {
        //TODO: pagination.
        let response = await api.call(`GET common/location/universities/${cityID}?limit=100&offset=0`),
            { success, result } = response;
        if(success) {
            dispatch(getUniversitiesSuccess(cityID, result));
        }
        return response;
    };
};

/**
 * **********
 * GET_DEEP_LINK
 * **********
 */
const getDeepLinkSuccess = (data) => {
    return {
        type: GET_DEEP_LINK,
        payload: {
            data
        }
    };
};

export const getDeepLink = (path, queryParams) => {
    return async (dispatch) => {
        if(!path && Object.keys(queryParams).length === 0) {
            return { success: true };
        }
        let response = await api.call(`POST common/resolve-deep-link`, {
                path, queryParams
            }),
            { success, result } = response;
        if(success) {
            if(result && result.type === 'location') {
                let lastVisits = await keyValueStorage.get('newChannelsLastVisits', {});
                await dispatch(getLocationSuccess(result, lastVisits));
            }
            dispatch(getDeepLinkSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * UPDATE_TRANSPORT_STATUS
 * **********
 */
export const updateTransportStatus = (status) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_TRANSPORT_STATUS,
            payload: { status }
        });
    };
};

/**
 * **********
 * CLEAR_DEEP_LINK
 * **********
 */
export const clearDeepLink = () => {
    return async (dispatch) => {
        dispatch({
            type: CLEAR_DEEP_LINK
        });
    };
};

/**
 * **********
 * REGISTER_DEVICE
 * **********
 */
const registerDeviceSuccess = (data) => {
    return {
        type: REGISTER_DEVICE,
        payload: {
            data
        }
    };
};

export const registerDevice = (token) => {
    return async (dispatch) => {
        if(token === null || token.token === null) {
            return { "success": true };
        }
        let response = await api.call(`POST common/register-device`, token),
            { success, result } = response;
        if(success) {
            dispatch(registerDeviceSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * FORGOT_DEVICE
 * **********
 */
const forgotDeviceSuccess = (data) => {
    return {
        type: FORGOT_DEVICE,
        payload: {
            data
        }
    };
};

export const forgotDevice = (token) => {
    return async (dispatch) => {
        let response = await api.call(`POST common/forgot-device`, token),
            { success, result } = response;
        if(success) {
            dispatch(forgotDeviceSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * OPEN_IMAGE
 * **********
 */
export const openImage = (image) => {
    return async (dispatch) => {
        return dispatch({
            type: OPEN_IMAGE,
            payload: {
                images: [{
                    url: image
                }]
            }
        });
    };
};

/**
 * **********
 * CLOSE_IMAGE
 * **********
 */
export const closeImage = () => {
    return async (dispatch) => {
        return dispatch({
            type: CLOSE_IMAGE,
        });
    };
};

/**
 * **********
 * GLOBAL_SEARCH
 * **********
 */
const globalSearchSuccess = (data) => {
    return {
        type: GLOBAL_SEARCH,
        payload: {
            data
        }
    };
};
export const globalSearch = (query) => {
    let limit = 10, offset = OFFSETS[GLOBAL_SEARCH] = 0;
    return async (dispatch) => {
        if(!query) {
            dispatch(globalSearchSuccess([]));
            return { success: true };
        }
        let response = await api.call(`GET common/search/${query}?limit=${limit}&offset=${offset}`),
            { success, result } = response;
        if(success) {
            dispatch(globalSearchSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * LOAD_MORE_GLOBAL_SEARCH
 * **********
 */
const loadMoreGlobalSearchSuccess = (data) => {
    return {
        type: LOAD_MORE_GLOBAL_SEARCH,
        payload: {
            data
        }
    };
};

export const loadMoreGlobalSearch = (query) => {
    if(!query || OFFSETS[GLOBAL_SEARCH] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreGlobalSearchSuccess([]));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GLOBAL_SEARCH]) {
        OFFSETS[GLOBAL_SEARCH] = 0;
    }

    let limit = 10, offset = 10 + OFFSETS[GLOBAL_SEARCH];

    return async (dispatch) => {
        let response = await api.call(`GET common/search/${query}?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GLOBAL_SEARCH] += result.length;
            } else {
                OFFSETS[GLOBAL_SEARCH] = null;
            }
            await dispatch(loadMoreGlobalSearchSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * GET_NEARBY_USERS
 * **********
 */
const getNearbyUsersSuccess = (cityID, data, lastVisits) => {
    return {
        type: GET_NEARBY_USERS,
        payload: {
            cityID, data, lastVisits
        }
    };
};

let _cityTimeout;
export const getNearbyUsers = (cityID) => {
    let limit = 10, offset = OFFSETS[GET_NEARBY_USERS] = 0;
    return async (dispatch) => {
        if(!cityID) {
            dispatch(getNearbyUsersSuccess(cityID, []));
            return { success: true };
        }
        let response = await api.call(`GET common/nearby-users?cityID=${cityID}&limit=${limit}&offset=${offset}`),
            { success, result } = response;
        if(success) {
            let lastVisits = await keyValueStorage.get('cityLastVisits', {});
            await dispatch(getNearbyUsersSuccess(cityID, result, lastVisits));
            if(result[0] && result[0].created_at) {
                if(_cityTimeout) {
                    clearTimeout(_cityTimeout);
                }
                _cityTimeout = setTimeout(() => {
                    dispatch(updateCityLastVisit(cityID, result[0].created_at));
                }, 5000);
            }
        }
        return response;
    };
};

/**
 * **********
 * LOAD_MORE_NEARBY_USERS
 * **********
 */
const loadMoreNearbyUsersSuccess = (cityID, data) => {
    return {
        type: LOAD_MORE_NEARBY_USERS,
        payload: {
            cityID, data
        }
    };
};

export const loadMoreNearbyUsers = (cityID) => {
    if(!cityID || OFFSETS[GET_NEARBY_USERS] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreNearbyUsersSuccess(cityID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_NEARBY_USERS]) {
        OFFSETS[GET_NEARBY_USERS] = 0;
    }

    let limit = 10, offset = 10 + OFFSETS[GET_NEARBY_USERS];

    return async (dispatch) => {
        let response = await api.call(`GET common/nearby-users?cityID=${cityID}&limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_NEARBY_USERS] += result.length;
            } else {
                OFFSETS[GET_NEARBY_USERS] = null;
            }
            await dispatch(loadMoreNearbyUsersSuccess(cityID, result));
        }
        return response;
    };
};

/**
 * **********
 * GET_UNIVERSITY_USERS
 * **********
 */
const getUniversityUsersSuccess = (universityID, data, lastVisits) => {
    return {
        type: GET_UNIVERSITY_USERS,
        payload: {
            universityID, data, lastVisits
        }
    };
};


let _universityTimeout;
export const getUniversityUsers = (universityID) => {
    let limit = 10, offset = OFFSETS[GET_UNIVERSITY_USERS] = 0;
    return async (dispatch) => {
        if(!universityID) {
            dispatch(getUniversityUsersSuccess(universityID, []));
            return { success: true };
        }
        let response = await api.call(`GET common/university-users/${universityID}?limit=${limit}&offset=${offset}`),
            { success, result } = response;
        if(success) {
            let lastVisits = await keyValueStorage.get('universityLastVisits', {});
            await dispatch(getUniversityUsersSuccess(universityID, result, lastVisits));
            if(result[0] && result[0].created_at) {
                if(_universityTimeout) {
                    clearTimeout(_universityTimeout);
                }
                _universityTimeout = setTimeout(() => {
                    dispatch(updateUniversityLastVisit(universityID, result[0].created_at));
                }, 5000);
            }
        }
        return response;
    };
};

/**
 * **********
 * LOAD_MORE_UNIVERSITY_USERS
 * **********
 */
const loadMoreUniversityUsersSuccess = (universityID, data) => {
    return {
        type: LOAD_MORE_UNIVERSITY_USERS,
        payload: {
            universityID, data
        }
    };
};

export const loadMoreUniversityUsers = (universityID) => {
    if(!universityID || OFFSETS[GET_UNIVERSITY_USERS] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreUniversityUsersSuccess(universityID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_UNIVERSITY_USERS]) {
        OFFSETS[GET_UNIVERSITY_USERS] = 0;
    }

    let limit = 10, offset = 10 + OFFSETS[GET_UNIVERSITY_USERS];

    return async (dispatch) => {
        let response = await api.call(`GET common/university-users/${universityID}?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_UNIVERSITY_USERS] += result.length;
            } else {
                OFFSETS[GET_UNIVERSITY_USERS] = null;
            }
            await dispatch(loadMoreUniversityUsersSuccess(universityID, result));
        }
        return response;
    };
};

/**
 * **********
 * UPDATE_CITY_LAST_VISIT
 * **********
 */
const updateCityLastVisitSuccess = (cityID, data) => {
    return {
        type: UPDATE_CITY_LAST_VISIT,
        payload: {
            cityID, data
        }
    };
};

export const updateCityLastVisit = (cityID, date) => {
    return async (dispatch) => {
        let lastVisits = await keyValueStorage.get('cityLastVisits', {});
        lastVisits[cityID] = date + 1;
        await keyValueStorage.set('cityLastVisits', lastVisits);
        await dispatch(updateCityLastVisitSuccess(cityID, lastVisits[cityID]));
        return { success: true };
    };
};


/**
 * **********
 * UPDATE_UNIVERSITY_LAST_VISIT
 * **********
 */
const updateUniversityLastVisitSuccess = (universityID, data) => {
    return {
        type: UPDATE_UNIVERSITY_LAST_VISIT,
        payload: {
            universityID, data
        }
    };
};

export const updateUniversityLastVisit = (universityID, date) => {
    return async (dispatch) => {
        let lastVisits = await keyValueStorage.get('universityLastVisits', {});
        lastVisits[universityID] = date + 1;
        await keyValueStorage.set('universityLastVisits', lastVisits);
        await dispatch(updateUniversityLastVisitSuccess(universityID, lastVisits[universityID]));
        return { success: true };
    };
};

/**
 * **********
 * UPDATE_NEW_CHANNELS_LAST_VISIT
 * **********
 */
const updateNewChannelsLastVisitSuccess = (key, count) => {
    return {
        type: UPDATE_NEW_CHANNELS_LAST_VISIT,
        payload: {
            key, count
        }
    };
};

export const updateNewChannelsLastVisit = (key, count) => {
    return async (dispatch) => {
        let lastVisits = await keyValueStorage.get('newChannelsLastVisits', {});
        lastVisits[key] = count;
        await keyValueStorage.set('newChannelsLastVisits', lastVisits);
        await dispatch(updateNewChannelsLastVisitSuccess(key, count));
        return { success: true };
    };
};

/**
 * **********
 * OPEN_COMMUNITY_RULES
 * **********
 */
export const openCommunityRules = (callback) => {
    return async (dispatch) => {
        return dispatch({
            type: OPEN_COMMUNITY_RULES,
            payload: {
                callback
            }
        });
    };
};

/**
 * **********
 * CLOSE_COMMUNITY_RULES
 * **********
 */
export const closeCommunityRules = () => {
    return async (dispatch) => {
        return dispatch({
            type: CLOSE_COMMUNITY_RULES,
            payload: {}
        });
    };
};

/**
 * **********
 * RETRIEVE_LOCALE
 * **********
 */
export const retrieveLocale = () => {
    return async (dispatch) => {
        const locale = await keyValueStorage.get('locale', getMinCurrentLocale());
        await dispatch({
            type: RETRIEVE_LOCALE,
            payload: {
                locale
            }
        });
        return { "success": true };
    };
};

/**
 * **********
 * CHANGE_LOCALE
 * **********
 */
export const changeLocale = (locale) => {
    return async (dispatch) => {
        await keyValueStorage.set('locale', locale);
        await dispatch({
            type: CHANGE_LOCALE,
            payload: {
                locale
            }
        });
        return { "success": true };
    };
};

/**
 * **********
 * RETRIEVE_THEME
 * **********
 */
export const retrieveTheme = () => {
    return async (dispatch) => {
        const colorScheme = Appearance.getColorScheme();
        const theme = await keyValueStorage.get('theme', (
            colorScheme === 'light'
                ? 'light'
                : 'default'
        ));
        await dispatch({
            type: RETRIEVE_THEME,
            payload: {
                theme
            }
        });
        return { "success": true };
    };
};

/**
 * **********
 * CHANGE_THEME
 * **********
 */
export const changeTheme = (theme) => {
    return async (dispatch) => {
        await keyValueStorage.set('theme', theme);
        await dispatch({
            type: CHANGE_THEME,
            payload: {
                theme
            }
        });
        return { "success": true };
    };
};
