import React, { PureComponent } from 'react';

import { Text, View, Image, Avatar, Icon, Pressable, Hyperlink  } from '../../components/base';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";

class MessageItem extends PureComponent {

    render() {

        const {
            styles,
            style,
            author_avatar_normal,
            author_nickname,
            author_messages,
            author_karma,
            isOpened,
            hasSubscription,
            reply,
            quote,
            intl,
            created_at,
            reply_author_nickname,
            isOP,
            authorPermission
        } = this.props;

        if(reply) {
            return (
                <View style={styles.replyWrapper}>
                    <View style={styles.headerWrapper}>
                        <Text style={styles.messageHeaderText}>@{author_nickname}</Text>
                    </View>
                    {this.renderContent({
                        message: this.props.message,
                        extra_type: this.props.extra_type,
                        extra: this.props.extra,
                        reported: false,
                        rating: 0,
                        isSafe: true,
                        isOpened: false,
                        reply
                    })}
                </View>
            );
        }

        if(quote) {
            return (
                <View style={styles.quoteWrapper}>
                    <View style={styles.headerWrapper}>
                        <Text style={styles.messageHeaderText}>@{reply_author_nickname}</Text>
                    </View>
                    {this.renderContent({
                        message: this.props.reply_message,
                        extra_type: this.props.reply_extra_type,
                        extra: this.props.reply_extra,
                        reported: false,
                        rating: 0,
                        isSafe: true,
                        isOpened,
                        quote
                    })}
                </View>
            );
        }

        return (
            <Pressable onPress={this.onPress} onLongPress={this.onLongPress} style={[
                styles.wrapper,
                style
            ]}>
                <Pressable onPress={this.onPressAvatar} style={styles.avatarWrapper}>
                    <Avatar style={styles.avatar} avatarUrl={author_avatar_normal} />
                    {authorPermission === 'admin' ? (
                        <Text style={styles.adminText}>
                            A
                        </Text>
                    ) : authorPermission === 'moderator' ? (
                        <Text style={styles.moderText}>
                            M
                        </Text>
                    ) : (
                        <Text style={[styles.karmaText, (author_karma < 0 ? styles.karmaTextBad : null)]}>
                            {author_karma > 0 ? '+' + author_karma : author_karma}
                        </Text>
                    )}
                </Pressable>
                <View style={styles.headerWrapper}>
                    <Text style={styles.messageHeaderText}>@{author_nickname} ({isOP ? 'OP' : author_messages})</Text>
                    <Text style={styles.messageHeaderDateText}>{intl.formatRelative(created_at, true)}</Text>
                </View>
                {this.props.reply_author_nickname && <MessageItem {...this.props} quote />}
                {this.renderContent(this.props)}
                {this.props.rating !== 0 && (
                    <View style={this.props.rating > 0 ? styles.goodRating : styles.badRating}>
                        <Text style={styles.ratingMiniText}>{this.props.rating > 0 ? '+' : ''}{this.props.rating}</Text>
                    </View>
                )}
                {isOpened && (
                    <View style={styles.actionsWrapper}>
                        <Pressable disabled={!!this.props.hasVote} onPress={this.onVoteDown} style={[styles.iconButtonWrapper, { opacity: this.props.hasVote ? 0.5 : 1 }]}>
                            <Icon name={'thumbs-down'} style={[ styles.iconButton, styles.iconDown ]} />
                        </Pressable>
                        <Pressable disabled={!!this.props.hasVote} onPress={this.onVoteUp} style={[styles.iconButtonWrapper, { opacity: this.props.hasVote ? 0.5 : 1 }]}>
                            <Icon name={'thumbs-up'} style={[ styles.iconButton, styles.iconUp ]} />
                        </Pressable>
                        <Pressable onPress={this.onNewReply} style={styles.replyButton}>
                            <Icon name={'corner-up-left'} style={styles.iconButton} />
                        </Pressable>
                        <Pressable onPress={this.onLongPress} style={styles.optionsButton}>
                            <Icon name={'more-horizontal'} style={styles.iconButton} />
                        </Pressable>
                    </View>
                )}
            </Pressable>
        );
    }

    renderContent = ({ message, extra_type, extra, reported, rating, isSafe, isOpened, quote, reply }) => {
        const { styles, intl } = this.props;
        const offensiveContent = reported && isSafe;
        const opacity = (rating >=0 || isOpened) ? null : Math.abs(rating) < 5 ? 1 - (Math.abs(rating) * 0.2) : 0.2;
        const parsedExtra = ( extra_type === 2 && extra )
            ? JSON.parse(extra)
            : {};

        return offensiveContent && !isOpened ? (
            <View style={[styles.contentWrapper, { opacity }]}>
                <Text style={styles.unsafeMessageText}>[{intl.formatMessage({ id: 'common.unsafe_message' })}]</Text>
            </View>
        ) : extra_type === 1 ? (
            <View style={[styles.contentWrapper, { opacity }]}>
                <Pressable onPress={this.onScaleImage} style={styles.imageMessageWrapper}>
                    <View style={styles.imagePlaceholder} />
                    <Image style={styles.imageMessage} source={{ uri: message }} />
                </Pressable>
            </View>
        ) : extra_type === 2 && parsedExtra.r && parsedExtra.i ? (
            <View style={[styles.contentWrapper, { opacity }]}>
                <Hyperlink onPress={this.props.onPressLink} linkStyle={styles.messageLinkText}>
                    <Text style={styles.messageText} numberOfLines={(!isOpened && (quote || reply)) ? 1 : null}>
                        {message}
                    </Text>
                </Hyperlink>
                <Pressable onPress={this.openLink} style={styles.bigExtraImageMessageWrapper}>
                    <View style={styles.bigExtraImagePlaceholder} />
                    <Image style={styles.bigExtraImageMessage} source={{ uri: parsedExtra.i }} />
                </Pressable>
                <Text style={styles.bigExtraImageDescription} numberOfLines={1}>
                    {this.renderBigExtraIcon(parsedExtra)} {parsedExtra.t}
                </Text>
            </View>
        ) : extra_type === 2 ? (
            <View style={[styles.contentWrapper, { opacity }]}>
                <Hyperlink onPress={this.props.onPressLink} linkStyle={styles.messageLinkText}>
                    <Text style={styles.messageText} numberOfLines={(!isOpened && (quote || reply)) ? 1 : null}>
                        {message}
                    </Text>
                </Hyperlink>
                <Pressable onPress={this.openLink} style={styles.extraMessageWrapper}>
                    {!!parsedExtra.i && (
                        <Image style={styles.extraImage} source={{ uri: parsedExtra.i }} />
                    )}
                    <View style={styles.extraContentWrapper}>
                        {!!parsedExtra.t && (
                            <Text style={styles.extraTitle} numberOfLines={1}> {parsedExtra.t} </Text>
                        )}
                        {!!parsedExtra.d && (
                            <Text style={styles.extraDescription} numberOfLines={1}> {parsedExtra.d} </Text>
                        )}
                    </View>
                </Pressable>
            </View>
        ) : (
            <View style={[styles.contentWrapper, { opacity }]}>
                <Hyperlink onPress={this.props.onPressLink} linkStyle={styles.messageLinkText}>
                    <Text style={styles.messageText} numberOfLines={(!isOpened && (quote || reply)) ? 1 : null}>
                        {message}
                    </Text>
                </Hyperlink>
            </View>
        );
    };

    renderBigExtraIcon = (extra) => {
        if(extra.u && /youtu/.test(extra.u)) {
            return <Icon name={'youtube'} color={'#ff3654'} />
        }
        if(extra.u && /instagram/.test(extra.u)) {
            return <Icon name={'instagram'} color={'#ff3654'} />
        }
        return null;
    };

    onPress = () => {
        this.props.onPress(this.props);
    };

    onLongPress = () => {
        this.props.onLongPress(this.props);
    };

    onVoteDown = () => {
        this.props.onVoteDown(this.props);
    };

    onVoteUp = () => {
        this.props.onVoteUp(this.props);
    };

    onPressAvatar = () => {
        this.props.onPressAvatar(this.props);
    };

    onNewReply = () => {
        this.props.onNewReply(this.props);
    };

    onScaleImage = () => {
        this.props.onPressImage(this.props);
    };

    openLink = () => {
        if(this.props.reply_extra_type || this.props.reply_extra) {
            const extra = this.props.reply_extra_type === 2 && this.props.reply_extra ? JSON.parse(this.props.reply_extra) : {};
            if(extra.u) {
                this.props.onPressLink(extra.u);
            }
        } else {
            const extra = this.props.extra_type === 2 && this.props.extra ? JSON.parse(this.props.extra) : {};
            if(extra.u) {
                this.props.onPressLink(extra.u);
            }
        }
    };
}

export default injectIntl(withStyles(MessageItem, theme => ({
    wrapper: {
        paddingLeft: 36 + theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },
    headerWrapper: {
        marginLeft: 10,
        marginRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },

    avatarWrapper: {
        position: 'absolute',
        left: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
        top: 0
    },

    avatar: {
        width: 36,
        height: 36,
        borderRadius: 15
    },

    contentWrapper: {
        marginLeft: 10,
        marginTop: 3,
        paddingRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },

    messageHeaderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '700',
        fontSize: 13
    },

    messageHeaderDateText: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '300',
        fontSize: 12
    },

    messageText: {
        color: theme.COLORS.TEXT,
        fontSize: 13
    },

    messageLinkText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 13
    },

    unsafeMessageText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 13
    },

    karmaText: {
        width: 36,
        textAlign: 'center',
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 10,
        fontWeight: '700',
        marginTop: 2
    },

    adminText: {
        width: 36,
        textAlign: 'center',
        color: theme.COLORS.DANGER,
        fontSize: 10,
        fontWeight: '900',
        marginTop: 2
    },

    moderText: {
        width: 36,
        textAlign: 'center',
        color: theme.COLORS.WARNING,
        fontSize: 10,
        fontWeight: '900',
        marginTop: 2
    },

    karmaTextBad: {
        color: theme.COLORS.DANGER,
    },

    imageMessageWrapper: {
        overflow: 'hidden',
        width: theme.SIZES.SCREEN_WIDTH * .33,
        height: theme.SIZES.SCREEN_WIDTH * .33,
        borderRadius: 17.5
    },
    imagePlaceholder: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },
    imageMessage: {
        flex: 1,
        width: null,
        height: null
    },

    bigExtraImageMessageWrapper: {
        marginTop: 3,
        overflow: 'hidden',
        width: theme.SIZES.SCREEN_WIDTH * .33,
        height: theme.SIZES.SCREEN_WIDTH * .23,
        borderRadius: 17.5
    },
    bigExtraImagePlaceholder: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },
    bigExtraImageMessage: {
        flex: 1,
        width: null,
        height: null
    },
    bigExtraImageDescription: {
        fontSize: 13,
        color: theme.COLORS.TEXT,
        width: theme.SIZES.SCREEN_WIDTH * .33,
        fontWeight: '700'
    },

    extraMessageWrapper: {
        marginTop: 5,
        alignItems: 'center',
        padding: 10,
        flexDirection: 'row',
        borderRadius: 9,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },

    extraImage: {
        width: 40,
        height: 40,
        borderRadius: 9,
        marginRight: 5
    },

    extraContentWrapper: {
        flex: 1,
        justifyContent: 'center'
    },

    extraTitle: {
        fontSize: 14,
        color: theme.COLORS.TEXT,
        fontWeight: '700'
    },

    extraDescription: {
        fontSize: 13,
        color: theme.COLORS.SECONDARY_TEXT
    },

    actionsWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        height: 30,
        marginTop: 10,
        marginRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },

    replyButton: {
        marginHorizontal: 3.75,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 5,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        borderRadius: 8
    },
    optionsButton: {
        marginHorizontal: 3.75,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 5,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        borderRadius: 8
    },
    ratingText: {
        fontSize: 14,
        lineHeight: 30,
        color: theme.COLORS.TEXT,
        fontWeight: '500'
    },
    ratingTextWrapper: {
        alignSelf: 'center',
        paddingHorizontal: 10
    },
    iconButtonWrapper: {
        marginHorizontal: 3.75,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 6,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        borderRadius: 8
    },
    iconButton: {
        color: theme.COLORS.SECONDARY_TEXT,
        paddingHorizontal: 10,
        fontSize: 15
    },
    iconUp: {
        color: theme.COLORS.SUCCESS
    },
    iconDown: {
        color: theme.COLORS.DANGER
    },
    goodRating: {
        marginTop: 2,
        marginLeft: 10,
        alignSelf: 'flex-start',
        backgroundColor: theme.COLORS.SUCCESS,
        borderRadius: 5,
        paddingHorizontal: 3,
        paddingVertical: 1,
        opacity: 0.9
    },
    badRating: {
        marginTop: 2,
        marginLeft: 10,
        alignSelf: 'flex-start',
        backgroundColor: theme.COLORS.DANGER,
        borderRadius: 5,
        paddingHorizontal: 3,
        paddingVertical: 1,
        opacity: 0.9
    },
    ratingMiniText: {
        fontSize: 11,
        color: theme.COLORS.WHITE,
        fontWeight: '500'
    },

    replyWrapper: {},
    quoteWrapper: {
        borderColor: theme.COLORS.SECONDARY_TEXT,
        borderLeftWidth: 1.5,
        marginLeft: 12
    }
})));
