import { createIntl, getMinCurrentLocale, getLocaleMessages } from "../../utils/localizationHelper";
import {
    CLOSE_IMAGE,
    CHANGE_LOCALE,
    RETRIEVE_LOCALE,
    CHANGE_THEME,
    RETRIEVE_THEME,
    GET_LOCATION,
    OPEN_IMAGE,
    GLOBAL_SEARCH,
    LOAD_MORE_GLOBAL_SEARCH,
    GET_DEEP_LINK,
    CLEAR_DEEP_LINK,
    UPDATE_TRANSPORT_STATUS,
    GET_COUNTRIES,
    GET_CITIES,
    GET_UNIVERSITIES,
    CLEAR_COMMON_REDUCER,
    GET_UNIVERSITY_USERS,
    LOAD_MORE_UNIVERSITY_USERS,
    UPDATE_CITY_LAST_VISIT,
    UPDATE_UNIVERSITY_LAST_VISIT,
    UPDATE_NEW_CHANNELS_LAST_VISIT,
    GET_NEARBY_USERS,
    LOAD_MORE_NEARBY_USERS,
    OPEN_COMMUNITY_RULES,
    CLOSE_COMMUNITY_RULES
} from '../action-types';

import { list } from '../../theme';

let initialState = {
    city: null,
    country: null,
    university: null,
    channels_count: null,
    isImageOpened: false,
    isCommunityRulesOpened: false,
    onCommunityRulesAccept: () => {},
    searchResults: [],
    deepLink: null,
    isTransportOnline: true,
    images: [],
    countries: [],
    cities: {},
    universities: {},
    nearbyUsers: {},
    universityUsers: {},
    cityLastVisits: {},
    universityLastVisits: {},
    newChannelsLastVisits: {},
    intl: createIntl(getMinCurrentLocale(), getLocaleMessages(getMinCurrentLocale())),
    locale: getMinCurrentLocale(),
    theme: {},
    themeName: 'default'
};

export default (state = { ... initialState }, action) => {
    const {type, payload} = action;
    switch (type) {
        case GET_LOCATION:
            let { data } = payload;

            return {
                ...state,
                newChannelsLastVisits: payload.lastVisits,
                city: data.city,
                country: data.country,
                university: data.university,
                channels_count: data.channels_count
            };
        case CHANGE_LOCALE:
        case RETRIEVE_LOCALE:
            return {
                ...state,
                intl: createIntl(payload.locale, getLocaleMessages(payload.locale)),
                locale: payload.locale
            };
        case CHANGE_THEME:
        case RETRIEVE_THEME:
            return {
                ...state,
                themeName: payload.theme,
                theme: list[payload.theme]
            };
        case OPEN_IMAGE:
            state.isImageOpened = true;
            state.images = payload.images;
            return {
                ...state
            };
        case OPEN_COMMUNITY_RULES:
            state.isCommunityRulesOpened = true;
            state.onCommunityRulesAccept = payload.callback;
            return {
                ...state
            };
        case CLOSE_COMMUNITY_RULES:
            state.isCommunityRulesOpened = false;
            return {
                ...state
            };
        case GET_COUNTRIES:
            state.countries = payload.data;
            return {
                ...state
            };
        case GET_CITIES:
            state.cities[payload.countryID] = payload.data;
            return {
                ...state
            };
        case GET_UNIVERSITIES:
            state.universities[payload.cityID] = payload.data;
            return {
                ...state
            };
        case CLOSE_IMAGE:
            state.isImageOpened = false;
            state.images = [];
            return {
                ...state
            };
        case GLOBAL_SEARCH:
            return {
                ...state,
                searchResults: payload.data
            };
        case GET_NEARBY_USERS:
            if(payload.lastVisits) {
                state.cityLastVisits = payload.lastVisits;
            }
            state.nearbyUsers[payload.cityID] = payload.data;
            return {
                ...state
            };
        case LOAD_MORE_NEARBY_USERS:
            if(state.nearbyUsers[payload.cityID]) {
                state.nearbyUsers[payload.cityID] = state.nearbyUsers[payload.cityID].concat(payload.data);
            }
            return {
                ...state,
                nearbyUsers: {
                    ...state.nearbyUsers
                }
            };
        case GET_UNIVERSITY_USERS:
            if(payload.lastVisits) {
                state.universityLastVisits = payload.lastVisits;
            }
            state.universityUsers[payload.universityID] = payload.data;
            return {
                ...state
            };
        case LOAD_MORE_UNIVERSITY_USERS:
            if(state.universityUsers[payload.universityID]) {
                state.universityUsers[payload.universityID] = state.universityUsers[payload.universityID].concat(payload.data);
            }
            return {
                ...state,
                universityUsers: {
                    ...state.universityUsers
                }
            };
        case GET_DEEP_LINK:
            return {
                ...state,
                deepLink: payload.data
            };
        case CLEAR_DEEP_LINK:
            return {
                ...state,
                deepLink: null
            };
        case UPDATE_TRANSPORT_STATUS:
            return {
                ...state,
                isTransportOnline: payload.status
            };
        case LOAD_MORE_GLOBAL_SEARCH:
            if(state.searchResults) {
                state.searchResults = state.searchResults.concat(payload.data);
            }
            return {
                ...state
            };
        case UPDATE_CITY_LAST_VISIT:
            state.cityLastVisits[payload.cityID] = payload.data;
            return {
                ...state
            };
        case UPDATE_UNIVERSITY_LAST_VISIT:
            state.universityLastVisits[payload.universityID] = payload.data;
            return {
                ...state
            };
        case UPDATE_NEW_CHANNELS_LAST_VISIT:
            state.newChannelsLastVisits[payload.key] = payload.count;
            return {
                ...state
            };
        case CLEAR_COMMON_REDUCER:
            return {
                ...initialState,
                theme: state.theme,
                themeName: state.themeName,
                locale: state.locale,
                intl: state.intl,
                city: state.city,
                country: state.country,
                university: state.university,
                channels_count: state.channels_count,
            };
        default:
            return state;
    }
};
