import React, { PureComponent } from 'react';

import { View } from '../../components/base';
import { withStyles } from "../../theme";
import { LinearGradient } from "expo-linear-gradient/build/index";

class SectionHeader extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                <LinearGradient
                    {...theme.COLORS.GRADIENTS.PRIMARY}
                    style={styles.gradient}
                />
            </View>
        );
    }
}

export default withStyles(SectionHeader, theme => ({
    wrapper: {
        marginTop: 5
    },
    gradient: {
        marginTop: 10,
        borderRadius: 2,
        height: 4,
        marginHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
    }
}));
