import React from 'react';

import { StyleSheet, StatusBar } from "react-native";
import PropTypes from 'prop-types';
import Theme from './default';
import ThemeLight from './light';

export const list = {
    [Theme.name]: Theme,
    [ThemeLight.name]: ThemeLight
};

const Context = React.createContext({});

export function withStyles(Component, styles) {
    const _themesCache = {};
    return class extends React.PureComponent {
        _styles = (theme) => {
            if(_themesCache[theme.name]) {
                return _themesCache[theme.name];
            }
            if(styles) {
                return _themesCache[theme.name] = StyleSheet.create(styles(theme))
            }
            return null;
        };
        render() {
            const { props } = this;
            return (
                <Context.Consumer>
                    {theme => (
                        <Component
                            {...props}
                            theme={theme}
                            styles={this._styles(theme)}
                        />
                    )}
                </Context.Consumer>
            );
        }
    };
}

export class ThemeProvider extends React.Component {
    static defaultProps = {
        children: null,
        theme: {},
    };

    render() {
        const { theme, children } = this.props;
        const { COLORS: CUSTOM_COLORS, SIZES: CUSTOM_SIZES, FONTS: CUSTOM_FONTS, IMAGES: CUSTOM_IMAGES, ...customTheme } = theme;
        const providerTheme = {
            COLORS: { ...Theme.COLORS, ...CUSTOM_COLORS },
            SIZES: { ...Theme.SIZES, ...CUSTOM_SIZES },
            FONTS: { ...Theme.FONTS, ...CUSTOM_FONTS },
            IMAGES: { ...Theme.IMAGES, ...CUSTOM_IMAGES },
            ...customTheme
        };
        const currentTheme = { ...Theme, ...providerTheme };
        return (
            <Context.Provider value={currentTheme}>
                <StatusBar barStyle={currentTheme.COLORS.BAR_STYLE} />
                {children}
            </Context.Provider>
        );
    }
}

ThemeProvider.propTypes = {
    children: PropTypes.any,
    theme: PropTypes.any
};
