import React from 'react';

import { createAppContainer } from 'react-navigation';
import { Transition } from 'react-native-reanimated';
import createAnimatedSwitchNavigator from 'react-navigation-animated-switch';

import AuthStack from './AuthStack';
import SignedStack from './SignedStack';
import screenNames from './screenNames';
import screens from '../screens';

export default createAppContainer(createAnimatedSwitchNavigator({
    [screenNames.APP_LOADING]: screens.AppLoadingScreen,
    [screenNames.SIGNED_STACK]: SignedStack,
    [screenNames.AUTH_STACK]: AuthStack
},{
    initialRouteName: screenNames.APP_LOADING,
    transition: (
        <Transition.Out type="fade" durationMs={300} interpolation="easeInOut" />
    )
}));
