import { Platform } from "react-native";

if(Platform.OS === 'android') {
    require('intl');
    require('intl/locale-data/jsonp/en');
    require('intl/locale-data/jsonp/ru');
    require('intl/locale-data/jsonp/uk');
}

require('intl-pluralrules');
require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/dist/include-aliases');
require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
require('@formatjs/intl-relativetimeformat/dist/locale-data/ru');
require('@formatjs/intl-relativetimeformat/dist/locale-data/uk');
