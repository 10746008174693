import React, { Component } from 'react';

import { View, Text, Modal } from "../../components/base";
import { withStyles } from "../../theme";
import { withNavigation } from 'react-navigation';
import { Button, Checkbox, TextInput, Toast, WelcomeFade } from "../../components";
import { ifIphoneX } from "react-native-iphone-x-helper";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Validator from '../../utils/validator';
import screenNames from "../../navigation/screenNames";
import { wait } from "../../utils/async";
import { StatusBar } from "react-native";
import { registerDevice } from "../../state/common/actions";
import { signIn, signUp } from "../../state/auth/actions";

class SignModal extends Component {

    state = {
        isRegistration: true,
        form: {
            nickname: '',
            password: ''
        },
        showPassword: false,
        isLoading: false,
        errors: {},
    };

    render() {

        const { form, errors, isLoading, isRegistration, showPassword } = this.state;
        const { theme, styles, intl, visible } = this.props;

        return (
            <Modal
                visible={visible}
                onRequestClose={this.onClose}
                avoidKeyboard
            >
                {isRegistration ? (
                    <View style={styles.bottomContainer}>
                        <Text style={styles.headingText} numberOfLines={1}>
                            {intl.formatMessage({ id: 'home.please_log_or_reg' })}
                        </Text>
                        <View style={[styles.formField, { marginTop: 0 }]}>
                            <TextInput
                                style={styles.formInput}
                                placeholder={intl.formatMessage({ 'id': 'sign_up.nickname_placeholder' })}
                                value={form.nickname}
                                variation={'default'}
                                blurOnSubmit={false}
                                inputRef={component => this._nicknameInput = component}
                                onSubmitEditing={this.onPressSignUp}
                                error={errors.nickname}
                                onChangeText={(nickname) => this.setState({ form: { ...form, nickname } })}
                            />
                        </View>
                        <View style={styles.formField}>
                            <TextInput
                                style={styles.formInput}
                                secureTextEntry={!showPassword}
                                inputRef={component => this._passwordInput = component}
                                placeholder={intl.formatMessage({ 'id': 'sign_up.password_placeholder' })}
                                icon={showPassword ? 'eye-off' : 'eye'}
                                value={form.password}
                                variation={'default'}
                                error={errors.password}
                                onSubmitEditing={this.onPressSignUp}
                                onChangeText={(password) => this.setState({ form: { ...form, password } })}
                                onPressIcon={() => this.setState({ showPassword: !showPassword })}
                            />
                        </View>
                        <Button loading={isLoading} onPress={this.onPressSignUp} size={'medium'} style={styles.acceptButton}>
                            {intl.formatMessage({ 'id': 'sign_up.sign_up' })} 🎉
                        </Button>
                        <Button onPress={this.onSwitch} size={'medium'} variation={'transparent'} style={styles.switchButton}>
                            {intl.formatMessage({ 'id': 'sign_up.have_account' })} <Text style={styles.switchButtonTextLink}>{intl.formatMessage({ 'id': 'sign_up.sign_in' })}</Text>
                        </Button>
                        <Button onPress={this.onClose} size={'medium'} variation={'transparent'} style={styles.cancelButton}>
                            <Text style={styles.cancelButtonText}>{intl.formatMessage({ id: 'common.cancel' })}</Text>
                        </Button>
                    </View>
                ) : (
                    <View style={styles.bottomContainer}>
                        <Text style={styles.headingText} numberOfLines={1}>
                            {intl.formatMessage({ id: 'home.please_log_or_reg' })}
                        </Text>
                        <View style={[styles.formField, { marginTop: 0 }]}>
                            <TextInput
                                style={styles.formInput}
                                placeholder={intl.formatMessage({ 'id': 'welcome.nickname_placeholder' })}
                                value={form.nickname}
                                variation={'default'}
                                blurOnSubmit={false}
                                inputRef={component => this._nicknameInput = component}
                                onSubmitEditing={this.onPressSignIn}
                                onChangeText={(nickname) => this.setState({ form: { ...form, nickname } })}
                            />
                        </View>
                        <View style={styles.formField}>
                            <TextInput
                                style={styles.formInput}
                                secureTextEntry={!showPassword}
                                inputRef={component => this._passwordInput = component}
                                placeholder={intl.formatMessage({ 'id': 'welcome.password_placeholder' })}
                                icon={showPassword ? 'eye-off' : 'eye'}
                                variation={'default'}
                                value={form.password}
                                onSubmitEditing={this.onPressSignIn}
                                onChangeText={(password) => this.setState({ form: { ...form, password } })}
                                onPressIcon={() => this.setState({ showPassword: !showPassword })}
                            />
                        </View>
                        <Button loading={isLoading} onPress={this.onPressSignIn} size={'medium'} style={styles.acceptButton}>
                            {intl.formatMessage({ 'id': 'welcome.login' })} 👋
                        </Button>
                        <Button onPress={this.onSwitch} size={'medium'} variation={'transparent'} style={styles.switchButton}>
                            {intl.formatMessage({ 'id': 'welcome.dont_have_account' })} <Text style={styles.switchButtonTextLink}>{intl.formatMessage({ 'id': 'welcome.sign_up' })}</Text>
                        </Button>
                        <Button onPress={this.onClose} size={'medium'} variation={'transparent'} style={styles.cancelButton}>
                            <Text style={styles.cancelButtonText}>{intl.formatMessage({ id: 'common.cancel' })}</Text>
                        </Button>
                    </View>
                )}
            </Modal>
        );
    };

    onPressSignUp = async () => {
        const { nickname, password } = this.state.form;

        const validator = new Validator([
            ['nickname', Validator.rules.NOT_EMPTY],
            ['password', Validator.rules.NOT_EMPTY],
            ['nickname', Validator.rules.REGEX, Validator.regEx.NICKNAME],
            ['nickname', Validator.rules.LENGTH, { min: 4, max: 21 }],
            ['password', Validator.rules.LENGTH, { min: 6, max: 32 }],
        ], this.state.form);

        const { city, deepLink, intl, navigation } = this.props;

        let cityID = city.id, universityID = null;
        if(deepLink && deepLink.type === 'location') {
            deepLink.city_id && (cityID = deepLink.city_id);
            deepLink.university_id && (universityID = deepLink.university_id);
        }

        await this.setState({ isLoading: true, errors: {} });

        if(await validator.validate()) {
            const { success, errors } = await this.props.signUp(nickname, password, cityID, universityID);
            if(success) {
                await this.onClose();
                if(this.props.pushToken !== null) {
                    await this.props.registerDevice({
                        token: this.props.pushToken
                    });
                }
                await this.setState({ isLoading: false });

                await wait(200);
                Toast.showFade((
                    <WelcomeFade onComplete={async () => {
                        Toast.hideFade();
                        StatusBar.setHidden(false, 'fade');
                    }} />
                ));

                StatusBar.setHidden(true, 'fade');
                await wait(750);

                return navigation.navigate(screenNames.FEED);
            } else if(errors) {
                validator.setErrors(errors);
                await this.setState({ errors: validator.getIntlErrorsMessages(intl) });
            }
        } else {
            await this.setState({ errors: validator.getIntlErrorsMessages(intl) });
        }

        if(this.state.errors.nickname) {
            this._nicknameInput.focus();
        } else if(this.state.errors.password) {
            this._passwordInput.focus();
        }

        await this.setState({ isLoading: false });
    };

    onPressSignIn = async () => {
        const { nickname, password } = this.state.form,
            { navigation, intl } = this.props;

        if(!nickname) {
            return this._nicknameInput.focus()
        } else if (!password) {
            return this._passwordInput.focus()
        }

        await this.setState({ isLoading: true });

        const { success, error } = await this.props.signIn(nickname, password);

        if(success) {
            await this.onClose();
            if(this.props.pushToken !== null) {
                await this.props.registerDevice({
                    token: this.props.pushToken
                });
            }
            await this.setState({ isLoading: false });
            return navigation.navigate(screenNames.FEED);
        } else if(!error) {
            Toast.showSimpleDanger(intl.formatMessage({ id: 'welcome.wrong_login_or_password' }));
        }

        await this.setState({ isLoading: false });
    };

    onClose = async () => {
        if(this.props.onClose) {
            return this.props.onClose();
        }
        return null;
    };

    onSwitch = () => this.setState({
        isRegistration: !this.state.isRegistration
    });
}


const mapStateToProps = ({ auth, common }) => ({
    deepLink: common.deepLink,
    pushToken: auth.pushToken,
    city: common.city
});

const mapDispatchToProps = dispatch => ({
    registerDevice: (token) => dispatch(registerDevice(token)),
    signIn: (login, password) => dispatch(signIn(login, password)),
    signUp: (login, password, cityID, universityID) => dispatch(signUp(login, password, cityID, universityID)),

});

export default connect(mapStateToProps, mapDispatchToProps)(
    withNavigation(injectIntl(withStyles(SignModal, theme => ({
        wrapper: {
            padding: 10,
            alignItems: 'center'
        },
        lightboxWrapper: {
            position: 'absolute',
            width: theme.SIZES.SCREEN_WIDTH,
            height: theme.SIZES.SCREEN_HEIGHT,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: theme.COLORS.BACKGROUND,
            elevation: 2
        },
        imageViewer: {
            height: '50%'
        },
        image: {
            width: 22,
            height: 22,
            borderRadius: 11,
            borderWidth: 1.5
        },
        closeImage: {
            alignItems: 'flex-end',
            justifyContent: 'center',
            width: 100,
            height: 60,
            position: 'absolute',
            right: 20,
            top: 30,
            opacity: .85
        },

        modalWrapper: {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',

            backgroundColor: 'rgba(0,0,0,0.56)',
        },
        fadeWrapper: {
            flex: 1,
        },
        bottomContainer: {
            backgroundColor: theme.COLORS.BACKGROUND,
            paddingTop: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            ...ifIphoneX({
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN + 20,
            }, {
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            })
        },
        headingText: {
            textAlign: 'center',
            color: theme.COLORS.TEXT,
            fontWeight: '900',
            fontSize: 19,
            paddingHorizontal: 10,
            paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN
        },
        headingNicknameText: {
            color: theme.COLORS.SECONDARY_TEXT,
            fontWeight: '900',
            fontSize: 19,
        },
        formWrapper: {
            width: '100%',
            paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING
        },
        formContentWrapper: {
            paddingBottom: 10
        },
        formField: {
            marginTop: 15,
        },
        formLabelText: {
            marginLeft: 9,
            marginVertical: 3,
            fontSize: 14,
            color: theme.COLORS.SECONDARY_TEXT,
            fontWeight: '700'
        },
        formInput: {

        },
        acceptButton: {
            marginTop: 25
        },
        cancelButton: {
            marginTop: 5
        },
        switchButton: {
            marginTop: 15
        },
        cancelButtonText: {
            color: theme.COLORS.SECONDARY_TEXT,
            fontWeight: '900'
        },
        switchButtonText: {
            color: theme.COLORS.SECONDARY_TEXT,
            fontWeight: '900'
        },
        switchButtonTextLink: {
            fontWeight: '900',
            color: theme.COLORS.PRIMARY
        },
    }))))
);


