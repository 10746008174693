import { Platform } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import * as ImageManipulator from "expo-image-manipulator";

/**
 * @param saveOptions
 * @returns {Promise<string|null>}
 */
export const pickFromGallery = async (saveOptions) => {
    if(Platform.OS === 'web') {
        const pickerResult = await ImagePicker.launchImageLibraryAsync();
        return pickerResult.uri;
    }
    const {
        status: cameraRollPerm
    } = await Permissions.askAsync(Permissions.CAMERA_ROLL);

    if (cameraRollPerm === 'granted') {
        const pickerResult = await ImagePicker.launchImageLibraryAsync();
        if(pickerResult && pickerResult.uri) {
            if(saveOptions) {
                const { resizeCoefficient, compressRatio } = saveOptions;
                const result = await ImageManipulator.manipulateAsync(pickerResult.uri, [ { resize: {
                        width: pickerResult.width * resizeCoefficient,
                        height: pickerResult.height * resizeCoefficient
                    } }], {
                    compress: compressRatio
                });
                return result.uri;
            }
            return pickerResult.uri;
        }
    }

    return null;
};


/**
 * @param saveOptions
 * @returns {Promise<string|null>}
 */
export const takePhoto = async (saveOptions) => {
    if(Platform.OS === 'web') {
        const pickerResult = await ImagePicker.launchImageLibraryAsync();
        return pickerResult.uri;
    }
    const {
        status: cameraPerm
    } = await Permissions.askAsync(Permissions.CAMERA);

    if (cameraPerm === 'granted') {
        const pickerResult = await ImagePicker.launchCameraAsync();

        if(pickerResult && pickerResult.uri) {
            if(saveOptions) {
                const { resizeCoefficient, compressRatio } = saveOptions;
                const result = await ImageManipulator.manipulateAsync(pickerResult.uri, [ { resize: {
                        width: pickerResult.width * resizeCoefficient,
                        height: pickerResult.height * resizeCoefficient
                    } }], {
                    compress: compressRatio
                });
                return result.uri;
            }
            return pickerResult.uri;
        }
    }

    return null;
};
