import React, { PureComponent } from 'react';

import { Modal, Pressable, View, Icon, Text } from "../../components/base";
import { withStyles } from "../../theme";
import { withNavigation } from 'react-navigation';
import { Toast } from "../../components";
import { ifIphoneX } from "react-native-iphone-x-helper";
import {
   deleteChannelMessage,
   reportChannelMessage
} from "../../state/channel/actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Clipboard } from 'react-native';
import { blockUser } from "../../state/user/actions";
import BanUserModal from '../TopicMembersScreen/BanUserModal';
import { showConfirm } from "../../utils/simpleAlert";
import { checkPermissions } from "../../utils/permissions";

class MessageOptionsModal extends PureComponent {

    state = {
        isBanModalVisible: false,
        isUnbanLoading: false,
        banUser: null
    };

    render() {

        const { isBanModalVisible, banUser } = this.state;
        const { theme, styles, message, channel, me, intl, visible } = this.props;

        if(message === null) {
            return null;
        }

        const isMyMessage = me.id === message.author_id,
            isSystemMessage = message.is_system,
            isAdmin = (me.id === channel.author_id) || checkPermissions(me ? me.access : [], 'ban_member', channel.city_id, channel.university_id);

        return (
            <View>
                {!isBanModalVisible && (
                    <Modal
                        visible={visible}
                        onRequestClose={this.onClose}>
                        <View style={styles.bottomContainer}>

                            {!isSystemMessage && (
                                <Pressable style={styles.listItem} onPress={this.onPressCopyToClipboard}>
                                    <View style={styles.listItemLeft}>
                                        <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.INFO }]}>
                                            <Icon name={'link'} color={theme.COLORS.WHITE} size={19} />
                                        </View>
                                        <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.copy_message_text' })}</Text>
                                    </View>
                                    <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                                        <Icon name={'copy'} color={theme.COLORS.TEXT} size={19} />
                                    </View>
                                </Pressable>
                            )}

                            {(!isMyMessage && !isSystemMessage) && (
                                <Pressable style={styles.listItem} onPress={this.onPressIgnore}>
                                    <View style={styles.listItemLeft}>
                                        <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.WARNING }]}>
                                            <Icon name={'slash'} color={theme.COLORS.WHITE} size={19} />
                                        </View>
                                        <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.ignore' })} <Text style={styles.listItemSecondaryText}>(@{message.author_nickname})</Text></Text>
                                    </View>
                                    <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                                        <Icon name={'chevron-right'} color={theme.COLORS.TEXT} size={23} />
                                    </View>
                                </Pressable>
                            )}

                            {(isAdmin && !isMyMessage && !isSystemMessage) && (
                                <Pressable style={styles.listItem} onPress={this.onPressBanUser}>
                                    <View style={styles.listItemLeft}>
                                        <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.DANGER }]}>
                                            <Icon name={'slash'} color={theme.COLORS.WHITE} size={19} />
                                        </View>
                                        <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.ban' })} <Text style={styles.listItemSecondaryText}>(@{message.author_nickname})</Text></Text>
                                    </View>
                                    <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                                        <Icon name={'chevron-right'} color={theme.COLORS.TEXT} size={23} />
                                    </View>
                                </Pressable>
                            )}

                            {(!isMyMessage && !isSystemMessage) && (
                                <Pressable style={styles.listItem} onPress={this.onPressReportMessage}>
                                    <View style={styles.listItemLeft}>
                                        <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.WARNING }]}>
                                            <Icon name={'alert-circle'} color={theme.COLORS.WHITE} size={19} />
                                        </View>
                                        <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.report' })}</Text>
                                    </View>
                                    <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                                        <Icon name={'chevron-right'} color={theme.COLORS.TEXT} size={23} />
                                    </View>
                                </Pressable>
                            )}

                            {(isAdmin || isMyMessage) && (
                                <Pressable style={styles.listItem} onPress={this.onPressDeleteMessage}>
                                    <View style={styles.listItemLeft}>
                                        <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.DANGER }]}>
                                            <Icon name={'trash'} color={theme.COLORS.WHITE} size={19} />
                                        </View>
                                        <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.delete_message' })}</Text>
                                    </View>
                                    <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                                        <Icon name={'chevron-right'} color={theme.COLORS.TEXT} size={23} />
                                    </View>
                                </Pressable>
                            )}
                        </View>
                    </Modal>
                )}
                <BanUserModal
                    visible={isBanModalVisible}
                    banUser={banUser}
                    isAdmin={channel.author_id === me.id || checkPermissions(me.access, 'ban_member', channel.city_id, channel.university_id)}
                    onConfirm={() => this.setState({ isBanModalVisible: false })}
                    onClose={() => this.setState({ isBanModalVisible: false })}
                />
            </View>
        );
    };

    onClose = async () => {
        if(this.props.onClose) {
            return this.props.onClose();
        }
        return null;
    };

    onPressBanUser = async () => {
        const { message, channel } = this.props;
        await this.onClose();
        this.setState({
            isBanModalVisible: true,
            banUser: {
                topicID: channel.id,
                user_nickname: message.author_nickname,
                user_id: message.author_id
            }
        });
    };

    onPressCopyToClipboard = async () => {
        const { intl, message } = this.props;
        await this.onClose();
        Clipboard.setString(message.message);
        Toast.showSimpleSuccess(intl.formatMessage({ id: 'common.copied_to_clipboard' }));
    };

    onPressDeleteMessage = async () => {
        const { message, channel } = this.props;
        await this.props.deleteChannelMessage(channel.id, message.id);
        await this.onClose();
    };

    onPressReportMessage = async () => {
        const { showReport, message, channel, intl } = this.props;
        showReport(async (reportType) => {
            const { success } = await this.props.reportChannelMessage(channel.id, message.id, reportType);
            if(success) {
                await this.onClose();
                Toast.showSimpleSuccess(intl.formatMessage({ id: 'common.report_successfully_sent' }));
            }
        });
    };

    onPressIgnore = async () => {
        const { intl, message } = this.props;
        showConfirm(
            intl.formatMessage({ id: 'common.confirm_action' }),
            intl.formatMessage({ id: 'common.ignore_user_confirm_text' }, {
                nickname: `@${message.author_nickname}`
            }),
            intl.formatMessage({ id: 'common.yes_ignore' }),
            intl.formatMessage({ id: 'common.cancel' }),
            async () => {
                await this.onClose();
                Toast.showSimpleSuccess(intl.formatMessage({ id: 'profile.user_blocked' }));
                await this.props.blockUser(message.author_id);
            }
        );
    };
}

const mapStateToProps = ({ user }) => ({
    me: user.me
});

const mapDispatchToProps = dispatch => ({
    blockUser: (userID) => dispatch(blockUser(userID)),
    deleteChannelMessage: (channelID, messageID) => dispatch(deleteChannelMessage(channelID, messageID)),
    reportChannelMessage: (channelID, messageID, reportType) => dispatch(reportChannelMessage(channelID, messageID, reportType))
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withNavigation(injectIntl(withStyles(MessageOptionsModal, theme => ({
        wrapper: {
            padding: 10,
            alignItems: 'center'
        },
        modalWrapper: {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.56)',
        },
        fadeWrapper: {
            flex: 1,
        },
        bottomContainer: {
            backgroundColor: theme.COLORS.BACKGROUND,
            paddingTop: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            ...ifIphoneX({
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN + 20,
            }, {
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            })
        },

        listItem: {
            width: '100%',
            height: 50,
            flexDirection: 'row'
        },
        listItemLeft: {
            flex: .7,
            flexDirection: 'row',
            alignItems: 'center'
        },
        listItemRight: {
            flex: .3,
            alignItems: 'flex-end'
        },
        listItemRightIcon: {
            justifyContent: 'center'
        },
        listItemSecondaryText: {
            fontWeight: '700',
            fontSize: 18,
            color: theme.COLORS.SECONDARY_TEXT
        },
        listItemText: {
            fontWeight: '700',
            fontSize: 18,
            color: theme.COLORS.TEXT
        },
        listItemIconWrap: {
            width: 36,
            height: 36,
            borderRadius: 12,
            marginRight: 10,
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            alignItems: 'center',
            justifyContent: 'center'
        }
    }))))
);


