import React, { Component } from "react";

import Animated from 'react-native-reanimated';
import { LinearGradient } from "expo-linear-gradient";
import { withStyles } from "../../theme";
import memoize from "../../utils/memoize";

const { max, min, multiply } = Animated;

class TabBarIndicator extends Component {
    getTranslateX = memoize(
        (position, routes, width) =>
            multiply( max(min(position, routes.length - 1), 0), width )
    );

    render() {
        const { width, position, navigationState, theme, styles } = this.props;
        const { routes } = navigationState;

        const translateX = this.getTranslateX(position, routes, width);

        return (
            <Animated.View
                style={[ styles.wrapper, { width: `${100 / routes.length}%` },
                    // If layout is not available, use `left` property for positioning the indicator
                    // This avoids rendering delay until we are able to calculate translateX
                    width
                        ? { transform: [{ translateX }] }
                        : { left: `${(100 / routes.length) * navigationState.index}%` }
                ]}
            >
                <LinearGradient
                    {...theme.COLORS.GRADIENTS.PRIMARY}
                    style={styles.gradient}
                />
            </Animated.View>
        );
    }
}

export default withStyles(TabBarIndicator, theme => ({
    wrapper: {
        position: 'absolute',
        left: 0,
        bottom: 5,
        right: 0,
        height: 4
    },
    gradient: {
        marginTop: 5,
        borderRadius: 2,
        width: '100%',
        height: 4
    }
}));
