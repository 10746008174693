import React, { Component, Children } from "react";
import PropTypes from "prop-types";

export default class RootSiblingsProvider extends Component {
    _updatedSiblings = {};
    _siblings = {};
    _stores = {};

    constructor(props) {
        super(props);
        this._siblings = {};
        triggers.push(this._update);
    }

    componentWillUnmount() {
        triggers.splice(triggers.indexOf(this._update), 1);
    }

    _update = (id, element, callback, store) => {
        const siblings = { ...this._siblings };
        const stores = { ...this._stores };
        if (siblings[id] && !element) {
            delete siblings[id];
            delete stores[id];
        } else if (element) {
            siblings[id] = element;
            stores[id] = store;
        }
        this._updatedSiblings[id] = true;
        this._siblings = siblings;
        this._stores = stores;
        this.forceUpdate(callback);
    };

    render() {
        const siblings = this._siblings;
        const stores = this._stores;
        const elements = [];
        Object.keys(siblings).forEach(key => {
            const element = siblings[key];
            if (element) {
                const sibling = (
                    <StaticContainer
                        key={`root-sibling-${key}`}
                        shouldUpdate={!!this._updatedSiblings[key]}
                    >
                        {element}
                    </StaticContainer>
                );

                const store = stores[key];
                if (store) {
                    elements.push(
                        <Provider store={store} key={`root-sibling-${key}-provider`}>
                            {sibling}
                        </Provider>
                    );
                } else {
                    elements.push(sibling);
                }
            }
        });
        this._updatedSiblings = {};
        return elements;
    }
}

export class RootSiblingManager {
    constructor(element, callback, store) {
        const id = uuid++;
        function update(element, callback, store) {
            triggers.forEach(function(trigger) {
                trigger(id, element, callback, store);
            });
        }

        function destroy(callback) {
            triggers.forEach(function(trigger) {
                trigger(id, null, callback);
            });
        }

        update(element, callback, store);
        this.update = update;
        this.destroy = destroy;
    }
}

class StaticContainer extends Component {
    static propTypes = {
        shouldUpdate: PropTypes.bool.isRequired
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldUpdate;
    }

    render() {
        const child = this.props.children;
        return (child === null || child === false) ? null : Children.only(child);
    }
}

class Provider extends Component {
    static childContextTypes = {
        store: PropTypes.shape({
            subscribe: PropTypes.func.isRequired,
            dispatch: PropTypes.func.isRequired,
            getState: PropTypes.func.isRequired
        })
    };

    getChildContext() {
        return { store: this.props.store };
    }

    render() {
        return this.props.children;
    }
}

let uuid = 0;
const triggers = [];
