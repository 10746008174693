import validator from 'validator';

class Validator {

    constructor(fields = [], body = {}) {
        this._fields = fields;
        this._body = body;
        this._errors = [];
    }

    /**
     * @param errors
     */
    setErrors(errors) {
        this._errors = errors;
    }

    setBody(body) {
        this._body = body;
    }

    /**
     * @param field
     * @returns {*}
     */
    getFieldValue(field) {
        return this._body[field[0]];
    }

    static get rules() {
        return {
            ALPHA_NUMERIC: 'AlphaNum',
            WEBSITE: 'Website',
            LENGTH: 'Length',
            EMAIL: 'Email',
            INTEGER: 'Integer',
            NUMBER: 'Number',
            NOT_EMPTY: 'NotEmpty',
            ENUM: 'Enum',
            IN: 'In',
            REGEX: 'RegEx',
            UNIQUE_NICKNAME: 'UniqueNickname'
        };
    }

    static get regEx() {
        return {
            ALPHA_NUM: /^[a-z0-9]+$/i,
            NICKNAME: /^[a-z0-9_.\-а-яїґєі]+$/i,
            CHANNEL_NAME: /^[a-z0-9_.\-а-яїґєі\s?!]+$/i,
        };
    }

    getErrorMessage(field) {
        const rule = field[1];

        switch (rule) {
            case Validator.rules.UNIQUE_NICKNAME:
                return [ { id: 'validator.error_unique_nickname' } ];
            case Validator.rules.REGEX:
                if(Validator.regEx.NICKNAME.toString() === field[2].toString()) {
                    return [ { id: 'validator.error_regex_nickname' } ];
                } else if(Validator.regEx.CHANNEL_NAME.toString() === field[2].toString()) {
                    return [ { id: 'validator.error_regex_channel_name' } ];
                } else {
                    return [ { id: 'validator.error_invalid_value' } ];
                }
            case Validator.rules.ALPHA_NUMERIC:
                return [ { id: 'validator.error_alpha_numeric' } ];
            case Validator.rules.EMAIL:
                return [ { id: 'validator.error_email' } ];
            case Validator.rules.LENGTH:
                if(this.getFieldValue(field).length < field[2].min) {
                    return [ { id: 'validator.error_min_length' }, { min: field[2].min } ];
                } else {
                    return [ { id: 'validator.error_max_length' }, { max: field[2].max } ];
                }
            case Validator.rules.WEBSITE:
                return [ { id: 'validator.error_website' } ];
            case Validator.rules.INTEGER:
            case Validator.rules.NUMBER:
                return [ { id: 'validator.error_only_digits' } ];
            case Validator.rules.NOT_EMPTY:
                return [ { id: 'validator.error_not_empty' } ];
            default:
                return [ { id: 'validator.error_invalid_value' } ];
        }
    }

    add(field) {
        this._fields.push(field);
    }

    /**
     * @returns {Promise<boolean>}
     */
    async validate() {
        for(let i in this._fields) {
            let field = this._fields[i], result;
            if(field[1] !== Validator.rules.NOT_EMPTY && !this._body[field[0]]) {
                result = true;
            } else {
                result = await this[`_rule${field[1]}`].call(this, field);
            }
            if(result !== true) {
                this._errors.push(field);
            }
        }
        return this._errors.length === 0;
    }

    /**
     * @returns {Array}
     */
    get errors() {
        return this._errors;
    }

    /**
     * @returns {*}
     */
    get errorsKeys() {
        let result = {};
        for (let i in this._errors) {
            result[this._errors[i][0]] = this._errors[i][1];
        }
        return result;
    }

    /**
     * @returns {*}
     */
    get errorsMessages() {
        let result = {};
        for (let i in this._errors) {
            result[this._errors[i][0]] = this.getErrorMessage(this._errors[i]);
        }
        return result;
    }

    /**
     * @returns {*}
     */
    getIntlErrorsMessages(intl) {
        let result = {};
        for (let i in this._errors) {
            result[this._errors[i][0]] = intl.formatMessage.apply(intl, this.getErrorMessage(this._errors[i]));
        }
        return result;
    }

    /**
     * ********** ***** ************
     * ********** RULES ************
     * *****************************
     */

    /**
     * @param field
     * @returns {Promise<boolean>}
     * @private
     */
    async _ruleAlphaNum(field) {
        return validator.isAlphanumeric(this._body[field[0]]);
    }

    async _ruleLength(field) {
        return validator.isLength(String(this._body[field[0]]), field[2]);
    }

    async _ruleEmail(field) {
        return validator.isEmail(this._body[field[0]]);
    }

    async _ruleInteger(field) {
        return validator.isInt(String(this._body[field[0]]), field[2]);
    }

    async _ruleRegEx(field) {
        return field[2].test(this._body[field[0]]);
    }

    async _ruleWebsite(field) {
        return validator.isURL(String(this._body[field[0]]), field[2])
    }

    async _ruleIn(field) {
        return validator.isIn(String(this._body[field[0]]), field[2])
    }

    async _ruleUniqueNickname(field) {
        return false;
    }

    async _ruleNotEmpty(field) {
        return !!this._body[field[0]];
    }
}

export default Validator;
