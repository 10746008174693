import { Platform } from 'react-native';
import * as Crypto from "expo-crypto";

export const getRandomString = (length = 32) => {
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let text = '';
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
};

export const getStringsHash = async (strings) => {
    const string = strings.filter(str => !!str).join('|');
    if(Platform.OS === 'web') {
        return string;
    }
    return Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA1,
        string
    );
};
