import React from 'react';

import { injectIntl } from "react-intl";
import { withStyles } from "../../theme";
import { View, Screen, Text, Pressable, Icon, SList, Loader } from '../../components/base';
import { Button } from "../../components";
import { connect } from "react-redux";
import ContactItem from './ContactItem';
import SectionHeader from './SectionHeader';
import screenNames from "../../navigation/screenNames";
import { showConfirm } from "../../utils/simpleAlert";
import { connectActionSheet } from "@expo/react-native-action-sheet";
import styles from "./styles";
import {
    getCounters,
    retrieveContacts,
    getContact,
    loadMoreContacts,
    removeContact
} from "../../state/contact/actions";

class ContactsScreen extends Screen {

    screenName = screenNames.CONTACTS;

    state = {
        isLoading: false,
        isLoadingMore: false,
        isRefreshing: false,
        isLoadingRemoveContact: false
    };

    content() {

        const { isLoading, isRefreshing, isLoadingMore, isLoadingRemoveContact } = this.state;
        const { theme, styles, navigation, contacts_count, contacts, requests_count, intl } = this.props;

        return (
            <View style={styles.screenWrapper}>
                <View style={styles.headerWrapper}>
                    <Text style={[
                        styles.headingText,
                        styles.headingTextOffset
                    ]}>
                        {intl.formatMessage({ id: 'contacts.heading' })}
                    </Text>
                    <Text style={styles.subHeadingText}>
                        {intl.formatMessage({ id: 'contacts.contacts_count' }, { count: contacts_count })}
                    </Text>
                    <Pressable onPress={() => navigation.goBack()} style={styles.headerBackButton}>
                        <Icon name={'arrow-left'} color={theme.COLORS.TEXT} size={23} />
                    </Pressable>
                </View>
                <SectionHeader newRequests={isLoading ? 0 : requests_count} />
                {isLoading ? (
                    <View style={styles.sceneLoaderWrapper}>
                        <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                        <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                    </View>
                ) : (
                    <SList
                        contentContainerStyle={styles.contentWrapper}
                        data={contacts}
                        refreshing={isRefreshing}
                        onRefresh={this.onRefresh}
                        renderItem={ ({ item }, rowMap) => (
                            <ContactItem
                                {...item}
                                onPress={() => this.onPress(item)}
                                onPressAvatar={() => this.onPressAvatar(item)}
                                onLongPress={() => this.onLongPress(item)}
                                style={styles.contactItem}
                            />
                        )}
                        renderHiddenItem={ ({ item }, rowMap) => (
                            <View style={styles.contactItemMenuWrapper}>
                                <View style={styles.contactItemMenu}>
                                    <Button loading={isLoadingRemoveContact} onPress={() => this.onPressRemoveContact(item)} square size='small' variation={'danger'}>
                                        <Icon name='trash' size={14} />
                                    </Button>
                                </View>
                            </View>
                        )}
                        ListFooterComponent={(
                            <View style={styles.loaderWrapper}>
                                {isLoadingMore && (
                                    <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                )}
                            </View>
                        )}
                        ListEmptyComponent={(
                            <View style={styles.noDataPlaceholderWrapper}>
                                <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'contacts.no_contacts' })}</Text>
                            </View>
                        )}
                        onEndReached={this.loadMore}
                        keyExtractor={item => item.id}
                        disableRightSwipe={true}
                        rightOpenValue={-styles.contactItemMenu.width}
                        stopRightSwipe={-styles.contactItemMenu.width}
                    />
                )}
            </View>
        );
    }

    onNavigate = async () => {
        if(this.props.contacts.length > 0) return;
        await this.loadData();
    };

    loadData = async () => {
        await this.retrieveData([
            [ 'getCounters' ],
            [ 'retrieveContacts' ],
        ], 'isLoading');
    };

    onRefresh = async () => {
        await this.retrieveData([
            [ 'getCounters' ],
            [ 'retrieveContacts' ],
        ], 'isRefreshing');
    };

    loadMore = async () => {
        if(this.state.isLoading || this.state.isLoadingMore) return;
        await this.retrieveData([
            'loadMoreContacts'
        ], 'isLoadingMore');
    };

    onPressRemoveContact = async (contact) => {
        const { intl } = this.props;
        showConfirm(
            intl.formatMessage({ id: 'common.confirm_action' }),
            intl.formatMessage({ id: 'contacts.remove_nickname_from_contacts' }, {
                nickname: `@${contact.nickname}`
            }),
            intl.formatMessage({ id: 'common.yes_delete' }),
            intl.formatMessage({ id: 'common.cancel' }),
            async () => {
                await this.onRemoveContact(contact.user_id);
            }
        );
    };

    onRemoveContact = async (userID) => {
        await this.setState({ isLoadingRemoveContact: true });
        await this.props.removeContact(userID);
        await this.setState({ isLoadingRemoveContact: false });
    };

    onPress = async (contact) => {
        return this.navigateToConversation(contact.id);
    };

    onPressAvatar = async (contact) => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.PROFILE, {
            userID: contact.user_id
        });
    };

    onLongPress = async (contact) => {
        const { intl } = this.props;
        this.actionSheet([ {
            label: intl.formatMessage({ id: 'contacts.delete_contact' }),
            destructive: true,
            onPress: async () => {
                showConfirm(
                    intl.formatMessage({ id: 'common.confirm_action' }),
                    intl.formatMessage({ id: 'contacts.remove_nickname_from_contacts' }, {
                        nickname: `@${contact.nickname}`
                    }),
                    intl.formatMessage({ id: 'common.yes_delete' }),
                    intl.formatMessage({ id: 'common.cancel' }),
                    async () => {
                        await this.setState({ showLoader: true });
                        await this.onRemoveContact(contact.user_id);
                        await this.setState({ showLoader: false });
                    }
                );
            }
        }, {
            label: intl.formatMessage({ id: 'common.cancel' })
        } ]);
    };
}


const mapStateToProps = ({ contact }) => ({
    requests: contact.requests,
    contacts: contact.contacts,
    requests_count: contact.requests_count,
    contacts_count: contact.contacts_count
});

const mapDispatchToProps = dispatch => ({
    getCounters: () => dispatch(getCounters()),
    retrieveContacts: () => dispatch(retrieveContacts()),
    removeContact: (userID) => dispatch(removeContact(userID)),
    getContact: (userID) => dispatch(getContact(userID)),
    loadMoreContacts: () => dispatch(loadMoreContacts())
});

export default connect(mapStateToProps, mapDispatchToProps)(
    connectActionSheet(injectIntl(withStyles(ContactsScreen, styles)))
);

