import React from 'react';

import { Text, Loader, Pressable } from '../base';
import { withStyles } from "../../theme";
import { LinearGradient } from "expo-linear-gradient";

export default withStyles(({ theme, styles, style, children, loading, variation = 'primary', size = 'normal', square, ...restProps }) => (
    <Pressable style={[
        styles[`VARIATION__${variation}`],
        styles[`SIZE__${size}`],
        ( loading ? styles.loading : null ),
        ( square ? styles[`SIZE__${size}_square`] : null ),
        style
    ]} disabled={loading} {...restProps} >
        {variation !== 'transparent' && (
            <LinearGradient
                {...theme.COLORS.GRADIENTS[variation.toUpperCase()]}
                style={styles[`GRADIENT_SIZE__${size}`]}
            />
        )}
        <Text numberOfLines={1} style={[
            styles[`LABEL_SIZE__${size}`],
            ( square ? styles['LABEL_square'] : null ),
            ( variation === 'transparent' ? styles['LABEL_transparent'] : null ),
            ( loading ? { opacity: 0 } : null )
        ]}>
            {children}
        </Text>
        {loading && (
            <Loader style={styles.loader} color={ variation !== 'transparent' ? theme.COLORS.WHITE : theme.COLORS.TEXT } size={'small'} />
        )}
    </Pressable>
), theme => ({
    SIZE__normal: {
        borderRadius: 25,
        width: '100%',
        height: 50,
        alignItems: 'center',
        justifyContent: 'center'
    },
    SIZE__medium: {
        borderRadius: 20,
        width: '100%',
        height: 40,
        alignItems: 'center',
        justifyContent: 'center'
    },
    SIZE__small: {
        borderRadius: 15,
        width: null,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center'
    },
    SIZE__normal_square: {
        width: 50,
        height: 50
    },
    SIZE__medium_square: {
        width: 40,
        height: 40
    },
    SIZE__small_square: {
        width: 30,
        height: 30,
    },
    LABEL_SIZE__normal: {
        zIndex: 1,
        color: theme.COLORS.WHITE,
        fontWeight: 'bold',
        fontSize: 16
    },
    LABEL_SIZE__medium: {
        zIndex: 1,
        color: theme.COLORS.WHITE,
        fontWeight: 'bold',
        fontSize: 14
    },
    LABEL_SIZE__small: {
        zIndex: 1,
        paddingHorizontal: 9,
        color: theme.COLORS.WHITE,
        fontWeight: '500',
        fontSize: 14
    },
    LABEL_square: {
        paddingHorizontal: 0,
        textAlign: 'right'
    },
    LABEL_transparent: {
        color: theme.COLORS.TEXT,
    },
    GRADIENT_SIZE__normal: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: 25
    },
    GRADIENT_SIZE__medium: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: 20
    },
    GRADIENT_SIZE__small: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: 15
    },
    loader: {
        position: 'absolute'
    },
    loading: {
        opacity: .8
    }
}));
