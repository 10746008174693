import { flattenMessages } from "./flattenMessages";
import * as Localization from 'expo-localization';
import * as messages from "../i18n";
import "../i18n/locale-data";
import { createIntlCache } from "react-intl";
import { formatRelative } from "./date";
import { createIntl as cIntl } from 'react-intl';

const cache = createIntlCache();

export const getCurrentLocale = () => {
    let locale = 'en-US';
    if(Localization.locale.startsWith('en')) {
        locale = 'en-US';
    } else if(Localization.locale.startsWith('uk')) {
        locale = 'uk-UA';
    } else if(Localization.locale.startsWith('ru')) {
        locale = 'ru-RU';
    }
    return locale;
};

export const getMinCurrentLocale = () => {
    let locale = 'en';
    if(Localization.locale.startsWith('en')) {
        locale = 'en';
    } else if(Localization.locale.startsWith('uk')) {
        locale = 'uk';
    } else if(Localization.locale.startsWith('ru')) {
        locale = 'ru';
    }
    return locale;
};

/**
 * @returns {*}
 */
export const getLocaleMessages = (locale) => {
    return flattenMessages(messages[locale]);
};

export const localeNames = {
    'uk': 'Українська',
    'en': 'English',
    'ru': 'Русский',
};


/**
 * @returns {*}
 */
export const getListOfLocales = () => {
    return [ {
        key: 'uk',
        icon: '🇺🇦',
        name: localeNames['uk']
    }, {
        key: 'en',
        icon: '🇺🇸',
        name: localeNames['en']
    }, {
        key: 'ru',
        icon: '🇷🇺',
        name: localeNames['ru']
    } ];
};

/**
 * @param locale
 * @param messages
 */
export const createIntl = (locale, messages) => {
    const intl = cIntl({
        locale, messages
    }, cache);
    intl.formatRelative = (timestamp, tiny) => {
        if(tiny) {
            const res = formatRelative(timestamp, ['second', 'minute', 'hour', 'day', 'week']);
            return Math.abs(res[0]) + intl.formatMessage({ id: `time_format.${res[1]}` })
        }
        return intl.formatRelativeTime.apply(intl, [ ...formatRelative(timestamp), {
            style: 'short',
            numeric: 'always'
        } ]);
    };
    return intl;
};
