import React from 'react';

import { createStackNavigator } from 'react-navigation-stack';

import screens from '../screens';
import screenNames from './screenNames';

export default createStackNavigator({
    [screenNames.CONVERSATION]: screens.ConversationScreen,
    [screenNames.PROFILE]: screens.ProfileScreen,
}, {
    initialRouteName: screenNames.CONVERSATION,
    headerMode: 'none',
    defaultNavigationOptions: {
        header: null,
        cardShadowEnabled: false,
        cardOverlayEnabled: false,
        gesturesEnabled: true,
        cardTransparent: true
    },
});
