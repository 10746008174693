import React, { Component } from 'react';

import { View, Icon, Text, Pressable, Modal } from '../base';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";
import Button from "../Button";
import ScrollPicker from "./ScrollPicker";

class Select extends Component {

    state = {
        visible: false,
        selectedKey: null
    };

    /**
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const { value } = this.props;
        if(prevProps.value !== value) {
            this.setState({
                selectedKey: value
            });
        }
    }

    /**
     * @param value
     */
    onSelectionChange = (value) => {
        this.setState({
            selectedKey: value
        });
    };

    onPress = () => {
        this.setState({
            visible: true
        });
    };

    onCancel = () => {
        const { value } = this.props;
        this.setState({
            visible: false,
            selectedKey: value
        });
    };

    onConfirm = () => {
        const { selectedKey } = this.state;
        const { onChange, options } = this.props;
        if(onChange) {
            const keys = options.map(element => element.key);
            onChange(keys.indexOf(selectedKey) > -1 ? selectedKey : keys[0]);
        }
        this.setState({
            visible: false
        });
    };

    render() {

        const {
            theme,
            styles,
            style,
            error,
            variation = 'default',
            onPressIcon,
            options,
            value,
            placeholder,
            intl,
            showHeadingValue,
            ...restProps
        } = this.props;

        const {
            visible,
            selectedKey
        } = this.state;

        const hasHeading = options && options[0] && options[0].heading,
            keys = options.map(element => element.key),
            selectedIndex = keys.indexOf(value) > -1
                ? keys.indexOf(value)
                : 0;

        const labelText = hasHeading && showHeadingValue
            ? `${options[selectedIndex].heading} ${options[selectedIndex].text}`
            : (options[selectedIndex] && options[selectedIndex].heading)
                ? options[selectedIndex].heading
                : (options[selectedIndex] && !options[selectedIndex].heading) ? options[selectedIndex].text : '';

        return (
            <View style={[styles.wrapper, style]}>
                <Pressable
                    placeholderTextColor={theme.COLORS.PLACEHOLDER_TEXT}
                    style={[
                        styles[`VARIATION__${variation}`],
                        styles[`VARIATION__${variation}_withIcon`],
                        (error ? styles.hasError : null)
                    ]}
                    {...restProps}
                    onPress={this.onPress}
                >
                    <Text style={[
                        styles[`VARIATION__${variation}_valueText`],
                        ( value === null ? { color: variation === 'default'
                                ? theme.COLORS.SECONDARY_TEXT
                                : theme.COLORS.PLACEHOLDER_TEXT } : null )
                    ]}>
                        {value === null ? placeholder : labelText}
                    </Text>
                    <View style={styles[`VARIATION__${variation}_iconWrapper`]} >
                        <Icon name={'chevron-down'}  style={styles[`VARIATION__${variation}_icon`]} />
                    </View>
                </Pressable>
                {!!error && (
                    <View style={styles[`VARIATION__${variation}_errorWrapper`]}>
                        <Text style={styles[`VARIATION__${variation}_errorText`]}>{error}</Text>
                    </View>
                )}
                <Modal
                    visible={visible}
                    swipeDirection={null}
                    onRequestClose={this.onCancel}>
                    <View style={styles.bottomContainer}>
                        <View style={styles.buttonsWrap}>
                            <View style={styles.buttonsWrapColumn}>
                                <Button size={'medium'} variation={'secondary'} onPress={this.onCancel}> {intl.formatMessage({ id: 'common.cancel' })} </Button>
                            </View>
                            <View style={styles.buttonsWrapColumn} />
                            <View style={styles.buttonsWrapColumn}>
                                <Button size={'medium'} onPress={this.onConfirm}> {intl.formatMessage({ id: 'common.confirm' })} </Button>
                            </View>
                        </View>
                        <View style={styles.inputWrapPicker}>
                            <ScrollPicker
                                style={styles.picker}
                                options={options}
                                onSelectionChange={this.onSelectionChange}
                                selectedKey={selectedKey !== null ? selectedKey : value}
                            />
                        </View>
                    </View>
                </Modal>
            </View>
        );

    };

}

export default injectIntl(withStyles(Select, theme => ({
    wrapper: {
        width: '100%',
        justifyContent: 'center'
    },
    VARIATION__basic_valueText: {
        fontWeight: 'bold',
        fontSize: 15,
        color: theme.COLORS.TEXT,
    },
    VARIATION__default_valueText: {
        color: theme.COLORS.TEXT,
        fontSize: 15
    },
    VARIATION__basic: {
        borderWidth: 3.5,
        width: '100%',
        borderRadius: 25,
        height: 50,
        paddingHorizontal: 25,
        borderColor: theme.COLORS.TEXT,
        justifyContent: 'center'
    },
    VARIATION__default: {
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 17.5,
        height: 45,
        paddingHorizontal: 20,
        justifyContent: 'center'
    },
    VARIATION__basic_withIcon: {
        paddingRight: 40
    },
    VARIATION__default_withIcon: {
        paddingRight: 20
    },
    hasError: {
        borderColor: theme.COLORS.DANGER
    },
    VARIATION__basic_iconWrapper: {
        top: 0,
        height: 50,
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
    },
    VARIATION__default_iconWrapper: {
        top: 0,
        height: 45,
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
    },
    VARIATION__basic_icon: {
        fontSize: 20,
        color: theme.COLORS.TEXT,
        paddingRight: 20
    },
    VARIATION__default_icon: {
        fontSize: 20,
        color: theme.COLORS.SECONDARY_TEXT,
        paddingRight: 15
    },
    VARIATION__basic_errorWrapper: {
        paddingTop: 2
    },
    VARIATION__default_errorWrapper: {
        paddingTop: 2
    },
    VARIATION__basic_errorText: {
        color: theme.COLORS.DANGER,
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    VARIATION__default_errorText: {
        color: theme.COLORS.DANGER,
        fontSize: 15,
        textAlign: 'center'
    },

    modalWrapper: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute'
    },
    fadeWrapper: {
        flex: 1,
        backgroundColor: 'rgba(0,0,0,0.56)',
    },
    buttonsWrap: {
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
        backgroundColor: theme.COLORS.BACKGROUND,
        width: '100%',
        height: 70,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    buttonsWrapColumn: {
        flex: 0.333,
        paddingHorizontal: 6
    },
    inputWrapPicker: {
        // borderTopWidth: 1,
        // borderColor: theme.COLORS.BACKGROUND_ITEM
    },
    bottomContainer: {
        position: 'absolute',
        width: '100%',
        bottom: 0
    },
    picker: {

    }
})));
