import React from 'react';

import { Platform } from 'react-native';
import { Screen, Text, View, Loader, Pressable, List } from "../../components/base";
import { Button, TabView } from "../../components";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withStyles } from "../../theme";
import styles from "./styles";
import TopicItem from "./TopicItem";
import screenNames from "../../navigation/screenNames";
import { retrieveListChannels, loadMoreListChannels, retrieveChannel, voteChannel } from "../../state/channel/actions";
import { updateNewChannelsLastVisit } from "../../state/common/actions";
import Events from '../../utils/events';
import OptionsModal from "../TopicScreen/OptionsModal";
import { connectActionSheet } from "@expo/react-native-action-sheet";
import MobileDetect from '../../utils/mobileDetect';
import {openURL} from "../../utils/link";

class HomeScreen extends Screen {

    screenName = screenNames.HOME;

    state = {
        isLoadingTop: true,
        isLoadingMoreTop: null,
        isRefreshingTop: false,
        isLoadingNew: true,
        isLoadingMoreNew: null,
        isRefreshingNew: false,
        isLoadingMy: true,
        isLoadingMoreMy: null,
        isRefreshingMy: false,
        loadingVoteTopicID: null,
        isOptionsModalOpened: false,
        optionsTopic: null
    };

    content() {

        const { isLoadingMy, isLoadingMoreMy, isLoadingMoreNew, isLoadingMoreTop, isRefreshingMy, isLoadingNew, isRefreshingNew, isRefreshingTop, isLoadingTop, loadingVoteTopicID } = this.state;
        const { styles, theme, lastVisits, navigation, country, city, university, intl, me } = this.props;

        return (
            <View style={styles.screenWrapper}>
                <Pressable style={styles.headerWrapper} onPress={() => navigation.navigate(screenNames.EDIT_LOCATION)}>
                    {university ? (
                        <Text style={styles.headingText}>{university.short_name}</Text>
                    ) : (
                        <Text style={styles.headingText}>{city.short_name}</Text>
                    )}
                    {university ? (
                        <Text style={styles.subHeadingText}>{city.short_name}, {country.short_name}</Text>
                    ) : (
                        <Text style={styles.subHeadingText}>{country.long_name}</Text>
                    )}
                </Pressable>
                <TabView
                    onChange={(key) => {
                        if(key === LIST_TYPE_TOP && !this.props.channelsList[LIST_TYPE_TOP].length) {
                            return this.loadDataTop()
                        }
                        if(key === LIST_TYPE_MY && !this.props.channelsList[LIST_TYPE_MY].length) {
                            return this.loadDataMy()
                        }
                    }}
                    routes={{
                        [LIST_TYPE_NEW]: isLoadingNew ? (
                            <View style={styles.sceneLoaderWrapper}>
                                <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                                <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                            </View>
                        ) : (
                            <List
                                contentContainerStyle={{ paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING }}
                                refreshing={isRefreshingNew}
                                onRefresh={this.onRefreshNew}
                                data={this.props.channelsList[LIST_TYPE_NEW]}
                                renderItem={({ item }) => (
                                    <TopicItem
                                        {...item}
                                        style={styles.topicItem}
                                        isSafe={(me && me.restricted_mode) || item.myReport}
                                        loadingVoteTopicID={loadingVoteTopicID}
                                        lastVisit={lastVisits[item.id]}
                                        onPress={() => this.onPressTopic(item) }
                                        onLongPress={() => this.onLongPressTopic(item)}
                                        onPressVoteUp={this.onPressVoteUp}
                                        onPressVoteDown={this.onPressVoteDown}
                                    />
                                )}
                                ListHeaderComponent={(
                                    <Button style={styles.topicItem} variation={'primary'} size={'medium'} onPress={this.downloadApp}>
                                        {intl.formatMessage({ 'id': 'home.download_app' })} 📲
                                    </Button>
                                )}
                                ListFooterComponent={(
                                    <View style={styles.loaderWrapper}>
                                        {isLoadingMoreNew && (
                                            <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                        )}
                                    </View>
                                )}
                                ListEmptyComponent={(
                                    <View style={styles.noDataPlaceholderWrapper}>
                                        <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'home.no_topics' })}</Text>
                                    </View>
                                )}
                                onEndReached={this.loadMoreNew}
                                keyExtractor={item => item.id.toString()}
                            />
                        ),
                        [LIST_TYPE_TOP]: isLoadingTop ? (
                            <View style={styles.sceneLoaderWrapper}>
                                <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                                <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                            </View>
                        ) : (
                            <List
                                contentContainerStyle={{ paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING }}
                                refreshing={isRefreshingTop}
                                onRefresh={this.onRefreshTop}
                                data={this.props.channelsList[LIST_TYPE_TOP]}
                                renderItem={({ item }) => (
                                    <TopicItem
                                        {...item}
                                        style={styles.topicItem}
                                        lastVisit={lastVisits[item.id]}
                                        isSafe={(me && me.restricted_mode) || item.myReport}
                                        loadingVoteTopicID={loadingVoteTopicID}
                                        onPress={() => this.onPressTopic(item) }
                                        onLongPress={() => this.onLongPressTopic(item)}
                                        onPressVoteUp={this.onPressVoteUp}
                                        onPressVoteDown={this.onPressVoteDown}
                                    />
                                )}
                                ListFooterComponent={(
                                    <View style={styles.loaderWrapper}>
                                        {isLoadingMoreTop && (
                                            <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                        )}
                                    </View>
                                )}
                                ListEmptyComponent={(
                                    <View style={styles.noDataPlaceholderWrapper}>
                                        <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'home.no_topics' })}</Text>
                                    </View>
                                )}
                                onEndReached={this.loadMoreTop}
                                keyExtractor={item => item.id.toString()}
                            />
                        ),
                        [LIST_TYPE_MY]: isLoadingMy ? (
                            <View style={styles.sceneLoaderWrapper}>
                                <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                                <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                            </View>
                        ) : (
                            <List
                                contentContainerStyle={{ paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING }}
                                refreshing={isRefreshingMy}
                                onRefresh={this.onRefreshMy}
                                data={this.props.channelsList[LIST_TYPE_MY]}
                                renderItem={({ item }) => (
                                    <TopicItem
                                        {...item}
                                        style={styles.topicItem}
                                        lastVisit={lastVisits[item.id]}
                                        isSafe={(me && me.restricted_mode) || item.myReport}
                                        loadingVoteTopicID={loadingVoteTopicID}
                                        onPress={() => this.onPressTopic(item)}
                                        onLongPress={() => this.onLongPressTopic(item)}
                                        onPressVoteUp={this.onPressVoteUp}
                                        onPressVoteDown={this.onPressVoteDown}
                                    />
                                )}
                                ListFooterComponent={(
                                    <View style={styles.loaderWrapper}>
                                        {isLoadingMoreMy && (
                                            <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                        )}
                                    </View>
                                )}
                                ListEmptyComponent={(
                                    <View style={styles.noDataPlaceholderWrapper}>
                                        <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'home.no_topics' })}</Text>
                                    </View>
                                )}
                                onEndReached={this.loadMoreMy}
                                keyExtractor={item => item.id.toString()}
                            />
                        )
                    }}
                    tabs={[ {
                        key: LIST_TYPE_NEW,
                        title: intl.formatMessage({ id: 'home.new' })
                    }, {
                        key: LIST_TYPE_TOP,
                        title: intl.formatMessage({ id: 'home.top' })
                    }, {
                        key: LIST_TYPE_MY,
                        title: intl.formatMessage({ id: 'home.my' })
                    } ]}
                />
                <OptionsModal
                    visible={this.state.isOptionsModalOpened}
                    channel={this.state.optionsTopic}
                    showReport={this.showReport}
                    onClose={() => this.setState({ isOptionsModalOpened: false })}
                />
            </View>
        );
    }

    onNavigate = async () => {
        if(this.props.channelsList[LIST_TYPE_NEW].length > 0) {
            this.setState({ isLoadingNew: false });
            return;
        }
        await this.loadDataNew();
    };

    componentDidMount() {
        super.componentDidMount();
        this.locationListener = Events.subscribe(Events.Types.CHANGE_LOCATION, async () => {
            await this.loadDataNew();
            await this.loadDataTop();
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.locationListener.remove();
    }

    onPressVoteUp = async (topicID) => {
        await this.setState({ loadingVoteTopicID: topicID });
        await this.props.voteChannel(topicID, 'up');
        await this.setState({ loadingVoteTopicID: null });
    };

    onPressVoteDown = async (topicID) => {
        await this.setState({ loadingVoteTopicID: topicID });
        await this.props.voteChannel(topicID, 'down');
        await this.setState({ loadingVoteTopicID: null });
    };

    loadDataTop = async () => {
        await this.retrieveData([
            'retrieveListChannels', LIST_TYPE_TOP, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()
        ], 'isLoadingTop');
    };

    onRefreshTop = async () => {
        await this.retrieveData([
            'retrieveListChannels', LIST_TYPE_TOP, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()
        ], 'isRefreshingTop');
    };

    loadMoreTop = async () => {
        if(this.state.isLoadingTop || this.state.isLoadingMoreTop) return;
        await this.retrieveData([
            'loadMoreListChannels', LIST_TYPE_TOP, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()
        ], 'isLoadingMoreTop');
    };

    loadDataNew = async () => {
        await this.retrieveData([
            ['retrieveListChannels', LIST_TYPE_NEW, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()],
            ['updateNewChannelsLastVisit', this.props.city.id + '_' + ((this.props.university ? this.props.university.id : 'null')), this.props.channels_count]
        ], 'isLoadingNew');
    };

    onRefreshNew = async () => {
        await this.retrieveData([
            ['retrieveListChannels', LIST_TYPE_NEW, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()],
            ['updateNewChannelsLastVisit', this.props.city.id + '_' + ((this.props.university ? this.props.university.id : 'null')), this.props.channels_count]
        ], 'isRefreshingNew');
    };

    loadMoreNew = async () => {
        if(this.state.isLoadingNew || this.state.isLoadingMoreNew) return;
        await this.retrieveData([
            'loadMoreListChannels', LIST_TYPE_NEW, this.props.city.id, (this.props.university ? this.props.university.id : 'null'),  this.safe()
        ], 'isLoadingMoreNew');
    };

    loadDataMy = async () => {
        await this.retrieveData([
            'retrieveListChannels', LIST_TYPE_MY, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()
        ], 'isLoadingMy');
    };

    onRefreshMy = async () => {
        await this.retrieveData([
            'retrieveListChannels', LIST_TYPE_MY, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()
        ], 'isRefreshingMy');
    };

    loadMoreMy = async () => {
        if(this.state.isLoadingMy || this.state.isLoadingMoreMy) return;
        await this.retrieveData([
            'loadMoreListChannels', LIST_TYPE_MY, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()
        ], 'isLoadingMoreMy');
    };

    safe = () => {
        return (this.props.me && this.props.me.restricted_mode ? 1 : 0);
    };

    onPressTopic = async (topic) => {
        return this.navigateToTopic(topic.id)
    };

    onLongPressTopic = async (topic) => this.setState({
        optionsTopic: topic,
        isOptionsModalOpened: true
    });

    downloadApp = () => {
        if(MobileDetect.is('iOS')) {
            return openURL('https://itunes.apple.com/us/app/wace/id1471902543?ls=1&mt=8');
        } else if(MobileDetect.is('AndroidOS')) {
            return openURL('market://details?id=com.allhaze.wacex');
        }
        return openURL('https://wace.co')
    };
}

const LIST_TYPE_TOP = 'top',
    LIST_TYPE_NEW = 'new',
    LIST_TYPE_MY = 'my';

const mapStateToProps = ({ common, user, channel }) => ({
    me: user.me,
    channelsList: channel.list,
    lastVisits: channel.lastVisits,
    newChannelsLastVisits: common.newChannelsLastVisits,
    country: common.country,
    city: common.city,
    channels_count: common.channels_count,
    university: common.university,
    ignoreList: user.ignoreList
});

const mapDispatchToProps = dispatch => ({
    retrieveChannel: (topicID) => dispatch(retrieveChannel(topicID)),
    retrieveListChannels: (type, cityID, universityID, safe) => dispatch(retrieveListChannels(type, cityID, universityID, safe)),
    loadMoreListChannels: (type, cityID, universityID, safe) => dispatch(loadMoreListChannels(type, cityID, universityID, safe)),
    updateNewChannelsLastVisit: (key, count) => dispatch(updateNewChannelsLastVisit(key, count)),
    voteChannel: (channelID, type) => dispatch(voteChannel(channelID, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    connectActionSheet(injectIntl(withStyles(HomeScreen, styles)))
);

