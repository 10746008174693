import React from 'react';
import { Platform } from 'react-native';

import NativeModal from 'react-native-modal';
import WEBModal from 'modal-enhanced-react-native-web';

const Modal = Platform.OS === 'web' ? WEBModal : NativeModal;

import { withStyles } from "../../theme";

export default withStyles( ({ style, styles, theme, onRequestClose, visible, ...restProps }) => (
    <Modal isVisible={visible}
           swipeThreshold={10}
           hideModalContentWhileAnimating={true}
           useNativeDriver={true}
           deviceWidth={theme.SIZES.SCREEN_WIDTH}
           deviceHeight={theme.SIZES.SCREEN_HEIGHT}
           style={[
               styles.wrapper, style
           ]}
           swipeDirection="down"
           backdropOpacity={0.56}
           onSwipeComplete={onRequestClose}
           onBackButtonPress={onRequestClose}
           onBackdropPress={onRequestClose}
           {...restProps}
    />
), theme => ({
    wrapper: {
        justifyContent: 'flex-end',
        margin: 0
    }
}));
