import React from 'react';

import { Icon, Pressable } from '../base';
import { withStyles } from "../../theme";

export default withStyles(({ theme, styles, style, children, color, size, name, ...restProps }) => (
    <Pressable style={[ styles.wrapper, style ]} {...restProps}>
        <Icon style={[ styles.icon ]} color={color} size={size} name={name} />
        {children}
    </Pressable>
), theme => ({
    wrapper: {
        padding: 10,
        borderRadius: 15
    },
    icon: {

    }
}));
