import React from 'react';

import { injectIntl } from "react-intl";

import { Text, View, Screen, Image, Icon, SafeAreaView } from '../../components/base';
import { Button } from '../../components';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import screenNames from "../../navigation/screenNames";
import styles from './styles';
import { withStyles } from "../../theme";
import { connect } from "react-redux";

class WalkthroughScreen extends Screen {

    screenName = screenNames.WALKTHROUGH;

    state = {
        activeSlideIndex: 0,
        slides: [ {
            heading: 'Оцінюй',
            content: 'Голосую за Теми - тим самим вирішючи, про що говорить твоя спільнота.'
        }, {
            heading: 'Пиши',
            content: 'Ділися новинами, подіями, веселими враженнями та жартами - розповсюджуй свої дивовижні думки.'
        }, {
            heading: 'Розважайся',
            content: 'Ніколи не недооцінюй важливість розваг. Не сприймай життя занадто серйозно, роби добро і хай Карма буде з тобою!',
            hasSubmitButton: true
        } ]
    };

    handleChangeSlide = (index) => this.setState({
        activeSlideIndex: index
    });

    renderItem ({ item }) {
        const { theme, styles, intl } = this.props;
        return (
            <View style={styles.walkthroughSlideWrapper}>
                <View style={styles.walkthroughImageWrapper}>
                    {item.image && (
                        <Image style={styles.walkthroughImage} source={item.image} />
                    )}
                </View>
                <View style={styles.walkthroughTextWrapper}>
                    <Text style={styles.walkthroughHeadingText}>
                        {item.heading}
                    </Text>
                    <Text style={styles.walkthroughContentText}>
                        {item.content}
                    </Text>
                </View>
                {item.hasSubmitButton && (
                    <View style={styles.walkthroughButtonWrapper}>
                        <Button size={'medium'} onPress={this.onPressGoToApp}>
                            Поїхали! ✌
                        </Button>
                    </View>
                )}
            </View>
        );
    }

    content() {
        const { theme, styles, intl } = this.props;
        const { activeSlideIndex, slides } = this.state;

        return (
            <SafeAreaView style={styles.walkthroughContainer}>
                <Carousel
                    ref={component => this._slider = component}
                    data={slides}
                    renderItem={this.renderItem.bind(this)}
                    sliderWidth={theme.SIZES.SCREEN_WIDTH}
                    itemWidth={theme.SIZES.SCREEN_WIDTH}
                    onSnapToItem={this.handleChangeSlide}
                />
                <Pagination
                    dotsLength={slides.length}
                    activeDotIndex={activeSlideIndex}
                    containerStyle={styles.walkthroughPaginationWrapper}
                    renderDots={(activeIndex, total) => {
                        return Array.apply(null, Array(total)).map((element, index) => {
                            return (
                                <View key={index} style={[
                                    styles.walkthroughPaginationDot,
                                    (index === activeIndex ? styles.walkthroughPaginationDotActive : null),
                                    (index <= activeIndex ? styles.walkthroughPaginationDotCompleted : null)
                                ]} />
                            );
                        });
                    }}
                    carouselRef={this._slider}
                />
                <View style={styles.skipButtonWrapper}>
                    <Button onPress={this.onPressGoToApp} variation={'transparent'}>
                        Skip
                    </Button>
                </View>
                <View style={styles.nextButtonWrapper}>
                    <Button variation={'transparent'}>
                        <Icon name={'chevron-right'} size={25} />
                    </Button>
                </View>
            </SafeAreaView>
        );
    }

    onPressGoToApp = () => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.FEED);
    };
}

//Have fun and laugh with your local community
//Discover in real-time what's happening in your city
//Post messages and photos to everyone around you
//Vote on posts and decide what your city talks about
//Collect Karma to spread good vibes

const mapStateToProps = ({ common, user }) => ({
    me: user.me,
    country: common.country,
    city: common.city,
    channels_count: common.channels_count,
    university: common.university
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(WalkthroughScreen, styles))
);
