import React, { PureComponent } from 'react';

import { View, Icon } from '../../components/base';
import { withStyles } from "../../theme";
import { Button } from "../../components";

class MemberItemOptions extends PureComponent {

    render() {

        const {
            styles,
            loading,
            icon,
            variation
        } = this.props;

        return (
            <View style={styles.contactItemMenuWrapper}>
                <View style={styles.contactItemMenu}>
                    <Button onPress={this.onPress} square size='small' loading={loading} variation={variation}>
                        <Icon name={icon} size={14} />
                    </Button>
                </View>
            </View>
        );
    }

    onPress = () => {
        this.props.onPress(this.props);
    };
}

export default withStyles(MemberItemOptions, theme => ({
    contactItemMenuWrapper: {
        flex: 1,
        marginTop: 15
    },
    contactItemMenu: {
        position: 'absolute',
        right: 0,
        top: 0,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: 75
    }
}));
