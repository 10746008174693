import keyMirror from './keyMirror';

const events = {};
const hOP = events.hasOwnProperty;

export default {

    Types: keyMirror({
        SERVER_ERROR: null,
        SIGN_IN: null,
        SIGN_UP: null,
        LOGOUT: null,
        NAVIGATION_STATE_CHANGE: null,
        RECEIVED_MESSAGE_FROM_SERVER: null,
        TRANSPORT_UP: null,
        TRANSPORT_DOWN: null,
        CHANGE_LOCATION: null,
        CHANGE_APP_STATE: null,
    }),

    /**
     * @param event
     * @param listener
     * @returns {{remove(): void}}
     */
    subscribe(event, listener) {
        // Create the event's object if not yet created
        if(!hOP.call(events, event)) events[event] = [];

        // Add the listener to queue
        const index = events[event].push(listener) -1;

        // Provide handle back for removal of event
        return {
            remove () {
                delete events[event][index];
            }
        };
    },

    /**
     * @param event
     * @param args
     */
    publish (event, args) {
        // If the event doesn't exist, or there's no listeners in queue, just leave
        if(!hOP.call(events, event)) return;

        // Cycle through events queue, fire!
        events[event].forEach((fn) => {
            fn(args);
        });
    }
};
