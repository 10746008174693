export default (theme) => ({
    screenWrapper: {
        flex: 1,
        backgroundColor: theme.COLORS.BACKGROUND
    },
    headerWrapper: {
        marginTop: theme.SIZES.TOP_MARGIN,
        marginHorizontal: theme.SIZES.HEADER_HORIZONTAL_PADDING
    },
    headerBackButton: {
        width: 35,
        height: 35,
        position: 'absolute',
        left: 0,
        top: 0,
        justifyContent: 'center',
    },
    headingText: {
        color: theme.COLORS.TEXT,
        fontWeight: '900',
        fontSize: 25,
        height: 35,
        lineHeight: 35
    },
    headingTextOffset: {
        marginLeft: 30
    },
    subHeadingText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        fontSize: 15
    },
    formWrapper: {
        width: '100%',
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },
    formContentWrapper: {
        paddingBottom: 10
    },
    formField: {
        marginTop: 15,
    },
    formLabelText: {
        marginLeft: 9,
        marginVertical: 3,
        fontSize: 14,
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '700'
    },
    formInput: {

    },
    formInputArea: {

    },
    formInputSwitch: {
        marginRight: 9
    },
    submitButton: {
        marginTop: 25
    },
    logoutButton: {
        marginTop: 15
    },
    logoutButtonText: {
        color: theme.COLORS.DANGER,
        fontWeight: '700'
    },
    versionText: {
        marginTop: 15,
        fontWeight: '300',
        fontSize: 12,
        opacity: .4,
        color: theme.COLORS.SECONDARY_TEXT,
        textAlign: 'center'
    }
});
