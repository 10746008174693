import React from 'react';

import { injectIntl } from "react-intl";
import { withStyles } from "../../theme";
import { View, Screen, Text, Pressable, Icon, SList, List, Loader } from '../../components/base';
import { Button } from "../../components";
import { connect } from "react-redux";
import ContactRequestItem from './ContactRequestItem';
import SectionHeader from './SectionHeader';
import screenNames from "../../navigation/screenNames";
import { connectActionSheet } from "@expo/react-native-action-sheet";
import styles from "./styles";
import {
    getCounters,
    retrieveRequests,
    loadMoreRequests,
    sendRequest,
    declineRequest
} from "../../state/contact/actions";

class ContactsRequestsScreen extends Screen {

    screenName = screenNames.CONTACTS_REQUESTS;

    state = {
        isLoading: false,
        isRefreshing: false,
        isLoadingMore: false,
        isLoadingRemove: false,
        isLoadingSubmit: {}
    };

    content() {

        const { isLoading, isRefreshing, isLoadingMore, isLoadingSubmit, isLoadingRemove } = this.state;
        const { theme, styles, navigation, requests_count, requests, intl } = this.props;

        return (
            <View style={styles.screenWrapper}>
                <View style={styles.headerWrapper}>
                    <Text style={[
                        styles.headingText,
                        styles.headingTextOffset
                    ]}>
                        {intl.formatMessage({ id: 'contacts_requests.heading' })}
                    </Text>
                    <Text style={styles.subHeadingText}>
                        {intl.formatMessage({ id: 'contacts_requests.requests_count' }, {
                            count: requests_count
                        })}
                    </Text>
                    <Pressable onPress={() => navigation.goBack()} style={styles.headerBackButton}>
                        <Icon name={'arrow-left'} color={theme.COLORS.TEXT} size={23} />
                    </Pressable>
                </View>
                <SectionHeader />
                {isLoading ? (
                    <View style={styles.sceneLoaderWrapper}>
                        <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                        <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                    </View>
                ) : (
                    <SList
                        contentContainerStyle={styles.contentWrapper}
                        data={requests}
                        refreshing={isRefreshing}
                        onRefresh={this.onRefresh}
                        renderItem={ ({ item }, rowMap) => (
                            <ContactRequestItem
                                {...item}
                                onPress={() => this.onPress(item)}
                                onPressAdd={() => this.onPressAdd(item)}
                                onLongPress={() => this.onLongPress(item)}
                                isLoadingSubmit={isLoadingSubmit[item.id]}
                                style={styles.contactItem}
                            />
                        )}
                        renderHiddenItem={ ({ item }, rowMap) => (
                            <View style={styles.contactItemMenuWrapper}>
                                <View style={styles.contactItemMenu}>
                                    <Button onPress={() => this.onRemove(item)} loading={isLoadingRemove} square size='small' variation={'danger'}>
                                        <Icon name='trash' size={14} />
                                    </Button>
                                </View>
                            </View>
                        )}
                        ListFooterComponent={(
                            <View style={styles.loaderWrapper}>
                                {isLoadingMore && (
                                    <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                )}
                            </View>
                        )}
                        ListEmptyComponent={(
                            <View style={styles.noDataPlaceholderWrapper}>
                                <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'contacts_requests.no_requests' })}</Text>
                            </View>
                        )}
                        onEndReached={this.loadMore}
                        keyExtractor={item => item.id}
                        disableRightSwipe={true}
                        rightOpenValue={-styles.contactItemMenu.width}
                        stopRightSwipe={-styles.contactItemMenu.width}
                    />
                )}
            </View>
        );
    }

    onNavigate = async () => {
        if(this.props.requests.length > 0) return;
        await this.loadData();
    };

    loadData = async () => {
        await this.retrieveData([
            [ 'getCounters' ],
            [ 'retrieveRequests' ],
        ], 'isLoading');
    };

    onRefresh = async () => {
        await this.retrieveData([
            [ 'getCounters' ],
            [ 'retrieveRequests' ],
        ], 'isRefreshing');
    };

    loadMore = async () => {
        if(this.state.isLoading || this.state.isLoadingMore) return;
        await this.retrieveData([
            'loadMoreRequests'
        ], 'isLoadingMore');
    };

    onPress = async (request) => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.PROFILE, {
            userID: request.user_id
        })
    };

    onLongPress = async (request) => {
        const { intl } = this.props;
        this.actionSheet([ {
            label: intl.formatMessage({ id: 'contacts_requests.decline_request' }),
            destructive: true,
            onPress: async () => {
                await this.setState({ showLoader: true, isLoadingRemove: true });
                await this.props.declineRequest(request.user_id, request.id);
                await this.setState({ showLoader: false, isLoadingRemove: false });
            }
        }, {
            label: intl.formatMessage({ id: 'common.cancel' })
        } ]);
    };

    onPressAdd = async (request) => {
        const { isLoadingSubmit } = this.state;
        await this.setState({ isLoadingSubmit: { ...isLoadingSubmit, [request.id]: true } });
        await this.props.sendRequest(request.user_id, 'Accept request.');
        await this.setState({ isLoadingSubmit: { ...isLoadingSubmit, [request.id]: false } });
    };

    onRemove = async (request) => {
        await this.setState({ isLoadingRemove: true });
        await this.props.declineRequest(request.user_id, request.id);
        await this.setState({ isLoadingRemove: false });
    }
}


const mapStateToProps = ({ contact }) => ({
    requests: contact.requests,
    requests_count: contact.requests_count
});

const mapDispatchToProps = dispatch => ({
    getCounters: () => dispatch(getCounters()),
    retrieveRequests: () => dispatch(retrieveRequests()),
    loadMoreRequests: () => dispatch(loadMoreRequests()),
    sendRequest: (userID, message) => dispatch(sendRequest(userID, message)),
    declineRequest: (userID, requestID) => dispatch(declineRequest(userID, requestID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    connectActionSheet(injectIntl(withStyles(ContactsRequestsScreen, styles)))
);

