import { createStackNavigator } from 'react-navigation-stack';

import screens from '../screens';
import screenNames from './screenNames';

export default createStackNavigator({
    [screenNames.WELCOME]: screens.WelcomeScreen,
    [screenNames.SIGN_UP]: screens.SignUpScreen,
}, {
    initialRouteName: screenNames.WELCOME,
    headerMode: 'none',
    defaultNavigationOptions: {
        header: null,
        cardShadowEnabled: false,
        cardOverlayEnabled: false,
        gesturesEnabled: true,
        cardTransparent: true
    }
});
