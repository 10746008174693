import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { TabBar, TabView as RNTabView } from "react-native-tab-view";
import { Text, Icon } from "../base";
import TabBarIndicator from './TabBarIndicator';
import { withStyles } from "../../theme";

class TabView extends Component {

    constructor(props) {
        super(props);

        const tabs = props.tabs.map(tab => tab.key);
        this.state = {
            index: props.selectedKey ? tabs.indexOf(props.selectedKey) : 0,
            routes: props.tabs
        };
    }

    componentDidUpdate(prevProps) {
        const { selectedKey, tabs } = this.props;
        const _tabs = tabs.map(tab => tab.key);
        if(prevProps.selectedKey !== selectedKey) {
            const index = _tabs.indexOf(selectedKey);
            if(index !== this.state.index) {
                this.setState({
                    index
                });
            }
        }
        if(prevProps.tabs !== tabs) {
            this.setState({
                routes: tabs
            });
        }
    }

    _handleIndexChange = (index) => {
        const { onChange, tabs } = this.props;
        this.setState({
            index
        }, () => {
            if(onChange) onChange(tabs[index].key);
        });
    };

    render() {

        const { theme, styles, scrollTabBarEnabled, routes, style, tabBarStyle } = this.props;

        return (
            <RNTabView
                style={style}
                navigationState={this.state}
                renderTabBar={(props) => (
                    <TabBar
                        scrollEnabled={scrollTabBarEnabled}
                        style={[
                            styles.tabBar,
                            tabBarStyle
                        ]}
                        pressColor={'transparent'}
                        renderLabel={({ route }) => (
                            <Text numberOfLines={1} style={styles.label}>{route.icon ? (
                                <Icon size={14} name={route.icon} />
                            ) : null} {route.title}</Text>
                        )}
                        renderIndicator={(props) => <TabBarIndicator {...props} />}
                        {...props}
                    />
                )}
                renderScene={({ route, jumpTo }) => routes[route.key]}
                onIndexChange={this._handleIndexChange}
                initialLayout={styles.initialLayout}
            />
        );
    }
}

TabView.propTypes = {
    style: PropTypes.any,
    tabBarStyle: PropTypes.any,
    scrollY: PropTypes.any,
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
    routes: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    selectedKey: PropTypes.string,
    scrollTabBarEnabled: PropTypes.bool
};

export default withStyles(TabView, theme => ({
    initialLayout: {
        width: theme.SIZES.SCREEN_WIDTH
    },
    tabBar: {
        backgroundColor: null,
        marginHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
        marginTop: 10
    },
    label: {
        color: theme.COLORS.TEXT,
        fontWeight: '700',
        fontSize: 15
    }
}));
