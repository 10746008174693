import React, { PureComponent } from 'react';

import { Animated } from "react-native";
import { Text, View, Image, Avatar, Pressable } from '../../components/base';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";

class HeaderExpanded extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            scrollY,
            channel,
            intl,
            inverted,
            ...restProps
        } = this.props;

        if(inverted) {
            return null;
        }

        const imageHeight = scrollY.interpolate({
            inputRange: [-theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT, 0],
            outputRange: [(theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT + theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT) / theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT, 1],
            extrapolate: 'clamp'
        });

        const headerHeight = scrollY.interpolate({
            inputRange: [-theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT, 0, theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT-theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT],
            outputRange: [theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT / 3, 0, -(theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT-theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT) / 2],
            extrapolate: 'clamp'
        });

        const heroTitleOpacity = scrollY.interpolate({
            inputRange: [0, theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT-theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT],
            outputRange: [1, 0],
            extrapolate: 'clamp'
        });

        const heroImageOpacity = scrollY.interpolate({
            inputRange: [0, theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT-theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT],
            outputRange: [.5, 0],
            extrapolate: 'clamp'
        });

        return (
            <Animated.View style={[
                styles.wrapper,
                { opacity: heroTitleOpacity }
            ]}>
                <View style={styles.bottomWrapperBackground} />
                {!!channel.thumbnail_original && (
                    <View style={styles.coverImageWrapper}>
                        <Image animated={true} style={[
                            styles.coverImage,
                            { opacity: heroImageOpacity, transform: [ { scale: imageHeight }, { translateY: headerHeight } ] },
                        ]} source={{ uri: channel.thumbnail_large }} />
                    </View>
                )}
                <Animated.View style={[ styles.content, { transform: [ { translateY: headerHeight }] } ]}>
                    <Text style={styles.headingText}>
                        {channel.subject}
                    </Text>
                </Animated.View>
                <Animated.View style={[ styles.bottomWrapper, { transform: [ { translateY: headerHeight }] } ]}>
                    <View style={styles.bottomAuthorWrapper}>
                        <Avatar style={styles.bottomAuthorAvatar} avatarUrl={channel.author_avatar_bigger} />
                        <Text style={styles.bottomAuthorNickname}> @{channel.author_nickname} </Text>
                    </View>
                    <View style={styles.bottomTimeWrapper}>
                        <Text style={styles.bottomTimeText}> {intl.formatRelative(channel.created_at)} </Text>
                    </View>
                </Animated.View>
            </Animated.View>
        );
    }
}

export default injectIntl(withStyles(HeaderExpanded, theme => ({
    wrapper: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },
    bottomWrapperBackground: {
        position: 'absolute',
        bottom: -theme.SIZES.SCREEN_HEIGHT * .3,
        width: theme.SIZES.SCREEN_WIDTH,
        height: theme.SIZES.SCREEN_HEIGHT * .3,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
    },
    coverImageWrapper: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT + (theme.SIZES.SCREEN_HEIGHT * .3),
        overflow: 'hidden'
    },
    coverImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT
    },
    content: {
        paddingHorizontal: 30,
        justifyContent: 'center',
        flex: 1
    },
    headingText: {
        marginTop: -15,
        color: theme.COLORS.TEXT,
        fontSize: 18,
        fontFamily: theme.FONTS.BALOO,
        textAlign: 'center'
    },
    bottomWrapper: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: theme.SIZES.HEADER_HORIZONTAL_PADDING,
        position: 'absolute',
        bottom: 40
    },
    bottomAuthorWrapper: {
        flex: 1,
        flexDirection: 'row'
    },
    bottomAuthorAvatar: {
        width: 16,
        height: 16,
        borderRadius: 6
    },
    bottomAuthorNickname: {
        fontSize: 11,
        fontWeight: '700',
        color: theme.COLORS.TEXT,
        marginLeft: 4
    },
    bottomTimeWrapper: {
        flex: .5
    },
    bottomTimeText: {
        fontSize: 11,
        color: theme.COLORS.TEXT,
        textAlign: 'right'
    }
})));
