import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import channel from './channel/reducer';
import common from './common/reducer';
import contact from './contact/reducer';

const Reducers = combineReducers({
    auth,
    user,
    channel,
    common,
    contact,
});

export default Reducers;
