import {
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    CLEAR_USER_REDUCER,
    CLEAR_CONTACT_REDUCER,
    CLEAR_COMMON_REDUCER,
    CLEAR_CHANNEL_REDUCER,
    CLEAR_AUTH_REDUCER,
    SIGNUP_SUCCESS,
    INITIALIZE_PUSH_SERVICE,
    RECOVER_SESSION
} from '../action-types';

import { getMe } from "../user/actions";
import { getLocation } from "../common/actions";
import { getCurrentLocale } from "../../utils/localizationHelper";
import api from '../../networking/api';
import comet from '../../networking/comet';
import * as Permissions from 'expo-permissions';
import { Platform } from 'react-native';
import { Notifications } from 'expo';
import * as Sentry from 'sentry-expo';

/**
 * **********
 * SIGNUP_SUCCESS
 * **********
 */
const signUpSuccess = (userID) => {
    return {
        type: SIGNUP_SUCCESS,
        payload: {
            userID
        }
    };
};

/**
 * **********
 * LOGIN_SUCCESS
 * **********
 */
const loginSuccess = (userID) => {
    return {
        type: LOGIN_SUCCESS,
        payload: {
            userID
        }
    };
};

export const initializeSession = (userID) => {
    return async (dispatch) => {
        Sentry.setUser({ userID });
        await comet.init();
        await dispatch(initializePushService());
        await dispatch(loginSuccess(userID));
        return { "success": true };
    };
};

/**
 * **********
 * INITIALIZE_PUSH_SERVICE
 * **********
 */
export const initializePushService = () => {
    return async (dispatch) => {
        const { status: existingStatus } = await Permissions.getAsync(
            Permissions.NOTIFICATIONS
        );
        let finalStatus = existingStatus;
        // only ask if permissions have not already been determined, because
        // iOS won't necessarily prompt the user a second time.
        if (existingStatus !== 'granted') {
            // Android remote notification permissions are granted during the app
            // install, so this will only ask on iOS
            const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
            finalStatus = status;
        }
        // Stop here if the user did not grant permissions
        if (finalStatus !== 'granted') {
            return { "success": true, "result": finalStatus === 'granted' };
        }
        // Get the token that uniquely identifies this device
        let token;
        if(Platform.OS === 'web') {
            token = await Notifications.getDevicePushTokenAsync({
                gcmSenderId: 406383187315
            });
        } else {
            token = await Notifications.getExpoPushTokenAsync();
        }
        await dispatch({
            type: INITIALIZE_PUSH_SERVICE,
            payload: {
                token
            }
        });
        return { "success": true, "result": finalStatus === 'granted' };
    };
};

export const clearStore = () => {
    return async (dispatch) => {
        await dispatch({
            type: CLEAR_USER_REDUCER
        });
        await dispatch({
            type: CLEAR_CONTACT_REDUCER
        });
        await dispatch({
            type: CLEAR_COMMON_REDUCER
        });
        await dispatch({
            type: CLEAR_CHANNEL_REDUCER
        });
        await dispatch({
            type: CLEAR_AUTH_REDUCER
        });
        return { success: true };
    };
};

export const signIn = (nickname, password) => {
    return async (dispatch) => {
        let response = await api.signIn(nickname, password),
            { result, success } = response;

        if(success) {
            await comet.init();
            Sentry.setUser({ userID: result.userID });
            await dispatch(getMe());
            await dispatch(getLocation(getCurrentLocale()));
            await dispatch(initializePushService());
            await dispatch(loginSuccess(result.userID));
        }

        return response;
    };
};

export const signUp = (nickname, password, cityID, universityID) => {
    return async (dispatch) => {
        let response = await api.signUp(nickname, password, cityID, universityID),
            { result, success } = response;

        if(success) {
            await comet.init();
            Sentry.setUser({ userID: result.userID });
            await dispatch(getMe());
            await dispatch(getLocation(getCurrentLocale()));
            await dispatch(initializePushService());
            await dispatch(signUpSuccess(result.userID));
        }

        return response;
    };
};

/**
 * **********
 * LOGOUT_SUCCESS
 * **********
 */
const logoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};

export const logout = (token) => {
    return async (dispatch) => {
        let response = await api.logout(token);
        await comet.destroy();
        await dispatch(logoutSuccess());
        return response;
    };
};


const recoverSessionSuccess = (userID) => {
    return {
        type: RECOVER_SESSION,
        payload: {
            userID
        }
    };
};

export const recoverSession = (platform) => {

};
