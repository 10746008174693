import React from 'react';

import { injectIntl } from "react-intl";
import { withStyles } from "../../theme";
import {View, Screen, Text, List, Loader, AnimatedDots} from '../../components/base';
import { connect } from "react-redux";
import styles from "./styles";
import SectionHeader from './SectionHeader';
import ResultItem from './ResultItem';
import NearbyUserItem from './NearbyUserItem';
import SearchInput from './SearchInput';
import screenNames from "../../navigation/screenNames";
import {
    getNearbyUsers,
    globalSearch,
    loadMoreNearbyUsers,
    loadMoreGlobalSearch
} from "../../state/common/actions";
import { retrieveChannel } from "../../state/channel/actions";
import { debounce } from "../../utils/async";

class SearchScreen extends Screen {

    screenName = screenNames.SEARCH;

    constructor(props) {
        super(props);

        this.state = {
            query: '',
            isLoading: false,
            isLoadingMore: false,
            isRefreshing: false
        };

        this.search = debounce(this.search, 1000);
    }

    async search() {
        return await this.loadData();
    }

    onChangeQuery = async (query) => {
        await this.setState({
            query,
            isLoading: query !== ''
        });
        if(query !== '') {
            await this.search();
        }
    };

    content() {

        const { theme, styles, country, city, intl, nearbyUsers, searchResults } = this.props;
        const { isRefreshing, isLoading, isLoadingMore, query } = this.state;

        const users = (city && city.id && nearbyUsers[city.id]) ? nearbyUsers[city.id] : [];

        return (
            <View style={styles.screenWrapper}>
                <View style={styles.headerWrapper}>
                    <SearchInput
                        placeholder={intl.formatMessage({ id: 'search.heading_placeholder' })}
                        onChangeText={this.onChangeQuery}
                    />
                    <Text style={styles.subHeadingText}>
                        {intl.formatMessage({ id: 'search.subtitle' })}
                    </Text>
                </View>
                <SectionHeader style={{ marginTop: 20 }} location={query === '' ? `${city.short_name}, ${country.short_name}` : ''}>
                    {query === '' ? intl.formatMessage({ id: 'search.people' }) : intl.formatMessage({ id: 'search.search_results' })}
                </SectionHeader>
                {query === '' && (isLoading ? (
                    <View style={styles.sceneLoaderWrapper}>
                        <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                        <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                    </View>
                ) : (
                    <List
                        contentContainerStyle={styles.contentWrapper}
                        data={users}
                        ListFooterComponent={(
                            <View style={styles.loaderWrapper}>
                                {isLoadingMore && (
                                    <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                )}
                            </View>
                        )}
                        ListEmptyComponent={(
                            <View style={styles.noDataPlaceholderWrapper}>
                                <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'search.no_people' })}</Text>
                            </View>
                        )}
                        renderItem={({ item }) => (
                            <NearbyUserItem
                                {...item}
                                onPress={() => this.onPressItem(item)}
                                style={styles.resultItem}
                            />
                        )}
                        refreshing={isRefreshing}
                        onRefresh={this.onRefresh}
                        onEndReached={this.loadMoreUsers}
                        keyExtractor={item => item.id}
                    />
                ))}
                {query !== '' && (isLoading ? (
                    <View style={styles.sceneLoaderWrapper}>
                        <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                        <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.searching_upper' })}<AnimatedDots /></Text>
                    </View>
                ) : (
                    <List
                        contentContainerStyle={styles.contentWrapper}
                        data={searchResults}
                        ListFooterComponent={(
                            <View style={styles.loaderWrapper}>
                                {isLoadingMore && (
                                    <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                )}
                            </View>
                        )}
                        ListEmptyComponent={(
                            <View style={styles.noDataPlaceholderWrapper}>
                                <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'search.no_results' })}</Text>
                            </View>
                        )}
                        renderItem={({ item }) => (
                            <ResultItem
                                {...item}
                                onPress={() => this.onPressResult(item)}
                                style={styles.resultItem}
                            />
                        )}
                        refreshing={isRefreshing}
                        onRefresh={this.onRefresh}
                        onEndReached={this.loadMore}
                        keyExtractor={item => item.id + item.type}
                    />
                ))}
            </View>
        );
    }

    onPressItem = async (item) => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.PROFILE, {
            userID: item.id
        });
    };

    onPressResult = async (item) => {
        if(item.type === 'user') {
            const { navigation } = this.props;
            navigation.navigate(screenNames.PROFILE, {
                userID: item.id
            });
        } else if(item.type === 'topic') {
            return this.navigateToTopic(item.id);
        }
    };

    onNavigate = async () => {
        const { nearbyUsers, city } = this.props;
        const users = (city && city.id && nearbyUsers[city.id]) ? nearbyUsers[city.id] : [];
        if(users.length > 0) return;
        await this.loadUsers();
    };

    onRefresh = async () => {
        const { query } = this.state;
        if(query === '') {
            await this.retrieveData([
                'getNearbyUsers', this.props.city.id
            ], 'isRefreshing')
        } else {
            await this.retrieveData([
                'globalSearch', query
            ], 'isRefreshing')
        }
    };

    loadUsers = async () => {
        if(!this.props.city) return;
        await this.retrieveData([
            'getNearbyUsers', this.props.city.id
        ], 'isLoading')
    };

    loadMoreUsers = async () => {
        if(!this.props.city || this.state.isLoading || this.state.isLoadingMore) return;
        await this.retrieveData([
            'loadMoreNearbyUsers', this.props.city.id
        ], 'isLoadingMore');
    };

    loadData = async () => {
        await this.retrieveData([
            'globalSearch', this.state.query
        ], 'isLoading')
    };

    loadMore = async () => {
        if(this.state.isLoading || this.state.isLoadingMore) return;
        await this.retrieveData([
            'loadMoreGlobalSearch', this.state.query
        ], 'isLoadingMore');
    };
}


const mapStateToProps = ({ user, common }) => ({
    searchResults: common.searchResults,
    nearbyUsers: common.nearbyUsers,
    cityLastVisits: common.cityLastVisits,
    country: common.country,
    university: common.university,
    city: common.city,
    ignoreList: user.ignoreList
});

const mapDispatchToProps = dispatch => ({
    globalSearch: (query) => dispatch(globalSearch(query)),
    retrieveChannel: (channelID) => dispatch(retrieveChannel(channelID)),
    getNearbyUsers: (cityID) => dispatch(getNearbyUsers(cityID)),
    loadMoreNearbyUsers: (cityID) => dispatch(loadMoreNearbyUsers(cityID)),
    loadMoreGlobalSearch: (query) => dispatch(loadMoreGlobalSearch(query))
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(SearchScreen, styles))
);

