import { images } from "../../../assets";

const IMAGES = {
    welcomeLogo: images.welcomeLogoLight,
    welcomeBackground: images.welcomeBackgroundLight,
    splash: images.splashLight,
    homeLogo: images.welcomeLogoLight,
};

export default IMAGES;
