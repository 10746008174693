import keyMirror from "../utils/keyMirror";

export default keyMirror({
    AUTH_STACK: null,
    SIGNED_STACK: null,
    APP_LOADING: null,

    WELCOME: null,
    SIGN_UP: null,
    HOME: null,
    WALKTHROUGH: null,
    MY_PROFILE: null,
    PROFILE: null,
    SETTINGS: null,
    NOTIFICATIONS: null,
    SEARCH: null,
    CONTACTS: null,
    CONTACTS_REQUESTS: null,
    CONVERSATION: null,
    TOPIC: null,
    TOPIC_MEMBERS: null,
    EDIT_LOCATION: null,
    START: null,
    FEED: null,
});
