import React from 'react';

import { TextArea, View, Icon, Text, Pressable } from '../base';
import { withStyles } from "../../theme";

export default withStyles(({ theme, styles, style, error, variation = 'default', icon, onPressIcon, ...restProps }) => (
    <View style={[styles.wrapper, style]}>
        <TextArea
            placeholderTextColor={(
                variation === 'default'
                    ? theme.COLORS.SECONDARY_TEXT
                    : theme.COLORS.PLACEHOLDER_TEXT
            )}
            style={[
                styles[`VARIATION__${variation}`],
                (icon ? styles[`VARIATION__${variation}_withIcon`] : null),
                (error ? styles.hasError : null)
            ]}
            {...restProps}
        />
        {icon && (
            <Pressable onPress={onPressIcon} disabled={!onPressIcon} style={styles.eyeIconWrapper} >
                <Icon name={icon} style={styles.eyeIcon} />
            </Pressable>
        )}
        {!!error && (
            <View style={styles[`VARIATION__${variation}_errorWrapper`]}>
                <Text style={styles[`VARIATION__${variation}_errorText`]}>{error}</Text>
            </View>
        )}
    </View>
), theme => ({
    wrapper: {
        width: '100%',
        justifyContent: 'center'
    },
    VARIATION__basic: {
        fontWeight: 'bold',
        borderWidth: 3.5,
        width: '100%',
        borderRadius: 25,
        height: 75,
        fontSize: 15,
        paddingHorizontal: 25,
        borderColor: theme.COLORS.TEXT,
        color: theme.COLORS.TEXT,
    },
    VARIATION__default: {
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 17.5,
        height: 75,
        fontSize: 15,
        paddingTop: 10,
        paddingBottom: 10,
        paddingHorizontal: 20,
        color: theme.COLORS.TEXT,
    },
    VARIATION__basic_withIcon: {
        paddingRight: 40
    },
    VARIATION__default_withIcon: {
        paddingRight: 20
    },
    hasError: {
        borderColor: theme.COLORS.DANGER
    },
    eyeIconWrapper: {
        top: 0,
        height: 50,
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
    },
    eyeIcon: {
        fontSize: 20,
        color: theme.COLORS.TEXT,
        paddingRight: 20
    },
    VARIATION__basic_errorWrapper: {
        paddingTop: 2
    },
    VARIATION__default_errorWrapper: {
        paddingTop: 2
    },
    VARIATION__basic_errorText: {
        color: theme.COLORS.DANGER,
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    VARIATION__default_errorText: {
        color: theme.COLORS.DANGER,
        fontSize: 15,
        textAlign: 'center'
    },
}));
