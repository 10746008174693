export default (theme) => ({
    screenWrapper: {
        flex: 1,
        backgroundColor: theme.COLORS.BACKGROUND,
        alignItems: 'center',
        justifyContent: 'center'
    },
    cover: {
        width: theme.SIZES.SCREEN_WIDTH,
        height: theme.SIZES.SCREEN_HEIGHT,
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: .25
    },
    form: {
        width: '100%',
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN
    },
    textInput: {
        marginTop: theme.SIZES.SCREEN_HEIGHT * .04,
    },
    submitButton: {
        marginTop: theme.SIZES.SCREEN_HEIGHT * .04,
    },
    signUpLink: {
        fontWeight: '900',
        color: theme.COLORS.PRIMARY
    },
    versionText: {
        fontWeight: '300',
        fontSize: 12,
        position: 'absolute',
        bottom: theme.SIZES.SCREEN_HEIGHT * .05,
        color: theme.COLORS.SECONDARY_TEXT,
        textAlign: 'center'
    }
});
