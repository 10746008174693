export const minute = 60; // in seconds

export const hour = 60 * minute; // in seconds

export const day = 24 * hour; // in seconds

// https://www.quora.com/What-is-the-average-number-of-days-in-a-month
export const month = 30.44 * day; // in seconds

// "400 years have 146097 days (taking into account leap year rules)"
export const year = (146097 / 400) * day; // in seconds

/**
 * Returns a step of gradation corresponding to the unit.
 * @param  {Object[]} gradation
 * @param  {string} unit
 * @return {?Object}
 */
export function getStep(gradation, unit) {
    for (const step of gradation) {
        if (step.unit === unit) {
            return step
        }
    }
}

/**
 * Converts value to a `Date`
 * @param {(number|Date)} value
 * @return {Date}
 */
export function getDate(value) {
    return value instanceof Date ? value : new Date(value)
}

// just now
// 1 minute ago
// 2 minutes ago
// 5 minutes ago
// 10 minutes ago
// 15 minutes ago
// 20 minutes ago
// an hour ago
// 2 hours ago
// …
// 20 hours ago
// a day ago
// 2 days ago
// 5 days ago
// a week ago
// 2 weeks ago
// 3 weeks ago
// a month ago
// 2 months ago
// 4 months ago
// a year ago
// 2 years ago
// …
export const convenient = [ {
    factor: 1,
    unit: 'now'
}, {
    threshold: 1,
    threshold_for_now: 45,
    factor: 1,
    unit: 'second'
}, {
    threshold: 45,
    factor: 60,
    unit: 'minute'
}, {
    threshold: 2.5 * 60,
    factor: 60,
    granularity: 5,
    unit: 'minute'
}, {
    threshold: 22.5 * 60,
    factor: 30 * 60,
    unit: 'half-hour'
}, {
    threshold: 42.5 * 60,
    threshold_for_minute: 52.5 * 60,
    factor: 60 * 60,
    unit: 'hour'
}, {
    threshold: (20.5 / 24) * day,
    factor: day,
    unit: 'day'
}, {
    threshold: 5.5 * day,
    factor: 7 * day,
    unit: 'week'
}, {
    threshold: 3.5 * 7 * day,
    factor: month,
    unit: 'month'
}, {
    threshold: 10.5 * month,
    factor: year,
    unit: 'year'
} ];

export function grade(elapsed, now, units, gradation = convenient) {
    // Leave only allowed time measurement units.
    // E.g. omit "quarter" unit.
    gradation = getAllowedSteps(gradation, units);

    // If no steps of gradation fit the conditions
    // then return nothing.
    if (gradation.length === 0) {
        return
    }

    // Find the most appropriate gradation step
    const i = findGradationStep(elapsed, now, gradation);
    const step = gradation[i];

    // If time elapsed is too small and even
    // the first gradation step doesn't suit it
    // then return nothing.
    if (i === -1) {
        return
    }

    // Apply granularity to the time amount
    // (and fall back to the previous step
    //  if the first level of granularity
    //  isn't met by this amount)
    if (step.granularity) {
        // Recalculate the elapsed time amount based on granularity
        const amount = Math.round((elapsed / step.factor) / step.granularity) * step.granularity
        // If the granularity for this step
        // is too high, then fallback
        // to the previous step of gradation.
        // (if there is any previous step of gradation)
        if (amount === 0 && i > 0) {
            return gradation[i - 1]
        }
    }

    return step
}

/**
 * Gets threshold for moving from `fromStep` to `next_step`.
 * @param  {Object} fromStep - From step.
 * @param  {Object} next_step - To step.
 * @param  {number} now - The current timestamp.
 * @return {number}
 * @throws Will throw if no threshold is found.
 */
function getThreshold(fromStep, toStep, now) {
    let threshold;

    // Allows custom thresholds when moving
    // from a specific step to a specific step.
    if (fromStep && (fromStep.id || fromStep.unit)) {
        threshold = toStep[`threshold_for_${fromStep.id || fromStep.unit}`];
    }

    // If no custom threshold is set for this transition
    // then use the usual threshold for the next step.
    if (threshold === undefined) {
        threshold = toStep.threshold;
    }

    // Convert threshold to a number.
    if (typeof threshold === 'function') {
        threshold = threshold(now);
    }

    // Throw if no threshold is found.
    if (fromStep && typeof threshold !== 'number') {
        // Babel transforms `typeof` into some "branches"
        // so istanbul will show this as "branch not covered".
        /* istanbul ignore next */
        const type = typeof threshold;
        throw new Error(`Each step of a gradation must have a threshold defined except for the first one. Got "${threshold}", ${type}. Step: ${JSON.stringify(toStep)}`)
    }

    return threshold
}

/**
 * @param  {number} elapsed - Time elapsed (in seconds).
 * @param  {number} now - Current timestamp.
 * @param  {Object} gradation - Gradation.
 * @param  {number} i - Gradation step currently being tested.
 * @return {number} Gradation step index.
 */
function findGradationStep(elapsed, now, gradation, i = 0) {
    // If the threshold for moving from previous step
    // to this step is too high then return the previous step.
    if (elapsed < getThreshold(gradation[i - 1], gradation[i], now)) {
        return i - 1
    }
    // If it's the last step of gradation then return it.
    if (i === gradation.length - 1) {
        return i
    }
    // Move to the next step.
    return findGradationStep(elapsed, now, gradation, i + 1)
}

/**
 * Leaves only allowed gradation steps.
 * @param  {Object[]} gradation
 * @param  {string[]} units - Allowed time units.
 * @return {Object[]}
 */
function getAllowedSteps(gradation, units) {
    return gradation.filter(({ unit }) => {
        // If this step has a `unit` defined
        // then this `unit` must be in the list of `units` allowed.
        if (unit) {
            return units.indexOf(unit) >= 0
        }
        // A gradation step is not required to specify a `unit`.
        // E.g. for Twitter gradation it specifies `format()` instead.
        return true
    })
}

export const formatRelative = (timestamp, units = [
    // 'now',
    // The rest are the same as in `Intl.RelativeTimeFormat`.
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'quarter',
    'year'
]) => {
    let now = Math.round(Date.now() / 1000);
    if((now - timestamp) === 0) {
        now = timestamp + 1;
    }
    const elapsed = now - timestamp;
    const step = grade(Math.abs(elapsed), now, units);
    if (!step) {
        return '';
    }
    const { unit, factor, granularity } = step;

    let amount = Math.abs(elapsed) / factor;

    if (granularity) {
        // Recalculate the elapsed time amount based on granularity
        amount = Math.round(amount / granularity) * granularity
    }

    return [
        -1 * Math.sign(elapsed) * Math.round(amount), unit
    ];
};
