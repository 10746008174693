import api from "./api";
import { getRandomString } from "../utils/random";

let _selectors = {};

export const noReloadErrorCodes = [
    api.errorCodes.COMMON_ERROR_CODE,
    api.errorCodes.UNAUTHORIZED_ERROR_CODE
];

export const retrieveData = async (requestPool, before, after) => {
    let result = await processSelector(requestPool, before, after),
        { success, errorCode } = result;
    if(!success && noReloadErrorCodes.indexOf(errorCode) === -1) {
        addSelector(requestPool, before, after);
    }
    return result;
};

const processSelector = async (requestPool, before, after) => {
    if(before) {
        await before();
    }
    let result = { success: true, errorCode: 0 };
    for(let i in requestPool) {
        let response = await requestPool[i]();
        if(!response.success) {
            result.success = false;
            result.errorCode = response.error && response.error.code ? response.error.code : 0;
            break;
        }
    }
    if(after) {
        await after(result);
    }
    return result;
};

export const processAllSelectors = async () => {
    for(let key in _selectors) {
        let result = await processSelector.apply(null, _selectors[key]),
            { success, errorCode } = result;
        if(success || noReloadErrorCodes.indexOf(errorCode) > -1) {
            removeSelector(key);
        }
    }
    return Object.keys(_selectors).length;
};

const addSelector = (requestPool, before, after) => {
    const key = getRandomString();
    _selectors[key] = [requestPool, before, after];
    return key;
};

const removeSelector = (key) => {
    delete _selectors[key];
};
