import React from 'react';

import { BottomTabBar } from 'react-navigation-tabs';
import { withStyles } from "../../theme";
import {connect} from "react-redux";

const BottomTab = ({ theme, styles, style, isLoggedIn, ...restProps }) => (
    <BottomTabBar
        style={[style, styles.wrapper,
            ( !isLoggedIn && styles.hidden )
        ]}
        activeTintColor={theme.COLORS.TEXT}
        inactiveTintColor={theme.COLORS.SECONDARY_TEXT}
        {...restProps}
    />
);

const mapStateToProps = ({ auth, user }) => ({
    isLoggedIn: auth.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(BottomTab, theme => ({
        wrapper: {
            shadowOffset: { width: 0, height: 0 },
            shadowColor: '#000',
            shadowOpacity: .15,
            shadowRadius: 4,
            elevation: 5,

            borderTopWidth: 0,
            height: 55,
            backgroundColor: theme.COLORS.BACKGROUND,
            paddingBottom: 7,
            paddingTop: 7,
        },
        hidden: {
            height: 0,
            shadowRadius: 0,
            elevation: 0,
            paddingBottom: 0,
            paddingTop: 0,
        }
    })));
