import React from 'react';

import { RootSiblingManager } from '../../providers/RootSiblingsProvider';
import { getStringsHash } from "../../utils/random";
import { ToastContainer, Containers } from './ToastContainer';

class Toast {

    static _toasts = {};
    static _siblings = {};

    /**
     * @param toastID
     * @returns boolean
     */
    static isAlive (toastID) {
        return !!Toast._toasts[toastID];
    }

    /**
     * @param toastID
     * @returns {*}
     */
    static get (toastID) {
        return Toast._toasts[toastID];
    }

    /**
     * @param toastID
     * @param method
     * @param args
     * @private
     */
    static _callMethod (toastID, method, ...args) {
        if(Containers[toastID]) {
            Containers[toastID][method](...args);
        }
    }

    /**
     * @param id
     * @param options
     */
    constructor(id, options) {
        if(Toast._toasts[id]) {
            return Toast._toasts[id];
        }
        this._id = id;
        this._options = {
            ...options,
            onHidden: (toastID) => {
                if(options.onHidden) {
                    options.onHidden();
                }
                if(Toast._siblings[toastID]) {
                    Toast._siblings[toastID].destroy();
                }
                delete Toast._siblings[toastID];
                delete Toast._toasts[toastID];
            },
            toastID: id
        };
        return Toast._toasts[id] = this;
    }

    show() {
        if(!Toast._siblings[this._id]) {
            Toast._siblings[this._id] = new RootSiblingManager(<ToastContainer {...this._options} visible={true} />);
        }
    }

    hide() {
        Toast._callMethod(this._id, '_hide');
    }

    setLoading(loading) {
        Toast._callMethod(this._id, 'setLoading', loading);
    }

}

export default {

    /**
     * @param id
     * @param options
     * @returns {Toast|*}
     * @private
     */
    async _show (id, options) {
        if(Toast.isAlive(id)) {
            return Toast.get(id);
        }
        const toast = new Toast(id, options);
        toast.show();
        return toast;
    },

    /**
     * @param title
     * @param message
     * @param onHide|null
     * @returns {Toast}
     */
    async showSuccess (title, message, onHide = null) {
        return this._show(await getStringsHash([ title, message ]), {
            title,
            onHide,
            message,
            icon: 'check-circle',
            variation: 'success'
        });
    },

    /**
     * @param title
     * @param message
     * @param onHide|null
     * @returns {Toast}
     */
    async showDanger (title, message, onHide = null) {
        return this._show(await getStringsHash([ title, message ]), {
            title,
            onHide,
            message,
            icon: 'alert-octagon',
            variation: 'danger'
        });
    },

    /**
     * @param title
     * @param message
     * @param nickname
     * @param avatarUrl
     * @param onPress|null
     * @returns {Toast}
     */
    async showUserMessage (title, message, nickname, avatarUrl, onPress = null) {
        return this._show(await getStringsHash([ title, message, nickname ]), {
            title,
            message,
            duration: 5000,
            nickname,
            avatarUrl,
            onPress,
        });
    },

    /**
     * @param title
     * @param message
     * @param onHide|null
     * @returns {Toast}
     */
    async showWarning (title, message, onHide = null) {
        return this._show(await getStringsHash([ title, message ]), {
            title,
            onHide,
            message,
            icon: 'alert-circle',
            variation: 'warning'
        });
    },

    async showSimpleSuccess (message) {
        return this._show(await getStringsHash([ message ]), {
            message,
            icon: 'check-circle',
            variation: 'success',
            small: true
        });
    },

    async showSimpleWarning (message) {
        return this._show(await getStringsHash([ message ]), {
            message,
            icon: 'alert-circle',
            variation: 'warning',
            small: true
        });
    },

    async showSimpleDanger (message) {
        return this._show(await getStringsHash([ message ]), {
            message,
            icon: 'alert-octagon',
            variation: 'danger',
            small: true
        });
    },

    /**
     * @param title
     * @param message
     * @param onPress
     * @returns {Toast}
     */
    async showServerError (title, message, onPress) {
        return this._show('ID_SERVER_ERROR', {
            title,
            message,
            onPress,
            // icon: 'alert-octagon',
            emoji: '😢‍',
            variation: 'danger',
            duration: 0,
            hideOnPress: false
        });
    },

    /**
     * @returns {Promise<void>}
     */
    async showFade (children) {
        this._show('FADE', {
            fade: true,
            opacity: 1,
            duration: 0,
            fadeChildren: children
        });
    },

    /**
     * @returns {Promise<void>}
     */
    async hideFade () {
         Toast.get('FADE').hide();
    },
};
