import React from 'react';

import { injectIntl } from "react-intl";
import { Text, View, KView, Image, Screen, SView } from '../../components/base';
import { TextInput, Button, Toast, WelcomeFade } from '../../components';
import { images } from '../../../assets'
import { withStyles } from "../../theme";
import screenNames from '../../navigation/screenNames';
import styles from './styles';
import { signUp } from "../../state/auth/actions";
import { connect } from "react-redux";
import Validator from '../../utils/validator';
import * as WebBrowser from 'expo-web-browser';
import { registerDevice } from "../../state/common/actions";
import { StatusBar } from "react-native";
import { wait } from "../../utils/async";

class SignUpScreen extends Screen {

    screenName = screenNames.SIGN_UP;

    state = {
        form: {
            nickname: '',
            password: ''
        },
        errors: {},
        isLoading: false,
        showPassword: false
    };

    onPressSignUp = async () => {
        const { nickname, password } = this.state.form;

        const validator = new Validator([
            ['nickname', Validator.rules.NOT_EMPTY],
            ['password', Validator.rules.NOT_EMPTY],
            ['nickname', Validator.rules.REGEX, Validator.regEx.NICKNAME],
            ['nickname', Validator.rules.LENGTH, { min: 4, max: 21 }],
            ['password', Validator.rules.LENGTH, { min: 6, max: 32 }],
        ], this.state.form);

        const { city, deepLink, intl, navigation } = this.props;

        let cityID = city.id, universityID = null;
        if(deepLink && deepLink.type === 'location') {
            deepLink.city_id && (cityID = deepLink.city_id);
            deepLink.university_id && (universityID = deepLink.university_id);
        }

        await this.setState({ isLoading: true, errors: {} });

        if(await validator.validate()) {
            const { success, errors } = await this.props.signUp(nickname, password, cityID, universityID);
            if(success) {
                if(this.props.pushToken !== null) {
                   await this.props.registerDevice({
                       token: this.props.pushToken
                   });
                }
                await this.setState({ isLoading: false });

                await wait(200);
                Toast.showFade((
                    <WelcomeFade onComplete={async () => {
                        Toast.hideFade();
                        StatusBar.setHidden(false, 'fade');
                    }} />
                ));

                StatusBar.setHidden(true, 'fade');
                await wait(750);

                return navigation.navigate(screenNames.SIGNED_STACK);
            } else if(errors) {
                validator.setErrors(errors);
                await this.setState({ errors: validator.getIntlErrorsMessages(intl) });
            }
        } else {
            await this.setState({ errors: validator.getIntlErrorsMessages(intl) });
        }

        if(this.state.errors.nickname) {
            this._nicknameInput.focus();
        } else if(this.state.errors.password) {
            this._passwordInput.focus();
        }

        await this.setState({ isLoading: false });
    };

    onPressTerms = async () => {
        const { theme } = this.props;
        await WebBrowser.openBrowserAsync('https://wace.co/terms', {
            toolbarColor: theme.COLORS.BACKGROUND,
            controlsColor: theme.COLORS.TEXT,
            collapseToolbar: true
        });
    };

    onPressPrivacyPolicy = async () => {
        const { theme } = this.props;
        await WebBrowser.openBrowserAsync('https://wace.co/policy', {
            toolbarColor: theme.COLORS.BACKGROUND,
            controlsColor: theme.COLORS.TEXT,
            collapseToolbar: true
        });
    };

    render() {

        const { form, showPassword, isLoading, errors } = this.state;
        const { intl, theme, navigation, styles } = this.props;

        return (
            <KView style={styles.screenWrapper}>
                <View style={styles.header}>
                    <Image blurRadius={10} resizeMode={'cover'} style={styles.cover} source={theme.IMAGES.welcomeBackground} />
                    <View style={styles.headerContent}>
                        <Text style={styles.headingText}>
                            {intl.formatMessage({ 'id': 'sign_up.registration' })} 👇
                        </Text>
                    </View>
                </View>
                <SView style={{ width: '100%', flex: 1 }} pointerEvents="box-none">
                    <View style={{ height: theme.SIZES.SCREEN_HEIGHT * .2 }} pointerEvents={'none'} />
                    <View style={styles.form}>
                        <TextInput
                            style={styles.textInput}
                            placeholder={intl.formatMessage({ 'id': 'sign_up.nickname_placeholder' })}
                            value={form.nickname}
                            variation={'basic'}
                            blurOnSubmit={false}
                            inputRef={component => this._nicknameInput = component}
                            onSubmitEditing={this.onPressSignUp}
                            error={errors.nickname}
                            onChangeText={(nickname) => this.setState({ form: { ...form, nickname } })}
                        />
                        <TextInput
                            style={styles.textInput}
                            secureTextEntry={!showPassword}
                            inputRef={component => this._passwordInput = component}
                            placeholder={intl.formatMessage({ 'id': 'sign_up.password_placeholder' })}
                            icon={showPassword ? 'eye-off' : 'eye'}
                            value={form.password}
                            variation={'basic'}
                            error={errors.password}
                            onSubmitEditing={this.onPressSignUp}
                            onChangeText={(password) => this.setState({ form: { ...form, password } })}
                            onPressIcon={() => this.setState({ showPassword: !showPassword })}
                        />
                        <Button
                            loading={isLoading}
                            onPress={this.onPressSignUp}
                            variation={'primary'}
                            style={styles.submitButton}
                        >
                            {intl.formatMessage({ 'id': 'sign_up.sign_up' })} 🎉
                        </Button>
                        <Text style={styles.agreeText}>
                            {intl.formatMessage({ 'id': 'sign_up.agree_to_our' })} {'\n'}
                            <Text onPress={this.onPressTerms} style={styles.agreeTextLink}>
                                {intl.formatMessage({ 'id': 'sign_up.terms' })}
                            </Text>
                            {` ${intl.formatMessage({ 'id': 'sign_up.and' })} `}
                            <Text onPress={this.onPressPrivacyPolicy} style={styles.agreeTextLink}>
                                {intl.formatMessage({ 'id': 'sign_up.privacy_policy' })}
                            </Text>
                        </Text>
                    </View>
                    <Button
                        variation={'transparent'}
                        onPress={() => navigation.navigate(screenNames.WELCOME)}
                        style={styles.signInButton}
                    >
                        {intl.formatMessage({ 'id': 'sign_up.have_account' })} <Text style={styles.signInLink}>{intl.formatMessage({ 'id': 'sign_up.sign_in' })}</Text>
                    </Button>
                </SView>
            </KView>
        );
    }
}

const mapStateToProps = ({ auth, common }) => ({
    deepLink: common.deepLink,
    pushToken: auth.pushToken,
    city: common.city
});

const mapDispatchToProps = dispatch => ({
    registerDevice: (token) => dispatch(registerDevice(token)),
    signUp: (login, password, cityID, universityID) => dispatch(signUp(login, password, cityID, universityID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(SignUpScreen, styles))
);

