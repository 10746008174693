import { images } from "../../../assets";

const IMAGES = {
    welcomeLogo: images.welcomeLogo,
    welcomeBackground: images.welcomeBackground,
    splash: images.splash,
    homeLogo: images.welcomeLogo,
};

export default IMAGES;
