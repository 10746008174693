import React from 'react';

import { SwipeListView } from 'react-native-swipe-list-view';
import { withStyles } from "../../theme";
import { RefreshControl } from "react-native";

export default withStyles(({ refreshing, onRefresh, theme, ...restProps }) => (
    <SwipeListView {...restProps}
                   useFlatList
                   onEndReachedThreshold={.1}
                   refreshControl={
                       onRefresh
                           ? <RefreshControl refreshing={refreshing} tintColor={theme.COLORS.SECONDARY_TEXT} onRefresh={onRefresh} />
                           : undefined
                   }
    />
), () => ({}));
