import React from 'react';

import { injectIntl } from "react-intl";
import { withStyles } from "../../theme";
import { View, Screen, Text, Icon, Pressable, Loader, SView, Avatar } from '../../components/base';
import { Button, Toast } from "../../components";
import { connect } from "react-redux";
import { connectActionSheet } from '@expo/react-native-action-sheet'
import {
    getMe,
    getUser,
    blockUser,
    unblockUser,
    loadMoreUserChannelMessages,
    loadMoreUserContacts,
    retrieveUserChannelMessages,
    retrieveUserContacts,
    retrieveUserChannels,
    loadMoreUserChannels,
    updateProfileImage
} from "../../state/user/actions";
import { retrieveChannel } from "../../state/channel/actions";
import { isContacts, sendRequest } from "../../state/contact/actions";
import { takePhoto, pickFromGallery } from "../../utils/imagePicker";
import { openLink, shareProfile } from "../../utils/link";
import { openImage } from "../../state/common/actions";
import TopicItem from "./TopicItem";
import SectionHeader from "./SectionHeader";
import TinyHeader from "./TinyHeader";
import styles from "./styles";
import screenNames from "../../navigation/screenNames";
import {Animated, Clipboard} from 'react-native';

class ProfileScreen extends Screen {

    screenName = screenNames.PROFILE;

    state = {
        isLoading: false,
        userID: null,
        isLoadingTopics: false,
        isRefreshing: false,
        scrollY: new Animated.Value(0)
    };

    content() {
        const { userID, isLoading, isLoadingTopics, isRefreshing } = this.state;
        const { theme, styles, users, is_contacts, user_channels, me, navigation, intl } = this.props;
        const isMyProfile = userID === (me && me.id);

        const user = isMyProfile
            ? me
            : (users && users[userID])
                ? users[userID]
                : null;

        const isContacts = is_contacts
            ? is_contacts[userID]
            : null;

        const topics = user_channels && user_channels[userID]
            ? user_channels[userID]
            : null;

        if(!user) {
            return null;
        }
        return (
            <View style={styles.screenWrapper}>
                <SView
                    animated
                    contentContainerStyle={styles.scrollWrapper}
                    scrollEventThrottle={16}
                    onScroll={Animated.event(
                        [{ nativeEvent: { contentOffset: { y: this.state.scrollY } } }],
                        { useNativeDriver: true, listener: this.onScroll }
                    )}
                    isRefreshing={isRefreshing}
                    onRefresh={this.onRefresh}
                >
                    <View style={styles.headerWrapper} pointerEvents="box-none">
                        <Text style={[
                            styles.headingText,
                            ( navigation.getParam('userID', null) ? styles.headingTextOffset : null )
                        ]} numberOfLines={1}>
                            @{user.nickname}
                        </Text>
                        {user.permission === 'admin' ? (
                            <Text style={styles.subHeadingText} numberOfLines={1}>
                                <Icon name={'award'} /> {intl.formatMessage({ id: 'common.admin' })} ({(
                                user.university_name
                                    ? `${user.university_name}, ${user.city_name}`
                                    : `${user.city_name}, ${user.country_name}`
                            )})
                            </Text>
                        ) : user.permission === 'moderator' ? (
                            <Text style={styles.subHeadingText} numberOfLines={1}>
                                <Icon name={'award'} /> {intl.formatMessage({ id: 'common.moderator' })} ({(
                                user.university_name
                                    ? `${user.university_name}, ${user.city_name}`
                                    : `${user.city_name}, ${user.country_name}`
                            )})
                            </Text>
                        ) : (
                            <Text style={styles.subHeadingText} numberOfLines={1}>
                                <Icon name={'map-pin'} /> {(
                                user.university_name
                                    ? `${user.university_name} (${user.city_name}, ${user.country_name})`
                                    : `${user.city_name}, ${user.country_name}`
                            )}
                            </Text>
                        )}
                        {navigation.getParam('userID', null) && (
                            <Pressable onPress={() => navigation.goBack()} style={styles.headerBackButton}>
                                <Icon name={'arrow-left'} color={theme.COLORS.TEXT} size={23} />
                            </Pressable>
                        )}
                        <View style={styles.avatarWrapper}>
                            <Pressable style={styles.avatar} disabled={!user.avatar_bigger && !isMyProfile} onPress={this.onPressAvatar}>
                                <Avatar style={styles.avatar} showCameraIcon={isMyProfile && !user.avatar_bigger} avatarUrl={user.avatar_bigger} />
                            </Pressable>
                            {user.karma !== 0 && (
                                <View style={( user.karma < 0 ? styles.negativeKarma : styles.positiveKarma )}>
                                    <Text style={styles.karmaText}>
                                        {user.karma > 0 ? '+' : ''}{user.karma}
                                    </Text>
                                </View>
                            )}
                        </View>

                        {isMyProfile ? (
                            <View style={styles.headerButtons}>
                                <Button size='small' onPress={this.onPressContacts}>
                                    <Icon name='users' size={14} /> {intl.formatMessage({ id: 'profile.contacts' })}
                                </Button>
                                <Button square size='small' style={{ marginLeft: 10 }} onPress={this.onPressSettings} >
                                    <Icon name='settings' size={14} />
                                </Button>
                                <Button variation='transparent' size='small' onPress={this.onPressShare}  style={{ marginLeft: 6 }}>
                                    <Icon name='share-2' size={14} color={theme.COLORS.SECONDARY_TEXT} />
                                </Button>
                            </View>
                        ) : (
                            <View style={styles.headerButtons}>
                                {isContacts && isContacts.contact ? (
                                    <Button size='small' onPress={this.onPressConversation}>
                                        <Icon name='message-circle' size={14} /> {intl.formatMessage({ id: 'profile.chat' })}
                                    </Button>
                                ) : isContacts && isContacts.request ? (
                                    <Button size='small' disabled>
                                        <Icon name='check' size={14} /> {intl.formatMessage({ id: 'profile.request_sent' })}
                                    </Button>
                                ) : isContacts && !isLoading ? (
                                    <Button size='small' onPress={this.onPressSendRequest}>
                                        <Icon name='plus' size={14} /> {intl.formatMessage({ id: 'profile.add' })}
                                    </Button>
                                ) : (
                                    <Button size='small' loading>
                                        <Icon name='plus' size={14} /> {intl.formatMessage({ id: 'common.loading' })}
                                    </Button>
                                )}
                                <Button square size='small' onPress={this.onPressMore} style={{ marginLeft: 10 }}>
                                    <Icon name='more-horizontal' size={14} />
                                </Button>
                                <Button variation='transparent' size='small' onPress={this.onPressShare} style={{ marginLeft: 6 }}>
                                    <Icon name='share-2' size={14} color={theme.COLORS.SECONDARY_TEXT} />
                                </Button>
                            </View>
                        )}

                        <View style={{ marginTop: 17 }}>
                            {!!user.full_name && (
                                <Text numberOfLines={2} style={{ color: theme.COLORS.TEXT, fontWeight: '700', fontSize: 15, marginVertical: 2.5 }}>
                                    {user.full_name}
                                </Text>
                            )}
                            {!!user.bio && (
                                <SView style={{ maxHeight: theme.SIZES.SCREEN_HEIGHT * .4, marginVertical: 2.5 }} showsVerticalScrollIndicator={false} bounces={false}>
                                    <Text style={{ color: theme.COLORS.SECONDARY_TEXT, fontSize: 15 }}>
                                        {user.bio}
                                    </Text>
                                </SView>
                            )}
                            {!!user.website && (
                                <Pressable onPress={this.onPressWebSite} >
                                    <Text style={{ color: theme.COLORS.TEXT, fontSize: 15, marginVertical: 2.5 }}>
                                        <Icon name='link' size={15} /> {user.website ? user.website.replace('https://', '').replace('http://', '') : ''}
                                    </Text>
                                </Pressable>
                            )}
                        </View>
                    </View>

                    <SectionHeader style={{ marginTop: 20 }} >
                        {intl.formatMessage({ id: 'profile.topics' })} <Text style={styles.messagesCountText}> ({intl.formatMessage({ id: 'profile.messages_count' }, {
                        count: user.messages_count
                    })})</Text>
                    </SectionHeader>

                    <View style={{ paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING }}>
                        {topics ? (
                            topics.length > 0 ? topics.map(topic => (
                                <TopicItem
                                    key={topic.id}
                                    onPress={() => this.navigateToTopic(topic.id)}
                                    coverImageUrl={topic.thumbnail_original}
                                    text={topic.subject}
                                    style={styles.topicItem} />
                            )) : (
                                <View style={styles.noDataPlaceholderWrapper}>
                                    <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'profile.no_active_subscriptions' })}</Text>
                                </View>
                            )
                        ) : (
                            <Loader color={theme.COLORS.SECONDARY_TEXT} size='small' style={styles.topicLoaderItem} />
                        )}
                        {topics && isLoadingTopics && (
                            <Loader color={theme.COLORS.SECONDARY_TEXT} size='small' style={styles.topicLoaderItem} />
                        )}
                    </View>
                </SView>

                <TinyHeader scrollY={this.state.scrollY}>
                    {navigation.getParam('userID', null) && (
                        <Pressable onPress={() => navigation.goBack()} style={styles.tinyHeaderBackButton}>
                            <Icon name={'arrow-left'} color={theme.COLORS.TEXT} size={23} />
                        </Pressable>
                    )}
                    <View style={[
                        styles.tinyHeaderContentWrapper,
                        ( navigation.getParam('userID', null) ? styles.tinyHeaderContentWrapperOffset : null )
                    ]}>
                        <Avatar style={styles.tinyHeaderAvatar} avatarUrl={user.avatar_normal} />
                        <Text style={styles.tinyHeaderNickname} numberOfLines={1}>
                            @{user.nickname}
                        </Text>
                    </View>
                    {isMyProfile ? (
                        <Pressable onPress={this.onPressSettings} style={styles.tinyHeaderMoreButton}>
                            <Icon name={'settings'} color={theme.COLORS.TEXT} size={19} />
                        </Pressable>
                    ) : (
                        <Pressable onPress={this.onPressMore} style={styles.tinyHeaderMoreButton}>
                            <Icon name={'more-horizontal'} color={theme.COLORS.TEXT} size={23} />
                        </Pressable>
                    )}
                </TinyHeader>
            </View>
        );
    }

    onNavigate = async () => {
        const { navigation, me, isLoggedIn } = this.props;
        await this.setState({
            userID: navigation.getParam('userID', isLoggedIn ? me.id : 1)
        });
        await this.loadData();
    };

    onScroll = ({ nativeEvent }) => {
        if(nativeEvent.contentOffset.y < 0) return;
        const { layoutMeasurement, contentOffset, contentSize } = nativeEvent,
            isCloseToBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height - 50;
        if(isCloseToBottom !== this._isCloseToBottom && isCloseToBottom === true) {
            this.onEndReached();
        }
        this._isCloseToBottom = isCloseToBottom;
    };

    loadData = async () => {
        const { userID } = this.state;
        const { me, isLoggedIn } = this.props;

        if(!isLoggedIn) {
            await this.retrieveData([
                [ 'getUser', userID ]
            ]);
            await this.retrieveData([
                [ 'retrieveUserChannels', userID ]
            ], 'isLoadingTopics');
            return;
        }

        if(isLoggedIn && userID !== me.id) {
            await this.retrieveData([
                [ 'isContacts', userID ],
                [ 'getUser', userID ]
            ]);
            await this.retrieveData([
                [ 'retrieveUserChannels', userID ]
            ], 'isLoadingTopics');
        } else {
            await this.retrieveData([
                [ 'retrieveUserChannels', userID ]
            ], 'isLoadingTopics');
        }
    };

    onRefresh = async () => {
        const { userID } = this.state;
        const { me } = this.props;

        if(userID !== me.id) {
            await this.retrieveData([
                [ 'isContacts', userID ],
                [ 'getUser', userID ],
                [ 'retrieveUserChannels', userID ]
            ], 'isRefreshing');
        } else {
            await this.retrieveData([
                [ 'getMe' ],
                [ 'retrieveUserChannels', userID ]
            ], 'isRefreshing');
        }
    };

    onEndReached = async () => {
        const { userID, isLoadingTopics } = this.state;
        if(isLoadingTopics) return;
        await this.retrieveData([
            'loadMoreUserChannels', userID
        ], 'isLoadingTopics');
    };

    onPressSendRequest = async () => {
        const { userID } = this.state;
        await this.setState({ isLoading: true });
        await this.props.sendRequest(userID, 'add me!');
        await this.setState({ isLoading: false });
    };

    onPressSettings = async () => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.SETTINGS);
    };

    onPressContacts = async () => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.CONTACTS);
    };

    onPressMore = async () => {
        const { userID } = this.state,
            { users, intl } = this.props;

        const user = users[userID];

        this.actionSheet([ {
            label: intl.formatMessage({ id: 'profile.unblock_user' }),
            onPress: this.onUnblock,
            visible: !!user.blocked
        }, {
            label: intl.formatMessage({ id: 'profile.block_user' }),
            onPress: this.onBlock,
            destructive: true,
            visible: !user.blocked
        }, {
            label: intl.formatMessage({ id: 'common.cancel' })
        } ]);
    };

    onBlock = async () => {
        const { intl } = this.props;
        await this.setState({ showLoader: true });
        await this.props.blockUser(this.state.userID);
        Toast.showSimpleSuccess(intl.formatMessage({ id: 'profile.user_blocked' }));
        await this.setState({ showLoader: false });
        this.props.navigation.navigate(screenNames.FEED);
    };

    onUnblock = async () => {
        const { intl } = this.props;
        await this.setState({ showLoader: true });
        await this.props.unblockUser(this.state.userID);
        Toast.showSimpleSuccess(intl.formatMessage({ id: 'profile.user_unblocked' }));
        await this.setState({ showLoader: false });
    };

    onPressConversation = async () => {
        const { userID } = this.state;
        const { navigation, is_contacts } = this.props;

        const contactID = is_contacts
            && is_contacts[userID]
            && is_contacts[userID].contact;

        contactID && navigation.navigate(screenNames.CONVERSATION, {
            contactID
        });
    };

    onPressShare = async () => {
        const { userID } = this.state;
        const { intl, users, me } = this.props;
        const isMyProfile = userID === me.id;

        const user = isMyProfile
            ? me
            : (users && users[userID])
                ? users[userID]
                : null;

        if(user) {
            Clipboard.setString(`https://wace.co/u/${user.nickname}`);
            Toast.showSimpleSuccess(intl.formatMessage({ id: 'common.copied_to_clipboard' }));
            // await shareProfile(user.nickname);
        }
    };

    onPressWebSite = async () => {
        const { userID } = this.state;
        const { users, me, theme } = this.props;
        const isMyProfile = userID === me.id;
        const user = isMyProfile
            ? me
            : (users && users[userID])
                ? users[userID]
                : null;
        if(!user) {
            return;
        }
        await openLink(user.website, theme)
    };

    onPressAvatar = async () => {
        const { userID } = this.state;
        const { users, me, intl } = this.props;
        const isMyProfile = userID === me.id;

        const user = isMyProfile
            ? me
            : (users && users[userID])
                ? users[userID]
                : null;
        if(!user) {
            return;
        }
        if(!isMyProfile) {
            this.props.openImage(user.avatar_original)
        } else {
            this.actionSheet([ {
                label: intl.formatMessage({ id: 'profile.open_image' }),
                onPress: () => {
                    this.props.openImage(user.avatar_original)
                },
                visible: !!user.avatar_original
            }, {
                label: !user.avatar_original
                    ? intl.formatMessage({ id: 'profile.add_photo' })
                    : intl.formatMessage({ id: 'profile.change_photo' }),
                onPress: () => {
                    this.actionSheet([ {
                        label: intl.formatMessage({ id: 'common.open_camera' }),
                        onPress: async () => {
                            const imageUri = await takePhoto({ resizeCoefficient: .5, compressRatio: .8 });
                            if(imageUri) {
                                await this.uploadProfilePicture(imageUri);
                            }
                        }
                    }, {
                        label: intl.formatMessage({ id: 'common.choose_from_gallery' }),
                        onPress: async () => {
                            const imageUri = await pickFromGallery({ resizeCoefficient: .5, compressRatio: .8 });
                            if(imageUri) {
                                await this.uploadProfilePicture(imageUri);
                            }
                        }
                    } , {
                        label: intl.formatMessage({ id: 'common.cancel' })
                    } ]);
                }
            } , {
                label: intl.formatMessage({ id: 'common.cancel' })
            } ]);
        }
    };

    /**
     * @param imageUri
     * @returns {Promise<void>}
     */
    uploadProfilePicture = async (imageUri) => {
        this.setState({ showLoader: true });
        const { success } = await this.props.updateProfileImage(imageUri);
        this.setState({ showLoader: false });
    }
}


const mapStateToProps = ({ user, contact, auth }) => ({
    me: user.me,
    user_channels: user.user_channels,
    users: user.users,
    is_contacts: contact.is_contacts,
    isLoggedIn: auth.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
    openImage: (image) => dispatch(openImage(image)),
    getMe: (userID) => dispatch(getMe(userID)),
    getUser: (userID) => dispatch(getUser(userID)),
    blockUser: (userID) => dispatch(blockUser(userID)),
    unblockUser: (userID) => dispatch(unblockUser(userID)),
    sendRequest: (userID, message) => dispatch(sendRequest(userID, message)),
    isContacts: (userID) => dispatch(isContacts(userID)),
    loadMoreUserContacts: (userID) => dispatch(loadMoreUserContacts(userID)),
    retrieveUserContacts: (userID) => dispatch(retrieveUserContacts(userID)),
    updateProfileImage: (imageUri) => dispatch(updateProfileImage(imageUri)),
    retrieveUserChannelMessages: (userID) => dispatch(retrieveUserChannelMessages(userID)),
    loadMoreUserChannelMessages: (userID) => dispatch(loadMoreUserChannelMessages(userID)),
    retrieveUserChannels: (userID) => dispatch(retrieveUserChannels(userID)),
    loadMoreUserChannels: (userID) => dispatch(loadMoreUserChannels(userID)),
    retrieveChannel: (channelID) => dispatch(retrieveChannel(channelID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    connectActionSheet(injectIntl(withStyles(ProfileScreen, styles)))
);

