import React from 'react';

import { Screen, Text, View, Loader, Image, List } from "../../components/base";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withStyles } from "../../theme";
import styles from "./styles";
import TopicItem from "./TopicItem";
import screenNames from "../../navigation/screenNames";
import { retrieveListChannels, loadMoreListChannels, retrieveChannel, voteChannel } from "../../state/channel/actions";
import { updateNewChannelsLastVisit } from "../../state/common/actions";
import Events from '../../utils/events';
import OptionsModal from "../TopicScreen/OptionsModal";
import { connectActionSheet } from "@expo/react-native-action-sheet";
import SectionHeader from './SectionHeader';
import SignModal from './SignModal';
import {Button} from "../../components";

class StartScreen extends Screen {

    screenName = screenNames.START;

    state = {
        isLoadingNew: true,
        isLoadingMoreNew: null,
        isRefreshingNew: false,
        loadingVoteTopicID: null,
        isOptionsModalOpened: false,
        optionsTopic: null,
        isOpenedRegistration: false
    };

    content() {

        const { isLoadingMoreNew, isLoadingNew, isRefreshingNew, loadingVoteTopicID, isOpenedRegistration } = this.state;
        const { styles, theme, lastVisits, navigation, country, city, university, intl, me } = this.props;

        return (
            <View style={styles.screenWrapper}>
                <View style={styles.headerWrapper}>
                    {university ? (
                        <Text style={styles.headingText}>{university.short_name}</Text>
                    ) : (
                        <Text style={styles.headingText}>{city.short_name}</Text>
                    )}
                    {university ? (
                        <Text style={styles.subHeadingText}>{city.short_name}, {country.short_name}</Text>
                    ) : (
                        <Text style={styles.subHeadingText}>{country.long_name}</Text>
                    )}

                    <Image source={theme.IMAGES.homeLogo} style={{
                        position: 'absolute',
                        right: 0,
                        top: 11,
                        width: 46.76,
                        height: 40
                    }} />
                </View>
                <SectionHeader />
                {isLoadingNew ? (
                    <View style={styles.sceneLoaderWrapper}>
                        <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                        <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                    </View>
                ) : (
                    <List
                        contentContainerStyle={{ paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING }}
                        refreshing={isRefreshingNew}
                        onRefresh={this.onRefreshNew}
                        data={this.props.channelsList[LIST_TYPE_NEW]}
                        renderItem={({ item }) => (
                            <TopicItem
                                {...item}
                                style={styles.topicItem}
                                isSafe={(me && me.restricted_mode) || item.myReport}
                                loadingVoteTopicID={loadingVoteTopicID}
                                lastVisit={lastVisits[item.id]}
                                onPress={() => this.openRegistration(item) }
                                onLongPress={() => this.openRegistration(item)}
                                onPressVoteUp={this.openRegistration}
                                onPressVoteDown={this.openRegistration}
                            />
                        )}
                        ListHeaderComponent={(
                            <Button style={styles.topicItem} variation={'primary'} size={'medium'} onPress={this.openRegistration}>
                                {intl.formatMessage({ 'id': 'sign_up.sign_up' })} 👈
                            </Button>
                        )}
                        ListFooterComponent={(
                            <View style={styles.loaderWrapper}>
                                {isLoadingMoreNew && (
                                    <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                )}
                            </View>
                        )}
                        ListEmptyComponent={(
                            <View style={styles.noDataPlaceholderWrapper}>
                                <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'home.no_topics' })}</Text>
                            </View>
                        )}
                        onEndReached={this.loadMoreNew}
                        keyExtractor={item => item.id.toString()}
                    />
                )}
                <OptionsModal
                    visible={this.state.isOptionsModalOpened}
                    channel={this.state.optionsTopic}
                    showReport={this.showReport}
                    onClose={() => this.setState({ isOptionsModalOpened: false })}
                />
                <SignModal
                    visible={isOpenedRegistration}
                    onClose={() => this.setState({
                        isOpenedRegistration: false
                    })}
                />
            </View>
        );
    }

    onNavigate = async () => {
        if(this.props.channelsList[LIST_TYPE_NEW].length > 0) return;
        await this.loadDataNew();
    };

    componentDidMount() {
        super.componentDidMount();
        this.locationListener = Events.subscribe(Events.Types.CHANGE_LOCATION, async () => {
            await this.loadDataNew();
            await this.loadDataTop();
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.locationListener.remove();
    }

    onPressVoteUp = async (topicID) => {
        await this.setState({ loadingVoteTopicID: topicID });
        await this.props.voteChannel(topicID, 'up');
        await this.setState({ loadingVoteTopicID: null });
    };

    onPressVoteDown = async (topicID) => {
        await this.setState({ loadingVoteTopicID: topicID });
        await this.props.voteChannel(topicID, 'down');
        await this.setState({ loadingVoteTopicID: null });
    };


    loadDataNew = async () => {
        await this.retrieveData([
            ['retrieveListChannels', LIST_TYPE_NEW, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()],
            ['updateNewChannelsLastVisit', this.props.city.id + '_' + (this.props.university ? this.props.university.id : 'null'), this.props.channels_count]
        ], 'isLoadingNew');
    };

    onRefreshNew = async () => {
        await this.retrieveData([
            ['retrieveListChannels', LIST_TYPE_NEW, this.props.city.id, (this.props.university ? this.props.university.id : 'null'), this.safe()],
            ['updateNewChannelsLastVisit', this.props.city.id + '_' + (this.props.university ? this.props.university.id : 'null'), this.props.channels_count]
        ], 'isRefreshingNew');
    };

    loadMoreNew = async () => {
        if(this.state.isLoadingNew || this.state.isLoadingMoreNew) return;
        await this.retrieveData([
            'loadMoreListChannels', LIST_TYPE_NEW, this.props.city.id, (this.props.university ? this.props.university.id : 'null'),  this.safe()
        ], 'isLoadingMoreNew');
    };

    safe = () => {
        return (this.props.me && this.props.me.restricted_mode ? 1 : 0);
    };

    onPressTopic = async (topic) => {
        return this.navigateToTopic(topic.id)
    };

    onLongPressTopic = async (topic) => this.setState({
        optionsTopic: topic,
        isOptionsModalOpened: true
    });

    openRegistration = () => this.setState({
        isOpenedRegistration: true
    });
}

const LIST_TYPE_NEW = 'feed';

const mapStateToProps = ({ common, user, channel }) => ({
    me: user.me,
    channelsList: channel.list,
    lastVisits: channel.lastVisits,
    newChannelsLastVisits: common.newChannelsLastVisits,
    country: common.country,
    city: common.city,
    channels_count: common.channels_count,
    university: common.university,
    ignoreList: user.ignoreList
});

const mapDispatchToProps = dispatch => ({
    retrieveChannel: (topicID) => dispatch(retrieveChannel(topicID)),
    retrieveListChannels: (type, cityID, universityID, safe) => dispatch(retrieveListChannels(type, cityID, universityID, safe)),
    loadMoreListChannels: (type, cityID, universityID, safe) => dispatch(loadMoreListChannels(type, cityID, universityID, safe)),
    updateNewChannelsLastVisit: (key, count) => dispatch(updateNewChannelsLastVisit(key, count)),
    voteChannel: (channelID, type) => dispatch(voteChannel(channelID, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    connectActionSheet(injectIntl(withStyles(StartScreen, styles)))
);

