import React, { PureComponent } from 'react';

import { Text, View, Avatar, Pressable, Icon } from '../../components/base';
import { withStyles } from "../../theme";
import {injectIntl} from "react-intl";

class NearbyUserItem extends PureComponent {

    render() {

        const {
            intl,
            theme,
            styles,
            style,
            onPress,
            avatar_bigger,
            nickname,
            full_name,
            karma,
            country_name,
            city_name,
            university_name,
            permission,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                <Pressable onPress={onPress} style={styles.content}>
                    <Avatar style={styles.avatarImage} avatarUrl={avatar_bigger} />
                    <View style={styles.textContent}>
                        <Text style={styles.titleText} numberOfLines={1}>
                            @{nickname}
                            {!permission && (
                                <Text style={{ color: theme.COLORS.SECONDARY_TEXT }}> {karma > 0 ? '+' + karma : karma < 0 ? '–' + Math.abs(karma) : null}</Text>
                            )}
                            {permission === 'admin' && (
                                <Text style={{ color: theme.COLORS.SECONDARY_TEXT, fontWeight: '700', fontSize: 13 }}> <Icon size={14} name={'award'} /> {intl.formatMessage({ id: 'common.admin' })}</Text>
                            )}
                            {permission === 'moderator' && (
                                <Text style={{ color: theme.COLORS.SECONDARY_TEXT, fontWeight: '700', fontSize: 13 }}> <Icon size={14} name={'award'} /> {intl.formatMessage({ id: 'common.moderator' })}</Text>
                            )}
                        </Text>
                        <Text style={styles.subTitleText} numberOfLines={1}>
                            {full_name}
                        </Text>
                        {false && (
                            <Text style={styles.karmaText}>
                                { ( university_name !== null ? `${university_name} (${city_name}, ${country_name})` : `${city_name}, ${country_name}` ) }
                            </Text>
                        )}
                    </View>
                </Pressable>
            </View>
        );
    }
}

export default injectIntl(withStyles(NearbyUserItem, theme => ({
    wrapper: {
        overflow: 'hidden',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 17.5
    },
    avatarImage: {
        width: 50,
        height: 50,
        marginRight: 10,
        borderRadius: 17.5
    },
    textContent: {
        marginVertical: 0,
        flex: 1
    },
    titleText: {
        color: theme.COLORS.TEXT,
        fontSize: 16,
        lineHeight: 25,
        height: 25,
        fontWeight: '900',
        textAlign: 'left'
    },
    subTitleText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 14,
        lineHeight: 20,
        height: 20,
        fontWeight: '500',
        textAlign: 'left'
    },
    karmaText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 11,
        position: 'absolute',
        top: 0,
        right: 0
    },
    content: {
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 12.5,
        flexDirection: 'row',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    }
})));
