import React from 'react';

import { createStackNavigator } from 'react-navigation-stack';

import screens from '../screens';
import screenNames from './screenNames';

export default createStackNavigator({
    [screenNames.SEARCH]: screens.SearchScreen,
    [screenNames.SETTINGS]: screens.SettingsScreen,
    [screenNames.CONTACTS]: screens.ContactsScreen,
    [screenNames.CONTACTS_REQUESTS]: screens.ContactsRequestsScreen,
    [screenNames.PROFILE]: screens.ProfileScreen,
}, {
    initialRouteName: screenNames.SEARCH,
    headerMode: 'none',
    defaultNavigationOptions: {
        header: null,
        cardShadowEnabled: false,
        cardOverlayEnabled: false,
        gesturesEnabled: true,
        cardTransparent: true
    },
});
