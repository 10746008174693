import { Platform } from 'react-native';

import * as SecureStore from 'expo-secure-store';
import storage from '../utils/keyValueStorage';

const STORAGE_KEY = 'userSession',
    OPTIONS = {},
    FALLBACK_TO_KEY_VALUE_STORAGE = Platform.OS === 'web' || (Platform.OS === 'android' && Platform.Version <= 22);

let _session = null;
export default {

    /**
     * @returns {Promise<*>}
     */
    async get() {
        if(_session !== null) {
            return _session;
        }
        let result;
        if(FALLBACK_TO_KEY_VALUE_STORAGE) {
            result = await storage.get(STORAGE_KEY, null);
        } else {
            result = await SecureStore.getItemAsync(STORAGE_KEY, OPTIONS);
        }
        if(result !== null) {
            _session = JSON.parse(result);
        }
        return _session;
    },

    /**
     * @returns {Promise<boolean>}
     */
    async isLoggedIn() {
        const session = await this.get();
        return session !== null;
    },

    /**
     * @param session
     * @returns {Promise<boolean|void>}
     */
    async set(session) {
        _session = session;
        if(FALLBACK_TO_KEY_VALUE_STORAGE) {
            return await storage.set(STORAGE_KEY, JSON.stringify(session));
        } else {
            return await SecureStore.setItemAsync(STORAGE_KEY, JSON.stringify(session), OPTIONS);
        }
    },

    /**
     * @returns {Promise<Promise<any>|Promise<void>>}
     */
    async delete() {
        _session = null;
        if(FALLBACK_TO_KEY_VALUE_STORAGE) {
            return storage.remove(STORAGE_KEY);
        } else {
            return SecureStore.deleteItemAsync(STORAGE_KEY, OPTIONS);
        }
    }
};
