import React, { PureComponent } from 'react';

import { Text, View, Avatar, Pressable } from '../../components/base';
import { withStyles } from "../../theme";
import {injectIntl} from "react-intl";

class MemberItem extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            intl,
            onPress,
            user_avatar_bigger,
            user_nickname,
            ban_expires_at,
            ban_id,
            user_id,
            created_at,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                <Pressable onPress={this.onPress} style={styles.content}>
                    <Avatar style={styles.avatarImage} avatarUrl={user_avatar_bigger} />
                    <View style={styles.textContent}>
                        <Text style={styles.titleText} numberOfLines={1}>
                            @{user_nickname}
                        </Text>
                        <Text style={styles.subTitleText} numberOfLines={1}>
                            {intl.formatMessage({ id: 'topic_members.joined' })} {intl.formatRelative(created_at)}
                        </Text>
                        <Text style={styles.karmaText}>
                            {ban_id ? intl.formatMessage({ id: 'topic_members.banned' }) : ''}
                        </Text>
                    </View>
                </Pressable>
            </View>
        );
    }

    onPress = () => {
        this.props.onPress(this.props);
    };
}

export default injectIntl(withStyles(MemberItem, theme => ({
    wrapper: {
        overflow: 'hidden',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 17.5
    },
    avatarImage: {
        width: 50,
        height: 50,
        marginRight: 10,
        borderRadius: 17.5
    },
    textContent: {
        marginVertical: 0,
        flex: 1
    },
    titleText: {
        color: theme.COLORS.TEXT,
        fontSize: 16,
        lineHeight: 25,
        height: 25,
        fontWeight: '900',
        textAlign: 'left'
    },
    subTitleText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 14,
        lineHeight: 20,
        height: 20,
        fontWeight: '500',
        textAlign: 'left'
    },
    karmaText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 11,
        position: 'absolute',
        top: 0,
        right: 0
    },
    content: {
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 12.5,
        flexDirection: 'row',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    }
})));
