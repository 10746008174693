export default (theme) => ({
    screenWrapper: {
        flex: 1,
        backgroundColor: theme.COLORS.BACKGROUND
    },
    headerWrapper: {
        marginTop: theme.SIZES.TOP_MARGIN,
        marginHorizontal: theme.SIZES.HEADER_HORIZONTAL_PADDING
    },
    headerBackButton: {
        width: 35,
        height: 35,
        position: 'absolute',
        left: 0,
        top: 0,
        justifyContent: 'center',
    },
    headingText: {
        color: theme.COLORS.TEXT,
        fontWeight: '900',
        fontSize: 25,
        height: 35,
        lineHeight: 35
    },
    headingTextOffset: {
        marginLeft: 30
    },
    subHeadingText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        fontSize: 15
    },
    contentWrapper: {
        paddingBottom: 15,
        marginHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },
    resultItem: {
        marginTop: 15
    },
    loaderWrapper: {
        width: '100%',
        height: 0
    },

    loader: {
        marginTop: 25,
        marginBottom: 10
    },
    noDataPlaceholderWrapper: {
        marginTop: 15,
        width: '100%',
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        borderRadius: 17.5,
        opacity: 0.65,
    },

    noDataPlaceholderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        fontSize: 16,
        lineHeight: 30,
        marginTop: -3
    },
    sceneLoaderWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    sceneLoader: {
        marginBottom: 8
    },
    sceneLoaderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        opacity: .7,
        fontSize: 9
    },
});
