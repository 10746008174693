import React from 'react';

import { ScrollView, Animated, RefreshControl } from 'react-native';
import { withStyles } from "../../theme";

export default withStyles((({ animated, isRefreshing, onRefresh, theme, ...restProps }) => !animated ? (
    <ScrollView {...restProps} refreshControl={
        onRefresh
            ? <RefreshControl refreshing={isRefreshing} tintColor={theme.COLORS.SECONDARY_TEXT} onRefresh={onRefresh} />
            : undefined
    } />
) : (
    <Animated.ScrollView {...restProps} refreshControl={
        onRefresh
            ? <RefreshControl refreshing={isRefreshing} tintColor={theme.COLORS.SECONDARY_TEXT} onRefresh={onRefresh} />
            : undefined
    } />)), () => ({}));
