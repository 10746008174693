import { ifIphoneX } from "react-native-iphone-x-helper";
export default (theme) => ({
    screenWrapper: {
        // ...ifIphoneX({
        //     marginTop: 40,
        //     borderBottomWidth: 10,
        // }, {
        //     borderBottomWidth: 0,
        //     marginTop: 30
        // }),
        flex: 1,
        // borderTopLeftRadius: 25,
        // borderTopRightRadius: 25,
        backgroundColor: theme.COLORS.BACKGROUND,
        borderBottomColor: theme.COLORS.BACKGROUND_ITEM
    },
    tickerText: {
        fontSize: 12,
        fontWeight: '700',
        color: theme.COLORS.TEXT,
    },
    tickerTextPositive: {
        color: theme.COLORS.SUCCESS
    },
    tickerTextNegative: {
        color: theme.COLORS.DANGER
    },
    topLineWrapper: {
        flex: 1,
        flexDirection: 'row'
    },
    messageCircleItem: {
        marginRight: 10
    },
    messagesListAsc: {
        flex: 1,
        marginTop: 50
    },
    messagesWrapperAsc: {
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        marginTop: theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT - 75,
        paddingBottom: theme.SIZES.TOPIC_HEADER_EXPANDED_HEIGHT - 40,
        width: '100%',
    },
    messagesListDesc: {
        flex: 1,
        marginTop: 10
    },
    messagesWrapperDesc: {
        paddingTop: 30,
        paddingBottom: theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT - 10
    },
    topWrapper: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING + 7,
        marginTop: 25,
    },
    topWrapperRoundedBackground: {
        position: 'absolute',
        top: -20,
        left: 0,
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        width: theme.SIZES.SCREEN_WIDTH,
        height: (theme.SIZES.SCREEN_HEIGHT * .6) + 20,
        backgroundColor: theme.COLORS.BACKGROUND,
    },
    messageItem: {
        marginVertical: 10
    },
    voteWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: -10,
        marginRight: -10
    },
    messagesCountText: {
        fontSize: 12,
        fontWeight: '700',
        color: theme.COLORS.TEXT
    },

    noDataPlaceholderWrapper: {
        marginTop: 15,
        paddingHorizontal: 20,
        width: '100%',
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },

    noDataPlaceholderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 30,
        marginTop: -3,
        textAlign: 'center'
    },

    sceneLoaderWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    sceneLoader: {
        marginBottom: 8
    },
    sceneLoaderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        opacity: .7,
        fontSize: 9
    },
    loaderWrapper: {
        width: '100%',
        height: 0
    },
    loader: {
        marginTop: 25,
        marginBottom: 10
    },
    loaderDesc: {
        marginTop: -10
    }
});
