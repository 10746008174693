import React, { PureComponent } from 'react';

import { Text, View, Image, Avatar, Icon, Pressable, Hyperlink } from '../../components/base';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";

class MessageItem extends PureComponent {

    render() {

        const {
            styles,
            style,
            author_avatar_normal,
            author_nickname,
            intl,
            created_at
        } = this.props;

        return (
            <Pressable onPress={this.onPress} onLongPress={this.onLongPress} style={[
                styles.wrapper,
                style
            ]}>
                <Pressable onPress={this.onPressAvatar} style={{ position: 'absolute', left: -36, top: 0 }}>
                    <Avatar style={{ width: 36, height: 36, borderRadius: 15 }} avatarUrl={author_avatar_normal} />
                </Pressable>
                <View style={styles.headerWrapper}>
                    <Text style={styles.messageHeaderText}>@{author_nickname}</Text>
                    <Text style={styles.messageHeaderDateText}>{intl.formatRelative(created_at, true)}</Text>
                </View>
                {this.renderContent()}
            </Pressable>
        );
    }

    renderContent = () => {

        const {
            styles,
            message,
            extra_type,
            reported,
            isSafe,
            isOpened,
            intl
        } = this.props;

        const extra = ( extra_type === 2 && this.props.extra )
            ? JSON.parse(this.props.extra)
            : {};

        return reported && isSafe && !isOpened ? (
            <View style={[styles.contentWrapper]}>
                <Text style={styles.messageText}>[{intl.formatMessage({ id: 'common.unsafe_message' })}]</Text>
            </View>
        ) : extra_type === 1 ? (
            <View style={[styles.contentWrapper]}>
                <Pressable onPress={this.onScaleImage} style={styles.imageMessageWrapper}>
                    <View style={styles.imagePlaceholder} />
                    <Image style={styles.imageMessage} source={{ uri: message }} />
                </Pressable>
            </View>
        ) : extra_type === 2 && extra.r && extra.i ? (
            <View style={[styles.contentWrapper]}>
                <Hyperlink onPress={this.props.onPressLink} linkStyle={styles.messageLinkText}>
                    <Text style={styles.messageText}>{message}</Text>
                </Hyperlink>
                <Pressable onPress={this.openLink} style={styles.bigExtraImageMessageWrapper}>
                    <View style={styles.bigExtraImagePlaceholder} />
                    <Image style={styles.bigExtraImageMessage} source={{ uri: extra.i }} />
                </Pressable>
                <Text style={styles.bigExtraImageDescription} numberOfLines={1}>
                    {this.renderBigExtraIcon(extra)} {extra.t}
                </Text>
            </View>
        ) : extra_type === 2 ? (
            <View style={[styles.contentWrapper]}>
                <Hyperlink onPress={this.props.onPressLink} linkStyle={styles.messageLinkText}>
                    <Text style={styles.messageText}>{message}</Text>
                </Hyperlink>
                <Pressable onPress={this.openLink} style={styles.extraMessageWrapper}>
                    {!!extra.i && (
                        <Image style={styles.extraImage} source={{ uri: extra.i }} />
                    )}
                    <View style={styles.extraContentWrapper}>
                        {!!extra.t && (
                            <Text style={styles.extraTitle} numberOfLines={1}> {extra.t} </Text>
                        )}
                        {!!extra.d && (
                            <Text style={styles.extraDescription} numberOfLines={1}> {extra.d} </Text>
                        )}
                    </View>
                </Pressable>
            </View>
        ) : (
            <View style={[styles.contentWrapper]}>
                <Hyperlink onPress={this.props.onPressLink} linkStyle={styles.messageLinkText}>
                    <Text style={styles.messageText}>{message}</Text>
                </Hyperlink>
            </View>
        );
    };

    renderBigExtraIcon = (extra) => {
        if(extra.u && /youtu/.test(extra.u)) {
            return <Icon name={'youtube'} color={'#ff3654'} />
        }
        if(extra.u && /instagram/.test(extra.u)) {
            return <Icon name={'instagram'} color={'#ff3654'} />
        }
        return null;
    };

    onPress = () => {
        this.props.onPress(this.props);
    };

    onLongPress = () => {
        this.props.onLongPress(this.props);
    };

    onVoteDown = () => {
        this.props.onVoteDown(this.props);
    };

    onVoteUp = () => {
        this.props.onVoteUp(this.props);
    };

    onPressAvatar = () => {
        this.props.onPressAvatar(this.props);
    };

    onNewReply = () => {
        this.props.onNewReply(this.props);
    };

    onScaleImage = () => {
        this.props.onPressImage(this.props);
    };

    openLink = () => {
        const extra = this.props.extra_type === 2 && this.props.extra ? JSON.parse(this.props.extra) : {};
        if(extra.u) {
            this.props.onPressLink(extra.u);
        }
    };
}

export default injectIntl(withStyles(MessageItem, theme => ({
    wrapper: {
        marginLeft: 36 + theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },
    headerWrapper: {
        marginLeft: 10,
        marginRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },
    contentWrapper: {
        marginLeft: 10,
        marginTop: 3,
        paddingRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },

    messageHeaderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '700',
        fontSize: 13
    },

    messageHeaderDateText: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '300',
        fontSize: 12
    },

    messageText: {
        color: theme.COLORS.TEXT,
        fontSize: 13
    },

    messageLinkText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 13
    },

    karmaText: {
        width: 36,
        textAlign: 'center',
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 10,
        fontWeight: '700',
        marginTop: 2
    },

    karmaTextBad: {
        color: theme.COLORS.DANGER,
    },

    imageMessageWrapper: {
        overflow: 'hidden',
        width: theme.SIZES.SCREEN_WIDTH * .33,
        height: theme.SIZES.SCREEN_WIDTH * .33,
        borderRadius: 17.5
    },
    imagePlaceholder: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },
    imageMessage: {
        flex: 1,
        width: null,
        height: null
    },

    bigExtraImageMessageWrapper: {
        marginTop: 3,
        overflow: 'hidden',
        width: theme.SIZES.SCREEN_WIDTH * .33,
        height: theme.SIZES.SCREEN_WIDTH * .23,
        borderRadius: 17.5
    },
    bigExtraImagePlaceholder: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },
    bigExtraImageMessage: {
        flex: 1,
        width: null,
        height: null
    },
    bigExtraImageDescription: {
        fontSize: 13,
        color: theme.COLORS.TEXT,
        width: theme.SIZES.SCREEN_WIDTH * .33,
        fontWeight: '700'
    },

    extraMessageWrapper: {
        marginTop: 5,
        alignItems: 'center',
        padding: 10,
        flexDirection: 'row',
        borderRadius: 9,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },

    extraImage: {
        width: 40,
        height: 40,
        borderRadius: 9,
        marginRight: 5
    },

    extraContentWrapper: {
        flex: 1,
        justifyContent: 'center'
    },

    extraTitle: {
        fontSize: 14,
        color: theme.COLORS.TEXT,
        fontWeight: '700'
    },

    extraDescription: {
        fontSize: 13,
        color: theme.COLORS.SECONDARY_TEXT
    },

    actionsWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        height: 30,
        marginTop: 10,
        marginRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING
    },

    replyButton: {
        marginLeft: 7.5,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 5,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        borderRadius: 8
    },
    ratingText: {
        fontSize: 14,
        lineHeight: 30,
        color: theme.COLORS.TEXT,
        fontWeight: '500'
    },
    ratingTextWrapper: {
        alignSelf: 'center',
        paddingHorizontal: 10
    },
    iconButtonWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 6,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        borderRadius: 8
    },
    iconButton: {
        color: theme.COLORS.SECONDARY_TEXT,
        paddingHorizontal: 10,
        fontSize: 15
    },
    iconUp: {
        color: theme.COLORS.SUCCESS
    },
    iconDown: {
        color: theme.COLORS.DANGER
    },
    goodRating: {
        marginTop: 2,
        marginLeft: 10,
        alignSelf: 'flex-start',
        backgroundColor: theme.COLORS.SUCCESS,
        borderRadius: 5,
        paddingHorizontal: 3,
        paddingVertical: 1,
        opacity: 0.9
    },
    badRating: {
        marginTop: 2,
        marginLeft: 10,
        alignSelf: 'flex-start',
        backgroundColor: theme.COLORS.DANGER,
        borderRadius: 5,
        paddingHorizontal: 3,
        paddingVertical: 1,
        opacity: 0.9
    },
    ratingMiniText: {
        fontSize: 11,
        color: theme.COLORS.TEXT,
        fontWeight: '500'
    }
})));
