import { ifIphoneX } from "react-native-iphone-x-helper";

export default (theme) => ({
    screenWrapper: {
        // ...ifIphoneX({
        //     marginTop: 40,
        //     borderBottomWidth: 10,
        // }, {
        //     borderBottomWidth: 0,
        //     marginTop: 30
        // }),
        flex: 1,
        // borderTopLeftRadius: 25,
        // borderTopRightRadius: 25,
        backgroundColor: theme.COLORS.BACKGROUND,
        borderBottomColor: theme.COLORS.BACKGROUND_ITEM
    },
    messagesList: {
        flex: 1,
        marginTop: 10
    },
    messagesWrapper: {
        paddingTop: 15,
        paddingBottom: theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT - 10
    },
    messageItem: {
        marginTop: 20
    },

    noDataPlaceholderWrapper: {
        marginTop: 15,
        paddingHorizontal: 20,
        width: '100%',
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
    },

    noDataPlaceholderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        fontSize: 14,
        lineHeight: 30,
        marginTop: -3,
        textAlign: 'center'
    },

    loaderWrapper: {
        width: '100%',
        height: 0
    },

    loader: {
        marginTop: 25,
        marginBottom: 10
    },
    sceneLoaderWrapper: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20
    },
    sceneLoader: {
        marginBottom: 8
    },
    sceneLoaderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        opacity: .7,
        fontSize: 9
    },
});
