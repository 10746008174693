import React, { Component } from 'react';

import { ScrollView, Platform } from 'react-native';
import { View, Text } from '../base';
import PropTypes from 'prop-types';

export default class ScrollPicker extends Component {

    constructor(props) {
        super(props);

        this.onMomentumScrollBegin = this.onMomentumScrollBegin.bind(this);
        this.onMomentumScrollEnd = this.onMomentumScrollEnd.bind(this);
        this.onScrollBeginDrag = this.onScrollBeginDrag.bind(this);
        this.onScrollEndDrag = this.onScrollEndDrag.bind(this);
        this.onScroll = this.onScroll.bind(this);

        this.state = {
            selectedIndex: props.selectedIndex,
        };
    }

    componentDidMount() {
        if (this.props.selectedIndex) {
            this.scrollToIndex(this.props.selectedIndex, false);
        }
    }

    componentDidUpdate(prevProps) {
        if(!this.dragStarted && prevProps.selectedIndex !== this.props.selectedIndex) {
            this.scrollToIndex(this.props.selectedIndex);
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    render() {
        const { header, footer } = this.renderPlaceHolder();
        return (
            <View style={{
                height: this.props.wrapperHeight,
                width: this.props.wrapperWidth,
                flex: 1,
                overflow: 'hidden',
                alignSelf: 'center'
            }}>
                <View style={{
                    position: 'absolute',
                    top: (this.props.wrapperHeight - this.props.itemHeight) / 2,
                    height: this.props.itemHeight,
                    width: this.props.highlightWidth,
                    borderTopColor: this.props.highlightColor,
                    borderBottomColor: this.props.highlightColor,
                    borderTopWidth: this.props.highlightBorderWidth,
                    borderBottomWidth: this.props.highlightBorderWidth
                }} />
                <ScrollView
                    ref={sview => this.sview = sview}
                    bounces={false}
                    scrollEventThrottle={16}
                    onScroll={this.onScroll}
                    showsVerticalScrollIndicator={false}
                    onTouchStart={this.props.onTouchStart}
                    onMomentumScrollBegin={this.onMomentumScrollBegin}
                    onMomentumScrollEnd={this.onMomentumScrollEnd}
                    onScrollBeginDrag={this.onScrollBeginDrag}
                    onScrollEndDrag={this.onScrollEndDrag}
                >
                    {header}
                    {this.props.dataSource.map(this.renderItem.bind(this))}
                    {footer}
                </ScrollView>
            </View>
        );
    }

    renderPlaceHolder() {
        const height = (this.props.wrapperHeight - this.props.itemHeight) / 2;
        return {
            header: <View style={{ height }} />,
            footer: <View style={{ height }} />
        };
    }

    /**
     * @param data
     * @param index
     * @returns {*}
     */
    renderItem(data, index) {
        const isSelected = index === this.state.selectedIndex,
            hasHeading = !!data.heading;
        return (
            <View key={index} style={this.props.itemStyle}>
                {hasHeading && (
                    <Text style={isSelected ? this.props.activeItemHeadingStyle : this.props.itemHeadingStyle}>{data.heading}</Text>
                )}
                <Text style={isSelected ? this.props.activeItemTextStyle : this.props.itemTextStyle}>{data.text}</Text>
            </View>
        );
    };

    scrollFix(e) {
        let verticalY = 0;
        const h = this.props.itemHeight;
        if (e.nativeEvent.contentOffset) {
            verticalY = e.nativeEvent.contentOffset.y;
        }
        const selectedIndex = Math.round(verticalY / h);
        const verticalElem = selectedIndex * h;
        if (verticalElem !== verticalY) {
            // using scrollTo in ios, onMomentumScrollEnd will be invoked
            if (Platform.OS === 'ios') {
                this.isScrollTo = true;
            }
            if (this.sview) {
                this.sview.scrollTo({y: verticalElem});
            }
        }
        if ((this.state.selectedIndex === selectedIndex && !this.hotUpdate)) {
            return;
        }
        this.setState({
            selectedIndex,
        });
        // onValueChange
        if (this.props.onValueChange) {
            const selectedValue = this.props.dataSource[selectedIndex];
            this.props.onValueChange(selectedValue, selectedIndex);
        }
    }

    onScrollBeginDrag() {
        this.dragStarted = true;
        this.hotUpdate = false;
        if (Platform.OS === 'ios') {
            this.isScrollTo = false;
        }
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    /**
     * @param event
     */
    onScroll(event) {
        const selectedIndex = Math.round(event.nativeEvent.contentOffset.y / this.props.itemHeight);

        if (this.state.selectedIndex === selectedIndex) {
            return;
        }

        this.setState({
            selectedIndex,
        }, () => {
            if (this.props.onValueChange && (this.dragStarted || Platform.OS === 'web')) {
                const selectedValue = this.props.dataSource[selectedIndex];
                this.props.onValueChange(selectedValue, selectedIndex);
            }
        });

        this.hotUpdate = true;
    }

    onScrollEndDrag(e) {
        this.props.onScrollEndDrag();
        // if not used, event will be garbaged
        const element = {
            nativeEvent: {
                contentOffset: {
                    y: e.nativeEvent.contentOffset.y,
                },
            },
        };
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(
            () => {
                if (!this.momentumStarted) {
                    this.scrollFix(element, 'timeout');
                }
            },
            10
        );
    }

    onMomentumScrollBegin() {
        this.momentumStarted = true;
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    onMomentumScrollEnd(e) {
        this.dragStarted = false;
        this.props.onMomentumScrollEnd();
        this.momentumStarted = false;
        if (!this.isScrollTo && !this.momentumStarted && !this.dragStarted) {
            this.scrollFix(e);
        }
    }

    scrollToIndex(ind, withAnimation) {
        this.setState({
            selectedIndex: ind,
        });
        const y = this.props.itemHeight * ind;
        setTimeout(() => {
            if (this.sview) {
                this.sview.scrollTo({y, animated: withAnimation});
            }
        }, 0);
    }
}

ScrollPicker.propTypes = {
    style: PropTypes.object,
    dataSource: PropTypes.array,
    selectedIndex: PropTypes.number,
    onValueChange: PropTypes.func,
    renderItem: PropTypes.func,
    highlightColor: PropTypes.string,
    itemHeight: PropTypes.number,
    wrapperWidth: PropTypes.number,
    wrapperHeight: PropTypes.number,
    highlightWidth: PropTypes.number,
    highlightBorderWidth: PropTypes.number,
    itemTextStyle: PropTypes.any,
    activeItemTextStyle: PropTypes.any,
    activeItemHeadingStyle: PropTypes.any,
    itemHeadingStyle: PropTypes.any,
    onMomentumScrollEnd: PropTypes.func,
    onScrollEndDrag: PropTypes.func,
};

ScrollPicker.defaultProps = {
    onMomentumScrollEnd: () => {},
    onScrollEndDrag: () => {},
};
