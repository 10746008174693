import {
    ADD_CHANNEL_RULE,
    BAN_CHANNEL_MEMBER,
    CHANNEL_MESSAGE_CHECK_VOTE,
    CHANNEL_MESSAGE_VOTE,
    CHANNEL_SUBSCRIBE,
    CHANNEL_UNSUBSCRIBE,
    CHANNEL_VOTE,
    CLEAR_CHANNEL_REDUCER, CLOSE_CHANNEL,
    CLOSE_CHANNEL_MESSAGE,
    CREATE_CHANNEL, CREATE_RICH_CHANNEL,
    DELETE_CHANNEL_MESSAGE,
    GET_CHANNEL,
    GET_CHANNEL_BACKGROUNDS,
    GET_CHANNEL_ICONS,
    GET_CHANNEL_MEMBERS_LIST,
    GET_CHANNEL_MESSAGES, GET_CHANNEL_MESSAGES_ASC,
    GET_LIST_CHANNELS,
    LOAD_MORE_CHANNEL_MEMBERS_LIST,
    LOAD_MORE_CHANNEL_MESSAGES,
    LOAD_MORE_CHANNEL_MESSAGES_ASC,
    LOAD_MORE_LIST_CHANNELS, MUTE_CHANNEL,
    OPEN_CHANNEL_MESSAGE,
    RECEIVED_CHANNEL_EVENT,
    REMOVE_CHANNEL_RULE,
    REPORT_CHANNEL,
    REPORT_CHANNEL_MESSAGE,
    RETRIEVE_CHANNEL_RULES,
    SEARCH_CHANNEL_MEMBERS_LIST,
    LOAD_MORE_SEARCH_CHANNEL_MEMBERS_LIST,
    SEND_CHANNEL_MESSAGE,
    UNBAN_CHANNEL_MEMBER,
    UPDATE_CHANNEL,
    UPDATE_CHANNEL_LAST_VISIT,
    UPDATE_CHANNEL_RULE
} from '../action-types';

let initialState = {
    list: {
        feed: [],
        local: [],
        my: []
    },
    icons: {
        categories: [],
        items: []
    },
    lastVisits: {},
    backgrounds: [],
    channels: {},
    rules: {},
    members: {},
    search_members: {},
    messages: {},
    messagesAsc: {},
};

export default (state = {...initialState}, action) => {
    const { type, payload } = action;
    switch (type) {
        case CREATE_CHANNEL:
        case CREATE_RICH_CHANNEL:
            state.list['my'].unshift(payload.channel);
            state.list['local'].unshift(payload.channel);
            return {
                ...state,
            };
        case GET_LIST_CHANNELS:
            state.list[payload.type] = payload.channels;
            if(payload.lastVisits) {
                state.lastVisits = payload.lastVisits;
            }
            return {
                ...state
            };
        case UPDATE_CHANNEL_LAST_VISIT:
            state.lastVisits[payload.channelID] = payload.data;
            return {
                ...state,
                lastVisits: {
                    ...state.lastVisits
                }
            };
        case LOAD_MORE_LIST_CHANNELS:
            if(state.list[payload.type]) {
                state.list[payload.type] = state.list[payload.type].concat(payload.channels);
            }
            return {
                ...state
            };
        case GET_CHANNEL:
            let {channelID, data} = payload,
                channel = {};
            channel[channelID] = data;
            return {
                ...state,
                channels: {
                    ...state.channels,
                    ...channel
                },
            };
        case UPDATE_CHANNEL:
            for(let key in payload.data) {
                state.channels[payload.channelID][key] = payload.data[key];
            }
            return {
                ...state,
                channels: {
                    ...state.channels
                },
            };
        case REPORT_CHANNEL:
            if(state.channels[payload.channelID]) {
                state.channels[payload.channelID].reported = 1;
                state.channels[payload.channelID].myReport = 1;
            }

            for(let key in state.list) {
                for(let i in state.list[key]) {
                    if(state.list[key][i].id == payload.channelID) {
                        state.list[key][i].reported = 1;
                        state.list[key][i].myReport = 1;
                    }
                }
            }

            return {
                ...state,
                channels: {
                    ...state.channels
                },
                list: {
                    ...state.list
                },
            };
        case REPORT_CHANNEL_MESSAGE:
            if(state.messages[payload.channelID]) {
                for(let i in state.messages[payload.channelID]) {
                    if(state.messages[payload.channelID][i]['id'] == payload.messageID) {
                        state.messages[payload.channelID][i]['myReport'] = 1;
                        state.messages[payload.channelID][i]['reported'] = 1;
                    }
                }
            }

            if(state.messagesAsc[payload.channelID]) {
                for(let i in state.messagesAsc[payload.channelID]) {
                    if(state.messagesAsc[payload.channelID][i]['id'] == payload.messageID) {
                        state.messagesAsc[payload.channelID][i]['myReport'] = 1;
                        state.messagesAsc[payload.channelID][i]['reported'] = 1;
                    }
                }
            }

            return {
                ...state,
                messages: {
                    ...state.messages
                },
                messagesAsc: {
                    ...state.messagesAsc
                },
            };
        case SEND_CHANNEL_MESSAGE:
            // if(state.messages[payload.channelID]) {
            //     if(state.messages[payload.channelID].map(o => o.id).indexOf(payload.message.id) === -1) {
            //         if(state.messages[payload.channelID]) {
            //             state.messages[payload.channelID].unshift(payload.message);
            //         }
            //         if(state.messagesAsc[payload.channelID]) {
            //             state.messagesAsc[payload.channelID].push(payload.message);
            //         }
            //         for(let key in state.list) {
            //             for(let i in state.list[key]) {
            //                 if(state.list[key][i].id == payload.channelID) {
            //                     state.list[key][i].messages_count++;
            //                 }
            //             }
            //         }
            //         if(state.channels[payload.channelID]) {
            //             state.channels[payload.channelID].messages_count++;
            //         }
            //     }
            // }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
            };
        case RECEIVED_CHANNEL_EVENT:
            let event = payload.data;

            switch(event.event) {
                case 'new_message':

                    if(state.messages[payload.channelID]) {
                        state.messages[payload.channelID].unshift(event.data);
                    }
                    if(state.messagesAsc[payload.channelID]) {
                        state.messagesAsc[payload.channelID].push(event.data);
                    }
                    if(!event.data.is_system) {
                        for(let key in state.list) {
                            for(let i in state.list[key]) {
                                if(state.list[key][i].id == payload.channelID) {
                                    state.list[key][i].messages_count++;
                                }
                            }
                        }
                        if(state.channels[payload.channelID]) {
                            state.channels[payload.channelID].messages_count++;
                        }
                    }

                    if(!event.data.is_system) {
                        for(let listType in state.list) {
                            let index = state.list[listType].findIndex(elt => elt.id === Number(payload.channelID));
                            if(index > -1) {
                                let element;
                                if(listType === 'my') {
                                    element = state.list[listType].splice(index, 1)[0];
                                    state.list[listType].push(element);
                                } else {
                                    element = state.list[listType][index];
                                }
                                element.last_message_from  = event.data.author_nickname;
                                element.last_message_at = event.data.created_at;
                            }
                        }
                    }

                    return {
                        ...state,
                        list: {
                            ...state.list
                        },
                        messages: {
                            ...state.messages
                        },
                        messagesAsc: {
                            ...state.messagesAsc
                        }
                    };
                    break;
                case 'vote_message':
                    if(state.messages[payload.channelID]) {
                        for(let i in state.messages[payload.channelID]) {
                            if(state.messages[payload.channelID][i]['id'] == event.data.messageID) {
                                state.messages[payload.channelID][i]['rating'] += Number(event.data.value);
                            }
                        }
                    }
                    if(state.messagesAsc[payload.channelID]) {
                        for(let i in state.messagesAsc[payload.channelID]) {
                            if(state.messagesAsc[payload.channelID][i]['id'] == event.data.messageID) {
                                state.messagesAsc[payload.channelID][i]['rating'] += Number(event.data.value);
                            }
                        }
                    }
                    break;
                case 'delete_message':
                    if(state.messages[payload.channelID]) {
                        for(let i in state.messages[payload.channelID]) {
                            if(state.messages[payload.channelID][i]['id'] == event.data.messageID) {
                                state.messages[payload.channelID].splice(i, 1);
                            }
                        }
                    }
                    if(state.messagesAsc[payload.channelID]) {
                        for(let i in state.messagesAsc[payload.channelID]) {
                            if(state.messagesAsc[payload.channelID][i]['id'] == event.data.messageID) {
                                state.messagesAsc[payload.channelID].splice(i, 1);
                            }
                        }
                    }
                    break;
                case 'delete_message_by_author':
                    if(state.messages[payload.channelID]) {
                        for(let i in state.messages[payload.channelID]) {
                            if(state.messages[payload.channelID][i]['author_id'] == event.data.authorID) {
                                state.messages[payload.channelID].splice(i, 1);
                            }
                        }
                    }
                    if(state.messagesAsc[payload.channelID]) {
                        for(let i in state.messagesAsc[payload.channelID]) {
                            if(state.messagesAsc[payload.channelID][i]['author_id'] == event.data.authorID) {
                                state.messagesAsc[payload.channelID].splice(i, 1);
                            }
                        }
                    }
                    break;
                case 'update_channel':
                    for(let key in event.data) {
                        if(typeof state.channels[payload.channelID][key] !== 'undefined') {
                            state.channels[payload.channelID][key] = event.data[key];
                        }
                    }
                    return {
                        ...state,
                        channels: {
                            ...state.channels
                        },
                    };
                case 'close_channel':
                    for (let listType in state.list) {
                        for (let i in state.list[listType]) {
                            if(state.list[listType][i].id === payload.channelID) {
                                state.list[listType].splice(i, 1);
                            }
                        }
                    }
                    return {
                        ...state,
                        list: {
                            ...state.list
                        },
                    };
            }

            return {
                ...state,
                messages: {
                    ...state.messages
                },
                messagesAsc: {
                    ...state.messagesAsc
                }
            };
        case CHANNEL_MESSAGE_CHECK_VOTE:
            if(state.messages[payload.channelID]) {
                for(let i in state.messages[payload.channelID]) {
                    if(state.messages[payload.channelID][i]['id'] == payload.messageID) {
                        state.messages[payload.channelID][i]['hasVote'] = payload.hasVote;
                    }
                }
            }
            if(state.messagesAsc[payload.channelID]) {
                for(let i in state.messagesAsc[payload.channelID]) {
                    if(state.messagesAsc[payload.channelID][i]['id'] == payload.messageID) {
                        state.messagesAsc[payload.channelID][i]['hasVote'] = payload.hasVote;
                    }
                }
            }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
                messagesAsc: {
                    ...state.messagesAsc
                }
            };
        case DELETE_CHANNEL_MESSAGE:
            if(state.messages[payload.channelID]) {
                for(let i in state.messages[payload.channelID]) {
                    if(state.messages[payload.channelID][i]['id'] == payload.messageID) {
                        state.messages[payload.channelID].splice(i, 1);
                    }
                }
            }
            if(state.messagesAsc[payload.channelID]) {
                for(let i in state.messagesAsc[payload.channelID]) {
                    if(state.messagesAsc[payload.channelID][i]['id'] == payload.messageID) {
                        state.messagesAsc[payload.channelID].splice(i, 1);
                    }
                }
            }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
                messagesAsc: {
                    ...state.messagesAsc
                }
            };
        case OPEN_CHANNEL_MESSAGE:
            if(state.messages[payload.channelID]) {
                for(let i in state.messages[payload.channelID]) {
                    if(state.messages[payload.channelID][i]['id'] == payload.messageID) {
                        state.messages[payload.channelID][i]['isOpened'] = true;
                    } else {
                        state.messages[payload.channelID][i]['isOpened'] = undefined;
                    }
                }
            }
            if(state.messagesAsc[payload.channelID]) {
                for(let i in state.messagesAsc[payload.channelID]) {
                    if(state.messagesAsc[payload.channelID][i]['id'] == payload.messageID) {
                        state.messagesAsc[payload.channelID][i]['isOpened'] = true;
                    } else {
                        state.messagesAsc[payload.channelID][i]['isOpened'] = undefined;
                    }
                }
            }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
                messagesAsc: {
                    ...state.messagesAsc
                },
            };
        case CLOSE_CHANNEL_MESSAGE:
            if(state.messages[payload.channelID]) {
                for(let i in state.messages[payload.channelID]) {
                    if(state.messages[payload.channelID][i]['id'] == payload.messageID) {
                        state.messages[payload.channelID][i]['isOpened'] = undefined;
                    }
                }
            }
            if(state.messagesAsc[payload.channelID]) {
                for(let i in state.messagesAsc[payload.channelID]) {
                    if(state.messagesAsc[payload.channelID][i]['id'] == payload.messageID) {
                        state.messagesAsc[payload.channelID][i]['isOpened'] = undefined;
                    }
                }
            }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
                messagesAsc: {
                    ...state.messagesAsc
                },
            };
        case CHANNEL_MESSAGE_VOTE:
            if(state.messages[payload.channelID]) {
                for(let i in state.messages[payload.channelID]) {
                    if(state.messages[payload.channelID][i]['id'] == payload.messageID) {
                        state.messages[payload.channelID][i]['hasVote'] = true;
                    }
                }
            }
            if(state.messagesAsc[payload.channelID]) {
                for(let i in state.messagesAsc[payload.channelID]) {
                    if(state.messagesAsc[payload.channelID][i]['id'] == payload.messageID) {
                        state.messagesAsc[payload.channelID][i]['hasVote'] = true;
                    }
                }
            }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
                messagesAsc: {
                    ...state.messagesAsc
                },
            };
        case LOAD_MORE_CHANNEL_MESSAGES_ASC:
            if(state.messagesAsc[payload.channelID]) {
                state.messagesAsc[payload.channelID] = state.messagesAsc[payload.channelID].concat(payload.data);
            }
            return {
                ...state
            };
        case LOAD_MORE_CHANNEL_MESSAGES:
            if(state.messages[payload.channelID]) {
                state.messages[payload.channelID] = (state.messages[payload.channelID].concat(payload.data));
            }
            return {
                ...state
            };
        case GET_CHANNEL_MESSAGES:
            let messages = {};
            messages[payload.channelID] = (payload.data);
            return {
                ...state,
                list: {
                    ...state.list
                },
                messages: {
                    ...state.messages,
                    ...messages
                },
            };
        case GET_CHANNEL_MESSAGES_ASC:
            let messagesAsc = {};
            messagesAsc[payload.channelID] = (payload.data);
            return {
                ...state,
                list: {
                    ...state.list
                },
                messagesAsc: {
                    ...state.messagesAsc,
                    ...messagesAsc
                },
            };
        case LOAD_MORE_CHANNEL_MEMBERS_LIST:
            if(state.members[payload.channelID]) {
                state.members[payload.channelID] = payload.data.concat(state.members[payload.channelID]);
            }
            return {
                ...state,
                members: {
                    ...state.members
                },
            };
        case GET_CHANNEL_MEMBERS_LIST:
            let members = {};
            members[payload.channelID] = payload.data;
            return {
                ...state,
                members: {
                    ...state.members,
                    ...members
                },
            };
        case SEARCH_CHANNEL_MEMBERS_LIST:
            let search_members = {};
            search_members[payload.channelID] = payload.data;
            return {
                ...state,
                search_members: {
                    ...state.search_members,
                    ...search_members
                },
            };
        case LOAD_MORE_SEARCH_CHANNEL_MEMBERS_LIST:
            if(state.search_members[payload.channelID]) {
                state.search_members[payload.channelID] = state.search_members[payload.channelID].concat(payload.data);
            }
            return {
                ...state,
                search_members: {
                    ...state.search_members
                },
            };
        case GET_CHANNEL_ICONS:
            return {
                ...state,
                icons: payload.data
            };
        case GET_CHANNEL_BACKGROUNDS:
            return {
                ...state,
                backgrounds: payload.data
            };
        case CHANNEL_SUBSCRIBE:
            state.channels[payload.channelID].hasSubscription = true;
            return {
                ...state,
                channels: {
                    ...state.channels,
                },
            };
        case CHANNEL_UNSUBSCRIBE:
            state.channels[payload.channelID].hasSubscription = false;
            return {
                ...state,
                channels: {
                    ...state.channels,
                },
            };
        case MUTE_CHANNEL:
            state.channels[payload.channelID].mute = payload.mute;
            return {
                ...state,
                channels: {
                    ...state.channels,
                },
            };
        case CHANNEL_VOTE:
            if(state.channels[payload.channelID]) {
                state.channels[payload.channelID].my_vote = payload.type === 'up' ? 1 : -1;
                state.channels[payload.channelID].rating = state.channels[payload.channelID].rating + (payload.type === 'up' ? 1 : -1);
            }
            for (let listType in state.list) {
                for (let i in state.list[listType]) {
                    if(state.list[listType][i].id === payload.channelID) {
                        state.list[listType][i].my_vote = payload.type === 'up' ? 1 : -1;
                        state.list[listType][i].rating = state.list[listType][i].rating + (payload.type === 'up' ? 1 : -1);
                    }
                }
            }
            return {
                ...state,
                list: state.list,
                channels: state.channels
            };
        case BAN_CHANNEL_MEMBER:
            for(let key in state.search_members[payload.channelID]) {
                if(state.search_members[payload.channelID][key].user_id === payload.data.userID) {
                    state.search_members[payload.channelID][key].ban_id = payload.data.banID;
                    state.search_members[payload.channelID][key].ban_expires_at = Math.floor(Date.now() / 1000) + (Number(payload.data.duration) * 3600);
                    return {
                        ...state,
                        search_members: {
                            [payload.channelID]: [ ...state.search_members[payload.channelID] ]
                        },
                    };
                }
            }
            return {
                ...state
            };
        case UNBAN_CHANNEL_MEMBER:
            for(let key in state.search_members[payload.channelID]) {
                if(state.search_members[payload.channelID][key].user_id === payload.data.userID) {
                    state.search_members[payload.channelID][key].ban_id = null;
                    state.search_members[payload.channelID][key].ban_expires_at = null;
                    return {
                        ...state,
                        search_members: {
                            [payload.channelID]: [ ...state.search_members[payload.channelID] ]
                        },
                    };
                }
            }
            return {
                ...state
            };
        case RETRIEVE_CHANNEL_RULES:
            let rules = {};
            rules[payload.channelID] = payload.rules;
            return {
                ...state,
                rules: rules
            };
        case ADD_CHANNEL_RULE:
            state.rules[payload.channelID].push(payload.rule);
            return {
                ...state,
                rules: state.rules
            };
        case UPDATE_CHANNEL_RULE:
            for(let i in state.rules[payload.channelID]) {
                if(state.rules[payload.channelID][i].id == payload.ruleID) {
                    state.rules[payload.channelID][i] = payload.rule;
                }
            }
            return {
                ...state,
                rules: state.rules
            };
        case REMOVE_CHANNEL_RULE:
            for(let i in state.rules[payload.channelID]) {
                if(state.rules[payload.channelID][i].id == payload.ruleID) {
                    state.rules[payload.channelID].splice(i, 1);
                }
            }
            return {
                ...state,
                rules: state.rules
            };
        case CLOSE_CHANNEL:
            for (let listType in state.list) {
                for (let i in state.list[listType]) {
                    if(state.list[listType][i].id === payload.channelID) {
                        state.list[listType].splice(i, 1);
                    }
                }
            }
            return {
                ...state,
                list: state.list
            };
        case CLEAR_CHANNEL_REDUCER:
            return { ...initialState };
        default:
            return state;
    }
};
