import React, { PureComponent } from 'react';

import { TextInput } from '../../components/base';
import { withStyles } from "../../theme";

class SearchInput extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            ...restProps
        } = this.props;

        return (
            <TextInput {...restProps} style={[
                styles.textInput,
                styles.headingTextOffset,
                style
            ]} placeholderTextColor={theme.COLORS.SECONDARY_TEXT} />
        );
    }
}

export default withStyles(SearchInput, theme => ({
    textInput: {
        color: theme.COLORS.TEXT,
        fontWeight: '900',
        fontSize: 25,
        height: 35,
        lineHeight: 35
    },
    headingTextOffset: {
        marginLeft: 30
    }
}));
