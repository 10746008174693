import React, { Component } from 'react';

import { withStyles } from "../../theme";
import { withNavigation } from 'react-navigation';
import storage from '../../utils/keyValueStorage';
import { View, TextArea, Modal, Text, Icon, Image } from '../base';
import IconButton from '../IconButton';
import Button from '../Button';
import Toast from '../Toast';
import { openCommunityRules } from "../../state/common/actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { connectActionSheet } from "@expo/react-native-action-sheet";
import { pickFromGallery, takePhoto } from "../../utils/imagePicker";
import { createChannel, createRichChannel } from "../../state/channel/actions";
import { getCurrentLocale } from "../../utils/localizationHelper";
import Validator from '../../utils/validator';
import screenNames from "../../navigation/screenNames";

const SUBJECT_LIMIT_LENGTH = 128;

class CreateButton extends Component {

    state = {
        isLoading: false,
        visible: false,
        form: {
            subject: '',
            imageUri: ''
        },
        errors: {}
    };

    onPress = async () => {
        const isAccepted = await storage.get('isRulesAccepted', false);
        if(isAccepted) {
            return this.onOpen();
        }
        this.props.openCommunityRules(async () => {
            await storage.set('isRulesAccepted', 1);
            return this.onOpen();
        });
    };

    onOpen = async () => this.setState({
        visible: true
    });

    onClose = () => {
        this.setState({
            visible: false
        });
    };

    onChangeTextAreaValue = (value) => {
        const LIMIT_DOUBLE_NEW_LINES_REGEX = /(?:\r?\n){2,}/;
        this.setState({
            form: {
                ...this.state.form,
                subject: value.replace(LIMIT_DOUBLE_NEW_LINES_REGEX, '')
            }
        });
    };

    onPressAddImage = async () => {
        const imageUri = await pickFromGallery({ resizeCoefficient: .5, compressRatio: .8 });
        if(imageUri) {
            this.setState({
                form: {
                    ...this.state.form,
                    imageUri
                }
            });
        }
    };

    onPressRemoveImage = () => {
        this.setState({
            form: {
                ...this.state.form,
                imageUri: ''
            }
        });
    };

    onPressSubmit = async () => {
        const { form } = this.state,
            { intl, navigation, city, university } = this.props;

        await this.setState({ isLoading: true, errors: {} });

        const formData = {
            title: '',
            mode: 1,
            visible: 1,
            subject: form.subject.replace(/^\s+|\s+$/g, ''),
            cityID: city.id,
            universityID: university ? university.id : 0,
            locale: getCurrentLocale()
        };

        const validator = new Validator([
                ['subject', Validator.rules.LENGTH, { min: 1, max: SUBJECT_LIMIT_LENGTH }],
                ['subject', Validator.rules.NOT_EMPTY]
            ], formData);

        if(await validator.validate()) {
            const { success, result, errors } = !form.imageUri
                ? await this.props.createChannel(formData)
                : await this.props.createRichChannel(formData, form.imageUri);
            if(success) {
                this.onClose();
                this.setState({
                    imageUri: null,
                    form: {
                        imageUri: '',
                        subject: '',
                    }
                });
                Toast.showSimpleSuccess(intl.formatMessage({ id: 'common.topic_create_success' }));
                navigation.navigate(screenNames.TOPIC, {
                    topicID: result.id
                })
            } else if(errors) {
                validator.setErrors(errors);
                await this.setState({
                    errors: validator.getIntlErrorsMessages(intl)
                });
            }
        } else {
            await this.setState({
                errors: validator.getIntlErrorsMessages(intl)
            });
        }
        await this.setState({ isLoading: false });
    };

    render () {

        const { isLoading, errors, form } = this.state;
        const { theme, styles, style, intl, isLoggedIn, ...restProps } = this.props;

        return isLoggedIn && (
            <IconButton
                name='plus-circle'
                size={20}
                color={theme.COLORS.SECONDARY_TEXT}
                style={[ style, styles.wrapper ]}
                {...restProps}
                onPress={this.onPress}
                onLongPress={this.onPress}
            >
                <Modal
                    visible={this.state.visible}
                    onRequestClose={this.onClose}
                    onModalShow={() => this._textInput.focus()}
                    avoidKeyboard
                >
                    <View style={styles.bottomContainer}>
                        <Text style={styles.headingText}>
                            {intl.formatMessage({ id: 'common.create_topic_heading' })}
                        </Text>
                        <View style={styles.textAreaWrapper}>
                            {!!form.imageUri && (
                                <Image blurRadius={3} style={styles.coverImage} source={{ uri: form.imageUri }} />
                            )}
                            <TextArea
                                placeholder={intl.formatMessage({ id: 'common.create_topic_placeholder' })}
                                inputRef={component => this._textInput = component}
                                placeholderTextColor={theme.COLORS.SECONDARY_TEXT}
                                value={this.state.form.subject}
                                onChangeText={this.onChangeTextAreaValue}
                                style={styles.textArea} />

                            <View style={styles.imageButtonWrapper}>
                                {form.imageUri ? (
                                    <Button onPress={this.onPressRemoveImage} size={'medium'} variation={'transparent'} style={styles.imageButton}>
                                        <Icon name={'trash-2'} size={17} color={theme.COLORS.SECONDARY_TEXT} />
                                    </Button>
                                ) : (
                                    <Button onPress={this.onPressAddImage} size={'medium'} variation={'transparent'} style={styles.imageButton}>
                                        <Icon name={'image'} size={17} color={theme.COLORS.SECONDARY_TEXT} />
                                    </Button>
                                )}
                            </View>

                            <View style={styles.subjectTextCounterWrapper}>
                                {form.subject.length > 0 && (
                                    <Text style={[
                                        styles.subjectTextCounter,
                                        ( form.subject.length > SUBJECT_LIMIT_LENGTH ? { color: theme.COLORS.DANGER } : null )
                                    ]}>{SUBJECT_LIMIT_LENGTH - form.subject.length}</Text>
                                )}
                            </View>
                        </View>
                        {!!errors.subject && (
                            <View style={styles.errorWrapper}>
                                <Text style={styles.errorText}>{errors.subject}</Text>
                            </View>
                        )}
                        <Button
                            onPress={this.onPressSubmit}
                            disabled={!form.subject.length || form.subject.length > SUBJECT_LIMIT_LENGTH}
                            variation={form.subject.length > SUBJECT_LIMIT_LENGTH ? 'secondary' : 'primary'}
                            size={'medium'}
                            style={styles.submitButton}
                            loading={isLoading}
                        >
                            {intl.formatMessage({ id: 'common.create' })} 👏
                        </Button>
                        <Button onPress={this.onClose} size={'medium'} variation={'transparent'} style={styles.cancelButton}>
                            <Text style={styles.cancelButtonText}>{intl.formatMessage({ id: 'common.cancel' })}</Text>
                        </Button>
                    </View>
                </Modal>
            </IconButton>
        );
    }

    /**
     * @param buttons
     */
    actionSheet = (buttons) => {
        buttons = buttons.filter(button => button.visible !== false);
        this.props.showActionSheetWithOptions({
            options: buttons.map(button => button.label),
            cancelButtonIndex: buttons.length - 1,
            destructiveButtonIndex: buttons.map(button => button.destructive).indexOf(true)
        }, (index) => {
            if(buttons[index].onPress) {
                buttons[index].onPress();
            }
        });
    };
}

const mapStateToProps = ({ auth, common }) => ({
    isLoggedIn: auth.isLoggedIn,
    city: common.city,
    university: common.university
});

const mapDispatchToProps = dispatch => ({
    openCommunityRules: (callback) => dispatch(openCommunityRules(callback)),
    createChannel: (fields) => dispatch(createChannel(fields)),
    createRichChannel: (fields, imageUri) => dispatch(createRichChannel(fields, imageUri))
});

export default connect(mapStateToProps, mapDispatchToProps)(
    connectActionSheet(withNavigation(injectIntl(withStyles(CreateButton, theme => ({
        wrapper: {
            padding: 10,
            alignItems: 'center'
        },
        headingText: {
            textAlign: 'center',
            color: theme.COLORS.TEXT,
            fontWeight: '900',
            fontSize: 19,
            paddingHorizontal: 10,
            paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN
        },
        bottomContainer: {
            backgroundColor: theme.COLORS.BACKGROUND,
            paddingTop: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
        },
        textAreaWrapper: {
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            borderRadius: 12.5,
            marginBottom: 10,
            paddingHorizontal: 0,
            paddingVertical: 0,
            overflow: 'hidden'
        },
        coverImage: {
            position: 'absolute',
            opacity: .3,
            top: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '100%'
        },
        textArea: {
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            marginTop: 0,

            paddingLeft: 20,
            paddingRight: 20,

            paddingTop: 30,
            paddingBottom: 30,

            maxHeight: 200,

            color: theme.COLORS.TEXT,
            fontSize: 18,
            fontFamily: theme.FONTS.BALOO,
            textAlign: 'center'
        },


        imageButtonWrapper: {
            position: 'absolute',
            right: 0,
            top: -3
        },
        imageButton: {
            width: null,
            paddingRight: 10
        },
        submitButton: {
            marginTop: 15
        },
        cancelButton: {
            marginTop: 15
        },
        cancelButtonText: {
            color: theme.COLORS.SECONDARY_TEXT,
            fontWeight: '900'
        },
        subjectTextCounterWrapper: {
            position: 'absolute',
            right: 5,
            bottom: 5
        },
        subjectTextCounter: {
            textAlign: 'right',
            marginRight: 5,
            fontWeight: '700',
            color: theme.COLORS.SECONDARY_TEXT,
            fontSize: 14
        },
        errorWrapper: {
            alignItems: 'center'
        },
        errorText: {
            color: theme.COLORS.DANGER,
            fontSize: 15,
            textAlign: 'center'
        },
    })))))
);
