import React, { PureComponent } from 'react';

import { Text, View, Image, Icon, Pressable, Avatar } from '../../components/base';
import { withStyles } from "../../theme";
import { IconButton, Ticker } from "../../components";
import { injectIntl } from "react-intl";
import {LinearGradient} from "expo-linear-gradient";

class TopicItem extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            intl,
            id,
            thumbnail_original,
            subject,
            messages_count,
            university,
            city,
            last_message_at,
            created_at,
            author_nickname,
            my_vote,
            author_avatar_normal,
            rating,
            reported,
            loadingVoteTopicID,
            isSafe,
            color_type,
            lastVisit,
            ...restProps
        } = this.props;

        const offensiveContent = reported > 0 && isSafe,
            hasNewMessages = lastVisit && lastVisit < last_message_at;

        return (
            <Pressable {...restProps} style={[
                styles.wrapper, style
            ]}>
                {!!color_type && !thumbnail_original && (
                    <LinearGradient
                        {...theme.COLORS.GRADIENTS[`TYPE_${color_type}`]}
                        style={styles.coverGradient}
                    />
                )}
                {(!offensiveContent && !!thumbnail_original) && (
                    <Image blurRadius={3} style={styles.coverImage} source={{ uri: thumbnail_original }} />
                )}
                <View style={styles.content}>
                    <View style={styles.topContainer}>
                        <View style={styles.topAuthorWrap}>
                            <Avatar style={styles.topAuthorImage} avatarUrl={author_avatar_normal} />
                            <Text style={styles.topAuthorNickname}> @{author_nickname} </Text>
                        </View>
                        <View style={styles.topTimeWrap}>
                            <Text style={styles.topTimeText} numberOfLines={1}>
                                {city ? <Icon name={'map-pin'} size={10} /> : null} {university ? `${university} (${city})` : city ? city : null}
                            </Text>
                        </View>
                    </View>
                    {offensiveContent ? (
                        <Text style={styles.titleOffensiveText}>
                            [{ intl.formatMessage({ id: 'common.unsafe_topic' }) }]
                        </Text>
                    ) : (
                        <Text style={styles.titleText}>
                            {subject}
                        </Text>
                    )}
                    <View style={styles.bottomContainer}>
                        {hasNewMessages ? (
                            <View style={styles.bottomContainerLeft}>
                                <Text style={styles.tickerTextNew}>+1</Text>
                                <Icon name={'message-circle'} color={theme.COLORS.DANGER} size={16} />
                                <Ticker style={styles.leftTickerNew} textStyle={styles.tickerText}>
                                    {messages_count.toString()}
                                </Ticker>
                            </View>
                        ) : (
                            <View style={styles.bottomContainerLeft}>
                                <Icon name={'message-circle'} color={theme.COLORS.TEXT} size={16} />
                                <Ticker style={styles.leftTicker} textStyle={styles.tickerText}>
                                    {messages_count.toString()}
                                </Ticker>
                            </View>
                        )}
                        <View style={styles.bottomContainerRight}>
                            <IconButton onPress={this.onPressVoteDown} disabled={my_vote || loadingVoteTopicID === id} name={'thumbs-down'} color={my_vote === -1 ? theme.COLORS.DANGER : my_vote ? theme.COLORS.SECONDARY_TEXT : theme.COLORS.TEXT} size={16} />
                            {created_at < ((Date.now() / 1000) - 4800)  ? (
                                <Ticker textStyle={[styles.tickerText, (rating > 0 ? styles.tickerTextPositive : rating < 0 ? styles.tickerTextNegative : null)]}>
                                    {rating.toString()}
                                </Ticker>
                            ) : (
                                <Text style={styles.tickerText}>
                                    ●
                                </Text>
                            )}
                            <IconButton onPress={this.onPressVoteUp} disabled={my_vote || loadingVoteTopicID === id} name={'thumbs-up'} color={my_vote === 1 ? theme.COLORS.SUCCESS : my_vote ? theme.COLORS.SECONDARY_TEXT : theme.COLORS.TEXT} size={16} />
                        </View>
                    </View>
                </View>
            </Pressable>
        );
    }

    onPressVoteUp = () => {
        this.props.onPressVoteUp(this.props.id);
    };

    onPressVoteDown = () => {
        this.props.onPressVoteDown(this.props.id);
    };
}

export default injectIntl(withStyles(TopicItem, theme => ({
    wrapper: {
        overflow: 'hidden',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 25
    },
    titleText: {
        color: theme.COLORS.TEXT,
        fontSize: 18,
        fontFamily: theme.FONTS.BALOO,
        marginVertical: 20,
        textAlign: 'center'
    },
    titleOffensiveText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 16,
        fontWeight: '900',
        marginVertical: 20,
        textAlign: 'center'
    },
    coverImage: {
        position: 'absolute',
        opacity: .3,
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%'
    },
    coverGradient: {
        position: 'absolute',
        opacity: .3,
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%'
    },
    content: {
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 15,
        paddingHorizontal: 20,
    },
    topContainer: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: 3
    },
    topAuthorWrap: {
        flex: 1,
        flexDirection: 'row'
    },
    topAuthorImage: {
        width: 16,
        height: 16,
        borderRadius: 6
    },
    topAuthorNickname: {
        fontSize: 11,
        fontWeight: '700',
        color: '#888888',
        marginLeft: 4
    },
    topTimeWrap: {
        flex: .5
    },
    topTimeText: {
        fontSize: 11,
        fontWeight: '700',
        color: '#888888',
        textAlign: 'right'
    },
    bottomContainer: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: 5,
        marginTop: -10
    },
    bottomContainerLeft: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    leftTicker: {
        marginHorizontal: 10
    },
    leftTickerNew: {
        marginHorizontal: 5
    },
    bottomContainerRight: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginRight: -10
    },
    tickerText: {
        fontSize: 12,
        fontWeight: '700',
        color: theme.COLORS.TEXT
    },
    tickerTextNew: {
        fontSize: 12,
        fontWeight: '700',
        color: theme.COLORS.DANGER,
        marginRight: 4
    },
    tickerTextPositive: {
        color: theme.COLORS.SUCCESS
    },
    tickerTextNegative: {
        color: theme.COLORS.DANGER
    },
})));
