export default (theme) => ({
    screenWrapper: {
        flex: 1,
        backgroundColor: theme.COLORS.BACKGROUND
    },
    scrollWrapper: {
        paddingBottom: 25
    },
    headerWrapper: {
        marginTop: theme.SIZES.TOP_MARGIN,
        marginHorizontal: theme.SIZES.HEADER_HORIZONTAL_PADDING
    },
    headerBackButton: {
        width: 35,
        height: 35,
        position: 'absolute',
        left: 0,
        top: 0,
        justifyContent: 'center',
    },
    headingText: {
        color: theme.COLORS.TEXT,
        fontWeight: '900',
        fontSize: 25,
        height: 35,
        lineHeight: 35,
        paddingRight: 100
    },
    headingTextOffset: {
        marginLeft: 30
    },
    subHeadingText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        fontSize: 15,
        paddingRight: 100
    },
    avatarWrapper: {
        position: 'absolute',
        alignItems: 'flex-end',
        right: 0,
        top: 0,
        width: 100,
        height: 100,
        paddingTop: 5
    },
    avatar: {
        width: 95,
        height: 95,
        borderRadius: 38,
    },
    karmaText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 11
    },
    positiveKarma: {
        opacity: .85,
        paddingVertical: 2.5,
        paddingHorizontal: 4,
        borderRadius: 5.6,
        backgroundColor: theme.COLORS.SUCCESS,
        position: 'absolute',
        top: 2,
        right: 3
    },
    negativeKarma: {
        opacity: .85,
        paddingVertical: 2.5,
        paddingHorizontal: 4,
        borderRadius: 5.6,
        backgroundColor: theme.COLORS.DANGER,
        position: 'absolute',
        top: 2,
        right: 3
    },
    headerButtons: {
        flexDirection: 'row',
        marginTop: 20
    },

    tinyHeaderContentWrapper: {
        position: 'absolute',
        left: 0,
        height: 40,
        alignItems: 'center',
        flexDirection: 'row'
    },

    tinyHeaderContentWrapperOffset: {
        left: 30
    },

    tinyHeaderAvatar: {
        width: 25,
        height: 25,
        borderRadius: 9,
        marginTop: 1.5
    },

    tinyHeaderNickname: {
        fontSize: 15,
        fontWeight: '700',
        color: theme.COLORS.TEXT,
        lineHeight: 25,
        marginLeft: 6,
        paddingRight: 70
    },

    tinyHeaderBackButton: {
        width: 35,
        height: 35,
        position: 'absolute',
        left: 0,
        justifyContent: 'center',
    },

    tinyHeaderMoreButton: {
        width: 35,
        height: 35,
        position: 'absolute',
        right: 0,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },

    topicItem: {
        marginTop: 15
    },

    topicLoaderItem: {
        marginTop: 25
    },

    messagesCountText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '700'
    },

    noDataPlaceholderWrapper: {
        marginTop: 15,
        width: '100%',
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        opacity: 0.65,
        borderRadius: 12.5
    },

    noDataPlaceholderText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontWeight: '500',
        fontSize: 16,
        lineHeight: 30,
        marginTop: -3
    }
});
