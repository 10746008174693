import { Linking } from "react-native";
import * as Sharing from 'expo-sharing';
import * as WebBrowser from 'expo-web-browser';

/**
 * @param url
 * @returns {Promise<{}>}
 */
export const shareUrl = async (url) => {
    const supported = await Linking.canOpenURL(url);
    if(supported && await Sharing.isAvailableAsync()) {
        await Sharing.shareAsync(url);
    }
};

/**
 * @param nickname
 * @returns {Promise<*>}
 */
export const shareProfile = async (nickname) => {
    return shareUrl(`https://wace.co/u/${nickname}`);
};

/**
 * @param url
 * @param theme
 * @returns {Promise<void>}
 */
export const openLink = async (url, theme) => {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
        url = 'http://' + url;
    }
    const supported = await Linking.canOpenURL(url);
    if(!supported) {
        return;
    }
    await WebBrowser.openBrowserAsync(url, {
        toolbarColor: theme.COLORS.BACKGROUND,
        controlsColor: theme.COLORS.TEXT,
        collapseToolbar: true
    });
};

/**
 * @param url
 * @returns {Promise<void>}
 */
export const openURL = async (url) => {
    if (!url.match(/^[a-zA-Z]+:\/\//)) {
        url = 'http://' + url;
    }
    const supported = await Linking.canOpenURL(url);
    if(!supported) {
        return;
    }
    await Linking.openURL(url);
};
