import {
    GET_USER,
    GET_ME,
    UPDATE_PROFILE_IMAGE,
    GET_USER_KARMA_HISTORY,
    LOAD_MORE_USER_KARMA_HISTORY,
    LOAD_MORE_USER_CHANNEL_MESSAGES,
    GET_USER_CHANNEL_MESSAGES,
    GET_USER_CHANNELS,
    LOAD_MORE_USER_CHANNELS,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PASSWORD,
    GET_NOTIFICATIONS,
    LOAD_MORE_NOTIFICATIONS,
    READ_NOTIFICATIONS,
    BLOCK_USER,
    UNBLOCK_USER,
    GET_USER_CONTACTS,
    LOAD_MORE_USER_CONTACTS, UPDATE_USER_MUTE,
} from '../action-types';

import api from '../../networking/api';

let OFFSETS = {};

/**
 * **********
 * GET_USER
 * **********
 */
const getUserSuccess = (userID, data) => {
    return {
        type: GET_USER,
        payload: {
            userID, data
        }
    };
};

export const getUser = (userID) => {
    return async (dispatch) => {
        let response = await api.call(`GET user/${userID}`),
            { success, result } = response;
        if(success) {
            await dispatch(getUserSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * BLOCK_USER
 * **********
 */
const blockUserSuccess = (userID, data) => {
    return {
        type: BLOCK_USER,
        payload: {
            userID, data
        }
    };
};

export const blockUser = (userID) => {
    return async (dispatch) => {
        let response = await api.call(`POST user/block`, { userID }),
            { success, result } = response;
        if(success) {
            await dispatch(blockUserSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * UNBLOCK_USER
 * **********
 */
const unblockUserSuccess = (userID, data) => {
    return {
        type: UNBLOCK_USER,
        payload: {
            userID, data
        }
    };
};

export const unblockUser = (userID) => {
    return async (dispatch) => {
        let response = await api.call(`POST user/unblock`, { userID }),
            { success, result } = response;
        if(success) {
            await dispatch(unblockUserSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * GET_ME
 * **********
 */
const getMeSuccess = (data) => {
    return {
        type: GET_ME,
        payload: {
            data
        }
    };
};

export const getMe = () => {
    return async (dispatch) => {
        let response = await api.call(`GET user/me`),
            { success, result } = response;
        if(success) {
            await dispatch(getMeSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * UPDATE_PROFILE_IMAGE
 * **********
 */
const updateProfileImageSuccess = (profileImage) => {
    return {
        type: UPDATE_PROFILE_IMAGE,
        payload: {
            profileImage
        }
    };
};

export const updateProfileImage = (imageUri) => {
    return async (dispatch) => {
        let response = await api.uploadImage('PUT user/upload-profile-image', 'profileImage', imageUri),
            { success, result } = response;
        if(success) {
            await dispatch(updateProfileImageSuccess(result));
        }
        return response;
    };
};


/**
 * **********
 * UPDATE_USER_MUTE
 * **********
 */
const updateMuteSuccess = (data) => {
    return {
        type: UPDATE_USER_MUTE,
        payload: {
            data
        }
    };
};

export const updateMute = (mute) => {
    return async (dispatch) => {
        let response = await api.call('POST user/update-mute', {
                mute
            }),
            { success, result } = response;
        if(success) {
            await dispatch(updateMuteSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * GET_USER_KARMA_HISTORY
 * **********
 */
const getUserKarmaHistorySuccess = (userID, data) => {
    return {
        type: GET_USER_KARMA_HISTORY,
        payload: {
            userID, data
        }
    };
};

export const retrieveUserKarmaHistory = (userID) => {
    let limit = 10, offset = 0;
    OFFSETS[GET_USER_KARMA_HISTORY] = OFFSETS[GET_USER_KARMA_HISTORY] || {};
    OFFSETS[GET_USER_KARMA_HISTORY][userID] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET user/${userID}/karma-history?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            await dispatch(getUserKarmaHistorySuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * LOAD_MORE_USER_KARMA_HISTORY
 * **********
 */
const loadMoreUserKarmaHistorySuccess = (userID, data) => {
    return {
        type: LOAD_MORE_USER_KARMA_HISTORY,
        payload: {
            userID, data
        }
    };
};

export const loadMoreUserKarmaHistory = (userID) => {
    if(OFFSETS[GET_USER_KARMA_HISTORY][userID] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreUserKarmaHistorySuccess(userID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_USER_KARMA_HISTORY][userID]) {
        OFFSETS[GET_USER_KARMA_HISTORY][userID] = 0;
    }

    let limit = 10, offset = 10 + OFFSETS[GET_USER_KARMA_HISTORY][userID];

    return async (dispatch) => {
        let response = await api.call(`GET user/${userID}/karma-history?limit=${limit}&offset=${offset}`),
            { result, success } = response;
        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_USER_KARMA_HISTORY][userID] += result.length;
            } else {
                OFFSETS[GET_USER_KARMA_HISTORY][userID] = null;
            }
            await dispatch(loadMoreUserKarmaHistorySuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * GET_USER_KARMA_HISTORY
 * **********
 */
const getUserContactsSuccess = (userID, data) => {
    return {
        type: GET_USER_CONTACTS,
        payload: {
            userID, data
        }
    };
};

export const retrieveUserContacts = (userID) => {
    let limit = 10, offset = 0;
    OFFSETS[GET_USER_CONTACTS] = OFFSETS[GET_USER_CONTACTS] || {};
    OFFSETS[GET_USER_CONTACTS][userID] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET user/${userID}/contacts?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            await dispatch(getUserContactsSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * LOAD_MORE_USER_CONTACTS
 * **********
 */
const loadMoreUserContactsSuccess = (userID, data) => {
    return {
        type: LOAD_MORE_USER_CONTACTS,
        payload: {
            userID, data
        }
    };
};

export const loadMoreUserContacts = (userID) => {
    if(OFFSETS[GET_USER_CONTACTS][userID] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreUserContactsSuccess(userID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_USER_CONTACTS][userID]) {
        OFFSETS[GET_USER_CONTACTS][userID] = 0;
    }

    let limit = 10, offset = 10 + OFFSETS[GET_USER_CONTACTS][userID];

    return async (dispatch) => {
        let response = await api.call(`GET user/${userID}/contacts?limit=${limit}&offset=${offset}`),
            { result, success } = response;
        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_USER_CONTACTS][userID] += result.length;
            } else {
                OFFSETS[GET_USER_CONTACTS][userID] = null;
            }
            await dispatch(loadMoreUserContactsSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * GET_USER_CHANNEL_MESSAGES
 * **********
 */
const getUserChannelMessageSuccess = (userID, data) => {
    return {
        type: GET_USER_CHANNEL_MESSAGES,
        payload: {
            userID, data
        }
    };
};

export const retrieveUserChannelMessages = (userID) => {
    let limit = 10, offset = 0;
    OFFSETS[GET_USER_CHANNEL_MESSAGES] = OFFSETS[GET_USER_CHANNEL_MESSAGES] || {};
    OFFSETS[GET_USER_CHANNEL_MESSAGES][userID] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET user/${userID}/channel-messages?limit=${limit}&offset=${offset}`),
            { result, success } = response;
        if(success) {
            await dispatch(getUserChannelMessageSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * LOAD_MORE_USER_CHANNEL_MESSAGES
 * **********
 */
const loadMoreUserChannelMessagesSuccess = (userID, data) => {
    return {
        type: LOAD_MORE_USER_CHANNEL_MESSAGES,
        payload: {
            userID, data
        }
    };
};

export const loadMoreUserChannelMessages = (userID) => {
    if(OFFSETS[GET_USER_CHANNEL_MESSAGES][userID] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreUserChannelMessagesSuccess(userID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_USER_CHANNEL_MESSAGES][userID]) {
        OFFSETS[GET_USER_CHANNEL_MESSAGES][userID] = 0;
    }

    let limit = 10, offset = 10 + OFFSETS[GET_USER_CHANNEL_MESSAGES][userID];

    return async (dispatch) => {
        let response = await api.call(`GET user/${userID}/channel-messages?limit=${limit}&offset=${offset}`),
            { result, success } = response;
        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_USER_CHANNEL_MESSAGES][userID] += result.length;
            } else {
                OFFSETS[GET_USER_CHANNEL_MESSAGES][userID] = null;
            }
            await dispatch(loadMoreUserChannelMessagesSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * GET_USER_CHANNELS
 * **********
 */
const getUserChannelsSuccess = (userID, data) => {
    return {
        type: GET_USER_CHANNELS,
        payload: {
            userID, data
        }
    };
};

export const retrieveUserChannels = (userID) => {
    let limit = 10, offset = 0;
    OFFSETS[GET_USER_CHANNELS] = OFFSETS[GET_USER_CHANNELS] || {};
    OFFSETS[GET_USER_CHANNELS][userID] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET user/${userID}/subscriptions?limit=${limit}&offset=${offset}`),
            { result, success } = response;
        if(success) {
            await dispatch(getUserChannelsSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * LOAD_MORE_USER_CHANNELS
 * **********
 */
const loadMoreUserChannelsSuccess = (userID, data) => {
    return {
        type: LOAD_MORE_USER_CHANNELS,
        payload: {
            userID, data
        }
    };
};

export const loadMoreUserChannels = (userID) => {
    if(OFFSETS[GET_USER_CHANNELS][userID] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreUserChannelsSuccess(userID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_USER_CHANNELS][userID]) {
        OFFSETS[GET_USER_CHANNELS][userID] = 0;
    }

    let limit = 10, offset = 10 + OFFSETS[GET_USER_CHANNELS][userID];

    return async (dispatch) => {
        let response = await api.call(`GET user/${userID}/subscriptions?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_USER_CHANNELS][userID] += result.length;
            } else {
                OFFSETS[GET_USER_CHANNELS][userID] = null;
            }
            await dispatch(loadMoreUserChannelsSuccess(userID, result));
        }
        return response;
    };
};

/**
 * **********
 * READ_NOTIFICATIONS
 * **********
 */
const readNotificationsSuccess = (data) => {
    return {
        type: READ_NOTIFICATIONS,
        payload: {
            data
        }
    };
};

export const readNotifications = () => {
    return async (dispatch) => {
        let response = await api.call(`POST user/read-notifications`),
            { result, success } = response;
        if(success) {
            await dispatch(readNotificationsSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * GET_NOTIFICATIONS
 * **********
 */
const getNotificationsSuccess = (data) => {
    return {
        type: GET_NOTIFICATIONS,
        payload: {
            data
        }
    };
};

export const retrieveNotifications = () => {
    let limit = 10, offset = 0;
    OFFSETS[GET_NOTIFICATIONS] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET user/notifications?limit=${limit}&offset=${offset}`),
            { result, success } = response;
        if(success) {
            await dispatch(getNotificationsSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * LOAD_MORE_NOTIFICATIONS
 * **********
 */
const loadMoreNotificationsSuccess = (data) => {
    return {
        type: LOAD_MORE_NOTIFICATIONS,
        payload: {
            data
        }
    };
};

export const loadMoreNotifications = () => {
    if(OFFSETS[GET_NOTIFICATIONS] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreNotificationsSuccess([]));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_NOTIFICATIONS]) {
        OFFSETS[GET_NOTIFICATIONS] = 0;
    }

    let limit = 10, offset = 10 + OFFSETS[GET_NOTIFICATIONS];

    return async (dispatch) => {
        let response = await api.call(`GET user/notifications?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_NOTIFICATIONS] += result.length;
            } else {
                OFFSETS[GET_NOTIFICATIONS] = null;
            }
            await dispatch(loadMoreNotificationsSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * UPDATE_USER_PROFILE
 * **********
 */
const updateProfileSuccess = (data) => {
    return {
        type: UPDATE_USER_PROFILE,
        payload: {
            data
        }
    };
};

export const updateProfile = (formData) => {
    return async (dispatch) => {
        let response = await api.call('POST user/update-profile', formData),
            { success, result } = response;
        if(success) {
            await dispatch(updateProfileSuccess(result));
        }
        return response;
    };
};

/**
 * **********
 * UPDATE_USER_PASSWORD
 * **********
 */
const updatePasswordSuccess = (data) => {
    return {
        type: UPDATE_USER_PASSWORD,
        payload: {
            data
        }
    };
};

export const updatePassword = (formData) => {
    return async (dispatch) => {
        let response = await api.call('POST user/update-password', formData),
            { success, result } = response;
        if(success) {
            await dispatch(updatePasswordSuccess(result));
        }
        return response;
    };
};
