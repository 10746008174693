import { Platform } from 'react-native';
import * as BackgroundFetch from 'expo-background-fetch';
import * as TaskManager from 'expo-task-manager';
import api from '../networking/api';

const BACKGROUND_UPDATE = 'BACKGROUND_UPDATE',
    INTERVAL = 15 * 60;

if(Platform.OS !== 'web') {
    TaskManager.defineTask(BACKGROUND_UPDATE, async () => {
        await api.call('POST user/ping');
        return BackgroundFetch.Result.NewData;
    });
}

/**
 * @returns {Promise<void>}
 */
export async function registerFetchTask() {
    if(Platform.OS === 'web') return;
    const status = await BackgroundFetch.getStatusAsync();
    switch (status) {
        case BackgroundFetch.Status.Restricted:
        case BackgroundFetch.Status.Denied:
            return;

        default: {
            let tasks = await TaskManager.getRegisteredTasksAsync();
            if (tasks.find(f => f.taskName === BACKGROUND_UPDATE) == null) {
                await BackgroundFetch.registerTaskAsync(BACKGROUND_UPDATE);
                tasks = await TaskManager.getRegisteredTasksAsync();
            }
            await BackgroundFetch.setMinimumIntervalAsync(INTERVAL);
        }
    }
}
