import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    ViewPropTypes,
    Animated,
    TouchableWithoutFeedback,
    Easing
} from 'react-native';
import { View, Text, Image, Avatar, Icon, Loader } from '../base';
import { withStyles } from "../../theme";
import { LinearGradient } from "expo-linear-gradient";

const TOAST_ANIMATION_DURATION = 200;
const FADE_ANIMATION_DURATION = 750;
const TOAST_VISIBLE_DURATION = 2000;

export const Containers = {};

class Container extends Component {
    static displayName = 'ToastContainer';

    static propTypes = {
        ...ViewPropTypes,
        toastID: PropTypes.string,
        title: PropTypes.string,
        nickname: PropTypes.string,
        message: PropTypes.string,
        duration: PropTypes.number,
        avatarUrl: PropTypes.any,
        variation: PropTypes.string,
        icon: PropTypes.string,
        emoji: PropTypes.string,
        visible: PropTypes.bool,
        animation: PropTypes.bool,
        fadeChildren: PropTypes.any,
        opacity: PropTypes.number,
        delay: PropTypes.number,
        hideOnPress: PropTypes.bool,
        onPress: PropTypes.func,
        onHide: PropTypes.func,
        onHidden: PropTypes.func,
        onShow: PropTypes.func,
        onShown: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        duration: TOAST_VISIBLE_DURATION,
        animation: true,
        opacity: 0.9,
        delay: 0,
        hideOnPress: true
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible,
            isLoading: false,
            marginTop: new Animated.Value(-70 - (props.theme.SIZES.SCREEN_HEIGHT * .05)),
            opacity: new Animated.Value(0),
        };
    }

    setLoading = (isLoading) => this.setState({
        isLoading
    });

    componentDidMount = () => {
        if (this.state.visible) {
            this._showTimeout = setTimeout(() => this._show(), this.props.delay);
        }
        Containers[this.props.toastID] = this;
    };

    componentWillReceiveProps = nextProps => {
        if (nextProps.visible !== this.props.visible) {
            if (nextProps.visible) {
                clearTimeout(this._showTimeout);
                clearTimeout(this._hideTimeout);
                this._showTimeout = setTimeout(() => this._show(), this.props.delay);
            } else {
                this._hide();
            }

            this.setState({
                visible: nextProps.visible
            });
        }
    };

    componentWillUnmount = () => {
        delete Containers[this.props.toastID];
        this._hide();
    };

    _animating = false;
    _root = null;
    _hideTimeout = null;
    _showTimeout = null;

    _show = () => {
        const { onShow, onShown, siblingManager, duration, animation, fade, opacity } = this.props;
        clearTimeout(this._showTimeout);
        if (!this._animating) {
            clearTimeout(this._hideTimeout);
            this._animating = true;
            this._root && this._root.setNativeProps({
                pointerEvents: 'auto'
            });
            onShow && onShow(siblingManager);

            Animated.timing(this.state.marginTop, {
                toValue: 0,
                duration: fade ? FADE_ANIMATION_DURATION : animation ? TOAST_ANIMATION_DURATION : 0,
                easing: Easing.out(Easing.ease)
            }).start();

            Animated.timing(this.state.opacity, {
                toValue: opacity,
                duration: fade ? FADE_ANIMATION_DURATION :  animation ? TOAST_ANIMATION_DURATION : 0,
                easing: Easing.out(Easing.ease)
            }).start(() => {
                this._animating = false;
                onShown && onShown(siblingManager);
                if (duration > 0) {
                    this._hideTimeout = setTimeout(() => this._hide(), duration);
                }
            });
        }
    };

    _hide = () => {
        clearTimeout(this._showTimeout);
        clearTimeout(this._hideTimeout);
        if (!this._animating && this.state.opacity._value > 0) {
            this._root && this._root.setNativeProps({
                pointerEvents: 'none'
            });
            this.props.onHide && this.props.onHide(this.props.siblingManager);
            Animated.timing(this.state.opacity, {
                toValue: 0,
                duration: this.props.fade ? FADE_ANIMATION_DURATION : this.props.animation ? TOAST_ANIMATION_DURATION : 0,
                easing: Easing.in(Easing.ease)
            }).start(() => {
                this._animating = false;
                this.props.onHidden && this.props.onHidden(this.props.toastID);
            });
        }
    };

    render() {
        const {
            styles,
            onPress,
            hideOnPress,
            title,
            theme,
            avatarUrl,
            nickname,
            variation = 'standard',
            icon,
            emoji,
            small,
            fade,
            fadeChildren,
            message
        } = this.props;

        const gradient = theme.COLORS.GRADIENTS[variation.toUpperCase()];

        return (this.state.visible || this._animating) ? <Animated.View
            style={(fade ? [ styles.fade, { opacity: this.state.opacity } ] : styles.wrapper)}
            pointerEvents="box-none"
        >
            {!fade && (
                <TouchableWithoutFeedback
                    disabled={this.state.isLoading}
                    onPress={() => {
                        typeof onPress === 'function' ? onPress() : null;
                        hideOnPress ? this._hide() : null
                    }}
                >
                    <Animated.View
                        style={[ ( small ? styles.smallContainer : styles.container ), { opacity: this.state.opacity, marginTop: this.state.marginTop }]}
                        pointerEvents="none"
                        ref={ele => this._root = ele}
                    >
                        {small ? (
                            <LinearGradient {...gradient} style={styles.smallContent}>
                                <View style={styles.smallIconWrapper}>
                                    <Icon name={icon} style={styles.smallIcon}  />
                                </View>
                                <Text numberOfLines={2} style={styles.smallMessageText}>
                                    {message}
                                </Text>
                            </LinearGradient>
                        ) : (!!title && !!nickname) ? (
                            <LinearGradient {...gradient} style={styles.contentWithTitle}>
                                <Text numberOfLines={1} style={styles.titleText}>
                                    {title}
                                </Text>
                                <View style={styles.messageWrapper}>
                                    {!!avatarUrl && (
                                        <Avatar style={styles.imageSmall} avatarUrl={avatarUrl} />
                                    )}
                                    <Text numberOfLines={1} style={styles.nicknameText}>
                                        {nickname}:
                                    </Text>
                                    <Text numberOfLines={1} style={styles.messageText}>
                                        {message}
                                    </Text>
                                </View>
                            </LinearGradient>
                        ) : (
                            <LinearGradient {...gradient} style={styles.content}>
                                {!!avatarUrl && (
                                    <Avatar style={styles.imageLarge} avatarUrl={avatarUrl} />
                                )}
                                {icon && (
                                    this.state.isLoading ? (
                                        <View style={styles.loadingWrapper}>
                                            <Loader color={theme.COLORS.TEXT} size={'small'} />
                                        </View>
                                    ) : (
                                        <View style={styles.iconWrapper}>
                                            <Icon name={icon} style={styles.icon}  />
                                        </View>
                                    )
                                )}
                                {emoji && (
                                    this.state.isLoading ? (
                                        <View style={styles.loadingWrapper}>
                                            <Loader color={theme.COLORS.TEXT} size={'small'} />
                                        </View>
                                    ) : (
                                        <View style={styles.emojiWrapper}>
                                            <Text style={styles.emoji}>{emoji}</Text>
                                        </View>
                                    )
                                )}
                                <View style={styles.textContent}>
                                    <Text numberOfLines={1} style={[ styles.nicknameStyle, ( this.state.isLoading ? styles.textLoading : null )]}>
                                        {title}
                                    </Text>
                                    <Text numberOfLines={1} style={[ styles.textStyle, ( this.state.isLoading ? styles.textLoading : null ) ]}>
                                        {message}
                                    </Text>
                                </View>
                            </LinearGradient>
                        )}

                    </Animated.View>
                </TouchableWithoutFeedback>
            )}
            {fade && (
                fadeChildren
            )}
        </Animated.View> : null;
    }
}

export const ToastContainer = withStyles(Container, theme => ({
    wrapper: {
        position: 'absolute',
        width: theme.SIZES.SCREEN_WIDTH,
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
        top: theme.SIZES.SCREEN_HEIGHT * .05,
        left: 0,
        right: 0
    },
    fade: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: theme.SIZES.SCREEN_WIDTH,
        height: theme.SIZES.SCREEN_HEIGHT,
        backgroundColor: theme.COLORS.BLACK
    },
    container: {
        width: '100%',
        opacity: 0.95,
        height: 70,
        borderRadius: 20,
        shadowColor: theme.COLORS.BLACK,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        elevation: 1
    },
    smallContainer: {
        width: '100%',
        opacity: 0.95,
        borderRadius: 12.5,
        shadowColor: theme.COLORS.BLACK,
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        elevation: 1
    },
    contentWithTitle: {
        width: '100%',
        paddingHorizontal: 21,
        justifyContent: 'center',
        borderRadius: 20,
        height: 70
    },
    smallContent: {
        width: '100%',
        paddingVertical: 5.5,
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12.5,
        flexDirection: 'row'
    },
    smallMessageText: {
        flex: 1,
        fontSize: 14,
        fontWeight: '500',
        color: theme.COLORS.WHITE
    },
    smallIconWrapper: {
        width: 35,
        height: 35,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING * .5
    },
    smallIcon: {
        color: theme.COLORS.WHITE,
        fontSize: 19
    },
    messageWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleText: {
        fontSize: 14,
        color: theme.COLORS.WHITE,
        fontWeight: '900',
        marginBottom: 4
    },
    nicknameText: {
        color: theme.COLORS.WHITE,
        fontWeight: '900',
        fontSize: 13
    },
    messageText: {
        color: theme.COLORS.WHITE,
        marginLeft: 5,
        fontSize: 13,
        fontWeight: '500',
        flex: 1
    },
    content: {
        flexDirection: 'row',
        paddingHorizontal: 17,
        alignItems: 'center',
        borderRadius: 20,
        height: 70,
    },
    imageSmall: {
        width: 16,
        height: 16,
        borderRadius: 5,
        marginRight: 5
    },
    imageLarge: {
        width: 40,
        height: 40,
        borderRadius: 13,
        marginRight: 5
    },
    iconWrapper: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    emojiWrapper: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingWrapper: {
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        color: theme.COLORS.WHITE,
        fontSize: 31
    },
    emoji: {
        color: theme.COLORS.WHITE,
        lineHeight: 40,
        fontSize: theme.SIZES.PLATFORM_OS === 'ios' ? 31 : 28
    },
    textContent: {
        flex: 1,
        marginLeft: 5
    },
    nicknameStyle: {
        fontSize: 13,
        fontWeight: '700',
        color: theme.COLORS.WHITE,
    },
    textStyle: {
        fontSize: 12,
        fontWeight: '300',
        color: theme.COLORS.WHITE
    },
    textLoading: {
        opacity: .8
    }
}));
