import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Text from './Text';
import { ViewPropTypes } from "react-native";

class AnimatedDots extends Component {

    state = {
        dots: ''
    };

    static propTypes = {
        ...ViewPropTypes,
        dotsCount: PropTypes.number,
        interval: PropTypes.number
    };

    static defaultProps = {
        dotsCount: 3,
        interval: 300
    };

    componentDidMount() {
        this.count = 0;
        this.tick = setInterval(() => {
            if(this.count >= this.props.dotsCount) {
                this.count = 0;
            } else {
                this.count++;
            }
            this.updateDots();
        }, 300);
    }

    updateDots() {
        this.setState({ dots: '.'.repeat(this.count) + '\u00A0'.repeat(this.props.dotsCount - this.count) })
    }

    componentWillUnmount() {
        if(this.tick) {
            clearInterval(this.tick);
        }
    }

    render () {
        const { dotsCount, ...restProps } = this.props;
        return (
            <Text {...restProps}>{this.state.dots}</Text>
        );
    }
}

export default AnimatedDots;
