import COLORS from './colors';
import SIZES from './sizes';
import FONTS from './fonts';
import IMAGES from './images';

export default {
    name: 'light',
    title: 'Light',
    icon: '🌞',
    COLORS, SIZES, FONTS, IMAGES
};
