import React from 'react';

import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Image, View, KView, Screen, Text } from '../../components/base';
import { TextInput, Button, Toast } from '../../components';
import { images } from '../../../assets'
import { withStyles } from "../../theme";
import { signIn } from "../../state/auth/actions";
import Constants from 'expo-constants';
import screenNames from '../../navigation/screenNames';
import styles from './styles';
import { wait } from "../../utils/async";
import { registerDevice } from "../../state/common/actions";

class WelcomeScreen extends Screen {

    screenName = screenNames.WELCOME;

    state = {
        form: {
            nickname: '',
            password: ''
        },
        showPassword: false,
        isLoading: false
    };

    onPressLogin = async () => {
        const { nickname, password } = this.state.form,
            { navigation, intl } = this.props;

        if(!nickname) {
            return this._nicknameInput.focus()
        } else if (!password) {
            return this._passwordInput.focus()
        }

        await this.setState({ isLoading: true });

        const { success, error } = await this.props.signIn(nickname, password);

        if(success) {
            if(this.props.pushToken !== null) {
                await this.props.registerDevice({
                    token: this.props.pushToken
                });
            }
            await this.setState({ isLoading: false });
            return navigation.navigate(screenNames.SIGNED_STACK);
        } else if(!error) {
            Toast.showSimpleDanger(intl.formatMessage({ id: 'welcome.wrong_login_or_password' }));
        }

        await this.setState({ isLoading: false });
    };

    content() {

        const { form, showPassword, isLoading } = this.state;
        const { intl, navigation, styles, theme } = this.props;

        return (
            <KView style={styles.screenWrapper}>
                <Image blurRadius={10} resizeMode={'cover'} style={styles.cover} source={theme.IMAGES.welcomeBackground} />

                <Image source={theme.IMAGES.welcomeLogo} style={{ width: 76, height: 65 }} />

                <View style={styles.form}>
                    <TextInput
                        style={styles.textInput}
                        placeholder={intl.formatMessage({ 'id': 'welcome.nickname_placeholder' })}
                        value={form.nickname}
                        variation={'basic'}
                        blurOnSubmit={false}
                        inputRef={component => this._nicknameInput = component}
                        onSubmitEditing={this.onPressLogin}
                        onChangeText={(nickname) => this.setState({ form: { ...form, nickname } })}
                    />
                    <TextInput
                        style={styles.textInput}
                        secureTextEntry={!showPassword}
                        inputRef={component => this._passwordInput = component}
                        placeholder={intl.formatMessage({ 'id': 'welcome.password_placeholder' })}
                        icon={showPassword ? 'eye-off' : 'eye'}
                        variation={'basic'}
                        value={form.password}
                        onSubmitEditing={this.onPressLogin}
                        onChangeText={(password) => this.setState({ form: { ...form, password } })}
                        onPressIcon={() => this.setState({ showPassword: !showPassword })}
                    />
                    <Button
                        loading={isLoading}
                        onPress={this.onPressLogin}
                        variation={'primary'}
                        style={styles.submitButton}
                    >
                        {intl.formatMessage({ 'id': 'welcome.login' })} 👋
                    </Button>

                    <Button
                        variation={'transparent'}
                        style={styles.submitButton}
                        onPress={() => navigation.navigate(screenNames.SIGN_UP)}
                    >
                        {intl.formatMessage({ 'id': 'welcome.dont_have_account' })} <Text style={styles.signUpLink}>{intl.formatMessage({ 'id': 'welcome.sign_up' })}</Text>
                    </Button>
                </View>

                <Text style={styles.versionText}>v{Constants.manifest.version}</Text>
            </KView>
        );
    }
}

const mapStateToProps = ({ auth, common }) => ({
    pushToken: auth.pushToken,
    deepLink: common.deepLink
});

const mapDispatchToProps = dispatch => ({
    registerDevice: (token) => dispatch(registerDevice(token)),
    signIn: (login, password) => dispatch(signIn(login, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(WelcomeScreen, styles))
);
