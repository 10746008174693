/**
 * @param obj
 */
export default function (obj) {
    let key, mirrored = {};

    if (obj && typeof obj === 'object') {
        for (key in obj) {
            if (obj.hasOwnProperty(key)) {
                mirrored[key] = key;
            }
        }
    }
    return mirrored;
};