import COLORS from './colors';
import SIZES from './sizes';
import FONTS from './fonts';
import IMAGES from './images';

export default {
    name: 'default',
    title: 'Dark',
    icon: '🌚',
    COLORS, SIZES, FONTS, IMAGES
};
