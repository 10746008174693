import AppLoadingScreen from './AppLoadingScreen';
import HomeScreen from './HomeScreen';
import WelcomeScreen from './WelcomeScreen';
import SignUpScreen from './SignUpScreen';
import TopicScreen from './TopicScreen';
import WalkthroughScreen from './WalkthroughScreen';
import ProfileScreen from './ProfileScreen';
import MyProfileScreen from './ProfileScreen/MyProfileScreen';
import SearchScreen from './SearchScreen';
import NotificationsScreen from './NotificationsScreen';
import ConversationScreen from './ConversationScreen';
import SettingsScreen from './SettingsScreen';
import ContactsScreen from './ContactsScreen';
import EditLocationScreen from './EditLocationScreen';
import ContactsRequestsScreen from './ContactsRequestsScreen';
import TopicMembersScreen from './TopicMembersScreen';
import StartScreen from './HomeScreen/StartScreen';
import FeedScreen from './FeedScreen';

export default {
    AppLoadingScreen,
    WelcomeScreen,
    HomeScreen,
    SignUpScreen,
    TopicScreen,
    WalkthroughScreen,
    ProfileScreen,
    MyProfileScreen,
    ContactsScreen,
    SettingsScreen,
    SearchScreen,
    NotificationsScreen,
    TopicMembersScreen,
    ContactsRequestsScreen,
    ConversationScreen,
    EditLocationScreen,
    StartScreen,
    FeedScreen
};
