export default (theme) => ({
    screenWrapper: {
        flex: 1,
        backgroundColor: theme.COLORS.BACKGROUND,
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        position: 'absolute',
        width: theme.SIZES.SCREEN_WIDTH,
        top: 0,
        height: theme.SIZES.SCREEN_HEIGHT * .6,
        overflow: 'hidden'
    },
    headerContent: {
        flexDirection: 'row',
        marginTop: theme.SIZES.SCREEN_HEIGHT * .01,
        height: theme.SIZES.SCREEN_HEIGHT * .2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    headingText: {
        marginTop: theme.SIZES.SCREEN_HEIGHT * .01,
        color: theme.COLORS.TEXT,
        fontSize: 24,
        fontWeight: '700'
    },
    cover: {
        width: theme.SIZES.SCREEN_WIDTH,
        height: theme.SIZES.SCREEN_HEIGHT,
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: .2
    },
    form: {
        width: '100%',
        minHeight: theme.SIZES.SCREEN_HEIGHT * (theme.SIZES.PLATFORM_OS === 'ios' ? .75 : .7),
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
        paddingTop: theme.SIZES.CONTENT_HORIZONTAL_MARGIN + 10,
        paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN + 10,
        backgroundColor: theme.COLORS.BACKGROUND,
        alignItems: 'center',
        flex: 1,
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25
    },
    textInput: {
        marginBottom: theme.SIZES.SCREEN_HEIGHT * .04,
    },
    agreeText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 13,
        textAlign: 'center'
    },
    agreeTextLink: {
        textDecorationLine: 'underline'
    },
    submitButton: {
        marginBottom: theme.SIZES.SCREEN_HEIGHT * .04,
    },
    signInButton: {
        marginTop: -theme.SIZES.SCREEN_HEIGHT * .05,
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
    },
    topMenuWrapper: {
        width: '100%',
        flexDirection: 'row',
        position: 'absolute',
        top: 25
    },
    backButton: {
        position: 'absolute',
        left: 0,
        paddingVertical: 10,
        paddingLeft: 15,
        paddingRight: 20,
        alignItems: 'flex-start'
    },
    backButtonIcon: {
        marginTop: 10,
        color: theme.COLORS.TEXT,
        fontSize: 33
    },
    signInLink: {
        fontWeight: '900',
        color: theme.COLORS.PRIMARY
    }
});
