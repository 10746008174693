import {
    ADD_CHANNEL_RULE,
    BAN_CHANNEL_MEMBER,
    CHANNEL_MESSAGE_CHECK_VOTE,
    CHANNEL_MESSAGE_VOTE,
    CHANNEL_SUBSCRIBE,
    CHANNEL_UNSUBSCRIBE, CHANNEL_VOTE,
    CLOSE_CHANNEL,
    CLOSE_CHANNEL_MESSAGE,
    CREATE_CHANNEL,
    CREATE_RICH_CHANNEL,
    DELETE_CHANNEL_MESSAGE,
    GET_CHANNEL,
    GET_CHANNEL_BACKGROUNDS,
    GET_CHANNEL_ICONS,
    GET_CHANNEL_MEMBERS_LIST,
    GET_CHANNEL_MESSAGES,
    GET_CHANNEL_MESSAGES_ASC,
    GET_LIST_CHANNELS,
    LOAD_MORE_CHANNEL_MEMBERS_LIST,
    LOAD_MORE_CHANNEL_MESSAGES,
    LOAD_MORE_CHANNEL_MESSAGES_ASC,
    LOAD_MORE_LIST_CHANNELS,
    LOAD_MORE_SEARCH_CHANNEL_MEMBERS_LIST,
    MUTE_CHANNEL,
    OPEN_CHANNEL_MESSAGE,
    RECEIVED_CHANNEL_EVENT,
    REMOVE_CHANNEL_RULE,
    REPORT_CHANNEL,
    REPORT_CHANNEL_MESSAGE,
    RETRIEVE_CHANNEL_RULES,
    SEARCH_CHANNEL_MEMBERS_LIST,
    SEND_CHANNEL_MESSAGE,
    UNBAN_CHANNEL_MEMBER,
    UPDATE_CHANNEL,
    UPDATE_CHANNEL_LAST_VISIT,
    UPDATE_CHANNEL_RULE,
    UPLOAD_CHANNEL_BACKGROUND,
    UPLOAD_CHANNEL_THUMBNAIL
} from "../action-types";

import keyValueStorage from '../../utils/keyValueStorage';
import api from '../../networking/api';
import comet from '../../networking/comet';
import { getCurrentLocale } from "../../utils/localizationHelper";
const isChannelOpened = () => { return null; };

let OFFSETS = {};

/**
 * **********
 * CREATE_CHANNEL
 * **********
 */
const createChannelSuccess = (channel) => {
    return {
        type: CREATE_CHANNEL,
        payload: {
            channel
        }
    };
};

export const createChannel = (fields) => {
    return async (dispatch) => {
        let response = await api.call('POST channel/create', fields),
            { result, success } = response;

        if(success) {
            await dispatch(createChannelSuccess(result));
        }

        return response;
    };
};

/**
 * **********
 * CREATE_RICH_CHANNEL
 * **********
 */
const createRichChannelSuccess = (channel) => {
    return {
        type: CREATE_RICH_CHANNEL,
        payload: {
            channel
        }
    };
};

export const createRichChannel = (fields, imageUri) => {
    return async (dispatch) => {
        let response = await api.uploadImage('PUT channel/create-rich', 'channelThumbnail', imageUri, fields),
            { result, success } = response;

        if(success) {
            await dispatch(createRichChannelSuccess(result));
        }

        return response;
    };
};

/**
 * **********
 * RECEIVED_CHANNEL_EVENT
 * **********
 */
const receivedChannelEventSuccess = (channelID, data) => {
    return {
        type: RECEIVED_CHANNEL_EVENT,
        payload: {
            channelID, data
        }
    };
};

export const subscribeOnCometChannel = (channelID) => {
    return async (dispatch) => {
        const channel = `/channel/${channelID}`;
        if(comet.getSubscription(channel)) {
            return {
                success: true,
                result: comet.getSubscription(channel)
            };
        }
        return {
            success: true,
            result: comet.subscribe(channel, (data) => {
                dispatch(receivedChannelEventSuccess(channelID, data));
            })
        };
    };
};

/**
 * **********
 * SEND_CHANNEL_MESSAGE
 * **********
 */
const sendMessageSuccess = (channelID, message) => {
    return {
        type: SEND_CHANNEL_MESSAGE,
        payload: {
            channelID, message
        }
    };
};

export const sendMessage = (channelID, replyID, message) => {
    return async (dispatch) => {
        let response = await api.call('POST channel/send-message', {
            channel_id: channelID,
            reply_id: replyID,
            message
        }), { result, success } = response;
        if(success) {
            await dispatch(sendMessageSuccess(channelID, result));
        }
        return response;
    };
};

export const sendPhoto = (channelID, replyID, imageUri) => {
    return async (dispatch) => {
        let response = await api.uploadImage(`PUT channel/${channelID}/send-message?reply_id=${replyID}`, 'sendPhoto', imageUri),
            { result, success } = response;

        if(success) {
            await dispatch(sendMessageSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * UPDATE_CHANNEL_LAST_VISIT
 * **********
 */
const updateLastVisitSuccess = (channelID, data) => {
    return {
        type: UPDATE_CHANNEL_LAST_VISIT,
        payload: {
            channelID, data
        }
    };
};

export const updateLastVisit = (channelID, date) => {
    return async (dispatch) => {
        let lastVisits = await keyValueStorage.get('lastVisits', {});
        lastVisits[channelID] = date + 1;
        await keyValueStorage.set('lastVisits', lastVisits);
        await dispatch(updateLastVisitSuccess(channelID, lastVisits[channelID]));
        return { success: true };
    };
};

/**
 * **********
 * GET_CHANNEL
 * **********
 */
const getChannelSuccess = (channelID, data) => {
    return {
        type: GET_CHANNEL,
        payload: {
            channelID, data
        }
    };
};

export const retrieveChannel = (channelID) => {
    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}`),
            { result, success } = response;

        if(success) {
            await dispatch(updateLastVisit(channelID, result.last_message_at));
            await dispatch(getChannelSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * BAN_CHANNEL_MEMBER
 * **********
 */
const banChannelMemberSuccess = (channelID, data) => {
    return {
        type: BAN_CHANNEL_MEMBER,
        payload: {
            channelID, data
        }
    };
};

export const banChannelMember = (channelID, userID, duration, reason, deleteMessages, ban1, ban2) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/ban-member`, {
            userID, duration, reason, delete_messages: deleteMessages ? 'true' : 'false',
            ban1: ban1 ? 'true' : 'false',
            ban2: ban2 ? 'true' : 'false'
        }), { success, result } = response;

        if(success) {
            await dispatch(banChannelMemberSuccess(channelID, {
                userID, duration, reason, banID: result, deleteMessages
            }));
        }

        return response;
    };
};

/**
 * **********
 * REPORT_CHANNEL
 * **********
 */
const reportChannelSuccess = (channelID, data) => {
    return {
        type: REPORT_CHANNEL,
        payload: {
            channelID, data
        }
    };
};

export const reportChannel = (channelID, reportType) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/report`, {
            channelID, reportType
        }), { success, result } = response;
        if(success) {
            await dispatch(reportChannelSuccess(channelID, result));
        }
        return response;
    };
};

/**
 * **********
 * REPORT_CHANNEL_MESSAGE
 * **********
 */
const reportChannelMessageSuccess = (channelID, messageID, data) => {
    return {
        type: REPORT_CHANNEL_MESSAGE,
        payload: {
            channelID, messageID, data
        }
    };
};

export const reportChannelMessage = (channelID, messageID, reportType) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/report-message`, {
            channelID, messageID, reportType
        }), { success, result } = response;

        if(success) {
            await dispatch(reportChannelMessageSuccess(channelID, messageID, result));
        }

        return response;
    };
};

/**
 * **********
 * UNBAN_CHANNEL_MEMBER
 * **********
 */
const unbanChannelMemberSuccess = (channelID, data) => {
    return {
        type: UNBAN_CHANNEL_MEMBER,
        payload: {
            channelID, data
        }
    };
};

export const unbanChannelMember = (channelID, userID) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/unban-member`, {
            userID
        }), { success } = response;

        if(success) {
            await dispatch(unbanChannelMemberSuccess(channelID, {
                userID
            }));
        }

        return response;
    };
};

/**
 * **********
 * GET_CHANNEL_ICONS
 * **********
 */
const getChannelIconsSuccess = (data) => {
    return {
        type: GET_CHANNEL_ICONS,
        payload: {
            data
        }
    };
};

export const retrieveChannelIcons = () => {
    return async (dispatch) => {
        let response = await api.call(`GET channel/icons`),
            { result, success } = response;

        if(success) {
            await dispatch(getChannelIconsSuccess(result));
        }

        return response;
    };
};

/**
 * **********
 * GET_CHANNEL_BACKGROUNDS
 * **********
 */
const getChannelBackgroundsSuccess = (data) => {
    return {
        type: GET_CHANNEL_BACKGROUNDS,
        payload: {
            data
        }
    };
};

export const retrieveChannelBackgrounds = () => {
    return async (dispatch) => {
        let response = await api.call(`GET channel/backgrounds`),
            { result, success } = response;

        if(success) {
            await dispatch(getChannelBackgroundsSuccess(result));
        }

        return response;
    };
};

/**
 * **********
 * GET_CHANNEL_MESSAGES
 * **********
 */
const getChannelMessagesSuccess = (channelID, data) => {
    return {
        type: GET_CHANNEL_MESSAGES,
        payload: {
            channelID, data
        }
    };
};

export const retrieveChannelMessages = (channelID, safe, mode) => {
    let limit = 20, offset = 0;
    safe = safe || 0;
    mode = mode || 0;
    OFFSETS[GET_CHANNEL_MESSAGES] = OFFSETS[GET_CHANNEL_MESSAGES] || {};
    OFFSETS[GET_CHANNEL_MESSAGES][channelID] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}/messages?limit=${limit}&offset=${offset}&safe=${safe}&mode=${mode}&order=desc`),
            { result, success } = response;

        if(success) {
            await dispatch(getChannelMessagesSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * GET_CHANNEL_MESSAGES_ASC
 * **********
 */
const getChannelMessagesAscSuccess = (channelID, data) => {
    return {
        type: GET_CHANNEL_MESSAGES_ASC,
        payload: {
            channelID, data
        }
    };
};

export const retrieveChannelAscMessages = (channelID, safe, mode) => {
    let limit = 20, offset = 0;
    safe = safe || 0;
    mode = mode || 0;
    OFFSETS[GET_CHANNEL_MESSAGES_ASC] = OFFSETS[GET_CHANNEL_MESSAGES_ASC] || {};
    OFFSETS[GET_CHANNEL_MESSAGES_ASC][channelID] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}/messages?limit=${limit}&offset=${offset}&safe=${safe}&mode=${mode}&order=asc`),
            { result, success } = response;

        if(success) {
            await dispatch(getChannelMessagesAscSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * GET_CHANNEL_MEMBERS_LIST
 * **********
 */
const getChannelMembersSuccess = (channelID, data) => {
    return {
        type: GET_CHANNEL_MEMBERS_LIST,
        payload: {
            channelID, data
        }
    };
};

export const retrieveChannelMembers = (channelID) => {
    OFFSETS[GET_CHANNEL_MEMBERS_LIST] = OFFSETS[GET_CHANNEL_MEMBERS_LIST] || {};
    OFFSETS[GET_CHANNEL_MEMBERS_LIST][channelID] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}/subscribers`),
            { result, success } = response;

        if(success) {
            await dispatch(getChannelMembersSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * SEARCH_CHANNEL_MEMBERS_LIST
 * **********
 */
const searchChannelMembersSuccess = (channelID, data) => {
    return {
        type: SEARCH_CHANNEL_MEMBERS_LIST,
        payload: {
            channelID, data
        }
    };
};

export const searchChannelMembers = (channelID, query) => {
    let limit = 20, offset = 0;
    OFFSETS[SEARCH_CHANNEL_MEMBERS_LIST] = OFFSETS[SEARCH_CHANNEL_MEMBERS_LIST] || {};
    OFFSETS[SEARCH_CHANNEL_MEMBERS_LIST][channelID] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}/subscribers?query=${query}&limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            await dispatch(searchChannelMembersSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * LOAD_MORE_SEARCH_CHANNEL_MEMBERS_LIST
 * **********
 */
const loadMoreSearchChannelMembersSuccess = (channelID, data) => {
    return {
        type: LOAD_MORE_SEARCH_CHANNEL_MEMBERS_LIST,
        payload: {
            channelID, data
        }
    };
};

export const loadMoreSearchChannelMembers = (channelID, query) => {
    if(OFFSETS[SEARCH_CHANNEL_MEMBERS_LIST][channelID] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreSearchChannelMembersSuccess(channelID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[SEARCH_CHANNEL_MEMBERS_LIST][channelID]) {
        OFFSETS[SEARCH_CHANNEL_MEMBERS_LIST][channelID] = 0;
    }

    let limit = 20, offset = 20 + OFFSETS[SEARCH_CHANNEL_MEMBERS_LIST][channelID];

    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}/subscribers?query=${query}&limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[SEARCH_CHANNEL_MEMBERS_LIST][channelID] += result.length;
            } else {
                OFFSETS[SEARCH_CHANNEL_MEMBERS_LIST][channelID] = null;
            }
            await dispatch(loadMoreSearchChannelMembersSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * LOAD_MORE_CHANNEL_MEMBERS_LIST
 * **********
 */
const loadMoreChannelMembersSuccess = (channelID, data) => {
    return {
        type: LOAD_MORE_CHANNEL_MEMBERS_LIST,
        payload: {
            channelID, data
        }
    };
};

export const loadMoreChannelMembers = (channelID) => {
    if(OFFSETS[GET_CHANNEL_MEMBERS_LIST][channelID] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreChannelMembersSuccess(channelID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_CHANNEL_MEMBERS_LIST][channelID]) {
        OFFSETS[GET_CHANNEL_MEMBERS_LIST][channelID] = 0;
    }

    let limit = 20, offset = 20 + OFFSETS[GET_CHANNEL_MEMBERS_LIST][channelID];

    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}/subscribers?limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_CHANNEL_MEMBERS_LIST][channelID] += result.length;
            } else {
                OFFSETS[GET_CHANNEL_MEMBERS_LIST][channelID] = null;
            }
            await dispatch(loadMoreChannelMembersSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * LOAD_MORE_CHANNEL_MESSAGES
 * **********
 */
const loadMoreChannelMessagesSuccess = (channelID, data) => {
    return {
        type: LOAD_MORE_CHANNEL_MESSAGES,
        payload: {
            channelID, data
        }
    };
};

export const loadMoreChannelMessages = (channelID, safe, mode) => {
    if(OFFSETS[GET_CHANNEL_MESSAGES][channelID] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreChannelMessagesSuccess(channelID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_CHANNEL_MESSAGES][channelID]) {
        OFFSETS[GET_CHANNEL_MESSAGES][channelID] = 0;
    }

    safe = safe || 0;
    mode = mode || 0;

    let limit = 20, offset = 20 + OFFSETS[GET_CHANNEL_MESSAGES][channelID];

    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}/messages?limit=${limit}&offset=${offset}&safe=${safe}&mode=${mode}&order=desc`),
            { result, success } =  response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_CHANNEL_MESSAGES][channelID] += result.length;
            } else {
                OFFSETS[GET_CHANNEL_MESSAGES][channelID] = null;
            }
            await dispatch(loadMoreChannelMessagesSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * LOAD_MORE_CHANNEL_MESSAGES_ASC
 * **********
 */
const loadMoreChannelMessagesAscSuccess = (channelID, data) => {
    return {
        type: LOAD_MORE_CHANNEL_MESSAGES_ASC,
        payload: {
            channelID, data
        }
    };
};

export const loadMoreChannelMessagesAsc = (channelID, safe, mode) => {
    if(OFFSETS[GET_CHANNEL_MESSAGES_ASC][channelID] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreChannelMessagesAscSuccess(channelID, []));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_CHANNEL_MESSAGES_ASC][channelID]) {
        OFFSETS[GET_CHANNEL_MESSAGES_ASC][channelID] = 0;
    }

    safe = safe || 0;
    mode = mode || 0;

    let limit = 20, offset = 20 + OFFSETS[GET_CHANNEL_MESSAGES_ASC][channelID];

    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}/messages?limit=${limit}&offset=${offset}&safe=${safe}&mode=${mode}&order=asc`),
            { result, success } =  response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_CHANNEL_MESSAGES_ASC][channelID] += result.length;
            } else {
                OFFSETS[GET_CHANNEL_MESSAGES_ASC][channelID] = null;
            }
            await dispatch(loadMoreChannelMessagesAscSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * GET_LIST_CHANNELS
 * **********
 */
const getListChannelsSuccess = (channels, type, lastVisits) => {
    return {
        type: GET_LIST_CHANNELS,
        payload: {
            channels, type, lastVisits
        }
    };
};

export const retrieveListChannels = (type, cityID, universityID, safe) => {
    OFFSETS[GET_LIST_CHANNELS] = OFFSETS[GET_LIST_CHANNELS] || {};
    OFFSETS[GET_LIST_CHANNELS][type] = 0;
    return async (dispatch) => {
        let response = await api.call(`GET thread/list/${type}?city_id=${cityID}&university_id=${universityID}&locale=${getCurrentLocale()}&safe=${safe}&limit=10&offset=0`),
            { result, success } = response;
        if(success) {
            let lastVisits = await keyValueStorage.get('lastVisits', {});
            await dispatch(getListChannelsSuccess(result, type, lastVisits));
        }

        return response;
    };
};

/**
 * **********
 * LOAD_MORE_LIST_CHANNELS
 * **********
 */
const loadMoreListChannelsSuccess = (channels, type) => {
    return {
        type: LOAD_MORE_LIST_CHANNELS,
        payload: {
            channels, type
        }
    };
};

export const loadMoreListChannels = (type, cityID, universityID, safe) => {
    if(OFFSETS[GET_LIST_CHANNELS][type] === null) {
        return async (dispatch) => {
            await dispatch(loadMoreListChannelsSuccess([], type));
            return { success: true, result: [] };
        };
    }

    if(!OFFSETS[GET_LIST_CHANNELS][type]) {
        OFFSETS[GET_LIST_CHANNELS][type] = 0;
    }

    let limit = 10, offset = 10 + OFFSETS[GET_LIST_CHANNELS][type];

    return async (dispatch) => {
        let response = await api.call(`GET thread/list/${type}?city_id=${cityID}&university_id=${universityID}&locale=${getCurrentLocale()}&safe=${safe}&limit=${limit}&offset=${offset}`),
            { result, success } = response;

        if(success) {
            if(result.length > 0) {
                OFFSETS[GET_LIST_CHANNELS][type] += result.length;
            } else {
                OFFSETS[GET_LIST_CHANNELS][type] = null;
            }
            await dispatch(loadMoreListChannelsSuccess(result, type));
        }

        return response;
    };
};

/**
 * **********
 * CLOSE_CHANNEL
 * **********
 */
const closeChannelSuccess = (channelID) => {
    return {
        type: CLOSE_CHANNEL,
        payload: {
            channelID
        }
    };
};

export const closeChannel = (channelID) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/close`),
            { result, success } = response;
        if(success) {
            await dispatch(closeChannelSuccess(channelID, result));
        }
        return response;
    };
};

/**
 * **********
 * UPDATE_CHANNEL
 * **********
 */
const updateChannelSuccess = (channelID, data) => {
    return {
        type: UPDATE_CHANNEL,
        payload: {
            channelID, data
        }
    };
};

export const updateChannel = (channelID, fields) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/update`, fields),
            { result, success } = response;

        if(success) {
            await dispatch(updateChannelSuccess(channelID, result));
        }

        return response;
    };
};

export const updateChannelThumbnail = (channelID, imageUri) => {
    return async (dispatch) => {
        let response = await api.uploadImage(`PUT channel/${channelID}/upload-thumbnail`, 'channelThumbnail', imageUri),
            { success, result } = response;
        if(success) {
            await dispatch(updateChannelSuccess(channelID, result));
        }

        return response;
    };
};

export const updateChannelBackground = (channelID, imageUri) => {
    return async (dispatch) => {
        let response = await api.uploadImage(`PUT channel/${channelID}/upload-background`, 'channelBackground', imageUri),
            { success, result } = response;
        if(success) {
            await dispatch(updateChannelSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * UPLOAD_CHANNEL_THUMBNAIL
 * **********
 */
const updateChannelThumbnailSuccess = (channelID, data) => {
    return {
        type: UPLOAD_CHANNEL_THUMBNAIL,
        payload: {
            channelID, data
        }
    };
};

/**
 * **********
 * UPLOAD_CHANNEL_BACKGROUND
 * **********
 */
const updateChannelBackgroundSuccess = (channelID, data) => {
    return {
        type: UPLOAD_CHANNEL_BACKGROUND,
        payload: {
            channelID, data
        }
    };
};

/**
 * **********
 * CHANNEL_SUBSCRIBE
 * **********
 */
const subscribeChannelSuccess = (channelID) => {
    return {
        type: CHANNEL_SUBSCRIBE,
        payload: {
            channelID
        }
    };
};

export const subscribeChannel = (channelID) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/subscribe`),
            { success } = response;

        if(success) {
            await dispatch(subscribeChannelSuccess(channelID));
        }

        return response;
    };
};

/**
 * **********
 * CHANNEL_MESSAGE_VOTE
 * **********
 */
const voteChannelMessageSuccess = (channelID, messageID, type) => {
    return {
        type: CHANNEL_MESSAGE_VOTE,
        payload: {
            channelID, messageID, type
        }
    };
};

export const voteChannelMessage = (channelID, messageID, type) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/message/${messageID}/vote`, { type }),
            { success } = response;

        if(success) {
            await dispatch(voteChannelMessageSuccess(channelID, messageID, type));
        }

        return response;
    };
};

/**
 * **********
 * CHANNEL_VOTE
 * **********
 */
const voteChannelSuccess = (channelID, type) => {
    return {
        type: CHANNEL_VOTE,
        payload: {
            channelID, type
        }
    };
};

export const voteChannel = (channelID, type) => {
    return async (dispatch) => {
        let response = await api.call(`POST thread/${channelID}/vote`, { type }),
            { success } = response;
        if(success) {
            await dispatch(voteChannelSuccess(channelID, type));
        }
        return response;
    };
};

/**
 * **********
 * DELETE_CHANNEL_MESSAGE
 * **********
 */
const deleteChannelMessageSuccess = (channelID, messageID) => {
    return {
        type: DELETE_CHANNEL_MESSAGE,
        payload: {
            channelID, messageID
        }
    };
};

export const deleteChannelMessage = (channelID, messageID) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/message/${messageID}/delete`),
            { success } = response;

        if(success) {
            await dispatch(deleteChannelMessageSuccess(channelID, messageID));
        }

        return response;
    };
};

/**
 * **********
 * CHANNEL_MESSAGE_CHECK_VOTE
 * **********
 */
const checkVoteChannelMessageSuccess = (channelID, messageID, hasVote) => {
    return {
        type: CHANNEL_MESSAGE_CHECK_VOTE,
        payload: {
            channelID, messageID, hasVote
        }
    };
};

export const checkVoteChannelMessage = (channelID, messageID) => {
    return async (dispatch) => {
        await dispatch(checkVoteChannelMessageSuccess(channelID, messageID, null));

        let response = await api.call(`POST channel/${channelID}/message/${messageID}/has-vote`),
            { success, result } = response;

        if(success) {
            await dispatch(checkVoteChannelMessageSuccess(channelID, messageID, result));
        } else {
            await dispatch(checkVoteChannelMessageSuccess(channelID, messageID, undefined));
        }

        return response;
    };
};

/**
 * **********
 * OPEN_CHANNEL_MESSAGE
 * **********
 */
export const openChannelMessage = (channelID, messageID) => {
    return async (dispatch) => {
        dispatch({
            type: OPEN_CHANNEL_MESSAGE,
            payload: {
                channelID, messageID
            }
        });

        return { success: true };
    };
};

/**
 * **********
 * CLOSE_CHANNEL_MESSAGE
 * **********
 */
export const closeChannelMessage = (channelID, messageID) => {
    return async (dispatch) => {
        dispatch({
            type: CLOSE_CHANNEL_MESSAGE,
            payload: {
                channelID, messageID
            }
        });

        return { success: true };
    };
};

/**
 * **********
 * CHANNEL_UNSUBSCRIBE
 * **********
 */
const unsubscribeChannelSuccess = (channelID) => {
    return {
        type: CHANNEL_UNSUBSCRIBE,
        payload: {
            channelID
        }
    };
};

export const unsubscribeChannel = (channelID) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/unsubscribe`),
            { success } = response;

        if(success) {
            await dispatch(unsubscribeChannelSuccess(channelID));
        }

        return response;
    };
};

/**
 * **********
 * MUTE_CHANNEL
 * **********
 */
const muteChannelSuccess = (channelID, mute) => {
    return {
        type: MUTE_CHANNEL,
        payload: {
            channelID, mute
        }
    };
};

export const muteChannel = (channelID, mute) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/mute`, { mute }),
            { success } = response;

        if(success) {
            await dispatch(muteChannelSuccess(channelID, mute));
        }

        return response;
    };
};

/**
 * **********
 * RETRIEVE_CHANNEL_RULES
 * **********
 */
const retrieveChannelRulesSuccess = (channelID, rules) => {
    return {
        type: RETRIEVE_CHANNEL_RULES,
        payload: {
            channelID, rules
        }
    };
};

export const retrieveChannelRules = (channelID) => {
    return async (dispatch) => {
        let response = await api.call(`GET channel/${channelID}/rules`),
            { success, result } = response;

        if(success) {
            await dispatch(retrieveChannelRulesSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * ADD_CHANNEL_RULE
 * **********
 */
const addChannelRuleSuccess = (channelID, rule) => {
    return {
        type: ADD_CHANNEL_RULE,
        payload: {
            channelID, rule
        }
    };
};

export const addChannelRule = (channelID, ruleText) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/rules`, {
                title: ruleText
            }),
            { success, result } = response;

        if(success) {
            await dispatch(addChannelRuleSuccess(channelID, result));
        }

        return response;
    };
};

/**
 * **********
 * UPDATE_CHANNEL_RULE
 * **********
 */
const updateChannelRuleSuccess = (channelID, ruleID, rule) => {
    return {
        type: UPDATE_CHANNEL_RULE,
        payload: {
            channelID, rule, ruleID
        }
    };
};

export const updateChannelRule = (channelID, ruleID, ruleText) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/rules/${ruleID}/update`, {
                title: ruleText
            }),
            { success, result } = response;

        if(success) {
            await dispatch(updateChannelRuleSuccess(channelID, ruleID, result));
        }

        return response;
    };
};

/**
 * **********
 * REMOVE_CHANNEL_RULE
 * **********
 */
const removeChannelRuleSuccess = (channelID, ruleID) => {
    return {
        type: REMOVE_CHANNEL_RULE,
        payload: {
            channelID, ruleID
        }
    };
};

export const removeChannelRule = (channelID, ruleID) => {
    return async (dispatch) => {
        let response = await api.call(`POST channel/${channelID}/rules/${ruleID}/delete`),
            { success } = response;

        if(success) {
            await dispatch(removeChannelRuleSuccess(channelID, ruleID));
        }

        return response;
    };
};
