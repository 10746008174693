import React from 'react';

import { fonts, images } from './assets';
import { Notifications, SplashScreen } from 'expo';
import { Asset } from 'expo-asset';
import { Provider } from 'react-redux';
import * as Font from 'expo-font';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import Providers from './src/providers';
import Navigation from './src/navigation';
import reducers from './src/state/reducers';
import Events from './src/utils/events';
import * as Sentry from 'sentry-expo';
import Constants from "expo-constants";
import { AppState, Platform } from 'react-native';
global.ErrorUtils = {
    setGlobalHandler(fun) {
        window.onerror = fun;
    }
};
const store = createStore(reducers, applyMiddleware(thunk));

Sentry.init({
    dsn: Constants.manifest.extra.sentryDsn,
    enableInExpoDevelopment: true,
    debug: !Constants.isDevice
});


export default class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            areResourcesReady: false
        };
        SplashScreen.preventAutoHide();
        AppState.addEventListener('change', nextAppState =>
            Events.publish(Events.Types.CHANGE_APP_STATE, nextAppState)
        );
        Platform.OS === 'android' && Notifications.dismissAllNotificationsAsync();
    }

    componentDidMount() {
        this._loadResourcesAsync()
            .then(this._handleFinishLoading)
            .catch(this._handleLoadingError);
    }

    render() {
        if (!this.state.areResourcesReady) {
            return null;
        }

        return (
            <Provider store={store}>
                <Providers.ActionSheetProvider>
                    <Providers.ConnectedIntlProvider>
                        <Providers.ConnectedThemeProvider>
                            <Navigation enableURLHandling={false} onNavigationStateChange={(prevState, currentState, action) => {
                                Events.publish(Events.Types.NAVIGATION_STATE_CHANGE, {
                                    prevState, currentState, action
                                });
                            }} />
                            <Providers.RootSiblingsProvider />
                        </Providers.ConnectedThemeProvider>
                    </Providers.ConnectedIntlProvider>
                </Providers.ActionSheetProvider>
            </Provider>
        );
    }

    _loadResourcesAsync = async () => {
        return Promise.all([
            Asset.fromModule(images.splash).downloadAsync(),
            Asset.fromModule(images.splashLight).downloadAsync(),
            Asset.loadAsync(Object.values(fonts)),
            Font.loadAsync(fonts)
        ]);
    };

    _handleLoadingError = error => {
        Sentry.captureException(error);
    };

    _handleFinishLoading = () => this.setState({
        areResourcesReady: true
    }, SplashScreen.hide)
}
