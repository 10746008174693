import React from 'react';

import { Loader, View } from '../base';
import { withStyles } from "../../theme";

export default withStyles(({ theme, styles, style, ...restProps }) => (
    <View style={[
        styles[`wrapper`],
        style
    ]} {...restProps} >
        <View style={styles.loaderBackground}>
            <Loader style={styles.loader} color={theme.COLORS.WHITE} size={'large'} />
        </View>
    </View>
), theme => ({
    wrapper: {
        left: 0,
        top: 0,
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    loaderBackground: {
        borderRadius: 10,
        width: 75,
        height: 75,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.65)'
    },
    loader: {
        position: 'absolute'
    }
}));
