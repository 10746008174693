import React, { Component } from 'react';

import ImageViewer from 'react-native-image-zoom-viewer';
import { Avatar, Pressable, Text, View, Modal } from '../base';
import { Platform, StyleSheet, Modal as RNModal } from 'react-native';
import WebModal from 'modal-react-native-web';
import { withStyles } from "../../theme";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { closeImage, closeCommunityRules } from "../../state/common/actions";
import { ifIphoneX } from "react-native-iphone-x-helper";

import IconButton from '../IconButton';
import Button from "../Button";
import {BottomTabBar} from "react-navigation-tabs";

const OldModal = Platform.OS === 'web' ? WebModal : RNModal;

class ProfileButton extends Component {
    render () {
        const { theme, styles, style, children, me, isLoggedIn, images, isImageOpened, isCommunityRulesOpened, onCommunityRulesAccept, intl, ...restProps } = this.props;
        const { style: labelStyle } = children[children.length - 1].props;

        return isLoggedIn && (
            <Pressable
                style={[ style, styles.wrapper ]}
                {...restProps}
            >
                <Avatar style={[ styles.image, {
                    borderColor: StyleSheet.flatten(labelStyle).color
                } ]} avatarUrl={me.avatar_normal} />
                <OldModal
                    visible={isImageOpened}
                    transparent={true}
                    onRequestClose={this.props.closeImage}>
                    <View style={styles.lightboxWrapper}>
                        <ImageViewer
                            renderIndicator={() => null}
                            enableSwipeDown={true}
                            style={styles.imageViewer}
                            backgroundColor={'transparent'}
                            saveToLocalByLongPress={false}
                            onSwipeDown={this.props.closeImage}
                            imageUrls={images} />
                        <IconButton
                            name={'x'}
                            color={theme.COLORS.TEXT}
                            size={25}
                            style={styles.closeImage}
                            onPress={this.props.closeImage}
                        />
                    </View>
                </OldModal>
                <Modal
                    visible={isCommunityRulesOpened}
                    onModalHide={this.onRulesHide}
                    onRequestClose={this.onPressCloseRules}
                >
                    <View style={styles.bottomContainer}>
                        <Text style={styles.headingText}>{intl.formatMessage({ id: 'community_rules.heading' })}</Text>
                        <View style={styles.ruleWrapper}>
                            <Text style={styles.ruleHeadingText}>{intl.formatMessage({ 'id': 'community_rules.heading_rule_1' })}</Text>
                            <Text style={styles.ruleDescriptionText}>{intl.formatMessage({ 'id': 'community_rules.description_rule_1' })}</Text>
                        </View>
                        <View style={styles.ruleWrapper}>
                            <Text style={styles.ruleHeadingText}>{intl.formatMessage({ 'id': 'community_rules.heading_rule_2' })}</Text>
                            <Text style={styles.ruleDescriptionText}>{intl.formatMessage({ 'id': 'community_rules.description_rule_2' })}</Text>
                        </View>
                        <View style={styles.ruleWrapper}>
                            <Text style={styles.ruleHeadingText}>{intl.formatMessage({ 'id': 'community_rules.heading_rule_3' })}</Text>
                            <Text style={styles.ruleDescriptionText}>{intl.formatMessage({ 'id': 'community_rules.description_rule_3' })}</Text>
                        </View>
                        <Button onPress={this.onPressAcceptRules} size={'medium'} style={styles.acceptButton}>
                            {intl.formatMessage({ id: 'community_rules.i_promise' })} ✋
                        </Button>
                        <Button onPress={this.onPressCloseRules} size={'medium'} variation={'transparent'} style={styles.cancelButton}>
                            <Text style={styles.cancelButtonText}>{intl.formatMessage({ id: 'common.cancel' })}</Text>
                        </Button>
                    </View>
                </Modal>
            </Pressable>
        );
    }

    onPressCloseRules = async () => {
        this._acceptedRules = false;
        this.props.closeCommunityRules();
    };

    onRulesHide = () => {
        if(this._acceptedRules) {
            if(this.props.onCommunityRulesAccept) {
                this.props.onCommunityRulesAccept();
            }
        }
    };

    onPressAcceptRules = async () => {
        this._acceptedRules = true;
        this.props.closeCommunityRules();
    };
}

const mapStateToProps = ({ common, user, auth }) => ({
    isLoggedIn: auth.isLoggedIn,
    me: user.me,
    isImageOpened: common.isImageOpened,
    isCommunityRulesOpened: common.isCommunityRulesOpened,
    onCommunityRulesAccept: common.onCommunityRulesAccept,
    images: common.images
});

const mapDispatchToProps = dispatch => ({
    closeImage: () => dispatch(closeImage()),
    closeCommunityRules: () => dispatch(closeCommunityRules()),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(ProfileButton, theme => ({
        wrapper: {
            padding: 10,
            alignItems: 'center'
        },
        lightboxWrapper: {
            position: 'absolute',
            width: theme.SIZES.SCREEN_WIDTH,
            height: theme.SIZES.SCREEN_HEIGHT,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: theme.COLORS.BACKGROUND,
            elevation: 2
        },
        imageViewer: {
            height: '50%'
        },
        image: {
            width: 22,
            height: 22,
            borderRadius: 11,
            borderWidth: 1.5
        },
        closeImage: {
            alignItems: 'flex-end',
            justifyContent: 'center',
            width: 100,
            height: 60,
            position: 'absolute',
            right: 20,
            top: 30,
            opacity: .85
        },

        modalWrapper: {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
        },
        fadeWrapper: {
            flex: 1,
        },
        bottomContainer: {
            backgroundColor: theme.COLORS.BACKGROUND,
            paddingTop: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            ...ifIphoneX({
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN + 20,
            }, {
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            })
        },
        headingText: {
            textAlign: 'center',
            color: theme.COLORS.TEXT,
            fontWeight: '900',
            fontSize: 19,
            paddingHorizontal: 10,
            paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN
        },
        ruleWrapper: {
            marginHorizontal: 10,
            marginBottom: 20
        },
        ruleHeadingText: {
            color: theme.COLORS.TEXT,
            fontWeight: '700',
            fontSize: 17
        },
        ruleDescriptionText: {
            fontWeight: 'normal',
            color: theme.COLORS.SECONDARY_TEXT,
            fontSize: 16
        },
        acceptButton: {
            marginTop: 25
        },
        cancelButton: {
            marginTop: 15
        },
        cancelButtonText: {
            color: theme.COLORS.SECONDARY_TEXT,
            fontWeight: '900'
        }
    })))
);
