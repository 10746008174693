import React, { Component } from 'react';

import { Modal, Pressable, View, Icon, Text } from "../../components/base";
import { withStyles } from "../../theme";
import { withNavigation } from 'react-navigation';
import {Checkbox, Toast} from "../../components";
import screenNames from "../../navigation/screenNames";
import { ifIphoneX } from "react-native-iphone-x-helper";
import {
    closeChannel,
    muteChannel,
    reportChannel
} from "../../state/channel/actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { showConfirm } from "../../utils/simpleAlert";
import { Clipboard } from 'react-native';
import {checkPermissions} from "../../utils/permissions";

class OptionsModal extends Component {

    state = {
        isMuteInPrecess: false
    };

    render() {

        const { isMuteInPrecess } = this.state;
        const { theme, styles, channel, me, intl, visible } = this.props;

        if(!channel) {
            return null;
        }

        const isMyChannel = me && me.id === channel.author_id,
            isAdmin = isMyChannel || checkPermissions(me ? me.access : [], 'ban_member', channel.city_id, channel.university_id);

        return (
            <Modal
                visible={visible}
                onRequestClose={this.onClose}>
                <View style={styles.bottomContainer}>

                    <Pressable style={styles.listItem} onPress={this.onPressCopyToClipboard}>
                        <View style={styles.listItemLeft}>
                            <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.INFO }]}>
                                <Icon name={'link'} color={theme.COLORS.WHITE} size={19} />
                            </View>
                            <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.share_link' })}</Text>
                        </View>
                        <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                            <Icon name={'copy'} color={theme.COLORS.TEXT} size={19} />
                        </View>
                    </Pressable>

                    {channel.hasSubscription && (
                        <Pressable style={styles.listItem} onPress={this.onPressMute} disabled={isMuteInPrecess}>
                            <View style={styles.listItemLeft}>
                                <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.WARNING }]}>
                                    <Icon name={'bell'} color={theme.COLORS.WHITE} size={19} />
                                </View>
                                <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.notifications' })}</Text>
                            </View>
                            <View style={styles.listItemRight}>
                                <Checkbox
                                    variation={'switch'}
                                    onChange={this.onPressMute}
                                    value={!channel.mute}
                                    disabled={isMuteInPrecess}
                                />
                            </View>
                        </Pressable>
                    )}

                    <Pressable style={styles.listItem} onPress={this.onPressAuthor}>
                        <View style={styles.listItemLeft}>
                            <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.PRIMARY }]}>
                                <Icon name={'at-sign'} color={theme.COLORS.WHITE} size={19} />
                            </View>
                            <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.author' })} <Text style={styles.listItemSecondaryText}>(@{channel.author_nickname})</Text></Text>
                        </View>
                        <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                            <Icon name={'chevron-right'} color={theme.COLORS.TEXT} size={23} />
                        </View>
                    </Pressable>

                    <Pressable style={styles.listItem} onPress={this.onPressMembers}>
                        <View style={styles.listItemLeft}>
                            <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.PRIMARY }]}>
                                <Icon name={'users'} color={theme.COLORS.WHITE} size={19} />
                            </View>
                            <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.members' })} <Text style={styles.listItemSecondaryText}>({channel.subscribers_count})</Text></Text>
                        </View>
                        <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                            <Icon name={'chevron-right'} color={theme.COLORS.TEXT} size={23} />
                        </View>
                    </Pressable>

                    {!isMyChannel && (
                        <Pressable style={styles.listItem} onPress={this.onPressReportChannel}>
                            <View style={styles.listItemLeft}>
                                <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.WARNING }]}>
                                    <Icon name={'alert-circle'} color={theme.COLORS.WHITE} size={19} />
                                </View>
                                <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.report' })}</Text>
                            </View>
                            <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                                <Icon name={'chevron-right'} color={theme.COLORS.TEXT} size={23} />
                            </View>
                        </Pressable>
                    )}

                    {isAdmin && (
                        <Pressable style={styles.listItem} onPress={this.onPressCloseChannel}>
                            <View style={styles.listItemLeft}>
                                <View style={[styles.listItemIconWrap, { backgroundColor: theme.COLORS.DANGER }]}>
                                    <Icon name={'trash'} color={theme.COLORS.WHITE} size={19} />
                                </View>
                                <Text style={styles.listItemText} numberOfLines={1}>{intl.formatMessage({ id: 'topic.close_topic' })}</Text>
                            </View>
                            <View style={[styles.listItemRight, styles.listItemRightIcon]}>
                                <Icon name={'chevron-right'} color={theme.COLORS.TEXT} size={23} />
                            </View>
                        </Pressable>
                    )}
                </View>
            </Modal>
        );
    };

    onClose = async () => {
        if(this.props.onClose) {
            return this.props.onClose();
        }
        return null;
    };

    onPressAuthor = async () => {
        const { navigation, channel } = this.props;
        await this.onClose();
        navigation.navigate(screenNames.PROFILE, {
            userID: channel.author_id
        });
    };

    onPressMembers = async () => {
        const { navigation, channel } = this.props;
        await this.onClose();
        navigation.navigate(screenNames.TOPIC_MEMBERS, {
            topicID: channel.id
        });
    };

    onPressMute = async () => {
        const { channel } = this.props;

        await this.setState({ isMuteInPrecess: true });
        await this.props.muteChannel(channel.id, channel.mute ? 0 : 1);
        await this.setState({ isMuteInPrecess: false });
    };

    onPressCopyToClipboard = async () => {
        const { intl, channel } = this.props;
        await this.onClose();
        Clipboard.setString(`https://wace.co/c/${channel.slug}`);
        Toast.showSimpleSuccess(intl.formatMessage({ id: 'common.copied_to_clipboard' }));
    };

    onPressCloseChannel = async () => {
        const { intl, channel, navigation } = this.props;
        showConfirm(
            intl.formatMessage({ id: 'common.confirm_action' }),
            intl.formatMessage({ id: 'topic.close_channel_confirmation_text' }),
            intl.formatMessage({ id: 'topic.yes_close' }),
            intl.formatMessage({ id: 'common.cancel' }),
            async () => {
                const { success } = await this.props.closeChannel(channel.id);
                if (success) {
                    await this.onClose();
                    Toast.showSimpleSuccess(intl.formatMessage({ id: 'topic.channel_is_closed' }));
                    navigation.goBack(null);
                }
            }
        )
    };

    onPressReportChannel = async () => {
        const { showReport, navigation, channel, intl } = this.props;
        showReport(async (reportType) => {
            const { success } = await this.props.reportChannel(channel.id, reportType);
            if(success) {
                await this.onClose();
                Toast.showSimpleSuccess(intl.formatMessage({ id: 'common.report_successfully_sent' }));
                navigation.goBack(null);
            }
        });
    };
}

const mapStateToProps = ({ user }) => ({
    me: user.me
});

const mapDispatchToProps = dispatch => ({
    closeChannel: (channelID) => dispatch(closeChannel(channelID)),
    muteChannel: (channelID, mute) => dispatch(muteChannel(channelID, mute)),
    reportChannel: (channelID, reportType) => dispatch(reportChannel(channelID, reportType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withNavigation(injectIntl(withStyles(OptionsModal, theme => ({
        wrapper: {
            padding: 10,
            alignItems: 'center'
        },
        modalWrapper: {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,0.56)',
        },
        fadeWrapper: {
            flex: 1,
        },
        bottomContainer: {
            backgroundColor: theme.COLORS.BACKGROUND,
            paddingTop: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            ...ifIphoneX({
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN + 20,
            }, {
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            })
        },

        listItem: {
            width: '100%',
            height: 50,
            flexDirection: 'row'
        },
        listItemLeft: {
            flex: .7,
            flexDirection: 'row',
            alignItems: 'center'
        },
        listItemRight: {
            flex: .3,
            alignItems: 'flex-end'
        },
        listItemRightIcon: {
            justifyContent: 'center'
        },
        listItemSecondaryText: {
            fontWeight: '700',
            fontSize: 18,
            color: theme.COLORS.SECONDARY_TEXT
        },
        listItemText: {
            fontWeight: '700',
            fontSize: 18,
            color: theme.COLORS.TEXT
        },
        listItemIconWrap: {
            width: 36,
            height: 36,
            borderRadius: 12,
            marginRight: 10,
            backgroundColor: theme.COLORS.BACKGROUND_ITEM,
            alignItems: 'center',
            justifyContent: 'center'
        }
    }))))
);


