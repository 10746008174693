import React from 'react';

import { injectIntl } from "react-intl";
import { withStyles } from "../../theme";
import {View, Screen, Text, Pressable, Icon, List, Loader, SList} from '../../components/base';
import { connect } from "react-redux";
import SectionHeader from './SectionHeader';
import NotificationItem from './NotificationItem';
import screenNames from "../../navigation/screenNames";
import styles from "./styles";
import { retrieveChannel } from '../../state/channel/actions';
import { retrieveNotifications, loadMoreNotifications, readNotifications } from '../../state/user/actions';

class NotificationsScreen extends Screen {

    screenName = screenNames.NOTIFICATIONS;

    state = {
        isLoading: false,
        isLoadingMore: false,
        isRefreshing: false
    };

    content() {

        const { isLoading, isLoadingMore, isRefreshing } = this.state;
        const { theme, styles, me, intl, notifications } = this.props;

        return (
            <View style={styles.screenWrapper}>
                <View style={styles.headerWrapper}>
                    <Text style={styles.headingText}>
                        {intl.formatMessage({ id: 'notifications.heading' })}
                    </Text>
                    <Text style={styles.subHeadingText}>
                        {!me.notifications_count ? (
                            intl.formatMessage({ id: 'notifications.no_new_notifications' })
                        ) : (
                            intl.formatMessage({ id: 'notifications.count_new_notifications' }, {
                                count: me.notifications_count
                            })
                        )}
                    </Text>
                </View>
                <SectionHeader />
                {isLoading ? (
                    <View style={styles.sceneLoaderWrapper}>
                        <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                        <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                    </View>
                ) : (
                    <List
                        contentContainerStyle={styles.contentWrapper}
                        refreshing={isRefreshing}
                        onRefresh={this.onRefresh}
                        data={notifications}
                        renderItem={({ item }) => (
                            <NotificationItem
                                {...item}
                                navigateToTopic={this.navigateToTopic}
                                style={styles.notificationItem}
                            />
                        )}
                        ListFooterComponent={(
                            <View style={styles.loaderWrapper}>
                                {isLoadingMore && (
                                    <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                )}
                            </View>
                        )}
                        ListEmptyComponent={(
                            <View style={styles.noDataPlaceholderWrapper}>
                                <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'notifications.no_notifications' })}</Text>
                            </View>
                        )}
                        onEndReached={this.loadMore}
                        keyExtractor={item => item.id}
                    />
                )}
            </View>
        );
    }

    onNavigate = async () => {
        if(this.props.notifications.length === 0) {
            await this.loadData();
        } else {
            await this.retrieveData([
                'retrieveNotifications'
            ]);
        }
        this.props.readNotifications();
    };

    onRefresh = async () => {
        await this.retrieveData([
            'retrieveNotifications'
        ], 'isRefreshing');
    };

    loadData = async () => {
        await this.retrieveData([
            'retrieveNotifications'
        ], 'isLoading');
    };

    loadMore = async () => {
        if(this.state.isLoading || this.state.isLoadingMore) return;
        await this.retrieveData([
            'loadMoreNotifications'
        ], 'isLoadingMore');
    };
}

const mapStateToProps = ({ user }) => ({
    me: user.me,
    ignoreList: user.ignoreList,
    notifications: user.notifications
});

const mapDispatchToProps = dispatch => ({
    retrieveChannel: (channelID) => dispatch(retrieveChannel(channelID)),
    retrieveNotifications: () => dispatch(retrieveNotifications()),
    loadMoreNotifications: () => dispatch(loadMoreNotifications()),
    readNotifications: () => dispatch(readNotifications())
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(NotificationsScreen, styles))
);

