import { AsyncStorage, Platform } from 'react-native';

class Storage {

    /**
     * @param prefix
     */
    constructor(prefix) {
        this._prefix = prefix;
    }

    /**
     * @param key
     * @param value
     * @returns {Promise<boolean>}
     */
    async set(key, value) {
        try {
            await AsyncStorage.setItem(this._prefix + key, JSON.stringify(value));
            return true;
        } catch (error) {
            return false;
        }
    }

    /**
     * @param key
     * @param defaultValue
     * @returns {Promise<any>}
     */
    async get(key, defaultValue) {
        try {
            const data = await AsyncStorage.getItem(this._prefix + key);
            if(data) {
                return JSON.parse(data);
            }
            return defaultValue;
        } catch (error) {
            return defaultValue;
        }
    }

    /**
     * @param key
     * @returns {Promise<any>}
     */
    async remove(key) {
        try {
            await AsyncStorage.removeItem(this._prefix + key);
            return true;
        } catch (error) {
            return false;
        }
    }
}

const storage = new Storage('wace_');

export default storage;
