import React, { PureComponent } from 'react';

import { View } from '../../components/base'
import { withStyles } from "../../theme";
import { Animated } from 'react-native';

class TinyHeader extends PureComponent {

    render() {
        const {
            theme,
            styles,
            style,
            children,
            scrollY,
            ...restProps
        } = this.props;
        const headerTransition = scrollY.interpolate({
            inputRange: [0, 100],
            outputRange: [0, 100],
            extrapolate: 'clamp'
        });
        const headerOpacity = scrollY.interpolate({
            inputRange: [100, 135],
            outputRange: [0, 1],
            extrapolate: 'clamp'
        });
        return (
            <Animated.View {...restProps} style={[
                styles.wrapper, style, { opacity: headerOpacity, transform: [ { translateY: headerTransition } ] }
            ]}>
                <View style={styles.container}>
                    {children}
                </View>
            </Animated.View>
        );
    }
}

export default withStyles(TinyHeader, theme => ({
    wrapper: {
        marginTop: -100,
        paddingTop: 0,
        height: 60,
        width: '100%',
        backgroundColor: theme.COLORS.BACKGROUND,
        position: 'absolute',
        top: 0,
        left: 0,
        shadowOffset: { width: 0, height: 0 },
        shadowColor: '#000',
        shadowOpacity: .15,
        shadowRadius: 4,
        elevation: 3
    },
    container: {
        height: '100%',
        justifyContent: 'center',
        marginHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING + 5,
        alignItems: 'center',
    }
}));
