import {
    GET_REQUESTS,
    GET_COUNTERS,
    LOAD_MORE_REQUESTS,
    GET_CONTACTS,
    LOAD_MORE_CONTACTS,
    LOAD_MORE_CONTACT_MESSAGES,
    GET_CONTACT_MESSAGES,
    RECEIVED_CONTACT_EVENT,
    SEND_REQUEST,
    DECLINE_REQUEST,
    REMOVE_CONTACT,
    IS_CONTACTS,
    GET_CONTACT,
    CLEAR_CONTACT_REDUCER,
    OPEN_CONTACT_MESSAGE,
    CLOSE_CONTACT_MESSAGE,
    SEND_PRIVATE_MESSAGE
} from '../action-types';

let initialState = {
    requests: [],
    contacts: [],
    requests_count: 0,
    contacts_count: 0,
    messages: {},
    is_contacts: {},
    contactsData: {}
};

export default (state = { ...initialState }, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_REQUESTS:
            return {
                ...state,
                requests: payload.data
            };
        case GET_CONTACT:
            state.contactsData[payload.contactID] = payload.data;
            return {
                ...state
            };
        case SEND_REQUEST:
            state.is_contacts[payload.userID] = payload.data;
            if(payload.data['contact'] && state.requests_count > 0) {
                for(let i in state.requests) {
                    if(state.requests[i].user_id == payload.userID) {
                        let contact = { ...state.requests[i] };
                        contact.id = payload.data['contact'];
                        contact.created_at = Date.now() / 1000;
                        state.contacts.unshift(contact);
                        state.requests.splice(i, 1);
                        state.requests_count--;
                        state.contacts_count++;
                    }
                }
            }
            return {
                ...state
            };
        case IS_CONTACTS:
            state.is_contacts[payload.userID] = payload.data;
            return {
                ...state
            };
        case DECLINE_REQUEST:
            for(let i in state.requests) {
                if(state.requests[i].user_id == payload.userID) {
                    state.requests_count--;
                    state.requests.splice(i, 1);
                }
            }
            return {
                ...state
            };
        case REMOVE_CONTACT:
            for(let i in state.contacts) {
                if(state.contacts[i].user_id == payload.userID) {
                    state.contacts_count--;
                    state.contacts.splice(i, 1);
                }
            }
            return {
                ...state
            };
        case OPEN_CONTACT_MESSAGE:
            if(state.messages[payload.contactID]) {
                for(let i in state.messages[payload.contactID]) {
                    if(state.messages[payload.contactID][i]['id'] == payload.messageID) {
                        state.messages[payload.contactID][i]['isOpened'] = true;
                    } else {
                        state.messages[payload.contactID][i]['isOpened'] = undefined;
                    }
                }
            }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
            };
        case CLOSE_CONTACT_MESSAGE:
            if(state.messages[payload.contactID]) {
                for(let i in state.messages[payload.contactID]) {
                    if(state.messages[payload.contactID][i]['id'] == payload.messageID) {
                        state.messages[payload.contactID][i]['isOpened'] = undefined;
                    }
                }
            }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
            };
        case LOAD_MORE_REQUESTS:
            return {
                ...state,
                requests: state.requests.concat(payload.data)
            };
        case GET_CONTACTS:
            return {
                ...state,
                contacts: payload.data
            };
        case LOAD_MORE_CONTACTS:
            return {
                ...state,
                contacts: state.contacts.concat(payload.data)
            };
        case GET_COUNTERS:
            return {
                ...state,
                requests_count: payload.data.requests,
                contacts_count: payload.data.contacts,
            };
        case LOAD_MORE_CONTACT_MESSAGES:
            if(state.messages[payload.contactID]) {
                state.messages[payload.contactID] = state.messages[payload.contactID].concat(payload.data);
            }
            return {
                ...state
            };
        case GET_CONTACT_MESSAGES:
            let messages = {};
            messages[payload.contactID] = payload.data;
            return {
                ...state,
                messages: {
                    ...state.messages,
                    ...messages
                },
            };
        case SEND_PRIVATE_MESSAGE:
            if(state.messages[payload.contactID]) {
                if(state.messages[payload.contactID].map(o => o.id).indexOf(payload.message.id) === -1) {
                    state.messages[payload.contactID].unshift(payload.message);
                }
            }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
            };
        case RECEIVED_CONTACT_EVENT:
            let event = payload.data;
            switch(event.event) {
                case 'new_message':
                    if(state.messages[payload.contactID]) {
                        if(state.messages[payload.contactID].map(o => o.id).indexOf(event.data.id) === -1) {
                            state.messages[payload.contactID].unshift(event.data);
                        }
                    }
                    break;
                case 'delete_message':
                    if(state.messages[payload.contactID]) {
                        for(let i in state.messages[payload.contactID]) {
                            if(state.messages[payload.contactID][i]['id'] == event.data.messageID) {
                                state.messages[payload.contactID].splice(i, 1);
                            }
                        }
                    }
                    break;
            }
            return {
                ...state,
                messages: {
                    ...state.messages
                },
            };
        case CLEAR_CONTACT_REDUCER:
            return { ...initialState };
        default:
            return state;
    }
};
