import React, { PureComponent } from 'react';

import { Text, View, Image } from '../../components/base';
import { IconButton } from '../../components';
import { withStyles } from "../../theme";
import { LinearGradient } from "expo-linear-gradient/build/index";
import FeedSettingsModal from './FeedSettingsModal';

class SectionHeader extends PureComponent {

    state = {
        isOpened: false
    };

    render() {

        const { isOpened } = this.state;
        const {
            theme,
            styles,
            style,
            children,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                <LinearGradient
                    {...theme.COLORS.GRADIENTS.HEADER}
                    style={styles.gradient}
                />
                <Image style={styles.logoImage} source={theme.IMAGES.homeLogo} />
                <Text style={styles.titleText} numberOfLines={1}>
                    Wace
                </Text>
                <IconButton onPress={this.handleOpen} name={'settings'} size={18} color={theme.COLORS.SECONDARY_TEXT} style={styles.settings} />
                <FeedSettingsModal
                    visible={isOpened}
                    onClose={this.handleClose}
                />
            </View>
        );
    }

    handleOpen = () => this.setState({
        isOpened: true
    });

    handleClose = () => this.setState({
        isOpened: false
    });
}

export default withStyles(SectionHeader, theme => ({
    wrapper: {
        marginTop: 0,
        height: 40,
        flexDirection: 'row',
        alignItems: 'center'
    },
    logoImage: {
        height: 20,
        width: 20 * 1.17,
        left: theme.SIZES.CONTENT_HORIZONTAL_PADDING + 2,
        tintColor: theme.COLORS.SECONDARY_TEXT,
        position: 'absolute'
    },
    settings: {
        position: 'absolute',
        // top: -5.5,
        right: theme.SIZES.CONTENT_HORIZONTAL_PADDING - 4,
    },
    titleText: {
        paddingLeft: 30,
        marginHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING + 2,
        color: theme.COLORS.SECONDARY_TEXT,
        fontFamily: theme.FONTS.BALOO,
        fontSize: 18
    },
    gradient: {
        position: 'absolute',
        opacity: .3,
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        // marginTop: 5,
        // borderRadius: 1,
        // height: 2,
        // marginHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
    }
}));
