import React, { PureComponent } from 'react';

import { Animated } from "react-native";
import { Text, View, Pressable, Icon } from '../../components/base';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";
import { withNavigation } from 'react-navigation';

class Header extends PureComponent {

    render() {

        const {
            theme,
            styles,
            navigation,
            contact,
            onPressMore,
            onPressNickname
        } = this.props;

        return (
            <View style={styles.wrapper}>
                <Animated.View style={[ styles.panel ]}>
                    <View style={styles.content}>
                        <Pressable onPress={onPressNickname}>
                            <Text numberOfLines={1} style={styles.headingText}>
                                @{contact.nickname}
                            </Text>
                        </Pressable>
                    </View>
                    <View style={styles.iconsRow} pointerEvents={'box-none'}>
                        <View style={{ flex: 1, alignItems: 'flex-start' }} pointerEvents={'box-none'}>
                            <Pressable onPress={() => navigation.goBack(null)} style={styles.backButton}>
                                <Icon name={'chevron-down'} color={theme.COLORS.TEXT} size={25} />
                            </Pressable>
                        </View>
                        <View style={{ flex: 1, alignItems: 'flex-end' }} pointerEvents={'box-none'}>
                            <Pressable onPress={onPressMore}>
                                <Icon name={'more-horizontal'} color={theme.COLORS.TEXT} size={25} />
                            </Pressable>
                        </View>
                    </View>
                </Animated.View>
            </View>
        );
    }
}

export default withNavigation(injectIntl(withStyles(Header, theme => ({
    wrapper: {
        position: 'absolute',
        width: '100%',
        top: 0
    },
    panel: {
        width: '100%',
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 25,
        justifyContent: 'center',
        height: theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        position: 'absolute',
        top: 0
    },
    content: {
        paddingTop: 0,
        paddingHorizontal: 60,
    },
    headingText: {
        color: theme.COLORS.TEXT,
        fontSize: 16,
        fontWeight: '900',
        textAlign: 'center'
    },
    iconsRow: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: theme.SIZES.HEADER_HORIZONTAL_PADDING,
        paddingTop: 0,
        position: 'absolute',
        height: theme.SIZES.TOPIC_HEADER_COLLAPSED_HEIGHT,
        alignItems: 'center'
    },
    backButton: {
        marginLeft: -4
    }
}))));
