import React from 'react';

import { injectIntl } from "react-intl";
import { withStyles } from "../../theme";
import {View, Screen, Text, Pressable, Icon, SList, Loader, AnimatedDots} from '../../components/base';
import { connect } from "react-redux";
import styles from "./styles";
import SectionHeader from './SectionHeader';
import MemberItem from './MemberItem';
import MemberItemOptions from './MemberItemOptions';
import SearchInput from './SearchInput';
import screenNames from "../../navigation/screenNames";
import {
    retrieveChannel,
    loadMoreSearchChannelMembers,
    searchChannelMembers,
    unbanChannelMember
} from "../../state/channel/actions";
import { blockUser } from "../../state/user/actions";
import { debounce } from "../../utils/async";
import BanUserModal from './BanUserModal';
import { showConfirm } from "../../utils/simpleAlert";
import { Toast } from "../../components";
import {checkPermissions} from "../../utils/permissions";

class TopicMembersScreen extends Screen {

    screenName = screenNames.TOPIC_MEMBERS;

    constructor(props) {
        super(props);

        this.state = {
            query: '',
            topicID: null,
            isLoading: false,
            isLoadingMore: false,
            isUnbanLoading: false,
            isRefreshing: false,
            isBanModalVisible: false,
            banUser: null
        };

        this.search = debounce(this.search, 1000);
    }

    async search() {
        return await this.loadData();
    }

    onChangeQuery = async (query) => {
        await this.setState({
            query,
            isLoading: query !== ''
        });
        await this.search();
    };

    content() {

        const { topicID, isLoading, isLoadingMore, isRefreshing, query, isBanModalVisible, banUser } = this.state,
            { styles, theme, intl, channels, search_members, navigation, me } = this.props;

        const channel = channels && channels[topicID] ? channels[topicID] : null,
            members = (search_members && search_members[topicID]) ? search_members[topicID] : [];

        if(!channel) {
            return null;
        }

        const isAdmin = (me.id === channel.author_id) || checkPermissions(me.access, 'ban_member', channel.city_id, channel.university_id);

        return (
            <View style={styles.screenWrapper}>
                <View style={styles.headerWrapper}>
                    <SearchInput
                        placeholder={intl.formatMessage({ id: 'topic_members.heading_placeholder' })}
                        onChangeText={this.onChangeQuery}
                    />
                    <Text style={styles.subHeadingText}>
                        {intl.formatMessage({ id: 'topic_members.members_count' }, {
                            count: channel.subscribers_count
                        })}
                    </Text>
                    <Pressable onPress={() => navigation.goBack()} style={styles.headerBackButton}>
                        <Icon name={'arrow-left'} color={theme.COLORS.TEXT} size={23} />
                    </Pressable>
                </View>
                <SectionHeader />
                {isLoading ? (
                    <View style={styles.sceneLoaderWrapper}>
                        <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                        {query ? (
                            <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.searching_upper' })}<AnimatedDots /></Text>
                        ) : (
                            <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                        )}
                    </View>
                ) : (
                    <SList
                        contentContainerStyle={styles.contentWrapper}
                        data={members}
                        ListFooterComponent={(
                            <View style={styles.loaderWrapper}>
                                {isLoadingMore && (
                                    <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                )}
                            </View>
                        )}
                        renderItem={ ({ item }, rowMap) => (
                            <MemberItem
                                {...item}
                                onPress={this.onPressItem}
                                onLongPress={this.onLongPressItem}
                                style={styles.resultItem}
                            />
                        )}
                        ListEmptyComponent={(
                            <View style={styles.noDataPlaceholderWrapper}>
                                {query ? (
                                    <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'topic_members.no_results' })}</Text>
                                ) : (
                                    <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'topic_members.no_members' })}</Text>
                                )}
                            </View>
                        )}
                        renderHiddenItem={ ({ item }, rowMap) => (
                            !isAdmin ? (
                                <MemberItemOptions
                                    {...item}
                                    loading={this.state.isUnbanLoading}
                                    onPress={this.onPressIgnore}
                                    variation={'danger'}
                                    icon={'slash'}
                                />
                            ) : item.ban_id ? (
                                <MemberItemOptions
                                    {...item}
                                    loading={this.state.isUnbanLoading}
                                    onPress={this.onPressUnban}
                                    variation={'success'}
                                    icon={'shield-off'}
                                />
                            ) : (
                                <MemberItemOptions
                                    {...item}
                                    onPress={this.openBanModal}
                                    variation={'danger'}
                                    icon={'slash'}
                                />
                            )
                        )}
                        refreshing={isRefreshing}
                        onRefresh={this.onRefresh}
                        onEndReached={this.loadMore}
                        disableRightSwipe={true}
                        rightOpenValue={-75}
                        stopRightSwipe={-75}
                        keyExtractor={item => item.user_id}
                    />
                )}
                <BanUserModal
                    visible={isBanModalVisible}
                    banUser={banUser}
                    isAdmin={channel.author_id === me.id || checkPermissions(me.access, 'ban_member', channel.city_id, channel.university_id)}
                    onConfirm={() => this.setState({ isBanModalVisible: false })}
                    onClose={() => this.setState({ isBanModalVisible: false })}
                />
            </View>
        );
    }

    onNavigate = async () => {
        const { navigation } = this.props;
        await this.setState({
            topicID: navigation.getParam('topicID')
        });

        const { topicID } = this.state,
            { search_members } = this.props;
        const members = (search_members && search_members[topicID]) ? search_members[topicID] : [];
        if(members.length > 0) return;
        await this.loadData();
    };

    onRefresh = async () => {
        await this.retrieveData([
            ['searchChannelMembers', this.state.topicID, this.state.query],
        ], 'isRefreshing')
    };

    loadData = async () => {
        await this.retrieveData([
            ['retrieveChannel', this.state.topicID],
            ['searchChannelMembers', this.state.topicID, this.state.query],
        ], 'isLoading')
    };

    loadMore = async () => {
        if(this.state.isLoading || this.state.isLoadingMore) return;
        await this.retrieveData([
            'loadMoreSearchChannelMembers', this.state.topicID, this.state.query
        ], 'isLoadingMore');
    };

    openBanModal = (item) => {
        const { me } = this.props;
        if(me.id === item.user_id) {
            return;
        }
        this.setState({
            isBanModalVisible: true,
            banUser: {
                ...item,
                topicID: this.state.topicID
            }
        });
    };

    onPressItem = (item) => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.PROFILE, {
            userID: item.user_id
        });
    };

    onLongPressItem = () => {

    };

    onPressUnban = async (item) => {
        await this.setState({ isUnbanLoading: true });
        await this.props.unbanChannelMember(this.state.topicID, item.user_id);
        await this.setState({ isUnbanLoading: false });
    };

    onPressIgnore = async (item) => {
        const { intl, me } = this.props;
        if(me.id === item.user_id) {
            return;
        }
        showConfirm(
            intl.formatMessage({ id: 'common.confirm_action' }),
            intl.formatMessage({ id: 'common.ignore_user_confirm_text' }, {
                nickname: `@${item.user_nickname}`
            }),
            intl.formatMessage({ id: 'common.yes_ignore' }),
            intl.formatMessage({ id: 'common.cancel' }),
            async () => {
                await this.setState({ isUnbanLoading: true });
                await this.props.blockUser(item.user_id);
                Toast.showSimpleSuccess(intl.formatMessage({ id: 'profile.user_blocked' }));
                await this.setState({ isUnbanLoading: false });
            }
        );
    };
}


const mapStateToProps = ({ user, channel }) => ({
    channels: channel.channels,
    search_members: channel.search_members,
    ignoreList: user.ignoreList,
    me: user.me
});

const mapDispatchToProps = dispatch => ({
    retrieveChannel: (channelID) => dispatch(retrieveChannel(channelID)),
    unbanChannelMember: (channelID, userID) => dispatch(unbanChannelMember(channelID, userID)),
    blockUser: (userID) => dispatch(blockUser(userID)),
    loadMoreSearchChannelMembers: (channelID, query) => dispatch(loadMoreSearchChannelMembers(channelID, query)),
    searchChannelMembers: (channelID, query) => dispatch(searchChannelMembers(channelID, query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(TopicMembersScreen, styles))
);

