import React from 'react';

import { injectIntl } from "react-intl";
import { withStyles } from "../../theme";
import {View, Screen, KView, Text, List, Loader, Icon} from '../../components/base';
import { connect } from "react-redux";
import screenNames from "../../navigation/screenNames";
import styles from "./styles";
import {
    retrieveContactMessages,
    subscribeOnCometContact,
    loadMoreContactMessages,
    getContact,
    openContactMessage,
    closeContactMessage, removeContact, sendPrivatePhoto
} from "../../state/contact/actions";
import { openImage } from "../../state/common/actions";
import { connectActionSheet } from "@expo/react-native-action-sheet";
import { showConfirm } from "../../utils/simpleAlert";
import Header from './Header';
import Footer from './Footer';
import MessageItem from "./MessageItem";
import { pickFromGallery, takePhoto } from "../../utils/imagePicker";
import { openLink } from "../../utils/link";

class ConversationScreen extends Screen {

    screenName = screenNames.CONVERSATION;

    state = {
        isLoading: false,
        isLoadingMore: false,
        isRefreshing: false,
        contactID: null
    };

    content() {

        const { contactID, isLoading, isLoadingMore, isRefreshing } = this.state,
            { styles, theme, messages, intl, contactsData } = this.props;

        const messagesList = messages && messages[contactID] ? messages[contactID] : [],
            contactData = contactsData && contactsData[contactID] ? contactsData[contactID] : null;

        if(!contactData) {
            return null;
        }

        return (
            <KView style={styles.screenWrapper}>
                {!isLoading && (
                    <List inverted
                          refreshing={isRefreshing}
                          onRefresh={this.onRefresh}
                          style={styles.messagesList}
                          contentContainerStyle={styles.messagesWrapper}
                          data={messagesList}
                          renderItem={({ item }) => (
                              <MessageItem
                                  {...item}
                                  isOpened={!!item.isOpened}
                                  onPress={this.onPressMessage}
                                  onPressAvatar={this.onPressMessageAvatar}
                                  onPressImage={this.onPressMessageImage}
                                  onLongPress={this.onLongPressMessage}
                                  onPressLink={this.onPressMessageLink}
                                  style={styles.messageItem}
                              />
                          )}
                          ListFooterComponent={(
                              <View style={styles.loaderWrapper}>
                                  {isLoadingMore && (
                                      <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.loader} />
                                  )}
                              </View>
                          )}
                          ListEmptyComponent={(
                              <View style={styles.noDataPlaceholderWrapper}>
                                  <Text style={styles.noDataPlaceholderText}>{intl.formatMessage({ id: 'conversation.no_messages' })}</Text>
                              </View>
                          )}
                          onEndReached={this.loadMore}
                          keyExtractor={item => item.id}
                    />
                )}
                {isLoading && (
                    <View style={styles.messagesList}>
                        <View style={[ styles.messagesWrapper, { flex: 1, minHeight: null, paddingBottom: 5 } ]} >
                            <View style={styles.sceneLoaderWrapper}>
                                <Loader color={theme.COLORS.SECONDARY_TEXT} size={'small'} style={styles.sceneLoader} />
                                <Text style={styles.sceneLoaderText}>{intl.formatMessage({ id: 'common.loading_upper' })}</Text>
                            </View>
                        </View>
                    </View>
                )}
                <Header
                    onPressNickname={this.onPressNickname}
                    onPressMore={this.onPressMore}
                    contact={contactData}
                />
                <Footer
                    contact={contactData}
                    onPressUploadPicture={this.onPressUploadPicture}
                />
            </KView>
        );
    }

    onNavigate = async () => {
        const { navigation } = this.props;
        await this.setState({
            contactID: navigation.getParam('contactID')
        });
        await this.loadData();
    };

    loadMore = async () => {
        if(this.state.isLoading || this.state.isLoadingMore) return;
        await this.retrieveData([
            'loadMoreContactMessages', this.state.contactID
        ], 'isLoadingMore');
    };

    onPressNickname = () => {
        const { contactID } = this.state,
            { contactsData, navigation } = this.props;
        const contactData = contactsData && contactsData[contactID] ? contactsData[contactID] : null;
        if(contactData) {
            navigation.navigate(screenNames.PROFILE, {
                userID: contactData.user_id
            });
        }
    };

    onPressMore = () => {
        const { contactID } = this.state,
            { contactsData, intl, navigation } = this.props;
        const contact = contactsData && contactsData[contactID] ? contactsData[contactID] : null;
        this.actionSheet([ {
            label: intl.formatMessage({ id: 'contacts.delete_contact' }),
            destructive: true,
            onPress: async () => {
                showConfirm(
                    intl.formatMessage({ id: 'common.confirm_action' }),
                    intl.formatMessage({ id: 'contacts.remove_nickname_from_contacts' }, {
                        nickname: `@${contact.nickname}`
                    }),
                    intl.formatMessage({ id: 'common.yes_delete' }),
                    intl.formatMessage({ id: 'common.cancel' }),
                    async () => {
                        await this.setState({ showLoader: true });
                        await this.props.removeContact(contact.user_id);
                        await this.setState({ showLoader: false });
                        navigation.navigate(screenNames.CONTACTS);
                    }
                );
            }
        }, {
            label: intl.formatMessage({ id: 'common.cancel' })
        } ]);
    };

    onLongPressMessage = (message) => {

    };

    onPressMessage = (message) => {
        if(message.isOpened) {
            this.props.closeContactMessage(this.state.contactID, message.id);
        } else {
            this.props.openContactMessage(this.state.contactID, message.id);
        }
    };

    onPressUploadPicture = async () => {
        const imageUri = await pickFromGallery({ resizeCoefficient: .5, compressRatio: .8 });
        if(imageUri) {
            await this.setState({ showLoader: true });
            const { success } = await this.props.sendPrivatePhoto(this.state.contactID, imageUri);
            await this.setState({ showLoader: false });
        }
    };

    onPressMessageImage = (message) => {
        this.props.openImage(message.extra);
    };

    onPressMessageAvatar = (message) => {
        const { navigation } = this.props;
        navigation.navigate(screenNames.PROFILE, {
            userID: message.author_id
        });
    };

    /**
     * @param linkUrl
     * @returns {Promise<void>}
     */
    onPressMessageLink = async (linkUrl) => {
        await openLink(linkUrl, this.props.theme);
    };

    loadData = async () => {
        await this.retrieveData([
            ['retrieveContactMessages', this.state.contactID],
            ['subscribeOnCometContact', this.state.contactID],
            ['getContact', this.state.contactID],
        ], 'isLoading');
    };

    onRefresh = async () => {
        await this.retrieveData([
            ['retrieveContactMessages', this.state.contactID],
            ['getContact', this.state.contactID],
        ], 'isRefreshing');
    };
}


const mapStateToProps = ({ common, user, contact }) => ({
    me: user.me,
    messages: contact.messages,
    isTransportOnline: common.isTransportOnline,
    contactsData: contact.contactsData
});

const mapDispatchToProps = dispatch => ({
    retrieveContactMessages: (contactID) => dispatch(retrieveContactMessages(contactID)),
    loadMoreContactMessages: (contactID) => dispatch(loadMoreContactMessages(contactID)),
    subscribeOnCometContact: (contactID) => dispatch(subscribeOnCometContact(contactID)),
    openContactMessage: (contactID, messageID) => dispatch(openContactMessage(contactID, messageID)),
    closeContactMessage: (contactID, messageID) => dispatch(closeContactMessage(contactID, messageID)),
    sendPrivatePhoto: (contactID, imageUri) => dispatch(sendPrivatePhoto(contactID, imageUri)),
    removeContact: (userID) => dispatch(removeContact(userID)),
    getContact: (contactID) => dispatch(getContact(contactID)),
    openImage: (image) => dispatch(openImage(image)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    connectActionSheet(injectIntl(withStyles(ConversationScreen, styles)))
);

