import { Alert } from "react-native";

/**
 * @param title
 * @param body
 * @param confirmButtonText
 * @param cancelButtonText
 * @param onConfirm
 * @param onCancel
 * @param isCancelButtonPreferred
 * @returns {*}
 */
export const showConfirm = (title, body, confirmButtonText, cancelButtonText, onConfirm, onCancel, isCancelButtonPreferred = true) => {
    return Alert.alert(title, body, [{
            text: cancelButtonText,
            onPress: onCancel,
            style: isCancelButtonPreferred ? 'cancel' : 'default',
        }, {
            text: confirmButtonText,
            onPress: onConfirm
        }],
        { cancelable: false }
    );
};
