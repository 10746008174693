import React, { PureComponent } from 'react';

import { Text, View, Avatar, Pressable, Icon } from '../../components/base';
import { withStyles } from "../../theme";
import { withNavigation } from 'react-navigation';
import screenNames from "../../navigation/screenNames";
import { injectIntl } from "react-intl";

class NotificationItem extends PureComponent {

    /**
     * @returns {*}
     */
    renderLikeMessage = () => {

        const {
            intl,
            author_avatar_normal,
            author_nickname,
            channel_title,
            channel_message,
            created_at,
            styles
        } = this.props;

        return (
            <View style={styles.content}>
                <Pressable onPress={this.onPressAvatar} style={styles.avatarWrapper}>
                    <Avatar style={styles.avatarImage} avatarUrl={author_avatar_normal} />
                    <View style={styles.iconWrapper}>
                        <Icon name={'thumbs-up'} style={styles.icon} />
                    </View>
                </Pressable>
                <View style={styles.textContent}>
                    <Text style={styles.titleText} numberOfLines={2}>
                        {intl.formatMessage({ id: 'notifications.liked_your_message' }, {
                            nickname: `@${author_nickname}`
                        })} <Text style={styles.timeAgoText}>{intl.formatRelative(created_at, true)}</Text>
                    </Text>
                    <View style={styles.secondaryBlock}>
                        <Text style={styles.secondaryBlockText} numberOfLines={2}>
                            #{channel_title}: {channel_message}
                        </Text>
                    </View>
                </View>
            </View>
        );
    };

    /**
     * @returns {*}
     */
    renderLikeChannel = () => {

        const {
            intl,
            author_avatar_normal,
            author_nickname,
            channel_description,
            created_at,
            styles
        } = this.props;

        return (
            <View style={styles.content}>
                <Pressable onPress={this.onPressAvatar} style={styles.avatarWrapper}>
                    <Avatar style={styles.avatarImage} avatarUrl={author_avatar_normal} />
                    <View style={styles.iconWrapper}>
                        <Icon name={'thumbs-up'} style={styles.icon} />
                    </View>
                </Pressable>
                <View style={styles.textContent}>
                    <Text style={styles.titleText} numberOfLines={2}>
                        {intl.formatMessage({ id: 'notifications.liked_your_topic' }, {
                            nickname: `@${author_nickname}`
                        })} <Text style={styles.timeAgoText}>{intl.formatRelative(created_at, true)}</Text>
                    </Text>
                    <View style={styles.secondaryBlock}>
                        <Text style={styles.secondaryBlockText} numberOfLines={2}>{channel_description}</Text>
                    </View>
                </View>
            </View>
        );
    };

    renderNewMember = () => {

        const {
            intl,
            author_avatar_normal,
            author_nickname,
            channel_description,
            created_at,
            styles,
            theme
        } = this.props;

        return (
            <View style={styles.content}>
                <Pressable onPress={this.onPressAvatar} style={styles.avatarWrapper}>
                    <Avatar style={styles.avatarImage} avatarUrl={author_avatar_normal} />
                    <View style={[styles.iconWrapper, { backgroundColor: theme.COLORS.INFO }]}>
                        <Icon name={'rss'} style={styles.icon} />
                    </View>
                </Pressable>
                <View style={styles.textContent}>
                    <Text style={styles.titleText} numberOfLines={2}>
                        {intl.formatMessage({ id: 'notifications.has_joined_to' }, {
                            nickname: `@${author_nickname}`
                        })} <Text style={styles.timeAgoText}>{intl.formatRelative(created_at, true)}</Text>
                    </Text>
                    <View style={styles.secondaryBlock}>
                        <Text style={styles.secondaryBlockText} numberOfLines={2}>{channel_description}</Text>
                    </View>
                </View>
            </View>
        );
    };

    renderReplyMessage = () => {

        const {
            intl,
            author_avatar_normal,
            author_nickname,
            channel_title,
            channel_message,
            created_at,
            styles,
            theme
        } = this.props;

        return (
            <View style={styles.content}>
                <Pressable onPress={this.onPressAvatar} style={styles.avatarWrapper}>
                    <Avatar style={styles.avatarImage} avatarUrl={author_avatar_normal} />
                    <View style={[styles.iconWrapper, { backgroundColor: theme.COLORS.WARNING }]}>
                        <Icon name={'corner-up-left'} style={styles.icon} />
                    </View>
                </Pressable>
                <View style={styles.textContent}>
                    <Text style={styles.titleText} numberOfLines={2}>
                        {intl.formatMessage({ id: 'notifications.replied_to_your_message' }, {
                            nickname: `@${author_nickname}`
                        })}  <Text style={styles.timeAgoText}>{intl.formatRelative(created_at, true)}</Text>
                    </Text>
                    <View style={styles.secondaryBlock}>
                        <Text style={styles.secondaryBlockText} numberOfLines={2}>
                            #{channel_title}: {channel_message}
                        </Text>
                    </View>
                </View>
            </View>
        );
    };

    renderSendRequest = () => {

        const {
            intl,
            author_avatar_normal,
            author_nickname,
            created_at,
            styles,
            theme
        } = this.props;

        return (
            <View style={styles.content}>
                <Pressable onPress={this.onPressAvatar} style={styles.avatarWrapper}>
                    <Avatar style={styles.avatarImage} avatarUrl={author_avatar_normal} />
                    <View style={[styles.iconWrapper, { backgroundColor: theme.COLORS.PRIMARY }]}>
                        <Icon name={'user-plus'} style={styles.icon} />
                    </View>
                </Pressable>
                <View style={styles.textContent}>
                    <Text style={styles.titleText} numberOfLines={2}>
                        {intl.formatMessage({ id: 'notifications.wants_to_be_contacts' }, {
                            nickname: `@${author_nickname}`
                        })} <Text style={styles.timeAgoText}>{intl.formatRelative(created_at, true)}</Text>
                    </Text>
                </View>
            </View>
        );
    };

    renderAcceptRequest = () => {

        const {
            intl,
            author_avatar_normal,
            author_nickname,
            created_at,
            styles,
            theme
        } = this.props;

        return (
            <View style={styles.content}>
                <Pressable onPress={this.onPressAvatar} style={styles.avatarWrapper}>
                    <Avatar style={styles.avatarImage} avatarUrl={author_avatar_normal} />
                    <View style={[styles.iconWrapper, { backgroundColor: theme.COLORS.SUCCESS }]}>
                        <Icon name={'user-plus'} style={styles.icon} />
                    </View>
                </Pressable>
                <View style={styles.textContent}>
                    <Text style={styles.titleText} numberOfLines={2}>
                        {intl.formatMessage({ id: 'notifications.confirm_contacts' }, {
                            nickname: `@${author_nickname}`
                        })} <Text style={styles.timeAgoText}>{intl.formatRelative(created_at, true)}</Text>
                    </Text>
                </View>
            </View>
        );
    };

    onPress = () => {
        const { navigation, navigateToTopic, type, channel_id } = this.props;
        switch(type) {
            case TYPE_SEND_REQUEST:
                return navigation.navigate(screenNames.CONTACTS_REQUESTS);
            case TYPE_ACCEPT_REQUEST:
                return navigation.navigate(screenNames.CONTACTS);
            case TYPE_JOIN_CHANNEL:
            case TYPE_REPLY_MESSAGE:
            case TYPE_LIKE_MESSAGE:
            case TYPE_LIKE_CHANNEL:
                return navigateToTopic(channel_id);
        }
    };

    onPressAvatar = () => {
        const { navigation, author_id } = this.props;
        navigation.navigate(screenNames.PROFILE, {
            userID: author_id
        });
    };

    render() {

        const {
            theme,
            styles,
            style,
            type,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                <Pressable onPress={this.onPress}>
                    {TYPE_LIKE_MESSAGE === type && (
                        this.renderLikeMessage()
                    )}
                    {TYPE_LIKE_CHANNEL === type && (
                        this.renderLikeChannel()
                    )}
                    {TYPE_REPLY_MESSAGE === type && (
                        this.renderReplyMessage()
                    )}
                    {TYPE_SEND_REQUEST === type && (
                        this.renderSendRequest()
                    )}
                    {TYPE_ACCEPT_REQUEST === type && (
                        this.renderAcceptRequest()
                    )}
                    {TYPE_JOIN_CHANNEL === type && (
                        this.renderNewMember()
                    )}
                </Pressable>
            </View>
        );
    }
}

const TYPE_LIKE_MESSAGE = 'like_channel_message',
    TYPE_LIKE_CHANNEL = 'like_channel',
    TYPE_REPLY_MESSAGE = 'reply_channel_message',
    TYPE_SEND_REQUEST = 'send_request',
    TYPE_ACCEPT_REQUEST = 'accept_request',
    TYPE_JOIN_CHANNEL = 'join_channel';

export default withNavigation(injectIntl(withStyles(NotificationItem, theme => ({
    wrapper: {
        overflow: 'hidden',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 17.5
    },
    avatarWrapper: {
        width: 50,
        height: 50,
        marginRight: 10
    },
    avatarImage: {
        width: 50,
        height: 50,
        borderRadius: 17.5
    },
    textContent: {
        marginVertical: 0,
        flex: 1
    },
    titleText: {
        color: theme.COLORS.TEXT,
        fontSize: 15,
        lineHeight: 25,
        marginRight: 5,
        fontWeight: '900',
        textAlign: 'left'
    },
    timeAgoText: {
        fontWeight: '900',
        color: theme.COLORS.SECONDARY_TEXT
    },
    subTitleText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 14,
        lineHeight: 20,
        fontWeight: '500',
        textAlign: 'left'
    },
    content: {
        paddingLeft: 15,
        paddingRight: 12.5,
        paddingVertical: 12.5,
        flexDirection: 'row',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },
    secondaryBlock: {
        backgroundColor: theme.COLORS.BACKGROUND,
        paddingHorizontal: 15,
        paddingVertical: 5,
        marginTop: 5,
        borderRadius: 12.5,
    },
    secondaryBlockText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 15,
        fontWeight: '700'
    },
    iconWrapper: {
        position: 'absolute',
        bottom: -2,
        right: -2,
        width: 22,
        height: 22,
        borderRadius: 9,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.COLORS.SUCCESS
    },
    icon: {
        fontSize: 12,
        color: theme.COLORS.WHITE
    }
}))));
