import React, { PureComponent } from 'react';

import { Text, View, Image, Avatar, Icon, Pressable } from '../../components/base';
import { withStyles } from "../../theme";

class SystemMessageItem extends PureComponent {
    render() {

        const {
            styles,
            style,
            message,
            isOpened,
            hasSubscription
        } = this.props;

        return (
            <Pressable onPress={this.onPress} onLongPress={this.onLongPress} style={[
                styles.wrapper,
                style
            ]}>
                <View style={styles.contentWrapper}>
                    <Text style={styles.messageText}>{message}</Text>
                </View>
            </Pressable>
        );
    }

    onPress = () => {
        this.props.onPress(this.props);
    };

    onLongPress = () => {
        this.props.onLongPress(this.props);
    };
}

export default withStyles(SystemMessageItem, theme => ({
    wrapper: {
        paddingLeft: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
        paddingRight: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
        alignItems: 'center'
    },

    contentWrapper: {
        marginLeft: 10,
        marginTop: 3,
    },

    messageText: {
        fontWeight: '500',
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 13,
        textAlign: 'center'
    },
}));
