import React from 'react';

import { injectIntl } from "react-intl";
import { withStyles } from "../../theme";
import { KView, View, Screen, Text, Icon, Pressable, SView } from '../../components/base';
import { TextInput, Button, Select, Toast } from "../../components";
import { connect } from "react-redux";
import screenNames from "../../navigation/screenNames";
import styles from "./styles";
import {
    getLocation,
    getCountries,
    getCities,
    getUniversities,
    updateLocation,
    sendUniversitySubmission
} from "../../state/common/actions";
import Validator from '../../utils/validator';
import SectionHeader from './SectionHeader';
import Events from '../../utils/events';

class EditLocationScreen extends Screen {

    screenName = screenNames.EDIT_LOCATION;

    state = {
        isLoading: false,
        isSubmissionLoading: false,
        isSubmissionSubmitted: false,
        form: {
            countryID: null,
            cityID: null,
            universityID: null
        },
        submissionFormData: {
            title: ''
        },
        submissionErrors: {},
        countries: [],
        cities: [],
        universities: [],
        errors: {}
    };

    content() {

        const {
            isLoading,
            isSubmissionLoading,
            form,
            errors,
            submissionFormData,
            submissionErrors,
            countries,
            cities,
            universities,
            isSubmissionSubmitted
        } = this.state;

        const { theme, styles, navigation, intl } = this.props;

        return (
            <KView style={styles.screenWrapper}>
                <View style={styles.headerWrapper}>
                    <Text style={[
                        styles.headingText,
                        styles.headingTextOffset
                    ]}>
                        {intl.formatMessage({ id: 'edit_location.heading' })}
                    </Text>
                    <Text style={styles.subHeadingText}>
                        {intl.formatMessage({ id: 'edit_location.subtitle' })}
                    </Text>
                    <Pressable onPress={() => navigation.goBack()} style={styles.headerBackButton}>
                        <Icon name={'chevron-down'} color={theme.COLORS.TEXT} size={23} />
                    </Pressable>
                </View>
                <SectionHeader />
                <SView
                    style={styles.formWrapper}
                    contentContainerStyle={styles.formContentWrapper}
                >
                    <View style={styles.formField}>
                        <Text style={styles.formLabelText}>
                            {intl.formatMessage({ id: 'edit_location.country' })}:
                        </Text>
                        <Select
                            style={styles.formInput}
                            variation={'default'}
                            value={form.countryID}
                            error={errors.countryID}
                            onChange={this.onSelectCountry}
                            placeholder={intl.formatMessage({ id: 'edit_location.country_placeholder' })}
                            showHeadingValue
                            options={countries}
                        />
                    </View>

                    <View style={styles.formField}>
                        <Text style={styles.formLabelText}>
                            {intl.formatMessage({ id: 'edit_location.city' })}:
                        </Text>
                        <Select
                            style={styles.formInput}
                            variation={'default'}
                            value={form.cityID}
                            error={errors.cityID}
                            onChange={this.onSelectCity}
                            placeholder={intl.formatMessage({ id: 'edit_location.city_placeholder' })}
                            options={cities}
                        />
                    </View>

                    {!(form.cityID && universities.length === 0) && (
                        <View style={styles.formField}>
                            <Text style={styles.formLabelText}>
                                {intl.formatMessage({ id: 'edit_location.education_institution' })}:
                            </Text>
                            <Select
                                style={styles.formInput}
                                variation={'default'}
                                disabled={!form.cityID}
                                error={errors.universityID}
                                value={form.universityID}
                                onChange={this.onSelectUniversity}
                                placeholder={intl.formatMessage({ id: 'edit_location.education_institution_placeholder' })}
                                options={universities}
                            />
                        </View>
                    )}

                    {form.cityID && universities.length === 0 && !isSubmissionSubmitted && (
                        <View style={[
                            styles.formField, { backgroundColor: theme.COLORS.BACKGROUND_ITEM, borderRadius: 17.5, padding: 10 }
                        ]}>
                            <Text style={styles.formLabelText}>
                                {intl.formatMessage({ id: 'edit_location.no_education_institution' }, {
                                    cityName: this.selectedCity()
                                })}
                            </Text>
                            <TextInput
                                placeholder={intl.formatMessage({ id: 'edit_location.name_placeholder' })}
                                style={[
                                    styles.formInput, { marginTop: 5 }
                                ]}
                                error={submissionErrors.title}
                                value={submissionFormData.title}
                                onChangeText={(title) => this.setState({ submissionFormData: { ...this.state.submissionFormData, title } })}
                                textInputStyle={{ backgroundColor: theme.COLORS.BACKGROUND }}
                                variation={'default'}
                            />
                            <Button
                                style={{ marginTop: 10 }}
                                variation={'secondary'}
                                size={'small'}
                                onPress={this.onPressSubmitUniversity}
                                loading={isSubmissionLoading}
                            >
                                {intl.formatMessage({ id: 'common.submit' })}
                            </Button>
                        </View>
                    )}

                    <Button
                        style={styles.submitButton}
                        variation={'primary'}
                        size={'medium'}
                        onPress={this.onPressChange}
                        loading={isLoading}
                    >
                        {intl.formatMessage({ id: 'common.change' })}
                    </Button>
                </SView>
                <Button
                    style={styles.cancelButton}
                    variation={'transparent'}
                    size={'medium'}
                    onPress={() => navigation.goBack()}
                >
                    <Text style={{
                        color: theme.COLORS.SECONDARY_TEXT,
                        fontWeight: '900'
                    }}>{intl.formatMessage({ id: 'common.cancel' })}</Text>
                </Button>
            </KView>
        );
    }

    onNavigate = async () => {
        const { result } = await this.props.getCountries();

        await this.setState({
            countries: result.sort(dynamicSort('long_name')).map(country => ({
                key: country.id,
                text: country.long_name,
                heading: country.flag
            })),
        });

        if(this.props.country && this.props.country.id) {
            await this.onSelectCountry(this.props.country.id);
        }

        if(this.props.city && this.props.city.id) {
            await this.onSelectCity(this.props.city.id);
        }

        if(this.props.university && this.props.university.id) {
            await this.onSelectUniversity(this.props.university.id);
        }
    };

    onSelectCountry = async (countryID) => {
        if(!this.props.cities[countryID]) {
            await this.props.getCities(countryID);
        }
        return this.setState({
            form: { ...this.state.form, countryID, cityID: null, universityID: null },
            cities: this.props.cities[countryID].sort(dynamicSort('long_name')).map(city => ({
                key: city.id,
                text: city.long_name
            }))
        });
    };

    onSelectCity = async (cityID) => {
        if(!this.props.universities[cityID]) {
            await this.props.getUniversities(cityID);
        }

        const typeToName = (type) => {
            switch (type) {
                case '0':
                    return this.props.intl.formatMessage({ id: 'edit_location.university' });
                case '1':
                    return this.props.intl.formatMessage({ id: 'edit_location.school' });
                case '2':
                    return this.props.intl.formatMessage({ id: 'edit_location.lyceum' }) + '/' + this.props.intl.formatMessage({ id: 'edit_location.gymnasium' });
                case '3':
                    return this.props.intl.formatMessage({ id: 'edit_location.college' });
                default:
                    return this.props.intl.formatMessage({ id: 'edit_location.educational_institution' });
            }
        };

        return this.setState({
            form: { ...this.state.form, cityID, universityID: null },
            universities: this.props.universities[cityID].sort(dynamicSort('long_name')).map(university => ({
                key: university.id,
                heading: university.short_name,
                text: typeToName(university.type.toString())
            }))
        });
    };

    onSelectUniversity = async (universityID) => {
        return this.setState({
            form: { ...this.state.form, universityID },
        });
    };

    selectedCity() {
        for(let i in this.state.cities) {
            if(this.state.form.cityID === this.state.cities[i].key) {
                return this.state.cities[i].text;
            }
        }
        return '';
    }

    onPressChange = async () => {
        await this.setState({ isLoading: true, errors: {} });

        let form = { ...this.state.form };
        form.cityID = this.props.city.id;
        if(this.props.university) {
            form.universityID = this.props.university.id;
        } else {
            form.universityID = 0;
        }

        let validator = new Validator([
            ['cityID', Validator.rules.NOT_EMPTY],
            ['cityID', Validator.rules.INTEGER, { min: 1 }],
            ['universityID', Validator.rules.INTEGER, { min: 1 }]
        ], form);

        if(await validator.validate()) {
            let { success, errors } = await this.props.updateLocation(this.state.form.cityID, this.state.form.universityID ? this.state.form.universityID : null);
            if(success) {
                await this.props.getLocation();
                Toast.showSimpleSuccess(this.props.intl.formatMessage({ id: 'edit_location.location_has_updated' }));
                this.props.navigation.goBack();
                Events.publish(Events.Types.CHANGE_LOCATION, {
                    country: this.props.country,
                    city: this.props.city,
                    university: this.props.university
                });
            } else if(errors) {
                validator.setErrors(errors);
                await this.setState({
                    errors: validator.getIntlErrorsMessages(this.props.intl)
                });
            }
        } else {
            await this.setState({
                errors: validator.getIntlErrorsMessages(this.props.intl)
            });
        }
        await this.setState({
            isLoading: false
        });
    };

    onPressSubmitUniversity = async () => {
        await this.setState({ isSubmissionLoading: true, submissionErrors: {} });

        let formData = { ...this.state.submissionFormData };

        formData.cityID = this.state.form.cityID;

        let validator = new Validator([
            ['title', Validator.rules.NOT_EMPTY],
            ['title', Validator.rules.LENGTH, { min: 2, max: 200 }],
            ['cityID', Validator.rules.INTEGER, { min: 1 }],
        ], formData);

        if(await validator.validate()) {
            let { success, errors } = await this.props.sendUniversitySubmission(formData.cityID, formData.title);
            if(success) {
                Toast.showSimpleSuccess(this.props.intl.formatMessage({ id: 'edit_location.request_sent' }));
                await this.setState({
                    isSubmissionSubmitted: true,
                    submissionFormData: {
                        title: ''
                    }
                });
            } else if(errors) {
                validator.setErrors(errors);
                await this.setState({
                    submissionErrors: validator.getIntlErrorsMessages(this.props.intl)
                });
            }
        } else {
            await this.setState({
                submissionErrors: validator.getIntlErrorsMessages(this.props.intl)
            });
        }
        await this.setState({
            isSubmissionLoading: false
        })
    };
}

const dynamicSort = (property) => {
    let sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
};

const mapStateToProps = ({ user, common }) => ({
    me: user.me,
    city: common.city,
    country: common.country,
    university: common.university,
    countries: common.countries,
    cities: common.cities,
    universities: common.universities,
});

const mapDispatchToProps = dispatch => ({
    getLocation: () => dispatch(getLocation()),
    getCountries: () => dispatch(getCountries()),
    getCities: (countryID) => dispatch(getCities(countryID)),
    getUniversities: (city_id) => dispatch(getUniversities(city_id)),
    updateLocation: (city_id, university_id) => dispatch(updateLocation(city_id, university_id)),
    sendUniversitySubmission: (cityID, title) => dispatch(sendUniversitySubmission(cityID, title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withStyles(EditLocationScreen, styles))
);

