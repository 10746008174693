import React from 'react';

import { createStackNavigator } from 'react-navigation-stack';

import screens from '../screens';
import screenNames from './screenNames';

export default createStackNavigator({
    [screenNames.TOPIC]: screens.TopicScreen,
    [screenNames.TOPIC_MEMBERS]: screens.TopicMembersScreen,
    [screenNames.PROFILE]: screens.ProfileScreen,
}, {
    initialRouteName: screenNames.TOPIC,
    headerMode: 'none',
    defaultNavigationOptions: {
        header: null,
        cardShadowEnabled: false,
        cardOverlayEnabled: false,
        gesturesEnabled: true,
        cardTransparent: true
    },
});
