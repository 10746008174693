import React, { PureComponent } from 'react';

import { Text, View, Image, Icon, Pressable } from '../../components/base';
import { withStyles } from "../../theme";
import { IconButton, Ticker } from "../../components";

class TopicItem extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            coverImageUrl,
            text,
            ...restProps
        } = this.props;

        return (
            <Pressable {...restProps} style={[
                styles.wrapper, style
            ]}>
                {!!coverImageUrl && (
                    <Image blurRadius={3} style={styles.coverImage} source={{ uri: coverImageUrl }} />
                )}
                <View style={styles.content}>
                    <Text style={styles.titleText} numberOfLines={2}>
                        {text}
                    </Text>
                </View>
            </Pressable>
        );
    }
}

export default withStyles(TopicItem, theme => ({
    wrapper: {
        overflow: 'hidden',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 12.5
    },
    coverImage: {
        position: 'absolute',
        opacity: .3,
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%'
    },
    titleText: {
        color: theme.COLORS.TEXT,
        fontSize: 18,
        fontFamily: theme.FONTS.BALOO,
        marginVertical: 10,
        textAlign: 'left'
    },
    content: {
        paddingHorizontal: 20,
    }
}));
