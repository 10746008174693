import React, { PureComponent } from 'react';

import { withStyles } from "../../theme";
import { StyleSheet } from 'react-native';
import IconButton from '../IconButton';
import { connect } from "react-redux";

class SearchButton extends PureComponent {
    render () {
        const { theme, styles, style, children, isLoggedIn, ...restProps } = this.props;
        const { style: labelStyle } = children[children.length - 1].props;
        return isLoggedIn && (
            <IconButton
                name='search'
                size={20}
                color={StyleSheet.flatten(labelStyle).color}
                style={[ style, styles.wrapper ]}
                {...restProps}
            />
        );
    }
}

const mapStateToProps = ({ auth, user }) => ({
    isLoggedIn: auth.isLoggedIn
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(SearchButton, theme => ({
        wrapper: {
            padding: 10,
            alignItems: 'center'
        }
    })));
