import React, { PureComponent } from 'react';

import { Text, View, Image, Avatar, Pressable } from '../../components/base';
import { withStyles } from "../../theme";

class ResultItem extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            onPress,
            thumbnail,
            title,
            description,
            type,
            score,
            country_name,
            city_name,
            university_name,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                {type === 'user' && (
                    <Pressable onPress={onPress} style={styles.content}>
                        <Avatar style={styles.avatarImage} avatarUrl={thumbnail} />
                        <View style={styles.textContent}>
                            <Text style={styles.titleText} numberOfLines={1}>
                                @{title} <Text style={{ color: theme.COLORS.SECONDARY_TEXT }}>{score > 0 ? '+' + score : score < 0 ? '–' + Math.abs(score) : null}</Text>
                            </Text>
                            <Text style={styles.subTitleText} numberOfLines={1}>
                                {description}
                            </Text>
                            {false && (
                                <Text style={styles.karmaText}>
                                    { ( university_name !== null ? `${university_name} (${city_name}, ${country_name})` : `${city_name}, ${country_name}` ) }
                                </Text>
                            )}
                        </View>
                    </Pressable>
                )}
                {type === 'topic' && (
                    <Pressable onPress={onPress} style={styles.topicContent}>
                        {!!thumbnail && (
                            <Image blurRadius={3} style={styles.coverImage} source={{ uri: thumbnail }} />
                        )}
                        <View style={styles.textContent}>
                            <Text style={styles.topicTitleText} numberOfLines={4}>
                                {description} <Text style={{ color: theme.COLORS.SECONDARY_TEXT }}>({score})</Text>
                            </Text>
                            <Text style={styles.locationText}>
                                { ( university_name !== null ? `${university_name} (${city_name}, ${country_name})` : `${city_name}, ${country_name}` ) }
                            </Text>
                        </View>
                    </Pressable>
                )}
            </View>
        );
    }
}

export default withStyles(ResultItem, theme => ({
    wrapper: {
        overflow: 'hidden',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 17.5
    },
    coverImage: {
        position: 'absolute',
        opacity: .3,
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%'
    },
    avatarImage: {
        width: 50,
        height: 50,
        marginRight: 10,
        borderRadius: 17.5
    },
    textContent: {
        marginVertical: 0,
        flex: 1
    },
    titleText: {
        color: theme.COLORS.TEXT,
        fontSize: 16,
        lineHeight: 25,
        fontWeight: '900',
        textAlign: 'left'
    },
    subTitleText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 14,
        lineHeight: 20,
        height: 20,        fontWeight: '500',
        textAlign: 'left'
    },
    karmaText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 11,
        position: 'absolute',
        top: 0,
        right: 0
    },
    locationText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 11,
        position: 'absolute',
        top: 12.5,
        right: 15
    },
    content: {
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 12.5,
        flexDirection: 'row',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },
    topicContent: {
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },
    topicTitleText: {
        color: '#fff',
        fontSize: 16,
        fontWeight: '900',
        marginVertical: 30,
        marginHorizontal: 30,
        textAlign: 'center'
    },
}));
