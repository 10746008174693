import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';

import BrowseStack from './BrowseStack';
import SearchStack from './SearchStack';
import NotificationsStack from './NotificationsStack';
import ProfileStack from './ProfileStack';
import TopicStack from './TopicStack';
import ConversationStack from './ConversationStack';
import { BottomTabBar } from '../components';
import screens from '../screens';

import screenNames from "./screenNames";

const TabNavigation = createBottomTabNavigator({
    BrowseStack: {
        screen: BrowseStack,
        navigationOptions: {
            tabBarButtonComponent: BottomTabBar.BrowseButton
        }
    },
    SearchStack: {
        screen: SearchStack,
        navigationOptions: {
            tabBarButtonComponent: BottomTabBar.SearchButton
        }
    },
    Create: {
        screen: () => {},
        navigationOptions: {
            tabBarButtonComponent: BottomTabBar.CreateButton
        }
    },
    NotificationsStack: {
        screen: NotificationsStack,
        navigationOptions: {
            tabBarButtonComponent: BottomTabBar.NotificationsButton
        }
    },
    ProfileStack: {
        screen: ProfileStack,
        navigationOptions: {
            tabBarButtonComponent: BottomTabBar.ProfileButton
        }
    }
}, {
    tabBarComponent: BottomTabBar.BottomTabBar
});

export default createStackNavigator({
    TabNavigation,
    [screenNames.TOPIC]: TopicStack,
    [screenNames.CONVERSATION]: ConversationStack,
    [screenNames.EDIT_LOCATION]: screens.EditLocationScreen,
},{
    headerMode: 'none',
    defaultNavigationOptions: {
        cardTransparent: true,
        gestureEnabled: true
    },
});
