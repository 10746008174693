export default (theme) => ({
    walkthroughContainer: {
        flex: 1,
        backgroundColor: theme.COLORS.BACKGROUND,
    },

    walkthroughSlideWrapper: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 50,
    },

    walkthroughImage: {
        width: 250,
        height: 180,
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        borderRadius: 25
    },

    walkthroughImageWrapper: {
        marginTop: 20
    },

    walkthroughTextWrapper: {
        marginTop: 25,
    },

    walkthroughHeadingText: {
        color: theme.COLORS.TEXT,
        fontSize: 20,
        fontWeight: '900',
        lineHeight: 30,
        marginBottom: 15,
        textAlign: 'center',
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN
    },
    walkthroughContentText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 20,
        fontWeight: '400',
        lineHeight: 30,
        paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
        textAlign: 'center'
    },

    walkthroughButtonWrapper: {
        marginTop: 30,
        width: '56%'
    },

    walkthroughPaginationWrapper: {
        width: theme.SIZES.SCREEN_WIDTH,
        position: 'absolute',
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        bottom: 0,
    },

    walkthroughPaginationDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        margin: 4,
        backgroundColor: theme.COLORS.SECONDARY_TEXT
    },

    walkthroughPaginationDotActive: {
        width: 10,
        height: 10,
        borderRadius: 5
    },

    walkthroughPaginationDotCompleted: {
        backgroundColor: theme.COLORS.PRIMARY
    },

    skipButtonWrapper: {
        position: 'absolute',
        bottom: 0,
        left: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
    },
    nextButtonWrapper: {
        position: 'absolute',
        bottom: 0,
        right: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
    }
});
