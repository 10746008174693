import { Platform } from 'react-native';
import Constants from 'expo-constants';
import Events from '../utils/events';
import { getCurrentLocale } from "../utils/localizationHelper";
import session from './session';
import base64 from 'react-native-base64'
import * as android from '../utils/androidVersions'

const NO_INTERNET_ERROR_CODE = -1;
const SERVER_HAS_BROKEN_ERROR_CODE = -2;
const COMMON_ERROR_CODE = 400;
const UNAUTHORIZED_ERROR_CODE = 401;
const BANNED_ERROR_CODE = 666;

// const API_URL = 'http://192.168.1.155:3000/v1';
const API_URL = Constants.manifest.extra.apiUrl;
// const API_URL = 'http://127.0.0.1:30044/v1';

const BASE_HEADERS = {
    'Accept': 'application/json',
    'Session-ID': Constants.sessionId,
    'Platform-ID': Platform.OS,
    'App-Version': Constants.manifest.version,
    'Payload': Constants.deviceId,
    'Locale': getCurrentLocale()
};

const HEADERS = {
    'Content-Type': 'application/json',
    ...BASE_HEADERS,
};

const UPLOAD_HEADERS = {
    ...BASE_HEADERS
};

export default {

    errorCodes: {
        NO_INTERNET_ERROR_CODE,
        SERVER_HAS_BROKEN_ERROR_CODE,
        COMMON_ERROR_CODE,
        UNAUTHORIZED_ERROR_CODE,
        BANNED_ERROR_CODE
    },

    /**
     * @param nickname
     * @param password
     * @returns {Promise<void>}
     */
    async signIn(nickname, password) {
        const result = await this.call('POST auth/token', {
            nickname,
            password
        }), { success } = result;
        if(success) {
            await session.set(result.result);
            Events.publish(Events.Types.SIGN_IN, { nickname });
        }
        return result;
    },

    /**
     * @param nickname
     * @param password
     * @param cityID
     * @param universityID
     * @returns {Promise<void>}
     */
    async signUp(nickname, password, cityID, universityID) {
        let os = '', v = '';
        try {
            os = Constants.platform.web.os.name.toLowerCase();
            v = Constants.platform.web.os.version.toLowerCase();
            if(os === 'android') {
                const code = android.get(v);
                if(code) { v = code.api.toString(); }
            }
        } catch(error) {}
        const result = await this.call('POST auth/sign-up', {
            nickname,
            password,
            city_id: cityID,
            university_id: universityID,
            os, v
        }), { success } = result;
        if(success) {
            await session.set(result.result);
            Events.publish(Events.Types.SIGN_UP, { nickname });
        }
        return result;
    },

    /**
     * @returns {Promise<void>}
     */
    async logout(token) {
        const result = await this.call('POST auth/logout', {
            token
        });
        await session.delete();
        Events.publish(Events.Types.LOGOUT);
        return result;
    },

    /**
     * @param actionString
     * @param params
     * @returns {Promise<void>}
     */
    async call(actionString, params) {
        const actionData = actionString.split(' '),
            method = actionData[0],
            action = actionData[1];

        const _session = await session.get();

        let response, result;

        try {
            response = await fetch(`${API_URL}/${action}`, {
                method,
                headers: {
                    ...HEADERS,
                    ...(_session ? {
                        'Authorization': `Bearer ${_session.token}`,
                    } : {})
                },
                body: params ? JSON.stringify(params) : params,
            });
            result = await response.json();
        } catch (error) {
            result = {
                success: false,
                error: {
                    message: error.toString(),
                    code: error.toString().startsWith('TypeError: Network')
                        ? NO_INTERNET_ERROR_CODE
                        : SERVER_HAS_BROKEN_ERROR_CODE
                }
            };
        }
        const { success, error } = result;
        if(!success && error) {
            Events.publish(Events.Types.SERVER_ERROR, {
                method, action, error
            });
        }

        return result;
    },

    /**
     * @param actionString
     * @param type
     * @param fileUri
     * @param payload
     * @returns {Promise<void>}
     */
    async uploadImage(actionString, type, fileUri, payload) {
        const actionData = actionString.split(' '),
            method = actionData[0],
            action = actionData[1],
            form = new FormData();

        const _session = await session.get();

        function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            let byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else
                byteString = unescape(dataURI.split(',')[1]);

            // separate out the mime component
            let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // write the bytes of the string to a typed array
            let ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], { type: mimeString });
        }

        form.append(type, dataURItoBlob(fileUri), base64.encode(encodeURIComponent(JSON.stringify(payload || {}))));

        let response, result;

        try {
            response = await fetch(`${API_URL}/${action}`, {
                body: form,
                method: method,
                headers: {
                    ...UPLOAD_HEADERS,
                    ...(_session ? {
                        'Authorization': `Bearer ${_session.token}`,
                    } : {})
                },
            });
            result = await response.json();
        } catch (error) {
            result = {
                success: false,
                error: {
                    message: error.toString(),
                    code: error.toString().startsWith('TypeError: Network')
                        ? NO_INTERNET_ERROR_CODE
                        : SERVER_HAS_BROKEN_ERROR_CODE
                }
            };
        }
        const { success, error } = result;
        if(!success && error) {
            Events.publish(Events.Types.SERVER_ERROR, {
                method, action, error
            });
        }

        return result;
    }
};
