import React, { PureComponent } from 'react';

import { Text, View } from '../../components/base';
import { withStyles } from "../../theme";
import { LinearGradient } from "expo-linear-gradient";

class SectionHeader extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            children,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                <Text style={styles.titleText} numberOfLines={1}>
                    {children}
                </Text>
                <LinearGradient
                    {...theme.COLORS.GRADIENTS.PRIMARY}
                    style={styles.gradient}
                />
            </View>
        );
    }
}

export default withStyles(SectionHeader, theme => ({
    wrapper: {
    },
    titleText: {
        marginHorizontal: theme.SIZES.HEADER_HORIZONTAL_PADDING + 2,
        color: theme.COLORS.TEXT,
        fontWeight: '700',
        fontSize: 15
    },
    gradient: {
        marginTop: 10,
        borderRadius: 2,
        height: 4,
        marginHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING,
    }
}));
