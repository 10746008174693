import Events from '../utils/events';
import faye from "faye";
import session from './session';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
// const COMET_URL = 'http://192.168.1.155:3000/comet/v1';
const COMET_URL = Constants.manifest.extra.cometUrl;

const _subscriptions = {};

export default {

    async init() {
        const _session = await session.get();
        this.client = new faye.Client(`${COMET_URL}`, {
            endpoints: { websocket: `${COMET_URL}` },
            timeout: 15,
            retry: 4
        });
        if(Platform.OS !== 'web') {
            this.client.disable('autodisconnect');
            this.client.disable('callback-polling');
            this.client.disable('in-process');
        }
        this.client.addExtension({
            outgoing: function(message, callback) {
                if(message.channel === '/meta/connect' && _session) {
                    message.ext = message.ext || {};
                    message.ext.authToken = _session.token;
                }
                callback(message);
            }
        });
        this.client.on('transport:down', () => {
            Events.publish(Events.Types.TRANSPORT_DOWN);
        });
        this.client.on('transport:up', () => {
            Events.publish(Events.Types.TRANSPORT_UP);
        });

    },

    destroy() {
        if(this.client) {
            for(let channel in _subscriptions) {
                _subscriptions[channel].cancel();
                delete _subscriptions[channel];
            }
            this.client.disconnect();
            delete this.client;
        }
    },

    /**
     * @param channel
     * @param callback
     * @returns {*}
     */
    subscribe(channel, callback) {
        if(!_subscriptions[channel]) {
            _subscriptions[channel] = this.client.subscribe(channel, (message) => {
                callback(message);
                Events.publish(Events.Types.RECEIVED_MESSAGE_FROM_SERVER, {
                    channel, message
                });
            });
        }
        return _subscriptions[channel];
    },

    /**
     * @param channel
     * @returns {null}
     */
    getSubscription(channel) {
        return _subscriptions[channel]
            ? _subscriptions[channel]
            : null;
    },

    /**
     * @param channel
     */
    unsubscribe(channel) {
        if(_subscriptions[channel]) {
            _subscriptions[channel].cancel();
            delete _subscriptions[channel];
        }
    }
};
