//auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const OPEN_REGISTRATION_MODAL = 'OPEN_REGISTRATION_MODAL';
export const INITIALIZE_PUSH_SERVICE = 'INITIALIZE_PUSH_SERVICE';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const CLEAR_AUTH_REDUCER = 'CLEAR_AUTH_REDUCER';
export const RECOVER_SESSION = 'RECOVER_SESSION';

//user
export const GET_ME = 'GET_ME';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const LOAD_MORE_NOTIFICATIONS = 'LOAD_MORE_NOTIFICATIONS';
export const UPDATE_USER_MUTE = 'UPDATE_USER_MUTE';
export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';
export const GET_USER = 'GET_USER';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const GET_USER_KARMA_HISTORY = 'GET_USER_KARMA_HISTORY';
export const LOAD_MORE_USER_KARMA_HISTORY = 'LOAD_MORE_USER_KARMA_HISTORY';
export const GET_USER_CHANNEL_MESSAGES = 'GET_USER_CHANNEL_MESSAGES';
export const LOAD_MORE_USER_CHANNEL_MESSAGES = 'LOAD_MORE_USER_CHANNEL_MESSAGES';
export const GET_USER_CHANNELS = 'GET_USER_CHANNELS';
export const LOAD_MORE_USER_CHANNELS = 'LOAD_MORE_USER_CHANNELS';
export const CLEAR_USER_REDUCER = 'CLEAR_USER_REDUCER';
export const BLOCK_USER = 'BLOCK_USER';
export const UNBLOCK_USER = 'UNBLOCK_USER';
export const GET_USER_CONTACTS = 'GET_USER_CONTACTS';
export const LOAD_MORE_USER_CONTACTS = 'LOAD_MORE_USER_CONTACTS';

//channel
export const CREATE_CHANNEL = 'CREATE_CHANNEL';
export const CREATE_RICH_CHANNEL = 'CREATE_RICH_CHANNEL';
export const GET_CHANNEL = 'GET_CHANNEL';
export const UPDATE_CHANNEL_LAST_VISIT = 'UPDATE_CHANNEL_LAST_VISIT';
export const GET_CHANNEL_ICONS = 'GET_CHANNEL_ICONS';
export const GET_CHANNEL_BACKGROUNDS = 'GET_CHANNEL_BACKGROUNDS';
export const GET_LIST_CHANNELS = 'GET_LIST_CHANNELS';
export const CHANNEL_SUBSCRIBE_COMET = 'CHANNEL_SUBSCRIBE_COMET';
export const CHANNEL_SUBSCRIBE = 'CHANNEL_SUBSCRIBE';
export const CHANNEL_UNSUBSCRIBE = 'CHANNEL_UNSUBSCRIBE';
export const CHANNEL_MESSAGE_VOTE = 'CHANNEL_MESSAGE_VOTE';
export const CHANNEL_VOTE = 'CHANNEL_VOTE';
export const CHANNEL_MESSAGE_CHECK_VOTE = 'CHANNEL_MESSAGE_CHECK_VOTE';
export const OPEN_CHANNEL_MESSAGE = 'OPEN_CHANNEL_MESSAGE';
export const CLOSE_CHANNEL_MESSAGE = 'CLOSE_CHANNEL_MESSAGE';
export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';
export const CLOSE_CHANNEL = 'CLOSE_CHANNEL';
export const UPLOAD_CHANNEL_THUMBNAIL = 'UPLOAD_CHANNEL_THUMBNAIL';
export const UPLOAD_CHANNEL_BACKGROUND = 'UPLOAD_CHANNEL_BACKGROUND';
export const RECEIVED_CHANNEL_EVENT = 'RECEIVED_CHANNEL_EVENT';
export const SEND_CHANNEL_MESSAGE = 'SEND_CHANNEL_MESSAGE';
export const GET_CHANNEL_MESSAGES = 'GET_CHANNEL_MESSAGES';
export const GET_CHANNEL_MESSAGES_ASC = 'GET_CHANNEL_MESSAGES_ASC';
export const LOAD_MORE_CHANNEL_MESSAGES = 'LOAD_MORE_CHANNEL_MESSAGES';
export const LOAD_MORE_CHANNEL_MESSAGES_ASC = 'LOAD_MORE_CHANNEL_MESSAGES_ASC';
export const GET_CHANNEL_MEMBERS_LIST = 'GET_CHANNEL_MEMBERS_LIST';
export const LOAD_MORE_CHANNEL_MEMBERS_LIST = 'LOAD_MORE_CHANNEL_MEMBERS_LIST';
export const SEARCH_CHANNEL_MEMBERS_LIST = 'SEARCH_CHANNEL_MEMBERS_LIST';
export const LOAD_MORE_SEARCH_CHANNEL_MEMBERS_LIST = 'LOAD_MORE_SEARCH_CHANNEL_MEMBERS_LIST';
export const LOAD_MORE_LIST_CHANNELS = 'LOAD_MORE_LIST_CHANNELS';
export const BAN_CHANNEL_MEMBER = 'BAN_CHANNEL_MEMBER';
export const UNBAN_CHANNEL_MEMBER = 'UNBAN_CHANNEL_MEMBER';
export const DELETE_CHANNEL_MESSAGE = 'DELETE_CHANNEL_MESSAGE';
export const ADD_CHANNEL_RULE = 'ADD_CHANNEL_RULE';
export const REMOVE_CHANNEL_RULE = 'REMOVE_CHANNEL_RULE';
export const UPDATE_CHANNEL_RULE = 'UPDATE_CHANNEL_RULE';
export const RETRIEVE_CHANNEL_RULES = 'RETRIEVE_CHANNEL_RULES';
export const CLEAR_CHANNEL_REDUCER = 'CLEAR_CHANNEL_REDUCER';
export const REPORT_CHANNEL = 'REPORT_CHANNEL';
export const REPORT_CHANNEL_MESSAGE = 'REPORT_CHANNEL_MESSAGE';
export const MUTE_CHANNEL = 'MUTE_CHANNEL';

//common
export const GET_LOCATION = 'GET_LOCATION';
export const REGISTER_DEVICE = 'REGISTER_DEVICE';
export const FORGOT_DEVICE = 'FORGOT_DEVICE';
export const OPEN_IMAGE = 'OPEN_IMAGE';
export const CLOSE_IMAGE = 'CLOSE_IMAGE';
export const GLOBAL_SEARCH = 'GLOBAL_SEARCH';
export const LOAD_MORE_GLOBAL_SEARCH = 'LOAD_MORE_GLOBAL_SEARCH';
export const GET_DEEP_LINK = 'GET_DEEP_LINK';
export const CLEAR_DEEP_LINK = 'CLEAR_DEEP_LINK';
export const UPDATE_TRANSPORT_STATUS = 'UPDATE_TRANSPORT_STATUS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_CITIES = 'GET_CITIES';
export const GET_UNIVERSITIES = 'GET_UNIVERSITIES';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const CLEAR_COMMON_REDUCER = 'CLEAR_COMMON_REDUCER';
export const GET_NEARBY_USERS = 'GET_NEARBY_USERS';
export const LOAD_MORE_NEARBY_USERS = 'LOAD_MORE_NEARBY_USERS';
export const GET_UNIVERSITY_USERS = 'GET_UNIVERSITY_USERS';
export const LOAD_MORE_UNIVERSITY_USERS = 'LOAD_MORE_UNIVERSITY_USERS';
export const UPDATE_CITY_LAST_VISIT = 'UPDATE_CITY_LAST_VISIT';
export const UPDATE_UNIVERSITY_LAST_VISIT = 'UPDATE_UNIVERSITY_LAST_VISIT';
export const SEND_UNIVERSITY_SUBMISSION = 'SEND_UNIVERSITY_SUBMISSION';
export const UPDATE_NEW_CHANNELS_LAST_VISIT = 'UPDATE_NEW_CHANNELS_LAST_VISIT';
export const OPEN_COMMUNITY_RULES = 'OPEN_COMMUNITY_RULES';
export const CLOSE_COMMUNITY_RULES = 'CLOSE_COMMUNITY_RULES';
export const RETRIEVE_LOCALE = 'RETRIEVE_LOCALE';
export const RETRIEVE_THEME = 'RETRIEVE_THEME';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const CHANGE_THEME = 'CHANGE_THEME';

//contact
export const GET_REQUESTS = 'GET_REQUESTS';
export const GET_CONTACT = 'GET_CONTACT';
export const LOAD_MORE_REQUESTS = 'LOAD_MORE_REQUESTS';
export const SEND_REQUEST = 'SEND_REQUEST';
export const DECLINE_REQUEST = 'DECLINE_REQUEST';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const IS_CONTACTS = 'IS_CONTACTS';
export const GET_COUNTERS = 'GET_COUNTERS';
export const GET_CONTACTS = 'GET_CONTACTS';
export const LOAD_MORE_CONTACTS = 'LOAD_MORE_CONTACTS';
export const SEND_PRIVATE_MESSAGE = 'SEND_PRIVATE_MESSAGE';
export const GET_CONTACT_MESSAGES = 'GET_CONTACT_MESSAGES';
export const LOAD_MORE_CONTACT_MESSAGES = 'LOAD_MORE_CONTACT_MESSAGES';
export const RECEIVED_CONTACT_EVENT = 'RECEIVED_CONTACT_EVENT';
export const DELETE_CONTACT_MESSAGE = 'DELETE_CONTACT_MESSAGE';
export const CLEAR_CONTACT_REDUCER = 'CLEAR_CONTACT_REDUCER';
export const OPEN_CONTACT_MESSAGE = 'OPEN_CONTACT_MESSAGE';
export const CLOSE_CONTACT_MESSAGE = 'CLOSE_CONTACT_MESSAGE';
