import {
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    OPEN_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    OPEN_REGISTRATION_MODAL,
    CLEAR_AUTH_REDUCER,
    SIGNUP_SUCCESS,
    INITIALIZE_PUSH_SERVICE
} from '../action-types';

const initialState = {
    isLoggedIn: false,
    isLoginModalOpen: false,
    isRegistration: false,
    isSignUpSession: false,
    userID: null,
    pushToken: null
};

export default (state = { ...initialState }, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                userID: payload.userID
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                isSignUpSession: true,
                userID: payload.userID
            };
        case INITIALIZE_PUSH_SERVICE:
            return {
                ...state,
                pushToken: payload.token
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
                pushToken: null,
                userID: null
            };
        case OPEN_LOGIN_MODAL:
            return {
                ...state,
                isLoginModalOpen: true,
                isRegistration: false
            };
        case OPEN_REGISTRATION_MODAL:
            return {
                ...state,
                isLoginModalOpen: true,
                isRegistration: true
            };
        case CLOSE_LOGIN_MODAL:
            return {
                ...state,
                isLoginModalOpen: false,
                isRegistration: false,
            };
        case CLEAR_AUTH_REDUCER:
            return { ...initialState };
        default:
            return state;
    }
};
