import React, { PureComponent } from 'react';

import PropTypes from "prop-types";
import Picker from './Picker';
import { View } from '../base';
import { withStyles } from "../../theme";

class ScrollPicker extends PureComponent {

    /**
     * @param data
     * @param selectedIndex
     */
    onValueChange = (data, selectedIndex) => {
        if(this.props.onSelectionChange) {
            const keys = this.props.options.map(element => element.key);
            this.props.onSelectionChange(keys[selectedIndex]);
        }
    };

    render () {

        const {
            theme,
            styles,
            options,
            selectedKey,
            style
        } = this.props;

        const hasHeading = options && options[0] && options[0].heading,
            keys = options.map(element => element.key),
            itemStyle = hasHeading ? styles.itemWithHeadingStyle : styles.itemStyle,
            selectedIndex = keys.indexOf(selectedKey) > -1 ? keys.indexOf(selectedKey) : 0;

        return (
            <View style={[styles.wrapper, style]}>
                <Picker
                    dataSource={options}
                    selectedIndex={selectedIndex}
                    onValueChange={this.onValueChange}
                    wrapperHeight={theme.SIZES.SELECT_HEIGHT}
                    itemStyle={itemStyle}
                    itemHeadingStyle={styles.itemHeading}
                    wrapperWidth={theme.SIZES.SCREEN_WIDTH}
                    highlightWidth={theme.SIZES.SCREEN_WIDTH}
                    activeItemTextStyle={styles.activeItemText}
                    activeItemHeadingStyle={styles.activeItemHeading}
                    itemTextStyle={styles.itemText}
                    itemHeight={hasHeading ? 64 : 36}
                    highlightColor={theme.COLORS.BACKGROUND_ITEM}
                    highlightBorderWidth={1}
                />
            </View>
        );
    }
}

ScrollPicker.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelectionChange: PropTypes.func,
    containerStyle: PropTypes.object
};

export default withStyles(ScrollPicker, theme => ({
    wrapper: {
        width: theme.SIZES.SCREEN_WIDTH,
        height: theme.SIZES.SELECT_HEIGHT,
        backgroundColor: theme.COLORS.BACKGROUND
    },
    itemStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 36
    },
    itemWithHeadingStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 64,
    },
    itemText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 16,
        fontWeight: '400',
        opacity: 0.7
    },
    activeItemText: {
        color: theme.COLORS.TEXT,
        fontSize: 16,
        fontWeight: '400'
    },
    itemHeading: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 16,
        fontWeight: '700',
        opacity: 0.7
    },
    activeItemHeading: {
        color: theme.COLORS.TEXT,
        fontSize: 16,
        fontWeight: '700'
    }
}));
