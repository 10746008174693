import React from 'react';

import { TextInput, StyleSheet } from 'react-native';
import { withStyles } from "../../theme";
import normalize from "../../utils/normalizeText";

export default withStyles(({ theme, styles, style, inputRef, ...restProps }) => {
    const flattenStyles = StyleSheet.flatten(style);
    if(flattenStyles && flattenStyles.fontSize) {
        // flattenStyles.fontSize = normalize(flattenStyles.fontSize);
    }
    return (
        <TextInput
            ref={inputRef}
            autoCorrect={false}
            autoCapitalize={'none'}
            multiline={false}
            keyboardAppearance={theme.COLORS.KEYBOARD}
            style={[
                (flattenStyles && styles[flattenStyles.fontWeight] ? styles[flattenStyles.fontWeight] : styles.normal),
                flattenStyles
            ]}
            {...restProps}
        />
    );
}, theme => ({
    normal: {
        fontFamily: theme.FONTS.OPEN_SANS['normal'],
        letterSpacing: theme.SIZES.PLATFORM_WEB === 'ios' ? -.6 : 0
    },
    bold: {
        fontFamily: theme.FONTS.OPEN_SANS['bold'],
        letterSpacing: theme.SIZES.PLATFORM_WEB === 'ios' ? -1 : 0
    },
    100: {
        fontFamily: theme.FONTS.OPEN_SANS['100']
    },
    300: {
        fontFamily: theme.FONTS.OPEN_SANS['300'],
        letterSpacing: theme.SIZES.PLATFORM_WEB === 'ios' ? -.6 : 0
    },
    500: {
        fontFamily: theme.FONTS.OPEN_SANS['500'],
        letterSpacing: theme.SIZES.PLATFORM_WEB === 'ios' ? -.9 : 0
    },
    700: {
        fontFamily: theme.FONTS.OPEN_SANS['700'],
        letterSpacing: theme.SIZES.PLATFORM_WEB === 'ios' ? -1 : 0
    },
    900: {
        fontFamily: theme.FONTS.OPEN_SANS['900'],
        letterSpacing: theme.SIZES.PLATFORM_WEB === 'ios' ? -1.1 : 0
    }
}));
