import React, { PureComponent } from 'react';

import { Text, View, Avatar, Pressable } from '../../components/base';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";

class ContactItem extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            avatar_normal,
            nickname,
            messages_count,
            intl,
            onPress,
            onLongPress,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                <Pressable onPress={onPress} onLongPress={onLongPress} style={styles.content}>
                    <Avatar style={styles.avatarImage} avatarUrl={avatar_normal} />
                    <View style={styles.textContent}>
                        <Text style={styles.titleText} numberOfLines={1}>
                            @{nickname}
                        </Text>
                        <Text style={styles.subTitleText} numberOfLines={1}>
                            {messages_count > 0
                                ? intl.formatMessage({ id: 'contacts.messages_count' }, { count: messages_count })
                                : intl.formatMessage({ id: 'contacts.no_messages' })}
                        </Text>
                    </View>
                </Pressable>
            </View>
        );
    }
}

export default injectIntl(withStyles(ContactItem, theme => ({
    wrapper: {
        overflow: 'hidden',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 17.5
    },
    avatarImage: {
        width: 50,
        height: 50,
        marginRight: 10,
        borderRadius: 17.5
    },
    textContent: {
        marginVertical: 0,
    },
    titleText: {
        color: theme.COLORS.TEXT,
        fontSize: 16,
        lineHeight: 25,
        height: 25,
        fontWeight: '900',
        textAlign: 'left',
        paddingRight: 60
    },
    subTitleText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 14,
        lineHeight: 20,
        height: 20,
        fontWeight: '500',
        textAlign: 'left',
        paddingRight: 60
    },
    content: {
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 12.5,
        flexDirection: 'row',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    }
})));
