import React, { PureComponent } from 'react';

import { Text, View, Avatar, Pressable, Icon } from '../../components/base';
import { Button } from '../../components';
import { withStyles } from "../../theme";
import { injectIntl } from "react-intl";

class ContactRequestItem extends PureComponent {

    render() {

        const {
            theme,
            styles,
            style,
            avatar_normal,
            nickname,
            onPress,
            onPressAdd,
            onLongPress,
            intl,
            created_at,
            isLoadingSubmit,
            ...restProps
        } = this.props;

        return (
            <View {...restProps} style={[
                styles.wrapper, style
            ]}>
                <Pressable onPress={onPress} onLongPress={onLongPress} style={styles.content}>
                    <Avatar style={styles.avatarImage} avatarUrl={avatar_normal} />
                    <View style={styles.textContent}>
                        <Text style={styles.titleText} numberOfLines={1}>
                            @{nickname}
                        </Text>
                        <Text style={styles.subTitleText} numberOfLines={1}>
                            {intl.formatRelative(created_at)}
                        </Text>
                    </View>
                    <View style={styles.addButtonWrapper}>
                        <Button loading={isLoadingSubmit} size='small' square onPress={onPressAdd}>
                            <Icon name='plus' size={14} />
                        </Button>
                    </View>
                </Pressable>
            </View>
        );
    }
}

export default injectIntl(withStyles(ContactRequestItem, theme => ({
    wrapper: {
        overflow: 'hidden',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM,
        width: '100%',
        borderRadius: 17.5
    },
    avatarImage: {
        width: 50,
        height: 50,
        marginRight: 10,
        borderRadius: 17.5
    },
    textContent: {
        marginVertical: 0,
    },
    titleText: {
        color: theme.COLORS.TEXT,
        fontSize: 16,
        lineHeight: 25,
        height: 25,
        fontWeight: '900',
        textAlign: 'left',
        paddingRight: 90
    },
    subTitleText: {
        color: theme.COLORS.SECONDARY_TEXT,
        fontSize: 14,
        lineHeight: 20,
        height: 20,
        fontWeight: '500',
        textAlign: 'left',
        paddingRight: 90
    },
    content: {
        alignItems: 'center',
        paddingHorizontal: 15,
        paddingVertical: 12.5,
        flexDirection: 'row',
        backgroundColor: theme.COLORS.BACKGROUND_ITEM
    },
    addButtonWrapper: {
        position: 'absolute',
        right: 20
    }
})));
