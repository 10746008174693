import React from 'react';

import { Feather } from '@expo/vector-icons';
import { StyleSheet } from "react-native";
import normalize from "../../utils/normalizeText";

export default ({ style, ...restProps }) => {
    const flattenStyles = StyleSheet.flatten(style);
    if(flattenStyles && flattenStyles.fontSize) {
        // flattenStyles.fontSize = normalize(flattenStyles.fontSize);
    }
    return (
        <Feather {...restProps} style={flattenStyles} />
    );
};
