import React, { Component } from 'react';

import { View, Text, Modal } from "../../components/base";
import { withStyles } from "../../theme";
import { withNavigation } from 'react-navigation';
import { Button, Checkbox, TextInput } from "../../components";
import { ifIphoneX } from "react-native-iphone-x-helper";
import { banChannelMember } from "../../state/channel/actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Validator from '../../utils/validator';

class BanUserModal extends Component {

    state = {
        isLoading: false,
        form: {
            reason: '',
            duration: '',
            delete_messages: false,
            ban1: false,
            ban2: false
        },
        errors: {},
    };

    render() {

        const { form, errors, isLoading } = this.state;
        const { theme, styles, intl, visible, banUser, isAdmin } = this.props;

        if(banUser === null) {
            return null;
        }

        return (
            <Modal
                visible={visible}
                onRequestClose={this.onClose}
                avoidKeyboard
            >
                <View style={styles.bottomContainer}>
                    <Text style={styles.headingText} numberOfLines={1}>
                        {intl.formatMessage({ id: 'topic_members.ban_user' })}: <Text style={styles.headingNicknameText}>@{banUser.user_nickname}</Text>
                    </Text>
                    <View style={[styles.formField, { marginTop: 0 }]}>
                        <Text style={styles.formLabelText}>
                            {intl.formatMessage({ id: 'topic_members.duration_hours' })}:
                        </Text>
                        <TextInput
                            style={styles.formInput}
                            placeholder={intl.formatMessage({ id: 'topic_members.hours_placeholder' })}
                            icon={'clock'}
                            error={errors.duration}
                            keyboardType={'number-pad'}
                            value={form.duration}
                            onChangeText={(duration) => this.setState({ form: { ...form, duration } })}
                            variation={'default'}
                        />
                    </View>
                    <View style={styles.formField}>
                        <Text style={styles.formLabelText}>
                            {intl.formatMessage({ id: 'topic_members.reason' })}:
                        </Text>
                        <TextInput
                            style={styles.formInput}
                            error={errors.reason}
                            placeholder={intl.formatMessage({ id: 'topic_members.reason_placeholder' })}
                            value={form.reason}
                            onChangeText={(reason) => this.setState({ form: { ...form, reason } })}
                            variation={'default'}
                        />
                    </View>
                    <View style={styles.formField}>
                        <Checkbox
                            style={styles.formInputSwitch}
                            labelStyle={styles.formLabelText}
                            variation={'switch'}
                            value={form.delete_messages}
                            onChange={(delete_messages) => this.setState({ form: { ...form, delete_messages } })}
                            label={intl.formatMessage({ id: 'topic_members.delete_all_user_messages' })}
                        />
                        {isAdmin && (
                            <Checkbox
                                style={styles.formInputSwitch}
                                labelStyle={styles.formLabelText}
                                variation={'switch'}
                                value={form.ban1}
                                onChange={(ban1) => this.setState({ form: { ...form, ban1 } })}
                                label={intl.formatMessage({ id: 'topic_members.ban_device' })}
                            />
                        )}
                        {isAdmin && (
                            <Checkbox
                                style={styles.formInputSwitch}
                                labelStyle={styles.formLabelText}
                                variation={'switch'}
                                value={form.ban2}
                                onChange={(ban2) => this.setState({ form: { ...form, ban2 } })}
                                label={intl.formatMessage({ id: 'topic_members.ban_ip_address' })}
                            />
                        )}
                    </View>
                    <Button loading={isLoading} onPress={this.onPressSubmit} size={'medium'} style={styles.acceptButton}>
                        {intl.formatMessage({ id: 'topic_members.submit_ban' })}
                    </Button>
                    <Button onPress={this.onClose} size={'medium'} variation={'transparent'} style={styles.cancelButton}>
                        <Text style={styles.cancelButtonText}>{intl.formatMessage({ id: 'common.cancel' })}</Text>
                    </Button>
                </View>
            </Modal>
        );
    };

    onPressSubmit = async () => {
        this.setState({ isLoading: true, errors: {} });
        const validator = new Validator([
            ['reason', Validator.rules.NOT_EMPTY],
            ['duration', Validator.rules.NOT_EMPTY],
            ['reason', Validator.rules.LENGTH, { min: 3, max: 60 }],
            ['duration', Validator.rules.INTEGER, { min: 1, max: 10000000 }],
        ], this.state.form);
        const { onConfirm } = this.props,
            { topicID, user_id } = this.props.banUser,
            { reason, duration, delete_messages, ban1, ban2 } = this.state.form;
        if(await validator.validate()) {
            const { success, errors } = await this.props.banChannelMember(
                topicID, user_id, duration, reason, delete_messages, ban1, ban2
            );
            if(success) {
                onConfirm(success);
                this.setState({
                    form: {
                        reason: '',
                        duration: '',
                        delete_messages: false,
                        ban1: false,
                        ban2: false
                    }
                });
            } else if(errors) {
                validator.setErrors(errors);
                await this.setState({
                    errors: validator.getIntlErrorsMessages(this.props.intl)
                });
            }
        } else {
            await this.setState({ errors: validator.getIntlErrorsMessages(this.props.intl) });
        }
        this.setState({ isLoading: false });
    };

    onClose = async () => {
        if(this.props.onClose) {
            return this.props.onClose();
        }
        return null;
    };
}


const mapStateToProps = ({}) => ({
});

const mapDispatchToProps = dispatch => ({
    banChannelMember: (channelID, userID, duration, reason, deleteMessages, ban1, ban2) => dispatch(banChannelMember(channelID, userID, duration, reason, deleteMessages, ban1, ban2))
});

export default connect(mapStateToProps, mapDispatchToProps)(
    withNavigation(injectIntl(withStyles(BanUserModal, theme => ({
        wrapper: {
            padding: 10,
            alignItems: 'center'
        },
        lightboxWrapper: {
            position: 'absolute',
            width: theme.SIZES.SCREEN_WIDTH,
            height: theme.SIZES.SCREEN_HEIGHT,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: theme.COLORS.BACKGROUND,
            elevation: 2
        },
        imageViewer: {
            height: '50%'
        },
        image: {
            width: 22,
            height: 22,
            borderRadius: 11,
            borderWidth: 1.5
        },
        closeImage: {
            alignItems: 'flex-end',
            justifyContent: 'center',
            width: 100,
            height: 60,
            position: 'absolute',
            right: 20,
            top: 30,
            opacity: .85
        },

        modalWrapper: {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',

            backgroundColor: 'rgba(0,0,0,0.56)',
        },
        fadeWrapper: {
            flex: 1,
        },
        bottomContainer: {
            backgroundColor: theme.COLORS.BACKGROUND,
            paddingTop: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            borderTopRightRadius: 25,
            borderTopLeftRadius: 25,
            paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            ...ifIphoneX({
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN + 20,
            }, {
                paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN,
            })
        },
        headingText: {
            textAlign: 'center',
            color: theme.COLORS.TEXT,
            fontWeight: '900',
            fontSize: 19,
            paddingHorizontal: 10,
            paddingBottom: theme.SIZES.CONTENT_HORIZONTAL_MARGIN
        },
        headingNicknameText: {
            color: theme.COLORS.SECONDARY_TEXT,
            fontWeight: '900',
            fontSize: 19,
        },
        formWrapper: {
            width: '100%',
            paddingHorizontal: theme.SIZES.CONTENT_HORIZONTAL_PADDING
        },
        formContentWrapper: {
            paddingBottom: 10
        },
        formField: {
            marginTop: 15,
        },
        formLabelText: {
            marginLeft: 9,
            marginVertical: 3,
            fontSize: 14,
            color: theme.COLORS.SECONDARY_TEXT,
            fontWeight: '700'
        },
        formInput: {

        },
        acceptButton: {
            marginTop: 25
        },
        cancelButton: {
            marginTop: 15
        },
        cancelButtonText: {
            color: theme.COLORS.SECONDARY_TEXT,
            fontWeight: '900'
        }
    }))))
);


